import edit from "../../assets/edit.png";
import remove from "../../assets/delete.png";

export const getDotMenu = (callback, tenantId) => {
  return [{
    icon: edit,
    type: "link",
    name: "Edit tenant",
    to: `/edit-tenant/${tenantId}/tenant-details/`
  }, {
    icon: remove,
    name: "Delete tenant",
    click: () => {
      callback(tenantId, "delete");
    }
  }];
};