import {isEmptyList} from "./index";

export const getUpdatedSpecialistPayload = (
  assigningLeader, selectedSpecialist, existingLeader, existingSpecialist
) => {
  const returnArr = [];
  const uniqueSet = new Set();
  if(selectedSpecialist && !isEmptyList(selectedSpecialist)){
    if(!assigningLeader && existingLeader){
      returnArr.push({
        id: existingLeader.id,
        isLeader: true
      });
      uniqueSet.add(existingLeader.id);
    }

    selectedSpecialist.forEach(({id}) => {
      if(!uniqueSet.has(id)){
        returnArr.push({
          id,
          isLeader: assigningLeader
        });
        uniqueSet.add(id);
      }
    });

    if(assigningLeader && existingSpecialist){
      existingSpecialist.forEach(el => {
        const {id, isLeader} = el;
        if(!uniqueSet.has(id) && !isLeader){
          returnArr.push({
            id,
            isLeader: false
          });
          uniqueSet.add(id);
        }
      });
    }
  }

  return returnArr;
};
