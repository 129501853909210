import React from "react";

import {ReactComponent as Info} from "../../assets/info.svg";

const ConfirmExit = (props) => {
  const {onConfirm, close} = props;
  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="w-60 bg-white rounded relative max-w-screen-md m-auto p-5 text-center">
        <Info className="w-7 h-7 m-auto mb-4 text-purple-light"/>
        <h3 className="text-medium font-bold">Exiting Workflow</h3>
        <p
          className="text-text-gray mt-3 mb-5 text-xs leading-4 px-2"
        >Are you sure, you want to exit this workflow without completing?</p>
        <div className="flex items-center justify-center">
          <button
            className="mr-4 text-purple-light font-medium text-xs"
            onClick={close}
          >Cancel</button>
          <button
            className="primary-btn"
            onClick={() => {
              close();
              onConfirm();
            }}
          >Exit</button>
        </div>
      </div>
    </div>
  )
};

export default ConfirmExit;
