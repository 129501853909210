import React, {Fragment, useState, useEffect} from "react";
import Form from "../common/form";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {getSelectTenantConfig} from "./configs/selectTenant.config";

const SelectTenant = (props) => {
  const {onValidate, onStepUpdate, stepData = {}, stepId, setLabelObject} = props;
  const [tenantData, setTenantData] = useState([]);

  const {getAllTenants} = API_PATH;
  const {data, loading} = useRequest(getAllTenants);
  useEffect(() => {
    if(data && !loading){
      const {result = []} = data;
      const allTenants = result.map(({name: label, id: value}) => ({
        label,
        value
      }));
      setTenantData(allTenants);

      const labelObj = allTenants.reduce((data, tenant) => {
        const {label, value} = tenant
        data[value] = label;
        return data;
      }, {tenant: "Tenant name", tenantUserId: "Consulting Doctor"});
      setLabelObject(lObj => ({...lObj, [stepId]: labelObj}))
    }
  }, [data, loading, setLabelObject, stepId]);

  const formConfig = getSelectTenantConfig(tenantData);

  return (
    <Fragment>
    {!loading && tenantData.length > 0 && 
      <Form
        config={formConfig}
        initialValues={stepData}
        multiColumn={true}
        onFormValid={(isFormValid) => {
          onValidate(stepId, isFormValid);
        }}
        onFormUpdate={(stepData) => {
          onStepUpdate(stepData)
        }}
      />
    }
    </Fragment>
  );
};

export default SelectTenant;
