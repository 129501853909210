import React, {useEffect, useRef} from "react";
import Note from "./note";
import moment from "moment";

const Events = (props) => {
  const {
    id, name, description, fromDate, toDate, patient, speciaListId,
    setSelectedEventData, selectedEventData, showNote, eventNoteData, 
    callback, setShowVMTBSummary
  } = props;
  const eventRef = useRef();
  const showDate = (fromDate === toDate) ? moment(fromDate).format("DD MMM YYYY") : 
    `${moment(fromDate).format("DD MMM YYYY")} - ${moment(toDate).format("DD MMM YYYY")}`;
  const isSelected = selectedEventData && selectedEventData.id === id;
  const noteData = eventNoteData[id] || {};

  useEffect(() => {
    if(isSelected && eventRef.current){
      eventRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [selectedEventData, isSelected]);

  const scrollEventToView = (id) => {
    const elm = document.getElementById(`event-details-${id}`);
    if(elm){
      setTimeout(() => {
        elm.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      });
    }
  };

  return (
    <div
      className={`relative border-b border-text-gray border-dashed tracking-wider px-2
      ${isSelected ? " bg-purple-l/10" : ""}`}
      ref={eventRef}
    >
      <div
        className="py-2 text-xs relative"
        onClick={() => {
          setSelectedEventData({
            id, name, description, fromDate, toDate, patient
          });
          setShowVMTBSummary(false);
          scrollEventToView(id);
        }}
      >
        <div
          className={
            `absolute w-2 h-2 border-t border-r transition duration-200
            border-${isSelected ? "sky" : "gray"} right-3 top-4 
            ${isSelected ? "rotate-135" : "rotate-45"}`
          }
        />
        <div className="cursor-pointer font-medium">
          <div
            className={`uppercase ${isSelected ? "text-sky" : "text-text-gray"}`}
          >{showDate}</div>
          <div
            className={`${isSelected ? "text-sky" : "text-black"} mt-1`}
          >{name}</div>
        </div>
        <div className={!isSelected ? "hidden" : ""}>
          <div className="mt-3">
            <div 
              className="text-text-gray leading-tight html-body"
              dangerouslySetInnerHTML={{__html: description}}
            />
          </div>
          {showNote && 
            <div className="mt-3">
              <Note
                callback={callback}
                note={noteData}
                title="Event notes"
                id={id}
                speciaListId={speciaListId}
                type="events"
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
};

export default Events;
