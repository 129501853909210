const ethnicity = [{
  label: "White",
  children: [{
    label: "English",
    value: "white_english",
  }, {
    label: "Welsh",
    value: "white_welsh",
  }, {
    label: "Scottish",
    value: "white_scottish",
  }, {
    label: "Northern Irish",
    value: "white_northern_irish",
  }, {
    label: "Irish",
    value: "white_irish",
  }, {
    label: "Gypsy or Irish Traveller",
    value: "white_gypsy_or_irish_traveller",
  }, {
    label: "Any other White background",
    value: "white_other",
  }]
},{
  label: "Mixed or Multiple ethnic groups",
  children: [{
    label: "Mixed White and Black Caribbean",
    value: "mixed_white_black_caribbean",
  }, {
    label: "Mixed White and Black African",
    value: "mixed_white_black_african",
  }, {
    label: "Mixed White Other",
    value: "mixed_white_other",
  }]
},{
  label: "Asian",
  children: [{
    label: "Indian",
    value: "asian_indian",
  }, {
    label: "Pakistani",
    value: "asian_pakistani",
  }, {
    label: "Bangladeshi",
    value: "asian_bangladeshi",
  }, {
    label: "Chinese",
    value: "asian_chinese",
  }, {
    label: "Any other Asian background",
    value: "asian_other",
  }]
},{
  label: "Black",
  children: [{
    label: "African",
    value: "black_african",
  }, {
    label: "African American",
    value: "black_african_american",
  }, {
    label: "Caribbean",
    value: "black_caribbean",
  }, {
    label: "Any other Black background",
    value: "black_other",
  }]
},{
  label: "Other ethnic groups",
  children: [{
    label: "Arab",
    value: "other_arab",
  },{
    label: "Hispanic",
    value: "other_hispanic",
  },{
    label: "Latino",
    value: "other_latino",
  },{
    label: "Native American",
    value: "other_native_american",
  },{
    label: "Pacific Islander",
    value: "other_pacific_islander",
  },{
    label: "Any other ethnic group",
    value: "other_other",
  }]
}];

export const formConfig = [{
  component: "textfield",
  name: "pid",
  label: "Patient ID"
}, {
  component: "paragraph",
  name: "demographic-para",
  title: "Fill out basic demographic data of the patient."
}, {
  component: "radio-group",
  name: "gender",
  label: "Gender",
  required: true,
  direction: "row",
  className: "w-68",
  children: [
    {name: "male", label: "Male"}, 
    {name: "female", label: "Female"},
    {name: "other", label: "Other"},
  ]
}, {
  component: "radio-group",
  name: "maritalStatus",
  label: "Marital status",
  required: true,
  direction: "row",
  className: "w-68",
  children: [
    {name: "married", label: "Married"}, 
    {name: "unmarried", label: "Unmarried"}
  ]
}, {
  component: "date",
  name: "dob",
  label: "Date of birth",
  required: true,
  tailComponent: <div className="uppercase pl-3 text-text-gray text-xs">or</div>,
  change: (dob) => {
    if(dob){
      const today = new Date();
      return {
        dob,
        age: today.getFullYear() - dob.getFullYear()
      }
    }

    return {dob};
  }
}, {
  component: "textfield",
  name: "age",
  label: "Age",
  number: true,
  required: true,
  change: (age) => {
    if(age && age > 0){
      age = parseInt(age);
      const today = new Date();
      const tobeSetYear = today.getFullYear() - age;
      return {
        age,
        dob: new Date(tobeSetYear, 0, 1)
      }
    }

    return {age: age ? parseInt(age) : age};
  }
}, {
  component: "paragraph",
  name: "weight-height-para",
  title: "Weight and height will be used to measure your body surface area."
}, {
  component: "textfield",
  name: "weight",
  label: "Weight (Kg)",
  number: true
}, {
  component: "textfield",
  name: "height",
  label: "Height (Cm)",
  number: true
}, {
  component: "paragraph",
  name: "description",
  title: "A category of humankind that shares certain distinctive physical traits."
}, {
  component: "dropdown",
  name: "ethnicity",
  label: "Ethnicity",
  data: ethnicity.reduce((data, el) => {
    data.push(...el.children);
    return data;
  }, [])
}];

export const formSchema = {
  age: [{
    condition: value => value < 1,
    message: "Not a valid age value."
  }],
  weight: [{
    condition: value => value < 1,
    message: "Not a valid weight value."
  }],
  height: [{
    condition: value => value < 1,
    message: "Not a valid height value."
  }]
};
