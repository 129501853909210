import React, {useState, useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";

import {showAlert} from "../utils";
import Head from "../components/head";
import useRequest from "../hooks/useRequest";
import Form from "../components/common/form";
import {ReactComponent as Logo} from "../assets/logo.svg";
import {getLoginConfig, getLoginSchema} from "./configs/login.config";
import {
  API_PATH, TOKEN_KEY, REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, USERNAME_KEY
} from "../constant";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const {login} = API_PATH;
  const {username, password, newPassword} = formData;
  const {data, loading, error, errorMessage, callAPI} = useRequest(login, false, {
    username, 
    password, 
    ...(changePassword ? {newPassword} : {})
  }, "POST");
  useEffect(() => {
    if(!loading && data && data.result){
      const {idToken, refreshToken, accessToken} = data.result;
      localStorage.setItem(TOKEN_KEY, idToken);
      localStorage.setItem(USERNAME_KEY, username);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      showAlert("Sucessfully logged in!");
      navigate("/case/");
    }
  }, [loading, data, username, navigate]);

  useEffect(() => {
    if(!loading && error && errorMessage){
      if(errorMessage === "NEW_PASSWORD_REQUIRED"){
        setChangePassword(true);
        setIsFormValid(false);
        return;
      }

      const messageToShow = errorMessage.includes("(") ? errorMessage.split("(")[0] : errorMessage;
      showAlert(messageToShow, "error");
    }
  }, [loading, error, errorMessage]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if(token){
      navigate("/case/");
    }
  }, [navigate]);

  return (
    <div className="w-full h-screen dotted-background">
      <Head title={"Login"}/>
      <div className="flex h-full w-full items-center justify-center mobile:px-3">
        <div className="bg-white border border-gray w-72 rounded px-5 py-6 mobile:w-full mobile:px-4">
          <Link to="/">
            <Logo
              className="fill-white-purple m-auto"
            />
          </Link>
          <p
            className="mt-4 text-center text-text-gray mx-auto w-50 leading-tight"
          >Every patient is unique, so is their cancer and its treatment.</p>
          <div className="mt-4">
            <Form
              config={getLoginConfig(changePassword)}
              initialValues={formData}
              onFormValid={(isValid) => {
                setIsFormValid(isValid);
              }}
              onFormUpdate={(data) => {
                setFormData(data)
              }}
              schema={getLoginSchema(changePassword)}
              onSubmit={callAPI}
            />
          </div>

          <div className="my-3">
            <button 
              className="h-7 py-1 text-center rounded font-medium text-xs 
              bg-purple-light text-white disabled:opacity-50 w-full" 
              onClick={() => {
                callAPI();
              }}
              disabled={!isFormValid}
            >Login</button>
          </div>
          <div className="text-center mb-1">
            <Link
              to="/forgot-password/"
              className="text-xs text-purple hover:text-purple-light"
            >Forgot password?</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Login;