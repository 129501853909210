import Done from "../../components/createCase/done";
import Summary from "../../components/createCase/summary";
import CaseType from "../../components/createCase/caseType";
import SelectTenant from "../../components/createCase/selectTenant";
import CaseBackground from "../../components/createCase/caseBackground";
import PatientDemographic from "../../components/createCase/patientDemographic";

export const getWorkflowConfig = (isOmAdmin = false) => {
  const firstStep = [{
    id: "case-type",
    title: "Case Type",
    description: "Select the type of case you want to create.",
    component: CaseType,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        hidden: true
      }
    }
  }];
  
  const tenantSelectionStep = [{
    id: "select-tenant",
    title: "Select Tenant",
    description: "Please select tenant under whom this case will be created",
    component: SelectTenant,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        label: "Back"
      }
    }
  }];

  const otherSteps = [{
    id: "patient-demographic",
    title: "Patient Demographic",
    description: `Patient demographics form the core of the data for any medical institution. 
    They allow for the identification of a patient and his categorization into categories 
    for the purpose of statistical analysis.`,
    component: PatientDemographic,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "case-background",
    title: "Case Background",
    description: `In general, a medical history includes an inquiry into the patient's 
    medical history, past surgical history, family medical history, social history, allergies, 
    and medications the patient is taking or may have recently stopped taking.`,
    component: CaseBackground,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "summary",
    title: "Summary",
    description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Next.`,
    component: Summary,
    layout: {
      primary: {
        label: "Create Case"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "done",
    title: "Done!",
    description: "The case is being created.",
    component: Done,
    layout: {
      footer: false
    }
  }];

  return [
    ...firstStep, ...(isOmAdmin ? tenantSelectionStep : []), ...otherSteps
  ];
}
