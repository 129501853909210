import React, {useState, Fragment} from "react";

import Head from "../components/head";
import Footer from "../components/landing/footer";
import Header from "../components/landing/header";

const PrivacyPolicy = () => {
  const [policyTab, setPolicyTab] = useState("global");

  return (
    <div className="bg-bg-color w-full">
      <Head title="Privacy Policy"/>
      <Header/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Privacy policy
        </h1>
        <ul className="text-medium-l leading-snug pt-13 flex gap-x-6 border-b border-b-gray mobile:pt-10">
          <li
            className={`pb-1.5 font-bold cursor-pointer ${
              policyTab === "global" ? "text-purple border-b border-b-purple" : "text-purple-light"
            }`}
            onClick={() => setPolicyTab("global")}
          >Global</li>
          <li
            className={`pb-1.5 font-bold cursor-pointer ${
              policyTab === "india" ? "text-purple border-b border-b-purple" : "text-purple-light"
            }`}
            onClick={() => setPolicyTab("india")}
          >India</li>
        </ul>
        <div className="pt-5 text-medium font-medium leading-snug text-purple">
          {policyTab === "global" && 
            <Fragment>
              <p>Last Modified: <b>January 27, 2024</b></p>
              <p className="pt-3">
                Oncominds, Inc. ("Company" or "We") respect your privacy and are committed 
                to protecting it through our compliance with this policy.
              </p>
              <p className="pt-3">
                Our website is designed to comply with existing and proposed federal standards 
                for the privacy and confidentiality of healthcare information. The database and 
                software components are structured to provide that maintenance and routine 
                troubleshooting occur with no access to your personal information. A security system 
                helps protect against unauthorized access or misuse by authorized users.
              </p>
              <p className="pt-3">
                All forms that request personal information (name, address, e-mail address, etc.) 
                employ the https communications protocol to ensure secure and encrypted transmissions 
                over the Internet.
              </p>
              <p className="pt-3">
                All personal information collected on our site is redacted before being distributed to 
                other experts or healthcare providers. We do not share any personal information without 
                your approval, which you will explicitly grant during account creation.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Information We Collect About You and How We Collect It</h3>
              <p className="pt-4">
                We collect several types of information from and about users of 
                our website, including information:
              </p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">
                  By which you may be personally identified, such as name, postal address, e-mail address, 
                  telephone number, or any other identifier by which you may be contacted online or offline 
                  (<b>"personal information"</b>);
                </li>
                <li className="ml-4 pl-2 mt-3">That is about you but individually does not identify you; and/or</li>
                <li className="ml-4 pl-2 mt-3">
                  About your internet connection, the equipment you use to access our website, 
                  and usage details.
                  <p className="pt-3">We collect this information:</p>
                  <ul className="list-disc">
                    <li className="ml-4 pl-2 mt-3">Directly from you when you provide it to us.</li>
                    <li className="ml-4 pl-2 mt-3">
                      Automatically as you navigate through the site. Information collected automatically 
                      may include usage details, IP addresses, and information collected through cookies, 
                      web beacons, and other tracking technologies.
                    </li>
                    <li className="ml-4 pl-2 mt-3">From third parties, for example, your doctor.</li>
                  </ul>
                </li>
              </ul>
              <h3 className="font-bold text-medium-l pt-5">Information You Provide to Us.</h3>
              <p className="pt-4">
                The information we collect on or through our website may include:
              </p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">
                  Information that you provide by filling in forms on our website. 
                  This includes information provided at the time of registering to use our website, 
                  subscribing to our service, posting material, or requesting further services. We may also ask you for 
                  information when you report a problem with our website.
                </li>
                <li className="ml-4 pl-2 mt-3">Records and copies of your correspondence (including email addresses), 
                if you contact us.</li>
                <li className="ml-4 pl-2 mt-3">Your responses to surveys that we might ask you to complete 
                for research purposes.</li>
                <li className="ml-4 pl-2 mt-3">Medical information that you or your doctor provides.</li>
                <li className="ml-4 pl-2 mt-3">Your search queries on the Website.</li>
              </ul>
              <h3 className="font-bold text-medium-l pt-5">Access and Disclosure</h3>
              <p className="pt-4">
                We do not disclose or provide access to any personal information to anyone without your 
                permission, unless ordered to do so by a court of law or otherwise required by law. 
                There may be isolated instances in which the staff must access the database to repair problems. 
                Any access to individual files is carefully controlled. Staff members must document the need 
                for access, log details of the access when complete, and confirm to management that they have 
                accessed only task-critical areas and are in compliance with confidentiality policies.
              </p>
              <p className="pt-3">
                Given the nature of the services provided, the users of the System, the information that is 
                stored on the System does not have to be HIPAA compliant, and will not be.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Links to Third Party Sites</h3>
              <p className="pt-4">
                Our site may contain links to other 3rd party websites ("Linked Sites"). Links are strictly 
                for informational purposes, and the appropriateness of such links is based on the judgment 
                of the Company. The Linked Sites are most likely not under the control of Oncominds, Inc., 
                and We are not responsible for the content of any Linked Site that We do not control, 
                including without limitation any link contained in a Linked Site, or any changes or updates 
                to a Linked Site. Oncominds, Inc. is not responsible for webcasting or any other form of 
                transmission received from any Linked Site. We provide these links as a convenience, and 
                the inclusion of any link does not imply endorsement of the site by the Company or any 
                association with its operators.
              </p>
              <h3 className="font-bold text-medium-l pt-5">
                Information We Collect Through Automatic Data Collection Technologies.
              </h3>
              <p className="pt-4">
                As you navigate through and interact with our website, we may use automatic data 
                collection technologies to collect certain information about your equipment, browsing 
                actions, and patterns, including:
              </p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">
                  Details of your visits to our website, including traffic data, location data, logs, 
                  and other communication data and the resources that you access and use on the Website.
                </li>
                <li className="ml-4 pl-2 mt-3">Information about your computer and internet connection, 
                including your IP address, operating system, and browser type.</li>
              </ul>
              <p className="pt-3">The technologies we use for this automatic data collection may include:</p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">
                  <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of 
                  your computer. You may refuse to accept browser cookies by activating the appropriate 
                  setting on your browser. However, if you select this setting you may be unable to access 
                  certain parts of our website. Unless you have adjusted your browser setting so that 
                  it will refuse cookies, our system will issue cookies when you direct your browser to our website.
                </li>
                <li className="ml-4 pl-2 mt-3">
                  <b>Flash Cookies.</b> Certain features of our website may use local stored objects 
                  (or Flash cookies) to collect and store information about your preferences and navigation 
                  to, from, and on our website. Flash cookies are not managed by the same browser settings 
                  as are used for browser cookies. For information about managing your privacy and security 
                  settings for Flash cookies, see Choices About How We Use and Disclose Your Information.
                </li>
                <li className="ml-4 pl-2 mt-3">
                  <b>Web Beacons.</b> Pages of our Website and our emails may contain small electronic files 
                  known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) 
                  that permit the Company, for example, to count users who have visited those pages or opened 
                  an email and for other related website statistics (for example, recording the popularity of 
                  certain website content and verifying system and server integrity).
                </li>
              </ul>
              <p className="pt-3">
                Oncominds, Inc. does not deploy spyware or any software that aids in gathering information 
                about a person or organization without their knowledge and which may send such information 
                to another entity without their consent.
              </p>
              <h3 className="font-bold text-medium-l pt-5">
                Disclosure of Your Information
              </h3>
              <p className="pt-4">
                Oncominds, Inc. uses information collected to improve customer experience and website 
                performance. Information is collected in a HIPAA-complaint platform and is not shared with 
                third-parties for marketing purposes.
              </p>
              <h3 className="font-bold text-medium-l pt-5">
                How We Use Your Information
              </h3>
              <p className="pt-4">
                We use information that we collect about you or that you provide to us, including any 
                personal information:
              </p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">To present our website and its contents to you.</li>
                <li className="ml-4 pl-2 mt-3">To provide you with information or services that you request from us.</li>
                <li className="ml-4 pl-2 mt-3">To fulfill any other purpose for which you provide it.</li>
                <li className="ml-4 pl-2 mt-3">
                  To provide you with notices about your account, including expiration and renewal notices.
                </li>
                <li className="ml-4 pl-2 mt-3">
                  To carry out our obligations and enforce our rights arising from any contracts entered into 
                  between you and us, including for billing and collection.
                </li>
                <li className="ml-4 pl-2 mt-3">
                  To notify you about changes to our website or any products or services we offer or provide though it.
                </li>
                <li className="ml-4 pl-2 mt-3">To allow you to participate in interactive features on our website.</li>
                <li className="ml-4 pl-2 mt-3">
                  To link your doctor with other doctors and experts in the field of oncology to aid in the 
                  provision of your care.
                </li>
                <li className="ml-4 pl-2 mt-3">In any other way we may describe when you provide the information.</li>
                <li className="ml-4 pl-2 mt-3">For any other purpose with your consent.</li>
              </ul>
              <h3 className="font-bold text-medium-l pt-5">Accessing and Correcting Your Information</h3>
              <p className="pt-4">
                You can review and change your personal information by logging into the Website and visiting
                your account profile page.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Changes to Our Privacy Policy</h3>
              <p className="pt-4">
                It is our policy to post any changes we make to our privacy policy on this page. If we 
                make material changes to how we treat our users' personal information, we will notify you. 
                The date the privacy policy was last revised is identified at the top of the page. 
                You are responsible for ensuring we have an up-to-date active and deliverable email address 
                for you, and for periodically visiting our website and this privacy policy to check for any changes.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Contact Information</h3>
              <p className="pt-4">
                To ask questions or comment about this privacy policy and our privacy practices, contact us at: 
                <a 
                  className="text-sky-blue underline ml-1" 
                  href="mailto:contact@oncominds.com" 
                  target="_blank"
                  rel="noopener noreferrer"
                >contact@oncominds.com</a>
              </p>
            </Fragment>
          }
          {policyTab === "india" && 
            <Fragment>
              <p>Last Modified: <b>January 27, 2024</b></p>
              <p className="pt-3">
                The Oncominds Platform (the <b>“Platform”</b>) is developed by Oncominds, Inc. 
                The Platform enables peer-to-peer oncology consultations and collaborations between on-boarded 
                oncologists from India and expert oncology professionals from leading institutions 
                in the United States and the rest of the world and for this purpose has partnered with 
                a with Delaware based Oncology health-tech company, Oncominds, Inc.
              </p>
              <p className="pt-3">
                BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO 
                HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY 
                POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, 
                USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
                WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS 
                PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THE 
                TERMS OF THIS PRIVACY POLICY, THEN WE RECOMMEND THAT YOU DO NOT USE THE PLATFORM OR ITS SERVICES.
              </p>
              <p className="pt-3">
                In this privacy policy, you, your and its other variations will include a medical 
                practitioner registered on the Platform (the “Practitioner”) or could also include an 
                oncology patient or his/ her legal guardian who has consented to providing personal 
                information of such patient in order to avail the services provided by the Platform, 
                which can be availed through the registered medical practitioner. 
              </p>
              <h3 className="font-bold text-medium-l pt-5">Information that we collect</h3>
              <p className="pt-3">
                By using our Platform, you agree to this Privacy Policy and the collection and use of 
                information contemplated hereby. 
              </p>
              <p className="pt-3">
                This Privacy Policy supersedes any earlier version. When you register on the Platform, 
                we may ask you for personal information (“Personal Information”) such as your name, home 
                or other physical address, email address and password, phone number, age information, 
                credit card or other payment information etc. We will also collect certain sensitive personal 
                information such as medical history, case reports, scans, medical reports etc the Practitioner 
                only subject to your (the patient's) prior consent. You may also be asked to choose a unique 
                username, a password, a security question and an answer to the security question. If you 
                choose not to provide requested Personal Information, you may not be able to use the Services 
                provided by the Platform.
              </p>
              <p className="pt-3">
                In addition, we may collect information regarding the browser type, browser language, 
                referring URL, files accessed, errors generated, operating system, usage of our services, 
                Internet Protocol address. In case of certain Services requiring payment, we may request 
                credit card or other payment account information, which will be used solely for processing 
                payments and shall not be stored on our system. The personal information that you provide will 
                be kept confidential and used to support your relationship with Oncominds and to provide 
                our services.
              </p>
              <p className="pt-3">
                If any information including the Personal Information that has been submitted by you is 
                no longer accurate, current, or complete, then we reserve the right to disallow you to use 
                the Platform unless you update your accurate and up-to-date information.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Cookies</h3>
              <p className="pt-3">
                Oncominds may use cookies, device fingerprinting, open device identifier number or 
                similar technologies to collect the information described above. Oncominds may also 
                use the above mentioned technologies to: (i) provide customized content ; 
                (ii) monitor usage; (iii) conduct research to improve the content and Services.
              </p>
              <p className="pt-3">
                Oncominds also allows other companies, medical entities and other medical practitioners 
                such as web/mobile analytics partners to set or access their cookies or web beacons or 
                similar technologies on the Platform. For example, Oncominds may use third party mobile 
                analytics partners to analyse big-data on its behalf. These third-party analytics 
                partners employ cookies, web beacons and other similar technologies to measure and 
                analyse the big-data. To do so, these partners may use information about the use of the 
                Platform, which information will be anonymised such that it cannot reasonably be used to 
                identify a user of the Platform.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Collection Purposes, Use of Data</h3>
              <p className="pt-3">
                We use any Personal Information that we collect and other data (account usage and 
                technical information) that is collected for the following purposes:
              </p>
              <ul className="list-disc">
                <li className="ml-4 pl-2 mt-3">To deliver the Services made available through the Platform;</li>
                <li className="ml-4 pl-2 mt-3">For research and consultation purposes;</li>
                <li className="ml-4 pl-2 mt-3">To administer, protect and improve the Platform;</li>
                <li className="ml-4 pl-2 mt-3">To facilitate receipt of payments made through the Platform;</li>
                <li className="ml-4 pl-2 mt-3">To better understand the preferences of users of the Platform;</li>
                <li className="ml-4 pl-2 mt-3">To identify server problems;</li>
                <li className="ml-4 pl-2 mt-3">To compile aggregated statistics about usage of the Platform;</li>
                <li className="ml-4 pl-2 mt-3">To help personalize the experience of the users on the Platform;</li>
                <li className="ml-4 pl-2 mt-3">To improve Services offered;</li>
                <li className="ml-4 pl-2 mt-3">To help Oncominds in its internal business functioning;</li>
                <li className="ml-4 pl-2 mt-3">To provide information in regard with the technologies, 
                product or Service releases, news, and other communications;</li>
                <li className="ml-4 pl-2 mt-3">To provide information and assistance to external institutions 
                as and when required under the applicable laws in India.</li>
                <li className="ml-4 pl-2 mt-3">Any other use that Oncominds may describe at the 
                point when it collects data.</li>
              </ul>
              <p className="pt-3">
                The Platform is offered and made available in India. If you are a user accessing the Platform
                from the United States of America, European Union, or any other region with laws or 
                regulations governing personal data collection, use, and disclosure, that differ from 
                Indian laws, please be advised that through your continued use of the Platform, which is 
                governed by Indian law, you are transferring your personal information to India and you 
                consent to that transfer and to the collection and usage of your personal information in 
                accordance with the laws of the Republic of India.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Disclosures of Personal & Other Information</h3>
              <p className="pt-3">
                Oncominds may share or transfer information that has been disclosed by you or 
                information that is collected through the Platform only for the following purposes: 
                (i) to third parties who process the data on its behalf for the purposes set forth in this 
                Policy and in the Platform Terms of use; (ii) to the approved payment processing provider(s), 
                to facilitate the processing of any Service requests by its users; (iii) for evaluation, 
                research and consultation (iv) to identified partners for their business use (including the 
                supply of Services requested by the User), when the Users subscribe to Services of the 
                Platform, Service; (v) as required by law or in the interest of protecting or exercising 
                the Company's or others' legal rights, e.g., without limitation, in connection with 
                requests from law enforcement officials and in connection with court proceedings; 
                and (vi) to partners of the Company who specialize in Big Data analytics, marketing and 
                advertising, for use of the data in their businesses on an anonymized and de-identified 
                basis such that it cannot reasonably be used to identify the User. 
              </p>
              <h3 className="font-bold text-medium-l pt-5">Security</h3>
              <p className="pt-3">
                Transmissions over the Internet are never 100% secure or error-free. However, 
                Oncominds takes reasonable steps to protect Personal Information of its Users from loss, 
                misuse, and unauthorized access, disclosure, alteration, and destruction. 
              </p>
              <h3 className="font-bold text-medium-l pt-5">Amendments</h3>
              <p className="pt-3">
                Oncominds reserves the right to change, modify, add or remove portions of this Policy 
                at any time, but will alert its users about any changes that have been made by indicating 
                on the Policy the date it was last updated. When any User uses the Platform, they are 
                automatically accepting the current version of this Policy as posted on the Platform at 
                that time. It is recommended that users revisit this Policy on occasion to learn of any 
                changes. 
              </p>
              <h3 className="font-bold text-medium-l pt-5">Choice/Opt outs</h3>
              <p className="pt-3">
                Oncominds gives its Users the choice regarding the collection and usage of other 
                personally identifiable information. During registration/enrolment, Oncominds request 
                the contact information in order to send bulletins and for advertising purposes. 
                The Users may therefore choose to opt out of providing such information.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Contact Us (Grievance Officer)</h3>
              <p className="pt-3">
                If any of the Users have any questions, queries or concerns about this Policy 
                or any complaints or grievances about the manner in which Oncominds handles the 
                personal information of its Users (in doing so acknowledging that Oncominds may 
                be unable to provide all or some of the Services), the Users can contact the 
                Grievance Officer any time at: 
              </p>
              <p className="pt-3">
                <b>Contact Details:</b>
                <a 
                  className="text-sky-blue underline ml-1" 
                  href="mailto:grievance@oncominds.com" 
                  target="_blank"
                  rel="noopener noreferrer"
                >grievance@oncominds.com</a>
              </p>
              <p className="pt-3">
                THE USE OF THE PLATFORM OR ANY PART OF THE SERVICES ON THE PLATFORM, EITHER IN 
                WHOLE OR IN PART, SIGNIFIES YOUR AGREEMENT TO THIS PRIVACY POLICY.
              </p>
            </Fragment>
          }
        </div>
        <div className="pt-30 mobile:pt-15">
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
