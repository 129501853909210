import CaseType from "../../components/createCase/caseType";
import PatientDemographic from "../../components/createCase/patientDemographic";

import Done from "../../components/editCase/done";
import Summary from "../../components/editCase/summary";
import CaseBackground from "../../components/editCase/caseBackground";

export const getWorkflowConfig = () => {
  return [{
    id: "case-type",
    title: "Case Type",
    description: "Select the type of case you want to edit.",
    component: CaseType,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        hidden: true
      }
    }
  }, {
    id: "patient-demographic",
    title: "Patient Demographic",
    description: `Patient demographics form the core of the data for any medical institution. 
    They allow for the identification of a patient and his categorization into categories 
    for the purpose of statistical analysis.`,
    component: PatientDemographic,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "case-background",
    title: "Case Background",
    description: `In general, a medical history includes an inquiry into the patient's 
    medical history, past surgical history, family medical history, social history, allergies, 
    and medications the patient is taking or may have recently stopped taking.`,
    component: CaseBackground,
    layout: {
      primary: {
        label: "Next"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "summary",
    title: "Summary",
    description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Next.`,
    component: Summary,
    layout: {
      primary: {
        label: "Edit Case"
      },
      secondary: {
        label: "Back"
      }
    }
  }, {
    id: "done",
    title: "Done!",
    description: "The case is being edited.",
    component: Done,
    layout: {
      footer: false
    }
  }];
};

export const createStepData = (caseData) => {
  const {
    caseType, priority, patient, stage: cancerStage, 
    cancerType, cancerSubType, description: summary
  } = caseData;
  const {
    id: patientId, gender, maritalStatus, height, weight, ethnicity, age, dob, pid
  } = patient;

  return {
    "case-type": {
      caseType,
      priority: priority === "high"
    },
    "patient-demographic": {
      pid,
      gender,
      maritalStatus, 
      age,
      dob: new Date(dob),
      height, 
      weight, 
      ethnicity
    },
    "case-background": {
      cancerType,
      cancerSubType,
      cancerStage,
      summary
    },
    "done": {
      patientId
    }
  }
};
