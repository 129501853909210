import React, {useEffect, useState, Fragment} from "react";
import {Link} from "react-router-dom";
import {createPayload} from "./configs/done.config";
import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

import {API_PATH} from "../../constant";
import {useContextData} from "../../store";
import useRequest from "../../hooks/useRequest";
import {formatString, isOmAdmin, showAlert} from "../../utils";

const Done = (props) => {
  const {getState} = props;
  const stateData = getState();

  const {userRoles = [], tenantId} = useContextData();
  const [caseId, setCaseId] = useState("");
  const [patientId, setPatientId] = useState("");

  const {createCase} = API_PATH;
  const payload = createPayload(stateData);
  const omAdmin = isOmAdmin(userRoles);
  const tenantID = omAdmin ? stateData["select-tenant"].tenant : tenantId;
  const {
    loading: caseLoading, callAPI: callCaseAPI, data: caseData, error, errorMessage
  } = useRequest(formatString(createCase, [tenantID]), false, payload, "POST");
  useEffect(() => {
    if(caseData && !caseLoading && caseData.result) {
      const {patient, id} = caseData.result;
      if(patient && patient.id){
        setCaseId(id);
        setPatientId(patient.id);
        showAlert("The case has been created successfully!");
      }
    }
  }, [caseData, caseLoading]);

  useEffect(() => {
    if(error && errorMessage){
      showAlert(errorMessage, "error");
    }
  }, [error, errorMessage]);

  useEffect(() => {
    callCaseAPI();
  }, [callCaseAPI]);

  const caseMessage = caseLoading ? "Case creation in progress..." : (
    error ? "Case creation has failed!" : "Case has been created successfully!"
  );
  
  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {caseLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!caseLoading && 
            <Fragment>
              {!error && <Success className="w-4 h-4"/>}
              {error && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {patientId &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            {omAdmin &&
              <div className="pt-3">
                <Link 
                  className="primary-btn" 
                  to={`/upload-report/${patientId}/${caseId}/`}
                >Upload file for the created case</Link>
              </div>
            }
            <div className={!omAdmin ? "pt-3" : "pt-5"}>
              <Link
                className={!omAdmin ? "primary-btn inline-block" : "text-purple-light hover:text-purple"}
                to={`/case/${patientId}/${caseId}/`}
              >Go to case details</Link>
            </div>
            <div className={!omAdmin ? "pt-2" : "pt-5"}>
              <Link className="text-purple-light hover:text-purple" to="/case/">View all Cases</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
