import React, {useState, useEffect, forwardRef, useImperativeHandle} from "react";

import File from "./file";
import Switch from "./switch";
import CustomDate from "./date";
import Address from "./address";
import Dropdown from "./dropdown";
import Repeater from "./repeater";
import TextArea from "./textarea";
import TextField from "./textfield";
import RadioCheckboxGroup from "./radioCheckboxGroup";

import {isEmptyObject} from "../../../utils";
import {validateForm} from "../../../utils/formUtils";
import { useCallback } from "react";

const Form = forwardRef((props, ref) => {
  const {
    config = [], multiColumn = false, initialValues = {}, onFormValid, 
    onFormUpdate, schema = {}, onSubmit
  } = props;
  const formClass = (
    multiColumn ? "max-w-160 flex-wrap justify-start" : "justify-center flex-col"
  );
  const {initFormData, initTouchObj} = config.reduce((data, el) => {
    const {name, component} = el;
    if(component !== "title" && component !== "paragraph"){
      data.initFormData[name] = (
        initialValues[name] !== undefined ? initialValues[name] : ""
      );

      data.initTouchObj[name] = initialValues[name] !== undefined;
    }
    return data;
  }, {initFormData: {}, initTouchObj: {}});

  const [keyConfig, setKeyConfig] = useState({});
  const [formData, setFormData] = useState(initFormData);
  const [validationObj, setValidationObj] = useState({});
  const [validationCalled, setValidationCalled] = useState(false);
  const [touchedFieldObj, setTouchedFieldObj] = useState(initTouchObj);
  useEffect(() => {
    const keyConfig = config.reduce((data, el) => {
      const {name} = el;
      data[name] = {...el};
      return data;
    }, {});
    setKeyConfig(keyConfig);
  }, [config]);

  useEffect(() => {
    if(isEmptyObject(keyConfig)){
      return;
    }

    if(!validationCalled){
      setValidationCalled(true);
      const curValidation = validateForm(formData, keyConfig, schema);
      setValidationObj(curValidation);

      const keys = [...Object.keys(curValidation)];
      if(onFormValid){
        const isFormValid = keys.every(key => (
          curValidation[key].isValid || !keyConfig[key].required
        ));
        onFormValid(isFormValid);
      }
    }
  }, [formData, keyConfig, schema, validationCalled, onFormValid]);

  useImperativeHandle(ref, () => ({
    updateFormData: (dataToAdd) => {
      setFormData(formData => ({
        ...formData,
        ...dataToAdd
      }))
    }
  }));

  const onUpdate = useCallback((data) => {
    setFormData(data);
    onFormUpdate(data);
    setTimeout(() => {
      setValidationCalled(false);
    });
  }, [onFormUpdate]);

  return (
    <form
      className={`custom-form min-w-100 flex w-full ${formClass}`}
      encType="multipart/form-data"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if(onSubmit && onSubmit instanceof Function){
          onSubmit(e, formData, validationObj);
        } 
      }}
    >
      {
        config.map(el => {
          const {component, title, name, className} = el;

          if(component === "title"){
            return (
              <h3 
                key={name}
                className="w-full mt-7 mb-0 text-black text-medium"
                id={name}
              >{title}</h3>
            );
          }

          if(component === "paragraph"){
            return (
              <p 
                key={name}
                className={
                  `w-full mt-3 mb-3 text-text-gray leading-snug text-base${className ? ` ${className}` : ""}`
                }
                id={name}
              >{title}</p>
            );
          }

          if(component === "radio-group" || component === "checkbox-group"){
            return (
              <RadioCheckboxGroup
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
              />
            );
          }

          if(component === "date"){
            return (
              <CustomDate
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                validationObj={validationObj}
                touchedFieldObj={touchedFieldObj}
                setTouchedFieldObj={setTouchedFieldObj}
              />
            );
          }

          if(component === "dropdown"){
            return (
              <Dropdown
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                validationObj={validationObj}
                touchedFieldObj={touchedFieldObj}
                setTouchedFieldObj={setTouchedFieldObj}
              />
            );
          }

          if(component === "textarea"){
            return (
              <TextArea
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                validationObj={validationObj}
                touchedFieldObj={touchedFieldObj}
                setTouchedFieldObj={setTouchedFieldObj}
              />
            );
          }

          if(component === "address"){
            return (
              <Address
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                validationObj={validationObj}
                touchedFieldObj={touchedFieldObj}
                setTouchedFieldObj={setTouchedFieldObj}
              />
            )
          }

          if(component === "add-item"){
            return (
              <Repeater
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                schema={schema}
              />
            )
          }

          if(component === "switch"){
            return (
              <Switch
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
              />
            )
          }

          if(component === "file"){
            return (
              <File
                {...el}
                key={name}
                formData={formData}
                onUpdate={onUpdate}
                multiColumn={multiColumn}
                validationObj={validationObj}
                touchedFieldObj={touchedFieldObj}
                setTouchedFieldObj={setTouchedFieldObj}
              />
            )
          }

          return (
            <TextField
              {...el}
              key={name}
              formData={formData}
              onUpdate={onUpdate}
              multiColumn={multiColumn}
              validationObj={validationObj}
              touchedFieldObj={touchedFieldObj}
              setTouchedFieldObj={setTouchedFieldObj}
            />
          );
        })
      }
      {onSubmit && <input type="submit" name="submit" className="hidden"/>}
    </form>
  );
});

export default Form;
