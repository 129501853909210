import React, {Fragment, useState, useEffect} from "react";
import Form from "../common/form";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {getSelectTenantConfig} from "./configs/selectTenant.config";

const SelectTenant = (props) => {
  const {onValidate, onStepUpdate, stepData = {}, stepId, setLabelObject} = props;

  const {tenant, tenantUserId} = stepData;
  const [tenantData, setTenantData] = useState([]);
  const [headerObject, setHeaderObject] = useState({});
  const [tenantUserData, setTenantUserData] = useState([]);

  const {getAllTenants, getAllTenantUsers} = API_PATH;
  const {data, loading} = useRequest(getAllTenants);
  useEffect(() => {
    if(data && !loading){
      const {result = []} = data;
      const allTenants = result.map(({name: label, id: value}) => ({
        label,
        value
      }));
      setTenantData(allTenants);

      const labelObj = allTenants.reduce((data, tenant) => {
        const {label, value} = tenant
        data[value] = label;
        return data;
      }, {tenant: "Tenant name", tenantUserId: "Consulting Doctor"});
      setLabelObject(lObj => ({...lObj, [stepId]: labelObj}))
    }
  }, [data, loading, setLabelObject, stepId]);

  const {
    data: consultingDoctorData, loading: consultingDoctorLoading, 
    callAPI: callConsultingDoctorAPI
  } = useRequest(getAllTenantUsers, false, {}, "GET", headerObject);
  useEffect(() => {
    if(!consultingDoctorLoading && consultingDoctorData){
      const {result = []} = consultingDoctorData;
      const allTenantUser = result.map(({name: label, id: value}) => ({
        label,
        value
      }));
      setTenantUserData(allTenantUser);

      const labelObj = allTenantUser.reduce((data, tenant) => {
        const {label, value} = tenant
        data[value] = label;
        return data;
      }, {});
      setLabelObject(lObj => ({...lObj, [stepId]: {
        ...lObj[stepId],
        ...labelObj
      }}))
    }
  }, [consultingDoctorData, setLabelObject, stepId, consultingDoctorLoading]);

  useEffect(() => {
    setHeaderObject(tenant ? {
      "X-OM-FILTER": `tenant.id:${tenant}`
    } : {});
  }, [tenant]);

  useEffect(() => {
    if(tenant && Object.keys(headerObject).length){
      setTimeout(() => {
        callConsultingDoctorAPI();
      });
    }
  }, [tenant, headerObject, callConsultingDoctorAPI]);

  const formConfig = getSelectTenantConfig(tenantData, tenantUserData);

  return (
    <Fragment>
    {!loading && tenantData.length > 0 && 
      <Form
        config={formConfig}
        initialValues={stepData}
        multiColumn={true}
        onFormValid={(isFormValid) => {
          onValidate(stepId, isFormValid && tenantUserId);
        }}
        onFormUpdate={(stepData) => {
          onStepUpdate(stepData)
        }}
      />
    }
    </Fragment>
  );
};

export default SelectTenant;
