import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {API_PATH} from "../constant";
import {formatString} from "../utils";
import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import {workflowSteps, createStepData} from "./configs/edit-specialist.config";

import Head from "../components/head";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

const EditSpecialist = (props) => {
  const navigate = useNavigate();
  const {loading: contextLoading} = useContextData();

  const [stepData, setStepData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const {specialistId} = useParams();
  const {getSpecificSpecialist} = API_PATH;
  const {
    loading: specialistLoading, data: specialistData
  } = useRequest(formatString(getSpecificSpecialist, [specialistId]));
  useEffect(() => {
    if(!specialistLoading && specialistData && specialistData.result){
      const {result} = specialistData;
      const stepData = createStepData(result);
      setStepData(stepData);
      setIsLoaded(true);
    }
  }, [specialistLoading, specialistData]);
  
  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading && isLoaded &&
        <Workflow
          {...props}
          isEdit={true}
          initStepData={stepData}
          steps={workflowSteps}
          onExit={() => {
            navigate("/manage-specialists/");
          }}
        />
      }
    </div>
  )
};

export default EditSpecialist;
