import React from "react";
import ReactDOM from "react-dom";
import {ToastContainer} from "react-toastify";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style/index.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";

import FAQs from "./pages/faqs";
import Login from "./pages/login";
import LogOut from "./pages/logout";
import Report from "./pages/reports";
import Landing from "./pages/landing";
// import Patient from "./pages/patient";
import AboutUs from "./pages/about-us";
// import Provider from "./pages/provider";
// import Services from "./pages/services";
import AddEvent from "./pages/add-event";
import EditCase from "./pages/edit-case";
import Dashboard from "./pages/dashboard";
import EditTenant from "./pages/edit-tenant";
import CreateCase from "./pages/create-case";
import TermsOfUse from "./pages/terms-of-use";
// import Institution from "./pages/institution";
import CaseDetails from "./pages/case-details";
import ManageUsers from "./pages/manage-users";
import reportWebVitals from "./reportWebVitals";
import UploadReport from "./pages/upload-report";
import CreateTenant from "./pages/create-tenant";
import PrivacyPolicy from "./pages/privacy-policy";
import ManageTenants from "./pages/manage-tenants";
import EditSpecialist from "./pages/edit-specialist";
import ForgotPassword from "./pages/forgot-password";
import OncologyNetwork from "./pages/oncology-network";
import CreateSpecialist from "./pages/create-specialist";
import ManageSpecialist from "./pages/manage-specialists";
import EditConsultingDoctor from "./pages/edit-consulting-doctor";
import CreateConsultingDoctor from "./pages/create-consulting-doctor";
import ManageConsultingDoctors from "./pages/manage-consulting-doctor";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "./components/authentication";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// "//projects.wojtekmaj.pl/react-pdf/static/pdf.worker.7e62777f.js";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/faqs/" element={<FAQs/>}/>
        <Route path="/login/" element={<Login/>}/>
        {/* <Route path="/patient/" element={<Patient/>}/> */}
        <Route path="/about-us/" element={<AboutUs/>}/>
        {/* <Route path="/services/" element={<Services/>}/> */}
        {/* <Route path="/provider/" element={<Provider/>}/> */}
        <Route path="/terms-of-use/" element={<TermsOfUse/>}/>
        {/* <Route path="/institution/" element={<Institution/>}/> */}
        <Route path="/privacy-policy/" element={<PrivacyPolicy/>}/>
        <Route path="/forgot-password/" element={<ForgotPassword/>}/>
        <Route path="/oncology-network/" element={<OncologyNetwork/>}/>

        <Route path="/logout/" element={<PrivateRoute><LogOut/></PrivateRoute>}/>
        <Route path="/case/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
        <Route path="/manage-users/" element={<PrivateRoute><ManageUsers/></PrivateRoute>}/>
        <Route path="/manage-tenants/" element={<PrivateRoute><ManageTenants/></PrivateRoute>}/>
        <Route path="/report/:patientId/:caseId/" element={<PrivateRoute><Report/></PrivateRoute>}/>
        <Route path="/manage-specialists/" element={<PrivateRoute><ManageSpecialist/></PrivateRoute>}/>
        <Route path="/create-case/:currentStep/" element={<PrivateRoute><CreateCase/></PrivateRoute>}/>
        <Route path="/case/:patientId/:caseId/" element={<PrivateRoute><CaseDetails/></PrivateRoute>}/>
        <Route path="/edit-case/:caseId/:currentStep/" element={<PrivateRoute><EditCase/></PrivateRoute>}/>
        <Route path="/create-tenant/:currentStep/" element={<PrivateRoute><CreateTenant/></PrivateRoute>}/>
        <Route path="/upload-report/:patientId/:caseId/" element={<PrivateRoute><UploadReport/></PrivateRoute>}/>
        <Route path="/edit-tenant/:tenantId/:currentStep/" element={<PrivateRoute><EditTenant/></PrivateRoute>}/>
        <Route path="/create-specialist/:currentStep/" element={<PrivateRoute><CreateSpecialist/></PrivateRoute>}/>
        <Route path="/add-event/:patientId/:caseId/:currentStep/" element={<PrivateRoute><AddEvent/></PrivateRoute>}/>
        <Route path="/manage-consulting-doctors/" element={<PrivateRoute><ManageConsultingDoctors/></PrivateRoute>}/>
        <Route path="/edit-specialist/:specialistId/:currentStep/" element={
          <PrivateRoute><EditSpecialist/></PrivateRoute>
        }/>
        <Route path="/create-consulting-doctor/:currentStep/" element={
          <PrivateRoute><CreateConsultingDoctor/></PrivateRoute>
        }/>
        <Route path="/edit-consulting-doctor/:doctorId/:currentStep/" element={
          <PrivateRoute><EditConsultingDoctor/></PrivateRoute>}
        />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="colored"
      />
    </BrowserRouter>
  </React.StrictMode>
, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
