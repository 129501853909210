import React from "react";
import pdf from "../../assets/pdf.png";
import jpg from "../../assets/jpg.png";
import dicomThumb from "../../assets/dicom.svg";

import {getFileSize} from "../../utils";
import {recordCategoryMap} from "../../constant";

const FileList = (props) => {
  const {allFiles} = props;
  
  const getFileImage = (fileType) => {
    switch(fileType){
      case "pdf":
        return pdf;

      case "dicom":
        return dicomThumb;

      default:
        return jpg;
    }
  };

  return (
    <ul className="max-h-41 pr-1 overflow-auto text-xs">
      {
        allFiles.map(({url, fileType, fileSize, type, description}, index) => (
          <li
            className={`flex items-center justify-start bg-gray-light p-1 rounded border-gray border${
              index !== allFiles.length - 1 ? " mb-1" : ""
            } font-medium`}
            key={url + index}
          >
            <img
              className="w-5 mr-1"
              src={getFileImage(fileType)}
              alt="file data"
            />
            <span className="max-w-35 text-ellipsis overflow-hidden whitespace-nowrap">{url}</span>
            <span className="grow text-text-gray text-center">{
              fileType === "dicom" ? description : recordCategoryMap[type].name
            }</span>
            <span className="text-text-gray text-right px-2">{
              fileType === "dicom" ? "-" : getFileSize(fileSize)
            }</span>
          </li>
        ))
      }
    </ul>
  )
};

export default FileList;
