import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import Form from "../common/form";
import {formatString} from "../../utils";
import RecordType from "../common/recordType";
import useRequest from "../../hooks/useRequest";
import useDebounce from "../../hooks/useDebounce";
import {API_PATH, typeToCategoryMap} from "../../constant";
import AnalyzeRecordModal from "./components/analyzeRecordModal";
import {getAnalyzeRecordConfigs} from "./configs/analyzeRecords.config";

const AnalyzeRecords = (props) => {
  const {onValidate, onStepUpdate, stepId} = props;

  const {patientId} = useParams();
  const [allRecords, setAllRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedRecordIndex, setSelectedRecordIndex] = useState(-1);

  const {getAllReports, specificRecord} = API_PATH;
  const {data: allReportData, loading: allReportLoading} = useRequest(
    formatString(getAllReports, [patientId]), true, {}, "GET", {
      "X-OM-FILTER": "?sort=recordOrder"
    }
  );
  useEffect(() => {
    if(allReportData && !allReportLoading){
      const {result = []} = allReportData;
      setAllRecords(result);
    }
  }, [allReportData, allReportLoading]);

  useEffect(() => {
    if(allRecords.length > 0){
      const status = allRecords.every(record => {
        const {type, date, description} = record;
        return type && date && description;
      });
      onValidate(stepId, status);
      onStepUpdate({
        records: allRecords
      });
    }
  }, [allRecords, onValidate, onStepUpdate, stepId]);

  const {id, type, date, category, description} = selectedRecord;
  const {data: recordData, loading: recordLoading, callAPI} = useRequest(
    formatString(specificRecord, [id]), false, {
      type, date, category, description
    }, "PATCH"
  );
  useEffect(() => {
    if(!recordLoading && recordData){
      setSelectedRecord({});
    }
  }, [recordData, recordLoading]);

  const debouncedFunc = useDebounce(callAPI, 1000);
  useEffect(() => {
    if(selectedRecord && Object.keys(selectedRecord).length){
      setTimeout(() => {
        debouncedFunc();
      });
    }
  }, [selectedRecord, debouncedFunc]);

  return (
    <div>
      {
        allRecords.map((record, index) => {
          const {fileType, id, url, type, date, description} = record;
          return (
            <div className="mb-2" key={id}>
              <div className="flex">
                <div
                  className="w-40 pr-4 cursor-pointer"
                  onClick={() => setSelectedRecordIndex(index)}
                >
                  <RecordType
                    fileType={fileType}
                    patientId={patientId}
                    url={url}
                    bigThumbnail={true}
                  />
                </div>
                <div className="grow">
                  {index !== selectedRecordIndex && 
                    <Form
                      config={getAnalyzeRecordConfigs(index)}
                      initialValues={{
                        [`type-${index}`]: type, 
                        [`date-${index}`]: date,
                        [`description-${index}`]: description
                      }}
                      multiColumn={true}
                      onFormUpdate={(stepData) => {
                        const type = stepData[`type-${index}`];
                        const date = stepData[`date-${index}`];
                        const description = stepData[`description-${index}`];
                        setAllRecords(records => {
                          const recordClone = [...records];
                          recordClone[index] = {
                            ...recordClone[index],
                            type,
                            date,
                            description,
                            category: typeToCategoryMap[type]
                          };
                          setSelectedRecord({
                            ...recordClone[index]
                          });
                          return recordClone;
                        });
                      }}
                    />
                  }
                </div>
              </div>
              {index < allRecords.length - 1 && 
                <hr 
                  className="w-9/12 mb-3 border-gray"
                />
              }
            </div>
          )
        })
      }

      {selectedRecordIndex > -1 &&
        <AnalyzeRecordModal
          selectedRecord={allRecords[selectedRecordIndex]}
          patientId={patientId}
          close={() => {
            setSelectedRecordIndex(-1);
          }}
          callback={recordData => {
            const {type, date, description} = recordData;
            setAllRecords(records => {
              const recordClone = [...records];
              recordClone[selectedRecordIndex] = {
                ...recordClone[selectedRecordIndex],
                type,
                date,
                description,
                category: typeToCategoryMap[type]
              };
              setSelectedRecord({
                ...recordClone[selectedRecordIndex]
              });
              return recordClone;
            });
          }}
        />
      }
    </div>
  )
};

export default AnalyzeRecords;