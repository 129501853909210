import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const DotMenu = (props) => {
  const navigate = useNavigate();
  const {items, minWidth = "min-w-32"} = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="relative text-text-gray text-xs font-medium">
      <div
        className={`
          cursor-pointer p-2 rounded grid grid-cols-3 gap-0.5 
          hover:bg-gray/50`}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <span className="w-0.5 h-0.5 bg-sky rounded-full"></span>
        <span className="w-0.5 h-0.5 bg-sky rounded-full"></span>
        <span className="w-0.5 h-0.5 bg-sky rounded-full"></span>
      </div>

      {showMenu && 
        <ul
          className={
            `bg-white absolute z-20 rounded top-full right-0 mt-0.5 ${
              minWidth
            } border border-gray/50 whitespace-nowrap`
          }
        >
          {
            items.map((el, index) => {
              const {name, type, to, click, icon, newTab = false} = el;
              return (
                <li
                  key={name + index}
                  className="flex items-center cursor-pointer py-1.5 px-3 hover:bg-gray-light"
                  onClick={() => {
                    setShowMenu(false);
                    if(type === "link" && to){
                      if(newTab){
                        window.open(to, "_blank");
                        return;
                      }
                      navigate(to);
                      return;
                    }
                    click();
                  }}
                >
                  {icon && <img src={icon} alt="menu" className="w-3.5 mr-1.5"/>}
                  <span className="block">{name}</span>
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  )
};

export default DotMenu;