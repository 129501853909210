import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {getConfigs} from "./configs/summary.config";

import {ReactComponent as ArrowDown} from "../../assets/arrow-down.svg";

const Summary = (props) => {
  const {
    getState, getSteps, getLabels, goto, onValidate, stepId
  } = props;
  const allStepData = getSteps();
  const allStateData = getState();
  const allLabelData = getLabels();

  const {specialistId} = useParams();
  const configs = getConfigs(specialistId);
  const initMap = configs.reduce((data, el) => {
    const {key} = el;
    data[key] = true;
    return data;
  }, {});
  const [openCloseMap, setOpenCloseMap] = useState(initMap);

  useEffect(() => {
    onValidate(stepId, true);
  }, [onValidate, stepId])

  return (
    <div className="accordian-panel-container">
      {
        configs.map((el, index) => {
          const {key, body} = el;
          const step = allStepData.find(el => el.id === key);
          const formData = allStateData[key] || {};
          const labelObj = allLabelData[key] || {};
          if(!step){
            return undefined;
          }

          const {title, id} = step;
          return (
            <div className={`accordian-panel mb-5${
              index !== configs.length - 1 ? " border-b border-gray" : ""
            }`} key={key}>
              <div className="flex items-center mb-4 leading-5">
                <ArrowDown
                  className={`text-sky mr-3 duration-500 ease-in-out transition-transform ${
                    !openCloseMap[key] ? "-rotate-90" : ""
                  }`}
                />
                <h3
                  className="text-medium-l text-sky mr-4 cursor-pointer"
                  onClick={() => {
                    setOpenCloseMap(oldData => ({
                      ...oldData,
                      [key]: !openCloseMap[key]
                    }))
                  }}
                >{title}</h3>
                <button
                  className="text-base text-sky"
                  onClick={() => {
                    goto(id)
                  }}
                >Edit</button>
              </div>
              <div 
                className={`pl-6 pb-5${
                  !openCloseMap[key] ? " hidden" : ""
                }`}
              >
                {body(formData, labelObj)}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default Summary;
