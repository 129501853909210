import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {API_PATH} from "../constant";
import {formatString} from "../utils";
import useRequest from "../hooks/useRequest";

import Block from "../components/report/block";
import BlockHeader from "../components/report/block-header";
import BlockFooter from "../components/report/block-footer";
import {getReportBlocks} from "./configs/reports.config";

const Report = () => {
  const {caseId, patientId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [tenantUserId, setTenantUserId] = useState("");

  const {
    caseDetails, caseSpecialist, getQueries, getAllReports, getAllEvents, getSpecificConsultingDoctor
  } = API_PATH;
  const {data: caseData, loading: caseLoading} = useRequest(formatString(caseDetails, [caseId]));
  const {data: queryData, loading: queryLoading} = useRequest(formatString(getQueries, [caseId]));
  const {data: caseSepcialistData, loading: caseSepcialistLoading} = useRequest(
    formatString(caseSpecialist, [caseId])
  );
  const {data: eventData, loading: eventLoading} = useRequest(formatString(getAllEvents, [patientId]));
  const {data: recordData, loading: recordLoading} = useRequest(formatString(getAllReports, [patientId]));
  const {
    data: tenantUserData, loading: tenantUserLoading, callAPI: calltenantUserAPI
  } = useRequest(formatString(getSpecificConsultingDoctor, [tenantUserId]), false);
  useEffect(() => {
    if(
      caseData && !caseLoading && 
      queryData && !queryLoading &&
      eventData && !eventLoading &&
      recordData && !recordLoading &&
      tenantUserData && !tenantUserLoading &&
      caseSepcialistData && !caseSepcialistLoading
    ){
      setIsLoading(false);
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [
    caseData, caseLoading, 
    queryData, queryLoading, 
    eventData, eventLoading,
    recordData, recordLoading,
    tenantUserData, tenantUserLoading,
    caseSepcialistData, caseSepcialistLoading
  ]);

  useEffect(() => {
    if(!caseLoading && caseData){
      const {result} = caseData;
      const {tenantUserId} = result;
      setTenantUserId(tenantUserId);
    }
  }, [caseData, caseLoading]);

  useEffect(() => {
    if(tenantUserId){
      setTimeout(() => {
        calltenantUserAPI();
      });
    }
  }, [tenantUserId, calltenantUserAPI]);

  const reportBlocks = getReportBlocks(caseData, queryData, caseSepcialistData, tenantUserData, eventData, recordData);
  return (
    isLoading ? (
      <div className="flex h-screen justify-center items-center text-purple text-medium font-bold">
        Loading...
      </div>
    ) : (
      <div className="report-block-container">
        <BlockHeader/>
        <BlockFooter/>
        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
              {
                reportBlocks.map(block => {
                  const {key, html} = block;
                  return (
                    <Block key={key}>
                      {html}
                    </Block>
                  )
                })
              }
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  );
};

export default Report;
