import {toast} from "react-toastify";
import {getListItems} from "../constant/lists";
import {API_PATH, API_URL, TOKEN_KEY} from "../constant";

// empty check value
export const isEmpty = value =>
	value === null || value === undefined || value === "";

//empty check object
export const isEmptyObject = object =>
	!(object && Object.keys(object).length);

export const isArray = arr => Array.isArray(arr);

export const isObject = obj => obj instanceof Object && !isArray(obj);

// empty list check
export const isEmptyList = value => isEmpty(value) || value.length === 0;

export const formatString = (str, args) => {
	if (!str || isEmpty(args) || !isArray(args)){
		return str;
	}

	args.forEach((el, index) => {
		const regex = new RegExp(`{arg${index + 1}}`, "g");
		str = str.replace(regex, el);
	});
	return str;
};

export const formUrlEncoded = x => Object.keys(x).reduce((p, c) => {
  if(x[c] === undefined || x[c] === null){
    return p;
  }
  return `${p}${p ? '&' : ''}${c}=${encodeURI(x[c])}`;
}, '');

export const fixVMTBDetails = (el) => {
  if (!el || isEmpty(el) || !isObject(el)){
		return el;
	}

  const {id, patient, createdDate} = el;
  return {
    ...el,
    date: createdDate,
    caseId: id ? id.split("-")[0] : id,
    disPatientId: patient && patient.id ? patient.id.split("-")[0] : "-"
  }
};

export const parseCases = (cases, userRoles) => {
  const formattedCases = cases.reduce((data, el) => {
    const {status} = el;
    if(!data[status]){
      data[status] = [];
    }

    data[status].push(fixVMTBDetails(el));
    return data;
  }, {});

  const listItems = getListItems(userRoles);
  return listItems.map(el => {
    const returnObj = {
      ...el,
      items: formattedCases[el.id] || []
    };
    return returnObj;
  });
};

export const debounce = (func, dTimer = 300) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, dTimer);
  };
};

export const fixURL = (isSpecialist, id, fileName, isTenantUser = false) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const {downloadContent} = API_PATH;
  const formattedStr = formatString(
    downloadContent, [isSpecialist ? "specialists" : (
      isTenantUser ? "tenantusers" : "patients"
    ), id, fileName]
  );
  return API_URL + formattedStr + (token ? `?token=${token}` : "");
};

export const getFileSize = (bytes) => {
  if (bytes >= 1000000000) bytes = (bytes / 1000000000).toFixed(1) + ' GB';
  else if (bytes >= 1000000) bytes = (bytes / 1000000).toFixed(1) + ' MB';
  else if (bytes >= 1000) bytes = (bytes / 1000).toFixed(1) + ' KB';
  else if (bytes > 1) bytes = bytes + ' bytes';
  else if (bytes === 1) bytes = bytes + ' byte';
  else bytes = '0 byte';
  
  return bytes;
};

export const bindEvent = (el, event, fn) => {
  el.addEventListener ? el.addEventListener(event, fn, false) : el.attachEvent && el.attachEvent('on' + event, fn);
};

export const unbindEvent = (el, event, fn) => {
  el.removeEventListener ? el.removeEventListener(event, fn) : el.detachEvent && el.detachEvent('on' + event, fn);
};

export const showAlert = debounce((message, type='success', autoClose=5000) => {
  toast[type](message, {
    position: 'bottom-right',
    autoClose
  });
});

export const isOmAdmin = (userRoles) => {
  return userRoles.includes("om_admin");
};

export const isTenantAdmin = (userRoles) => {
  return userRoles.includes("tenant_admin");
};

export const isTenantUser = (userRoles) => {
  return userRoles.includes("tenant_user");
};

export const isOmLeader = (userRoles) => {
  return userRoles.includes("om_leader");
};

export const isOmSpecialist = (userRoles) => {
  return userRoles.includes("om_specialist");
};

export const hasCaseCreationAccess = (userRoles) => {
  return isOmAdmin(userRoles) || isTenantAdmin(userRoles) || isTenantUser(userRoles);
};
