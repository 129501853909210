import React, {useRef, useState, useEffect, Fragment} from "react";
import moment from "moment";

import useRequest from "../../hooks/useRequest";
import useOnScreen from "../../hooks/useOnScreen";

import {formatString} from "../../utils";
import {eventTypeObject} from "../../constant/eventType";
import {API_PATH, recordCategoryMap} from "../../constant";

import Note from "./note";
import RecordType from "../common/recordType";

const EventDetails = (props) => {
  const {
    id:eventId, name, type, description, fromDate, toDate, recordLess,
    root, showRecordNote, speciaListId, callback, recordNoteData
  } = props;
  const [allRecordData, setAllRecordData] = useState([]);

  const showDate = (fromDate === toDate) ? moment(fromDate).format("DD MMM YYYY") : 
    `${moment(fromDate).format("DD MMM YYYY")} - ${moment(toDate).format("DD MMM YYYY")}`;
  const {getRecordsForEvent} = API_PATH;
  const {data: recordData, loading: recordLoading, callAPI: callRecordAPI} = useRequest(
    formatString(getRecordsForEvent, [eventId]), false
  );
  useEffect(() => {
    if(!recordLoading && recordData){
      const {result} = recordData;
      setAllRecordData(result);
    }
  }, [recordData, recordLoading]);

  const elm = useRef();
  const isVisible = useOnScreen(root, elm, 1);
  useEffect(() => {
    if(!recordLess && isVisible && allRecordData.length === 0){
      setTimeout(() => {
        callRecordAPI();
      });
    }
  }, [name, recordLess, isVisible, allRecordData, callRecordAPI]);

  return (
    <div
      ref={elm}
      id={`event-details-${eventId}`}
      data-id={eventId}
      className="bg-white p-3 rounded relative border-b border-card-shadow mx-auto max-w-screen-lg mt-3"
    >
      <h3 className="font-bold">{name}</h3>
      <div className="mt-1.5 flex tracking-wider text-text-gray text-xs">
        <div className="uppercase">{showDate}</div>
        {type && 
          <Fragment>
            <div className="ml-1 font-semibold">&#183;</div>
            <div className="ml-1 text-sky font-semibold">{eventTypeObject[type]}</div>
          </Fragment>
        }
      </div>
      <div
        className="mt-1 text-text-gray leading-tight html-body text-xs"
        dangerouslySetInnerHTML={{__html: description}}
      />
      
      <div className="mt-3 pt-3 border-t border-text-gray border-dashed">
        {recordLess &&
          <div className="text-black">No record is associated with this event.</div>
        }
        {
          allRecordData.map((record, index) => {
            const {id, date, description, fileType, type, url, patientId} = record;

            return (
              <div
                key={id}
                className={
                  index < allRecordData.length - 1 ? "border-b border-text-gray border-dashed pb-3 mb-3" : ""
                }
              >
                <div
                  className="flex"
                >
                  <div className="w-30">
                    <RecordType
                      fileType={fileType}
                      patientId={patientId}
                      url={url}
                      showPopupView={true}
                    />
                  </div>
                  <div className="grow pl-1">
                    <div className="flex tracking-wider text-text-gray text-xs">
                      <div className="uppercase">{moment(date).format("DD MMM YYYY")}</div>
                      <div className="ml-1 font-semibold">&#183;</div>
                      <div className="ml-1 text-sky font-semibold">{
                        recordCategoryMap[type] && recordCategoryMap[type].category ? recordCategoryMap[type].category.name : "--"
                      }</div>
                      <div className="ml-1 font-semibold">&#183;</div>
                      <div className="ml-1">{recordCategoryMap[type] ? recordCategoryMap[type].name : "--"}</div>
                    </div>

                    <div
                      className="mt-2 leading-tight html-body text-black"
                      dangerouslySetInnerHTML={{__html: description}}
                    />
                  </div>
                </div>
              {showRecordNote && 
                <div className="pt-3 text-xs">
                  <Note
                    callback={callback}
                    note={recordNoteData[id]}
                    title="Record notes"
                    id={id}
                    speciaListId={speciaListId}
                    type="records"
                  />
                </div>
              }
              </div>
            );
          })
        }
      </div>
    </div>
  )

};

export default EventDetails;
