import {useState, useEffect} from "react";

const useOnScreen = (root, elm, threshold = 0.0) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting), {
        root: root.current,
        threshold
      }
    );
    observer.observe(elm.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    }
  }, [root, elm, threshold])

  return isIntersecting
};

export default useOnScreen;
