export const getSelectTenantConfig = (data, tenantUserData = []) => {
  const tenantConfig = [{
    component: "dropdown",
    name: "tenant",
    label: "Select Tenant",
    required: true,
    data
  }];

  if(tenantUserData.length > 0){
    tenantConfig.push({
      component: "dropdown",
      name: "tenantUserId",
      label: "Select Consulting Doctor",
      required: true,
      data: tenantUserData
    });
  }

  return tenantConfig;
};
