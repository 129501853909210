export const createPayload = (stateData) => {
  const personalData = stateData["personal-details"];
  const {name, phone, address1, address2, city, postalCode, state, country, email, userName} = personalData;

  const professionalData = stateData["professional-details"];
  const {
    timezone, bio, qualification, specialization, experience, canBeLeader, languages,
    linkedin, instagram, facebook, twitter, youtube, website
  } = professionalData;

  const socialMediaObject = {
    linkedin, instagram, facebook, twitter, youtube, website
  };
  const socialMedia = Object.keys(socialMediaObject).reduce((data, key) => {
    if(socialMediaObject[key]){
      data.push({
        type: key,
        url: socialMediaObject[key]
      })
    }
    return data;
  }, []);

  return {
    name, 
    socialMedia,
    ...(
      phone.reduce((data, el, index) => {
        const key = index === 0 ? "phone" : `phone${index}`;
        data[key] = el;
        return data;
      }, {})
    ), 
    address1, 
    address2, 
    city, 
    postalCode, 
    state, 
    country, 
    timezone, 
    bio, 
    qualification, 
    specialization,
    experience,
    languages: languages.join(","),
    canBeLeader,
    userName,
    email
  };
};

export const createAvatarPayload = (stateData) => {
  const professionalData = stateData["professional-details"];
  const {avatar} = professionalData;

  const formData = new FormData();
  for (const file of avatar) {
    formData.append("file", file);
  }
  return formData;
};
