import React, {useEffect} from "react";
import Form from "../common/form";
import {getLabelObj} from "../../utils/workflowUtils";
import {formConfig, formSchema} from "./configs/patientDemographic.config";

const PatientDemographic = (props) => {
  const {onValidate, onStepUpdate, stepData = {
    gender: "male",
    maritalStatus: "married"
  }, setLabelObject, stepId} = props;

  useEffect(() => {
    const labelObj = getLabelObj(formConfig);
    setLabelObject(lObj => ({...lObj, [stepId]: labelObj}));
  }, [setLabelObject, stepId]);

  return (
    <Form
      config={formConfig}
      schema={formSchema}
      initialValues={stepData}
      multiColumn={true}
      onFormValid={(isFormValid) => {
        onValidate(stepId, isFormValid);
      }}
      onFormUpdate={(stepData) => {
        onStepUpdate(stepData)
      }}
    />
  )
};

export default PatientDemographic;
