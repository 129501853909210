export const createPayload = (stateData) => {
  const personalData = stateData["tenant-details"];
  const {name, phone, address1, address2, city, postalCode, state, country, email} = personalData;

  return {
    name, 
    email,
    ...(
      phone.reduce((data, el, index) => {
        const key = index === 0 ? "phone" : `phone${index}`;
        data[key] = el;
        return data;
      }, {})
    ), 
    address1, 
    address2, 
    city, 
    postalCode, 
    state, 
    country
  };
};
