import React, {useEffect} from "react";
import Form from "../common/form";
import {getLabelObj} from "../../utils/workflowUtils";
import {formConfig} from "./configs/caseBackground.config";

const CaseBackground = (props) => {
  const {
    onValidate, onStepUpdate, stepData = {}, setLabelObject, stepId
  } = props;

  useEffect(() => {
    const labelObj = getLabelObj(formConfig);
    setLabelObject(lObj => ({...lObj, [stepId]: labelObj}));
  }, [setLabelObject, stepId]);

  return (
    <Form
      config={formConfig}
      initialValues={stepData}
      multiColumn={true}
      onFormValid={(isFormValid) => {
        onValidate(stepId, isFormValid);
      }}
      onFormUpdate={(stepData) => {
        onStepUpdate(stepData)
      }}
    />
  );
};

export default CaseBackground;