import React, {Fragment} from "react";
import moment from "moment";
import {format} from "date-fns";

import {fixURL} from "../../utils";
import {recordCategoryMap} from "../../constant";
import {eventTypeObject} from "../../constant/eventType";
import {getLanguageObject} from "../../constant/languages";
import {createTimelineData} from "../../components/addEvent/configs/timelineView.config";

export const getReportBlocks = (caseData, queryData, specialistData, tenantUserData, eventData, recordData) => {
  if(!caseData || !queryData || !specialistData || !tenantUserData || !eventData || !recordData){
    return [];
  }
  // console.log(caseData, queryData, specialistData, tenantUserData, eventData, recordData);
  const langObject = getLanguageObject();
  const {id: caseID, description, lead, patient, summary, tenant, specialists, caseType} = caseData.result;
  const {name: leadName} = lead;
  const {id: patientID, age, gender} = patient;
  
  const {result: allQueries} = queryData;
  const answeredQueries = allQueries.filter(query => query.answer !== undefined);
  const {result: allSepcialists} = specialistData;

  const {
    id: tenantId, avatarUrl, name: tenantName, qualification, specialization, experience, languages,
    bio: tenantBio, //address1: tenantAddress1, city: tenantCity, state: tenantState,  country: tenantCountry, 
    // postalCode: tenantPostalCode, 
  } = tenantUserData.result;
  
  const timelineData = createTimelineData(recordData.result, eventData.result)
  const allEvents = timelineData.reverse().filter(timeline => timeline.vtype === "event");
  
  const expertNames = specialists.reduce((data, spclist) => {
    const specialistData = allSepcialists.find(el => el.id === spclist.id);
    if(specialistData){
      data.push(specialistData.name);
    }
    return data;
  }, []);

  return [{
    key: "doctor-details",
    html: (
      <div className="h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-max-xxl"
        >Precision Oncology International Expert Opinion</h2>
        <div className="mt-5">
          <div className="mb-2">Consulting Oncologist:</div>
          <div className="font-semibold">{leadName}</div>
        </div>
        <div className="mt-5">
          <div className="mb-2">International Expert(s):</div>
          <div className="font-semibold">{expertNames.join(", ")}</div>
        </div>
        <div className="mt-5">
          <div className="mb-2">Patient ID:</div>
          <div className="font-semibold">
            <span className="uppercase">{patientID.split("-")[0]}</span>
            <span className="pl-1 capitalize">({gender}, {age} Years)</span>
          </div>
        </div>
        <div className="mt-5">
          <div className="mb-2">Case ID:</div>
          <div className="font-semibold uppercase">{caseID.split("-")[0]} - {caseType}</div>
        </div>
        <div className="mt-5">
          <div className="mb-2">Date of Report:</div>
          <div className="font-semibold">{format(new Date(), "MMMM do, yyyy")}</div>
        </div>
      </div>
    )
  }, {
    key: "case-summmary",
    html: (
      <div className="flex flex-col justify-center h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-3xl"
        >Case Summary</h2>
        <div className="mt-5 leading-tight html-body" dangerouslySetInnerHTML={{__html: description}}/>
      </div>
    )
  }, {
    key: `event-timeline`,
    html: (
      <div className="h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-1xl"
        >Timeline</h2>
        {
          allEvents.map(event => {
            const {
              id:eventId, name, type, description, date, endDate, records = []
            } = event;
            const formatDate = moment(date).format("DD MMM YYYY");
            const formatEndDate = moment(endDate).format("DD MMM YYYY")
            const showDate = (formatDate === formatEndDate) ? formatDate : `${formatDate} - ${formatEndDate}`;

            return (
              <div className="py-3" key={eventId}>
                <h3 className="font-bold text-medium">{name}</h3>
                <div className="mt-2 flex tracking-wider text-text-gray">
                  <div className="uppercase">{showDate}</div>
                  {type && 
                    <Fragment>
                      <div className="ml-1 font-semibold">&#183;</div>
                      <div className="ml-1 text-sky font-semibold">{eventTypeObject[type]}</div>
                    </Fragment>
                  }
                </div>
                <div
                  className="mt-2 text-text-gray leading-tight html-body"
                  dangerouslySetInnerHTML={{__html: description}}
                />
                <div className="mt-3 pt-3 border-t border-text-gray border-dashed">
                  {records.length === 0 &&
                    <div className="text-purple">No record is associated with this event.</div>
                  }
                  {
                    records.map((record, index) => {
                      const {id, date, description, type} = record;

                      return (
                        <div
                          key={id}
                          className={
                            index < records.length - 1 ? "border-b border-text-gray border-dashed pb-3 mb-3" : ""
                          }
                        >
                          <div className="w-full">
                            <div className="flex tracking-wider text-text-gray">
                              <div className="uppercase">{moment(date).format("DD MMM YYYY")}</div>
                              <div className="ml-1 font-semibold">&#183;</div>
                              <div className="ml-1 text-sky font-semibold">
                                {
                                  recordCategoryMap[type] && recordCategoryMap[type].category ? 
                                  recordCategoryMap[type].category.name : "--"
                                }
                              </div>
                              <div className="ml-1 font-semibold">&#183;</div>
                              <div className="ml-1">
                                {recordCategoryMap[type] ? recordCategoryMap[type].name : "--"}
                              </div>
                            </div>
                            <div
                              className="mt-3 leading-tight html-body text-purple"
                              dangerouslySetInnerHTML={{__html: description}}
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    )
  }, {
    key: "all-questions",
    html: (
      <div className="h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-1xl"
        >Questions from the Consultant Oncologist</h2>
        <div className="mt-4 leading-tight html-body">
          <ol>
            {
              allQueries.map(el => (<li key={el.id}>{el.question}</li>))
            }
          </ol>
        </div>
      </div>
    )
  }, {
    key: "international-expert-oncology-discussion",
    html: (
      <div className="flex flex-col justify-center h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-max-xxl"
        >International Oncologist Discussion and Opinions</h2>
        <div className="mt-5">
        {
          answeredQueries.map((queries, index) => {
            const {question, answer} = queries;
            return (
              <div className={index > 0 ? "mt-4" : ""} key={question}>
                <div className="font-bold text-medium">{question}</div>
                <div className="mt-3 leading-tight html-body" dangerouslySetInnerHTML={{__html: answer}}/>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }, ...(summary && summary !== "<br/>" && summary !== "<br>" ? [{
    key: "further-management",
    html: (
      <div className="flex flex-col justify-center h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-1xl"
        >Further management options:</h2>
        <div className="mt-5 leading-tight html-body" dangerouslySetInnerHTML={{__html: summary}}/>
      </div>
    )
  }] : []), {
    key: "tenant-user-info",
    html: (
      <div className="h-full">
        <h2
          className="leading-tight font-bold text-purple-light text-3xl"
        >Consulting Doctor</h2>
        <div className="mt-5 flex items-center justify-start">
          <img 
            className="w-40 rounded"
            src={fixURL(false, tenantId, avatarUrl, true)}
            alt={tenantName}
          />
          <div className="pl-5">
            <h2
              className="leading-tight font-bold text-purple-light text-1xl"
            >{tenantName}, {qualification }</h2>
            <div className="mt-2">{specialization}</div>
            <div className="mt-2">Tenant: {tenant.name}</div>
            <div className="mt-2">Experience: {`${experience} year${experience > 1 ? "s" : ""}`}</div>
            <div className="mt-2">Languages: {languages ? languages.split(",").map(lang => langObject[lang]).join(", ") : ""}</div>
          </div>
        </div>
        <div className="mt-5 leading-tight html-body" dangerouslySetInnerHTML={{__html: tenantBio}}/>
      </div>
    )
  }, ...(
    allSepcialists.map(specialist => {
      const {
        userName, id, bio, name, avatarUrl, qualification, specialization,
        experience, languages//, address1, city, postalCode, 
      } = specialist;

      return {
        key: `${userName}_${id}`,
        html: (
          <div className="h-full">
            <h2
              className="leading-tight font-bold text-purple-light text-3xl"
            >Oncominds expert profile</h2>
            <div className="mt-5 flex items-center justify-start">
              <img 
                className="w-40 rounded"
                src={fixURL(true, id, avatarUrl)}
                alt={name}
              />
              <div className="pl-5">
                <h2
                  className="leading-tight font-bold text-purple-light text-1xl"
                >{name}, {qualification }</h2>
                <div className="mt-2">{specialization}</div>
                <div className="mt-2">Experience: {`${experience} year${experience > 1 ? "s" : ""}`}</div>
                <div className="mt-2">Languages: {languages ? languages.split(",").map(lang => langObject[lang]).join(", ") : ""}</div>
                {/* <div
                  className="mt-2 leading-tight"
                >{`${address1}, ${city}, ${state}, ${country}, ${postalCode}`}</div> */}
              </div>
            </div>
            <div className="mt-5 leading-tight html-body" dangerouslySetInnerHTML={{__html: bio}}/>
          </div>
        )
      }
    })
  ), {
    key: "about-oncominds-and-platform",
    html: (
      <div className="h-full">
        <h2 className="leading-tight font-bold text-xl">About Oncominds and Oncominds Platform</h2>
        <div className="leading-tight">
          <p className="mt-3">
            The Oncominds Platform is developed by Oncominds Inc, a Delaware based healthtech company and
            its affiliates namely Oncominds Solutions Private Limited and its other affiliate entities. The Platform
            enables peer-to-peer oncology consultations and collaborations between on-boarded oncologists
            from India and specialist oncology professionals from leading institutions in the United States and the
            rest of the world.
          </p>
          <p className="mt-3">Who is a REGISTERED USER</p>
          <p className="mt-3">
            Only registered medical practitioners are eligible to be registered on the Oncominds Platform and
            are considered as Registered Users for the purpose of the Oncominds Platform. The Services offered
            via the Platform cannot be directly availed by non-medical end-users (who are patients or their
            authorized representatives).
          </p>
          <p className="mt-3">What is the ROLE OF ONCOMINDS?</p>
          <p className="mt-3">
            Oncominds Inc and affiliated entities are technology solutions providers and have created the
            Oncominds Platform to connect oncologists with other expert oncology professionals from different
            parts of the world, who are registered on the Platform. Oncominds is an intermediary and being an
            intermediary, Oncominds has no responsibility and/or liability in respect of the content and
            interactions on the Platform including any content or information provided by the Registered Users,
            who are oncologists registered on the Platform. The Registered User is solely responsible for his/her
            interactions with other Registered Users on the Platform. Oncominds does not provide any medical
            advice, sell any medical products, provide any medical services or endorse any Registered User.
          </p>
          <h3 className="font-bold mt-3 text-medium-l">DISCLAIMERS</h3>
          <p className="mt-3">
            The Platform, its content and any services or items obtained through the Platform are provided on an
            "as is" and "as available" basis, without any warranties of any kind, either express or implied. Neither
            Oncominds nor any person associated with it makes any warranty or representation with respect to
            the completeness, security, reliability, quality, accuracy or availability of the contents, information and
            the third party links made available on the Platform and the final report.
          </p>
          <p className="mt-3">
            Opinions expressed by the expert oncologists are not based on the physical examination of the
            patient. The opinions are also based on the information provided by the consultant oncologist and
            the reports provided by the consulting oncologist. Oncominds in no way is responsible for any of the
            opinions or does not endorse them. As mentioned above, Oncominds is a technology platform only.
          </p>
          <p className="mt-3">
            All interaction, communication, or transaction between the oncologists and the other users or
            end-user while availing the services on the Platform is a separate transaction. Oncominds does not
            have any control over such information and play no determinative role in the finalization of the same
            and hence do not stand liable for the outcomes of such communication, interaction, or transactions.
          </p>
          <p className="mt-4">
            This medical report is intended solely for the use of the healthcare professionals involved in the care
            of oncology patients and is subject to the terms and conditions set forth in the report. The
            information contained in this report is based on the available medical information and the
            interpretation of the oncology professionals who contributed to this report. This report is not intended
            to provide medical advice or diagnosis and should not be relied upon as a substitute for professional
            medical advice, diagnosis, or treatment in the field of oncology.
          </p>
          <p className="mt-3">
            This medical report is provided to offer an additional perspective and does not replace the advice or
            diagnosis of the primary oncology healthcare provider. While every effort has been made to ensure
            the accuracy of the information contained in this report, the oncology professionals who contributed
            to this report are not liable for any injuries or damages that may occur as a result of the recipient's
            reliance on the information provided in this report in the field of oncology.
          </p>
          <p className="mt-3">
            Additionally, any recipient who receives this report should treat this with strict confidentiality and
            should not be shared with individuals who are not involved in the patient's oncology care without the
            appropriate prior consent.
          </p>
        </div>
      </div>
    )
  }];
};