import Done from "../../components/editTenant/done";

import Summary from "../../components/createTenant/summary";
import TenantDetails from "../../components/createTenant/tenantDetails";

export const workflowSteps = [{
  id: "tenant-details",
  title: "Tenant Details",
  description: "Edit the tenant details to update a tenant.",
  component: TenantDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "summary",
  title: "Summary",
  description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Edit Tenant.`,
  component: Summary,
  layout: {
    primary: {
      label: "Edit Tenant"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "done",
  title: "Done!",
  description: "The tenant is being edited.",
  component: Done,
  layout: {
    footer: false
  }
}];

export const createStepData = (tenantData) => {
  const {
    name, phone, email, address1, address2 = "", city, country, state, postalCode
  } = tenantData;

  return {
    "tenant-details": {
      name,
      email,
      phone: [phone],
      address1,
      address2,
      city,
      postalCode,
      state,
      country
    }
  }
};