import React, {useState, useEffect, Fragment} from "react";

import Form from "../common/form";
import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";
import ConfirmDelete from "../common/confirmDelete";
import { DefaultEditor } from "react-simple-wysiwyg";

const TopicDiscussion = (props) => {
  const {
    allTopics, setAllTopics, typedTopic, setTypedTopic, 
    createQuery, setTobeDeletedTopic, deleteTopicAPI
  } = props;

  const [topic, setTopic] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showCreateTopicModal, setShowCreateTopicModal] = useState(false);

  const {id:topicId} = topic;
  const {queryData} = API_PATH;
  const {data: topicData, loading: topicLoading, callAPI: callTopicAPI} = useRequest(
    formatString(queryData, [topicId]), false, {...topic}, "PATCH"
  );
  useEffect(() => {
    if(!topicLoading && topicData){
      setTopic({});
      showAlert("Answer has been successfully updated.")
    }
  }, [topicData, topicLoading])

  return (
    <Fragment>
      <div className="bg-white p-3 mt-3 rounded tracking-wider text-xs border-b border-card-shadow">
        <div className="flex items-center">
          <div className="grow uppercase font-medium">
            Questions from the Consultant Oncologist
          </div>
          <div className="">
          <button
            className="uppercase text-sky font-bold tracking-wider disabled:opacity-50"
            onClick={() => {
              setShowCreateTopicModal(true);
            }}
          >
            Add Question
          </button>
          </div>
        </div>
        {
          allTopics.map((el, index) => {
            const {id, answer, question} = el;
            return (
              <div
                className={`mt-3${
                  index === allTopics.length - 1 ? "" : " border-b border-gray pb-3"
                }`}
                key={id}
              >
                <div className="flex mb-1 justify-between">
                  <h3 className="text-black grow">{question}</h3>
                  <button
                    className="uppercase text-pink font-bold tracking-wider mr-3"
                    onClick={() => {
                      setTobeDeletedTopic({...el});
                      setShowDeletePopup(true);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="uppercase text-sky font-bold tracking-wider disabled:opacity-50"
                    disabled={!answer || (topicLoading && topicId === id)}
                    onClick={() => {
                      setTopic(el);
                      setTimeout(() => {
                        callTopicAPI();
                      });
                    }}
                  >
                    Save
                  </button>
                </div>
                <div className="relative fixed-height">
                  <DefaultEditor
                    value={answer}
                    onChange={(e) => {
                      const {value} = e.target;
                      const topicClone = [...allTopics];
                      topicClone[index] = {
                        ...topicClone[index],
                        answer: value
                      };
                      setAllTopics(topicClone);
                    }}
                  />
                </div>
              </div>
            )
          })
        }
      </div>

      {showCreateTopicModal && 
        <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
          <div className="w-110 bg-white rounded relative m-auto px-5 py-4">
            <h3 className="text-medium font-bold text-center">Add Question</h3>
            <div className="mt-1">
              <Form
                config={[{
                  component: "textarea",
                  name: "question",
                  label: "Question",
                  required: true,
                  isTextArea: true
                }]}
                initialValues={{question: typedTopic}}
                multiColumn={true}
                onFormValid={(isFormValid) => {
                  setIsFormValid(isFormValid);
                }}
                onFormUpdate={(formData) => {
                  const {question} = formData;
                  setTypedTopic(question)
                }}
              />
            </div>
            <div className="flex items-center justify-center mt-1">
              <button
                className="mr-4 text-purple-light font-medium text-xs"
                onClick={() => setShowCreateTopicModal(false)}
              >Cancel</button>
              <button
                className="primary-btn"
                disabled={!isFormValid}
                onClick={() => {
                  createQuery();
                  setShowCreateTopicModal(false);
                }}
              >Add Question</button>
            </div>
          </div>
        </div>
      }

      {showDeletePopup && 
        <ConfirmDelete
          heading="Deleting topic..."
          onConfirm={() => {
            deleteTopicAPI();
          }}
          close={() => {
            setShowDeletePopup(false);
          }}
        />
      }
    </Fragment>
  )
};

export default TopicDiscussion;
