import React, {useEffect, useState, Fragment} from "react";
import {Link, useParams} from "react-router-dom";
import {createPayload} from "./configs/done.config";
import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

import {API_PATH} from "../../constant";
import {useContextData} from "../../store";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";

const Done = (props) => {
  const {getState} = props;
  const stateData = getState();
  
  const {loading: contextLoading} = useContextData();

  const [patientId, setPatientId] = useState("");

  const {caseId} = useParams();
  const {caseDetails} = API_PATH;
  const payload = createPayload(stateData);
  const {
    data: caseData, loading: caseLoading, callAPI: callCaseAPI, error
  } = useRequest(formatString(caseDetails, [caseId]), false, payload, "PATCH");
  useEffect(() => {
    if(!caseLoading && caseData && caseData.result) {
      const {patient} = caseData.result;
      setPatientId(patient.id);
      showAlert("The case has been successfully updated!");
    }
  }, [caseData, caseLoading]);

  useEffect(() => {
    if(!contextLoading){
      setTimeout(() => {
        callCaseAPI();
      })
    }
  }, [contextLoading, callCaseAPI]);

  const caseMessage = caseLoading ? "Case updation is in progress..." : (
    error ? "Updation of the case has failed!" : "Case has been successfully updated!"
  );

  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {caseLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!caseLoading && 
            <Fragment>
              {!error && <Success className="w-4 h-4"/>}
              {error && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {patientId &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            <div className="pt-3">
              <Link 
                className="primary-btn" 
                to={`/upload-report/${patientId}/${caseId}/`}
              >Upload file for this case</Link>
            </div>
            <div className="pt-5">
              <Link
                className="text-purple-light hover:text-purple"
                to={`/case/${patientId}/${caseId}/`}
              >Go to case details</Link>
            </div>
            <div className="pt-2">
              <Link className="text-purple-light hover:text-purple" to="/case/">View all Cases</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
