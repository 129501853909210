import React from "react";
import {ReactComponent as Logo} from "../../assets/logo.svg";

const BlockHeader = () => {
  return (
    <div className="page-header flex items-center gap-3 justify-end">
      <span 
        className="text-purple font-extrabold uppercase text-xs tracking-wide"
      >Oncominds Solutions Private Limited</span>
      <span className="flex flex-col">
        <span className="w-1 h-4 bg-purple-light"></span>
        <span className="w-1 h-4 bg-sky"></span>
        <span className="w-1 h-4 bg-pink"></span>
      </span>
      <div><Logo className="h-6 fill-purple-light"/></div>
    </div>
  );
};

export default BlockHeader;
