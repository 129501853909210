import {fixURL} from "../../../utils";
import {getKeyValueHTML} from "../../../utils/workflowUtils";

const grey = require("../../../assets/grey.svg");

export const getConfigs = (specialistId) => [{
  key: "personal-details",
  body: (formData, labelObj) => {
    const keys = [{
      dkey: "name"
    }, {
      dkey: "email"
    }, {
      dkey: "userName"
    }, {
      dkey: "phone",
      args: ["phone"],
      format: (phone) => {
        return phone.join(", ")
      }
    }, {
      dkey: "address1"
    }, {
      dkey: "address2"
    }, {
      dkey: "city"
    }, {
      dkey: "postalCode"
    }, {
      dkey: "state"
    }, {
      dkey: "country"
    }];

    return getKeyValueHTML(keys, labelObj, formData)
  }
}, {
  key: "professional-details",
  body: (formData, labelObj) => {
    const keys = [{
      dkey: "avatar",
      args: ["avatar"],
      format: (avatar) => {
        const isString = typeof avatar === "string";
        if(!isString && avatar && avatar.length){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(avatar[0]);
          fileReader.addEventListener("load", () => {
            document.getElementById("preview-image").setAttribute("src", fileReader.result);
          });
        }

        return (
          <img
            src={isString ? fixURL(true, specialistId, avatar) : grey.default}
            id="preview-image"
            className="w-11 rounded" 
            alt="preview specialist"
          />
        );
      }
    }, {
      dkey: "timezone"
    }, {
      dkey: "specialization"
    }, {
      dkey: "qualification"
    }, {
      dkey: "experience",
      args: ["experience"],
      format: (experience) => {
        return `${experience} years`;
      }
    }, {
      dkey: "canBeLeader",
      args: ["canBeLeader"],
      format: (canBeLeader) => {
        return canBeLeader ? "True" : "False"
      }
    }, {
      dkey: "languages",
      args: ["languages"],
      format: (languages, labelObject) => {
        return languages.map(lang => labelObject[lang]).join(", ");
      }
    }, {
      dkey: "bio",
      args: ["bio"],
      format: (bio) => {
        return (
          <div
            className="whitespace-pre-line"
            dangerouslySetInnerHTML={{__html: bio}}
          />
        )
      }
    }, {
      dkey: "linkedin",
      args: ["linkedin"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }, {
      dkey: "instagram",
      args: ["instagram"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }, {
      dkey: "facebook",
      args: ["facebook"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }, {
      dkey: "twitter",
      args: ["twitter"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }, {
      dkey: "youtube",
      args: ["youtube"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }, {
      dkey: "website",
      args: ["website"],
      format: (link) => {
        if(!link){
          return undefined;
        }
        return (
          <a
            className="text-purple-light hover:text-purple"
            href={link} target="_blank" rel="noopener noreferrer"
          >{link}</a>
        );
      }
    }];

    return getKeyValueHTML(keys, labelObj, formData)
  }
}];