import React, {useState, useEffect} from "react";

import Form from "./form";

import remove from "../../assets/delete.png";

const ConfirmDelete = (props) => {
  const {
    onConfirm, close, heading = "Deleting...", desc = "", textToConfirm = "delete"
  } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState({text: ""});

  useEffect(() => {
    const {text} = formData;
    if(text && text.toLowerCase() === textToConfirm){
      setIsDisabled(false);
      return;
    }
    setIsDisabled(true);
  }, [formData, textToConfirm]);

  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="w-68 bg-white rounded relative max-w-screen-md m-auto p-5">
        <img src={remove} alt="delete" className="w-7 h-7 m-auto mb-4"/>
        <h3 className="text-medium font-bold text-center text-black">{heading}</h3>
        <p
          className="text-text-gray mt-3 text-xs leading-4 px-2 text-center"
        >{desc || "Are you sure, you want to delete? Once deleted you won't be able to recover."}</p>

        <div className="mt-3">
          <Form
            initialValues={formData}
            config={[{
              name: "text",
              component: "textfield",
              label: `Type ${textToConfirm.toUpperCase()} to confirm`,
              required: true
            }]}
            onFormUpdate={(data) => {
              setFormData(data);
            }}
            onSubmit={(e, formData) => {
              const {text} = formData;
              if(text && text.toLowerCase() === textToConfirm){
                close();
                onConfirm();
              }
            }}
          />
        </div>

        <div className="flex items-center justify-center mt-1">
          <button
            className="mr-4 text-purple-light font-medium text-xs"
            onClick={close}
          >Cancel</button>
          <button
            className="primary-btn capitalize"
            disabled={isDisabled}
            onClick={() => {
              close();
              onConfirm();
            }}
          >{textToConfirm}</button>
        </div>
      </div>
    </div>
  )
};

export default ConfirmDelete;
