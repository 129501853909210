import {Document, Page} from "react-pdf";

import React, {Component, createRef} from "react";

class PDFViewer extends Component {
  constructor(props) {
    super(props);

    const {url, isThumbnail = false, bigThumbnail = false} = props;
    this.file = {url};
    this.state = {
      url,
      isThumbnail,
      bigThumbnail,
      numPages: 1,
      pageNumber: 1
    };

    this.pdfRef = createRef();
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  componentWillReceiveProps(props){
    const {url} = props;
    if(url !== this.file.url){
      this.file = {url};
      this.setState({numPages: 1});
    }
  }

  onDocumentLoadSuccess(data) {
    if(!this.state.isThumbnail){
      const {numPages} = data;
      this.setState({
        ...this.state, 
        pageNumber: 1, 
        numPages
      });
      
      if(this.pdfRef.current){
        setTimeout(() => {
          const element = this.pdfRef.current;
          element.style.height = `${element.offsetHeight}px`;
        }, 100);
      }
    }
  }

  changePage(offset) {
    this.setState({
      ...this.state,
      pageNumber: this.state.pageNumber + offset
    });
  }

  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }

  render() {
    const {isFullScreen = false} = this.props;
    const {isThumbnail, numPages, pageNumber, bigThumbnail} = this.state;

    return (
      <div
        className={`relative ${
          isThumbnail ? "pdf-thumnail-container" : "pdf-viewer"
        } ${isFullScreen ? "max-h-screen rounded" : ""}${
          bigThumbnail ? "big" : ""
        }`}
        ref={this.pdfRef}
      >
        <Document
          onLoadSuccess={this.onDocumentLoadSuccess}
          file={this.file}
        >
          <Page pageNumber={pageNumber} />
        </Document>

        {!isThumbnail && 
          <div className="pdf-page-control absolute bg-white rounded left-1/2 -translate-x-1/2">
            <button
              className="rounded-l bg-white border-0"
              type="button"
              disabled={pageNumber <= 1}
              onClick={this.previousPage}
            >&lt;</button>
            <span
              className="px-2"
            >{pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}</span>
            <button
              className="rounded-r bg-white border-0"
              type="button"
              disabled={pageNumber >= numPages}
              onClick={this.nextPage}
            >&gt;</button>
          </div>
        }
      </div>
    );
  }

};

export default PDFViewer;
