import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";

import youtube from "../../assets/social/youtube.svg";
import twitter from "../../assets/social/twitter.svg";
import facebook from "../../assets/social/facebook.svg";
import linkedin from "../../assets/social/linkedin.svg";
import instagram from "../../assets/social/instagram.svg";

const Footer = () => {
  const [year, setYear] = useState("");
  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);

  const socialMediaLinks = [{
    image: facebook,
    link: "https://www.facebook.com/oncominds/"
  }, {
    image: youtube,
    link: "https://www.youtube.com/@oncominds/"
  }, {
    image: twitter,
    link: "https://twitter.com/oncominds/"
  }, {
    image: instagram,
    link: "https://www.instagram.com/oncominds/"
  }, {
    image: linkedin,
    link: "https://www.linkedin.com/company/oncominds/"
  }];

  return (
    <footer className="pt-2 pb-15">
      <ul className="pb-5 flex justify-center">
        {
          socialMediaLinks.map(el => (
            <li className="px-2" key={el.link}>
              <a href={el.link} target="_blank" rel="noopener noreferrer">
                <img className="w-7" src={el.image} alt="social"/>
              </a>
            </li>
          ))
        }
      </ul>
      <ul className="pb-5 flex justify-center">
        <li className="px-2 border-r border-r-purple">
          <Link
            className="text-purple hover:text-purple-light" to="/privacy-policy/"
          >Privacy policy</Link>
        </li>
        <li className="px-2">
          <Link 
            className="text-purple hover:text-purple-light" to="/terms-of-use/"
          >Terms of use</Link>
        </li>
      </ul>
      <p className="text-center">Copyright &#169; {year} Oncominds. All rights reserved.</p>
    </footer>
  )
};

export default Footer;
