export const languages = [
	{label: "Afar", value: "aa"},
	{label: "Abkhazian", value: "ab"},
	{label: "Afrikaans", value: "af"},
	{label: "Akan", value: "ak"},
	{label: "Amharic", value: "am"},
	{label: "Aragonese", value: "an"},
	{label: "Arabic", value: "ar"},
	{label: "Assamese", value: "as"},
	{label: "Avar", value: "av"},
	{label: "Aymara", value: "ay"},
	{label: "Azerbaijani", value: "az"},
	{label: "Bashkir", value: "ba"},
	{label: "Belarusian", value: "be"},
	{label: "Bulgarian", value: "bg"},
	{label: "Bihari", value: "bh"},
	{label: "Bislama", value: "bi"},
	{label: "Bambara", value: "bm"},
	{label: "Bengali", value: "bn"},
	{label: "Tibetan", value: "bo"},
	{label: "Breton", value: "br"},
	{label: "Bosnian", value: "bs"},
	{label: "Catalan", value: "ca"},
	{label: "Chechen", value: "ce"},
	{label: "Chamorro", value: "ch"},
	{label: "Corsican", value: "co"},
	{label: "Cree", value: "cr"},
	{label: "Czech", value: "cs"},
	{label: "Old Church Slavonic / Old Bulgarian", value: "cu"},
	{label: "Chuvash", value: "cv"},
	{label: "Welsh", value: "cy"},
	{label: "Danish", value: "da"},
	{label: "German", value: "de"},
	{label: "Divehi", value: "dv"},
	{label: "Dzongkha", value: "dz"},
	{label: "Ewe", value: "ee"},
	{label: "Greek", value: "el"},
	{label: "English", value: "en"},
	{label: "Esperanto", value: "eo"},
	{label: "Spanish", value: "es"},
	{label: "Estonian", value: "et"},
	{label: "Basque", value: "eu"},
	{label: "Persian", value: "fa"},
	{label: "Peul", value: "ff"},
	{label: "Finnish", value: "fi"},
	{label: "Fijian", value: "fj"},
	{label: "Faroese", value: "fo"},
	{label: "French", value: "fr"},
	{label: "West Frisian", value: "fy"},
	{label: "Irish", value: "ga"},
	{label: "Scottish Gaelic", value: "gd"},
	{label: "Galician", value: "gl"},
	{label: "Guarani", value: "gn"},
	{label: "Gujarati", value: "gu"},
	{label: "Manx", value: "gv"},
	{label: "Hausa", value: "ha"},
	{label: "Hebrew", value: "he"},
	{label: "Hindi", value: "hi"},
	{label: "Hiri Motu", value: "ho"},
	{label: "Croatian", value: "hr"},
	{label: "Haitian", value: "ht"},
	{label: "Hungarian", value: "hu"},
	{label: "Armenian", value: "hy"},
	{label: "Herero", value: "hz"},
	{label: "Interlingua", value: "ia"},
	{label: "Indonesian", value: "id"},
	{label: "Interlingue", value: "ie"},
	{label: "Igbo", value: "ig"},
	{label: "Sichuan Yi", value: "ii"},
	{label: "Inupiak", value: "ik"},
	{label: "Ido", value: "io"},
	{label: "Icelandic", value: "is"},
	{label: "Italian", value: "it"},
	{label: "Inuktitut", value: "iu"},
	{label: "Japanese", value: "ja"},
	{label: "Javanese", value: "jv"},
	{label: "Georgian", value: "ka"},
	{label: "Kongo", value: "kg"},
	{label: "Kikuyu", value: "ki"},
	{label: "Kuanyama", value: "kj"},
	{label: "Kazakh", value: "kk"},
	{label: "Greenlandic", value: "kl"},
	{label: "Cambodian", value: "km"},
	{label: "Kannada", value: "kn"},
	{label: "Korean", value: "ko"},
	{label: "Kanuri", value: "kr"},
	{label: "Kashmiri", value: "ks"},
	{label: "Kurdish", value: "ku"},
	{label: "Komi", value: "kv"},
	{label: "Cornish", value: "kw"},
	{label: "Kirghiz", value: "ky"},
	{label: "Latin", value: "la"},
	{label: "Luxembourgish", value: "lb"},
	{label: "Ganda", value: "lg"},
	{label: "Limburgian", value: "li"},
	{label: "Lingala", value: "ln"},
	{label: "Laotian", value: "lo"},
	{label: "Lithuanian", value: "lt"},
	{label: "Latvian", value: "lv"},
	{label: "Malagasy", value: "mg"},
	{label: "Marshallese", value: "mh"},
	{label: "Maori", value: "mi"},
	{label: "Macedonian", value: "mk"},
	{label: "Malayalam", value: "ml"},
	{label: "Mongolian", value: "mn"},
	{label: "Moldovan", value: "mo"},
	{label: "Marathi", value: "mr"},
	{label: "Malay", value: "ms"},
	{label: "Maltese", value: "mt"},
	{label: "Burmese", value: "my"},
	{label: "Nauruan", value: "na"},
	{label: "North Ndebele", value: "nd"},
	{label: "Nepali", value: "ne"},
	{label: "Ndonga", value: "ng"},
	{label: "Dutch", value: "nl"},
	{label: "Norwegian Nynorsk", value: "nn"},
	{label: "Norwegian", value: "no"},
	{label: "South Ndebele", value: "nr"},
	{label: "Navajo", value: "nv"},
	{label: "Chichewa", value: "ny"},
	{label: "Occitan", value: "oc"},
	{label: "Ojibwa", value: "oj"},
	{label: "Oromo", value: "om"},
	{label: "Oriya", value: "or"},
	{label: "Ossetian", value: "os"},
	{label: "Punjabi", value: "pa"},
	{label: "Pali", value: "pi"},
	{label: "Polish", value: "pl"},
	{label: "Pashto", value: "ps"},
	{label: "Portuguese", value: "pt"},
	{label: "Quechua", value: "qu"},
	{label: "Raeto Romance", value: "rm"},
	{label: "Kirundi", value: "rn"},
	{label: "Romanian", value: "ro"},
	{label: "Russian", value: "ru"},
	{label: "Rwandi", value: "rw"},
	{label: "Sanskrit", value: "sa"},
	{label: "Sardinian", value: "sc"},
	{label: "Sindhi", value: "sd"},
	{label: "Sango", value: "sg"},
	{label: "Serbo-Croatian", value: "sh"},
	{label: "Sinhalese", value: "si"},
	{label: "Slovak", value: "sk"},
	{label: "Slovenian", value: "sl"},
	{label: "Samoan", value: "sm"},
	{label: "Shona", value: "sn"},
	{label: "Somalia", value: "so"},
	{label: "Albanian", value: "sq"},
	{label: "Serbian", value: "sr"},
	{label: "Swati", value: "ss"},
	{label: "Southern Sotho", value: "st"},
	{label: "Sundanese", value: "su"},
	{label: "Swedish", value: "sv"},
	{label: "Swahili", value: "sw"},
	{label: "Tamil", value: "ta"},
	{label: "Telugu", value: "te"},
	{label: "Tajik", value: "tg"},
	{label: "Thai", value: "th"},
	{label: "Tigrinya", value: "ti"},
	{label: "Turkmen", value: "tk"},
	{label: "Tagalog", value: "tl"},
	{label: "Tswana", value: "tn"},
	{label: "Tonga", value: "to"},
	{label: "Turkish", value: "tr"},
	{label: "Tsonga", value: "ts"},
	{label: "Tatar", value: "tt"},
	{label: "Twi", value: "tw"},
	{label: "Tahitian", value: "ty"},
	{label: "Uyghur", value: "ug"},
	{label: "Ukrainian", value: "uk"},
	{label: "Urdu", value: "ur"},
	{label: "Venda", value: "ve"},
	{label: "Vietnamese", value: "vi"},
	{label: "Volapük", value: "vo"},
	{label: "Walloon", value: "wa"},
	{label: "Wolof", value: "wo"},
	{label: "Xhosa", value: "xh"},
	{label: "Yiddish", value: "yi"},
	{label: "Yoruba", value: "yo"},
	{label: "Zhuang", value: "za"},
	{label: "Chinese", value: "zh"},
	{label: "Zulu", value: "zu"}
];

export const getLanguageObject = () => {
	return languages.reduce((data, el) => {
		const {value, label} = el;
		data[value] = label;
		return data;
	}, {});
};
