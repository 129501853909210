import React from "react";

const RadioCheckboxGroup = (props) => {
  const {
    component, name, label, required, direction, children, formData, onUpdate, className = ""
  } = props;

  const selectedValue = formData[name] ? (
    component === "radio-group" ? [formData[name]] : formData
  ) : [];

  const getSelectedValue = (elName) => {
    if(component === "radio-group"){
      return elName;
    }

    const existinVal = new Set([...selectedValue]);
    if(existinVal.has(elName)){
      existinVal.delete(elName);
      return [...existinVal];
    }
    existinVal.add(elName);
    return [...existinVal];
  };

  return (
    <div className={`mb-4 ${className}`}>
      <label className="form-label mb-2" htmlFor={`input-${name}`}>
        {label}
        {required && <span className="text-pink-deep">*</span>}
      </label>
      <div className={`flex flex-wrap flex-${direction === "row" ? "row" : "column" }`}>
        {
          children.map((el, index) => {
            const {name: elName, label} = el;
            return (
              <label
                className={`block pl-5 relative custom-input ${index < children.length - 1 ? "mr-3" : ""}`}
                htmlFor={name + elName}
                key={name + elName}
              >
                <input
                  checked={selectedValue.includes(elName)}
                  type={component === "radio-group" ? "radio" : "checkbox"}
                  value={elName}
                  name={name}
                  id={name + elName}
                  className="absolute opacity-0"
                  onChange={() => {
                    onUpdate({
                      ...formData,
                      [name]: getSelectedValue(elName)
                    });
                  }}
                />
                <span
                  className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
                />
                <span className="label-text">{label}</span>
              </label>
            );
          })
        }
      </div>
    </div>
  )
};

export default RadioCheckboxGroup;
