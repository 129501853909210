import React from "react";
import { DefaultEditor } from "react-simple-wysiwyg";

const RichEditorModal = (props) => {
  const {text, callback, close} = props;

  return (
    <div 
      className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center text-base text-black"
    >
      <div
        className="bg-white rounded relative w-full max-w-screen-md flex m-auto popup-editor font-normal"
      >
        <DefaultEditor
          value={text}
          onChange={(e) => {
            callback(e.target.value)
          }}
        />
        <div
          className="text-gray absolute top-0.5 right-0 cursor-pointer text-1xl px-2"
          onClick={close}
        >&times;</div>
      </div>
    </div>
  );
};

export default RichEditorModal;
