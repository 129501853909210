import {isOmLeader, isOmAdmin, isTenantAdmin} from "../../utils";

import add from "../../assets/add.png";
import edit from "../../assets/edit.png";
import lead from "../../assets/leader.png";
import upload from "../../assets/upload.png";
import report from "../../assets/report.png";
import remove from "../../assets/delete.png";
import specialist from "../../assets/specialist.png";

export const getCaseDetailsDotMenu = (
  caseId, patientId, userRoles, caseStatus, setAssigningLeader, setShowSearchModal, setShowDeleteCaseConfirm
) => {
  if(!isOmAdmin(userRoles) && !isOmLeader(userRoles) && !isTenantAdmin(userRoles)){
    return [];
  }

  const returnArr = [{
    icon: edit,
    name: "Edit Case",
    type: "link",
    to: `/edit-case/${caseId}/case-type/`
  }, {
    icon: upload,
    name: "Upload Reports",
    type: "link",
    to: `/upload-report/${patientId}/${caseId}/`
  }, {
    icon: add,
    name: "Manage Events",
    type: "link",
    to: `/add-event/${patientId}/${caseId}/analyze-records/`
  }, {
    icon: lead,
    name: "Assign Lead",
    click: () => {
      setAssigningLeader(true);
      setShowSearchModal(true);
    }
  }, {
    icon: specialist,
    name: "Assign Specialists",
    click: () => {
      setAssigningLeader(false);
      setShowSearchModal(true);
    }
  }, {
    icon: report,
    name: "Generate Report",
    type: "link",
    newTab: true,
    to: `/report/${patientId}/${caseId}/`
  }];

  if((isTenantAdmin(userRoles) || isOmAdmin(userRoles)) && caseStatus === "new"){
    returnArr.splice(3, 0, {
      icon: remove,
      name: "Delete Case",
      click: () => {
        setShowDeleteCaseConfirm(true);
      }
    });
  }

  if(isTenantAdmin(userRoles)){
    return returnArr.slice(0, caseStatus === "new" ? 4 : 3);
  }

  if(isOmLeader(userRoles)){
    const reportGeneration = returnArr.pop();
    return [returnArr.pop(), reportGeneration];
  }

  return returnArr;
};
