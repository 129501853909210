import React, {useState, useEffect, useCallback} from "react";

import Dropdown from "./dropdown";
import TextField from "./textfield";
import add from "../../../assets/plus.png";
import trash from "../../../assets/delete.svg";

import {getValidationObj, schemaValidate} from "../../../utils/formUtils";

const Repeater = (props) => {
  const {
    name, multiColumn, children, formData, onUpdate, title, schema, limit = 0, fullWidth = false
  } = props;

  const initData = formData[name] || [];
  const initRepeaterData = initData.reduce((data, el, index) => {
    data[`${name}-${index + 1}`] = el;
    return data;
  }, {});
  const clonedFirstChild = children.length ? {...children[0]} : {
    name: `${name}-1`, 
    component: "textarea",
    label: title
  };
  const childrenObj = !initData.length ? children : initData.map((el, index) => {
    return {
      ...clonedFirstChild,
      name: `${name}-${index + 1}`,
      value: el
    }
  });
  
  const [validationObj, setValidationObj] = useState({});
  const [touchedFieldObj, setTouchedFieldObj] = useState({});
  const [allChildren, setAllChildren] = useState(childrenObj);
  const [curIndex, setCurrentIndex] = useState(childrenObj.length);
  const [repeaterData, setRepeaterData] = useState(initRepeaterData);

  useEffect(() => {
    const curValidation = [...Object.keys(repeaterData)].reduce((data, key) => {
      const value = repeaterData[key];
      if(schema && schema[name]){
        const {isValid, message} = schemaValidate(value, schema[name], repeaterData)
        data[key] = getValidationObj(isValid, message);
        return data;
      }

      data[key] = getValidationObj(value !== "");
      return data;
    }, {});
    setValidationObj(curValidation);
  }, [repeaterData, schema, name]);

  const handleDelete = useCallback((index, keyName) => {
    setAllChildren(allChildren => {
      const clonedChild = [...allChildren];
      console.log(allChildren);
      clonedChild.splice(index, 1);
      return clonedChild;
    });
    
    setTouchedFieldObj(tFields => {
      const clonedTFields = {...tFields};
      delete clonedTFields[keyName];
      return clonedTFields;
    });

    setValidationObj(vObj => {
      const clonedVObj = {...vObj};
      delete clonedVObj[keyName];
      return clonedVObj;
    });

    const clonedRData = {...repeaterData};
    delete clonedRData[keyName];
    setRepeaterData(clonedRData);

    const keyData = [...Object.keys(clonedRData)].map(key => clonedRData[key]);
    onUpdate({
      ...formData,
      [name]: keyData
    });
  }, [repeaterData, onUpdate, name, formData]);

  const shouldShowAddBtn = limit === 0 ? true : allChildren.length < limit;

  return (
    <div className="flex flex-wrap w-140">
      {
        allChildren.map((childData, index) => {
          const {name: childName, component} = childData;
          return (
            <div
              className={`relative${fullWidth ? " w-full" : ""}`}
              key={name + childName}
            >
              {
                component === "textarea" &&
                <TextField
                  {...childData}
                  fullWidth={fullWidth}
                  onUpdate={(data) => {
                    setRepeaterData(data);
                    const keyData = [...Object.keys(data)].map(key => data[key]);
                    onUpdate({
                      ...formData,
                      [name]: keyData
                    });
                  }}
                  formData={repeaterData}
                  multiColumn={multiColumn}
                  validationObj={validationObj}
                  touchedFieldObj={touchedFieldObj}
                  setTouchedFieldObj={setTouchedFieldObj}
                /> 
              }
              {
                component === "dropdown" &&
                <Dropdown
                  {...childData}
                  fullWidth={fullWidth}
                  onUpdate={(data) => {
                    setRepeaterData(data);
                    const keyData = [...Object.keys(data)].map(key => data[key]);
                    onUpdate({
                      ...formData,
                      [name]: keyData
                    });
                  }}
                  formData={repeaterData}
                  multiColumn={multiColumn}
                  validationObj={validationObj}
                  touchedFieldObj={touchedFieldObj}
                  setTouchedFieldObj={setTouchedFieldObj}
                />
              }
              <div
                className="absolute h-6 w-11 -right-3 top-3 flex flex-row justify-start items-center"
              >
                {allChildren.length > 1 && 
                  <div className="cursor-pointer">
                    <img
                      src={trash}
                      className="w-3"
                      alt="delete"
                      onClick={() => handleDelete(index, childName)}
                    />
                  </div>
                }
                {index === allChildren.length - 1 && shouldShowAddBtn &&
                  <div
                    className="ml-2 cursor-pointer"
                    onClick={() => {
                      setAllChildren(allChildren => {
                        const clonedChildren = [...allChildren];
                        const clonedElm = {...clonedChildren[clonedChildren.length - 1]};
                        clonedElm.name = `${name}-${curIndex + 1}`;
                        clonedChildren.push(clonedElm);
                        return clonedChildren;
                      });

                      const keyData = [...Object.keys(repeaterData)].map(key => repeaterData[key]);
                      onUpdate({
                        ...formData,
                        [name]: [...keyData, ""]
                      });
                    
                      setCurrentIndex(curIndex + 1);
                    }}
                  >
                    <img
                      src={add}
                      className="w-6"
                      alt="add"
                    />
                  </div>
                }
              </div>
            </div>
          )
        })
      }
      
    </div>
  );
};

export default Repeater;
