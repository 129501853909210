import React from "react";
import {fixURL} from "../../utils";

const TumourBoard = (props) => {
  const {allSpecialist} = props;

  return (
    <div className="bg-white p-3 mt-3 rounded tracking-wider text-xs border-b border-card-shadow">
      <div className="uppercase font-medium">Tumour board</div>
      <ul className="mt-2 flex">
        {
          allSpecialist.map(({id, name, avatarUrl, specialization}) => (
            <li
              title={name}
              key={id}
              className="mr-2 text-center"
            >
              <img
                className="max-w-25 max-h-20 rounded mx-auto"
                src={fixURL(true, id, avatarUrl)}
                alt={name}
              />
              <div className="max-w-25 overflow-hidden whitespace-nowrap">
                <div className="font-medium uppercase text-black mt-2 text-ellipsis overflow-hidden">
                  {name}
                </div>
                <div className="mt-1 text-text-gray text-ellipsis overflow-hidden">
                  {specialization}
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default TumourBoard;
