import React, {useState} from "react";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import Form from "../../common/form";
import {fixURL} from "../../../utils";
import PDFViewer from "../../pdfViewer";
import DicomViewer from "../../common/dicomViewer";
import {getAnalyzeRecordConfigs} from "../configs/analyzeRecords.config";

const grey = require("../../../assets/grey.svg");

const AnalyzeRecordModal = (props) => {
  const {selectedRecord, close, patientId, callback} = props;
  const {fileType, url, type, date, description} = selectedRecord;

  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <div 
      className={`bg-black/50 z-30 fixed top-0 left-0 w-full h-full 
      flex justify-center text-base text-black`}
    >
      <div
        className="bg-white rounded relative flex m-auto font-normal max-w-screen-xl"
      >
        <div className="popup-editor w-110">
          {fileType === "pdf" &&
            <PDFViewer
              url={fixURL(false, patientId, url)}
              isFullScreen={true}
            />
          }
          {fileType === "image" && 
            <LazyLoadImage
              className="m-auto rounded max-h-screen"
              alt={"record"}
              src={fixURL(false, patientId, url)}
              placeholder={grey.default}
              debounceDelay={100}
            />
          }
          {fileType === "dicom" && 
            <DicomViewer
              url={url}
            />
          }
        </div>
        <div className="grow p-3 relative">
          <Form
            config={getAnalyzeRecordConfigs(0)}
            initialValues={{
              "type-0": type, 
              "date-0": date,
              "description-0": description
            }}
            multiColumn={true}
            onFormUpdate={(stepData) => {
              const type = stepData["type-0"];
              const date = stepData["date-0"];
              const description = stepData["description-0"];
              setFormData({
                type,
                date,
                description
              });
            }}
            onFormValid={(isFormValid) => {
              setIsFormValid(isFormValid);
            }}
          />
          <div className="absolute flex justify-end left-0 right-0 bottom-0 pb-5 px-5">
            <button
              className="mr-4 text-purple-light font-medium text-xs"
              onClick={close}
            >Cancel</button>
            <button
              className="primary-btn"
              disabled={!isFormValid}
              onClick={() => {
                callback(formData);
                close();
              }}
            >Update record</button>
          </div>
        </div>
        <div
          className="text-white absolute top-0 -right-7 cursor-pointer text-3xl px-2"
          onClick={close}
        >&times;</div>
      </div>
    </div>
  )
};

export default AnalyzeRecordModal;
