import React, {useState} from "react";
import {recordCategories} from "../../constant";

import {ReactComponent as AscLogo} from "../../assets/asc.svg";
import {ReactComponent as DescLogo} from "../../assets/desc.svg";
import {ReactComponent as FilterLogo} from "../../assets/filter.svg";

const History = (props) => {
  const {isAsc, setIsAsc, selectedFilters, setSelectedFilters} = props;
  const [hideShowFilter, setHideShowFilter] = useState(false);

  return (
    <div className="p-2 text-text-gray tracking-wide">
      <div className="flex items-center justify-between">
        <h3 className="grow uppercase font-bold tracking-wider">
          Medical History
        </h3>
        <div
          title="Chronological Order"
          className="cursor-pointer py-0.5 px-1 rounded hover:bg-gray/50"
          onClick={() => {
            setIsAsc(!isAsc);
          }}
        >
          {isAsc ? <AscLogo className="w-4 fill-sky"/> : <DescLogo className="w-4 fill-sky"/>}
        </div>
        <div
          title="Hide/Show Filter"
          className="ml-1 cursor-pointer py-0.5 px-1 rounded hover:bg-gray/50"
          onClick={() => {
            setHideShowFilter(!hideShowFilter);
          }}
        >
          <FilterLogo className="w-4 h-4 fill-sky"/>
        </div>
      </div>
      {hideShowFilter && 
        <div className="my-2 text-xs grid grid-rows-4 gap-1.5 font-normal">
        {
          recordCategories.map(({name, value}) => (
            <label
              className="block pl-5 relative custom-input"
              key={value}
            >
              <input
                checked={selectedFilters.includes(value)}
                type="checkbox"
                value={value}
                name="medical-history"
                className="absolute opacity-0"
                onChange={() => setSelectedFilters(fData => {
                  const cData = Array.from(fData);
                  if(cData.includes(value)){
                    const index = cData.findIndex(el => el === value);
                    cData.splice(index, 1);
                    return cData;
                  }
                  cData.push(value);
                  return cData;
                })}
              />
              <span
                className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
              />
              <span className="label-text">{name}</span>
            </label>
          ))
        }
        </div>
      }
    </div>
  );
};

export default History;
