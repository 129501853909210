import Done from "../../components/createSpecialist/done";
import Summary from "../../components/createSpecialist/summary";
import PersonalDetails from "../../components/createSpecialist/personalDetails";
import ProfessionalDetails from "../../components/createSpecialist/professionalDetails";

export const workflowSteps = [{
  id: "personal-details",
  title: "Personal Details",
  description: "Fill out the personal details of the specialist to create specialist.",
  component: PersonalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "professional-details",
  title: "Professional Details",
  description: "Fill out the professional details of the specialist to create specialist.",
  component: ProfessionalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "summary",
  title: "Summary",
  description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Create Specialist.`,
  component: Summary,
  layout: {
    primary: {
      label: "Create Specialist"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "done",
  title: "Done!",
  description: "The specialist is being created.",
  component: Done,
  layout: {
    footer: false
  }
}];