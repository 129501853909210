import React, {useState} from "react";

import secure from "../../assets/secure.svg";
import globalAccess from "../../assets/global-access.svg";
import fasterDecision from "../../assets/faster-decision.svg";
import analytics from "../../assets/analytics-smart-search.svg";
import peerReview from "../../assets/peer-reviewed-diagnosis.svg";

const Aspects = () => {
  const aspectData = [{
    bg: "bg-sky-blue",
    img: analytics,
    cname: "w-9 bottom-5 right-6 mobile:w-6 mobile:right-2",
    textCname: "mobile:pt-2 mobile:pl-3",
    text: [{
      text: "Analytics"
    }, {
      text: "& smart",
      cname: "mt-1 pl-7 mobile:pl-3"
    }, {
      text: "Search",
      cname: "mt-1"
    }],
    description: `The platform sifts through gigabytes of data to
    surface relevant and actionable information`
  }, {
    bg: "bg-pink",
    img: peerReview,
    cname: "top-4 right-10 w-12 mobile:w-7 mobile:right-5 mobile:top-3",
    text: [{
      text: "Peer",
      cname: "mt-2"
    }, {
      text: "Reviewed",
      cname: "mt-1 pl-4 mobile:pl-2"
    }, {
      text: "Diagnosis",
      cname: "mt-1"
    }],
    description: `Peer-to-Peer network with
    renowned experts to tap into global expertise`
  }, {
    bg: "bg-purple-light",
    img: secure,
    cname: "w-9 top-6 right-8 max-h-12 mobile:w-5 mobile:right-5 mobile:top-4",
    text: [{
      text: "100%",
      cname: "mt-4"
    }, {
      text: "Secure",
      cname: "mt-1 pl-3"
    }],
    description: `Data privacy is strictly enforced`
  }, {
    bg: "bg-pink",
    img: globalAccess,
    cname: "w-9 top-7 right-5 mobile:top-3 mobile:w-6 mobile:right-6",
    textCname: "mobile:pt-10",
    text: [{
      text: "Global"
    }, {
      text: "Access",
      cname: "mt-1 pl-4"
    }],
    description: `Partnerships and alliances with global organisations,
    academia, R&D centres and emerging pharma`
  }, {
    bg: "bg-sky-blue",
    img: fasterDecision,
    cname: "w-9 top-4 right-7 mobile:w-5 mobile:right-3 mobile:top-4",
    textCname: "mobile:pt-7",
    text: [{
      text: "Faster"
    }, {
      text: "Decision",
      cname: "mt-1 pl-3 mobile:pl-1"
    }, {
      text: "Making",
      cname: "mt-1"
    }],
    description: `Intelligent workflow management and
    collaboration platform`
  }];

  const [{description: dscToSet}] = aspectData;
  const [description, setDescription] = useState(dscToSet);

  return (
    <section className="mt-23">
      <h2 className="text-purple-l font-bold text-3xl pb-10 mobile:pb-6">Benefits</h2>
      <div
        className={`
          flex justify-between text-white font-bold text-medium-l
          mobile:flex-col mobile:px-4 mobile:text-xs
        `}
      >
        {
          aspectData.map(({bg, img, cname, textCname = "", text, description}, index) => (
            <div
              key={`${bg}-${index}`}
              className={`
                cursor-pointer mobile:flex mobile:py-3 mobile:border-b-4
                mobile:border-sky-light mobile:items-center
                ${index === 0 ? " mobile:border-t-4" : ""}
              `}
              onClick={() => {
                setDescription(description);
              }}
            >
              <div
                className={`
                  h-36 w-36 ${bg} relative rounded-full flex items-center
                  mobile:h-22 mobile:w-22 mobile:min-w-22
                `}
              >
                <div className={`uppercase flex flex-col p-4 w-full ${textCname}`}>
                  {
                    text.map(({text, cname}, cindex) => (
                      <span key={text + cindex + index} className={cname}>{text}</span>
                    ))
                  }
                </div>
                <img
                  src={img}
                  className={`absolute ${cname}`}
                  alt="aspects"
                />
              </div>
              <div
                className="hidden leading-snug text-base text-black mobile:block mobile:pl-4"
              >{description}</div>
            </div>
          ))
        }
      </div>
      <div className="mt-11 text-center text-1xl text-black font-bold leading-tight mobile:hidden">
        {description}
      </div>
    </section>
  )
};

export default Aspects;
