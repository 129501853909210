import React, {useState} from "react";

const TextField = (props) => {
  const {
    name, multiColumn, className, required, label, password, number, validationObj, 
    touchedFieldObj, formData, setTouchedFieldObj, onUpdate, change, fullWidth = false,
    readonly = false
  } = props;
  const [hideShowPassword, setHideShowPassword] = useState(false);

  return (
    <div
      key={name}
      id={name}
      className={`flex flex-col items-start justify-start relative h-13 ${
        multiColumn ? (fullWidth ? "w-130" : "w-68") : ""
      }${className ? ` ${className}` : ""}`}
    >
      <div className={`flex flex-row items-center${fullWidth ? " w-full" : ""}`}>
        <div className={`input-group relative${fullWidth ? " full" : ""}`}>
          <input
            className={`${
              validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] && required ? "has-error" : ""
            } ${
              formData[name] ? "has-value" : ""
            }`}
            type={password && !hideShowPassword ? "password" : number ? "number" : "text"}
            name={name}
            id={`input-${name}`}
            required={required}
            autoComplete="off"
            onChange={(e) => {
              const value = (e.target || e.currentTarget).value;
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));

              if(change && change instanceof Function){
                const dataToSet = change(value, formData, number);
                onUpdate({
                  ...formData,
                  ...dataToSet
                });
                return;
              }
              onUpdate({
                ...formData,
                [name]: number ? parseInt(value) : value
              });
            }}
            onBlur={() => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }))
            }}
            value={formData[name] || ""}
            readOnly={readonly}
          />
          <label className="form-label" htmlFor={`input-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          {validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
            className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
          {formData[name] !== undefined && password && 
            <span
              className="absolute text-xs-m cursor-pointer text-sky tracking-wider right-0 top-5 uppercase"
              onClick={() => setHideShowPassword(!hideShowPassword)}
            >{hideShowPassword ? "hide" : "show"}</span>
          }
        </div>
      </div>
    </div>
  )
};

export default TextField;
