import React, {useState, useEffect} from "react";
import search from "../../assets/search.svg";
import useRequest from "../../hooks/useRequest";
import useDebounce from "../../hooks/useDebounce";
import {formUrlEncoded, fixURL} from "../../utils";
import {API_PATH, API_RESULT_COUNT} from "../../constant";

const SearchSpecialist = (props) => {
  const {close, alreadySelected, leaderSelection, onSelect} = props;
  const [offset, setOffset] = useState(0);
  const [queryTerm, setQueryTerm] = useState("");
  const [headerObject, setHeaderObject] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [specialistList, setSpecialistList] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState({});
  const [existingOrSelected, setExistingOrSelected] = useState(false);
  const [allSelectedSpecialist, setAllSelectedSpecialist] = useState([]);

  const {getAllSpecialists} = API_PATH;
  const {
    loading: specialistLoading, data: specialistData, callAPI: callSpecialistAPI
  } = useRequest(`${getAllSpecialists}?${formUrlEncoded({
    offset, limit: API_RESULT_COUNT
  })}`, false, {}, "GET", {...headerObject});

  useEffect(() => {
    if(!specialistLoading && specialistData && specialistData.result) {
      const {content} = specialistData.result;
      setSpecialistList(specialist => ([...specialist, ...content]));
      setOffset(offset => offset + 1);
    }
  }, [specialistData, specialistLoading]);

  const resetData = () => {
    setSpecialistList([]);
    setOffset(0);
    setSelectedSpecialist({});
    setShowDetails(false);
  };

  useEffect(() => {
    const allFilters = [];
    if(queryTerm){
      allFilters.push(`name:*${queryTerm}*`);
    }

    if(leaderSelection){
      allFilters.push(`canBeLeader:true`);
    }

    setHeaderObject(allFilters.length ? {
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ")
    } : {});
  }, [leaderSelection, queryTerm]);

  const debouncedFunc = useDebounce(callSpecialistAPI);
  useEffect(() => {
    resetData();
    setTimeout(() => {
      debouncedFunc();
    });
  }, [headerObject, debouncedFunc]);

  useEffect(() => {
    setAllSelectedSpecialist([...alreadySelected]);
  }, [alreadySelected]);

  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="bg-white rounded relative max-w-screen-md flex m-auto p-3">
        <div className="min-w-60">
          <h3 className="text-medium font-bold">Search {leaderSelection ? "Lead" : "Specialists"}</h3>
          <div className="flex py-1 mt-2 bg-gray/30 rounded items-center">
            <div className="pl-2">
              <img src={search} alt="search" className="w-5"/>
            </div>
            <div className="w-full">
              <input
                type="text"
                value={queryTerm}
                className="h-6 w-full px-2 outline-none bg-transparent"
                placeholder="Search"
                onChange={e => setQueryTerm(e.target.value)}
              />
            </div>
          </div>

          <ul className="select-record-container overflow-auto mt-1">
            {
              specialistList.map((specialist, index) => {
                const {id, name, avatarUrl, email, specialization} = specialist;

                return (
                  <li
                    key={id}
                    className={`
                      flex py-2 cursor-pointer 
                      ${index < specialistList.length - 1 ? "border-b border-b-gray-light" : ""}
                      ${selectedSpecialist && id === selectedSpecialist.id ? "border-r-4 border-r-sky" : ""}
                    `}
                    onClick={() => {
                      setSelectedSpecialist(specialist);
                      setShowDetails(true);
                      setExistingOrSelected(
                        allSelectedSpecialist.map(el => el.id).includes(specialist.id)
                      );
                    }}
                  >
                    <img
                      src={fixURL(true, id, avatarUrl)}
                      alt="profile"
                      className="w-11 rounded"
                    />
                    <div className="grow pl-3 tracking-wider">
                      <h3 className={
                        `text-medium font-bold tracking-normal whitespace-nowrap overflow-hidden text-ellipsis w-50
                        ${selectedSpecialist && id === selectedSpecialist.id ? "text-sky" : "text-black"}
                        `
                      }>{name}</h3>
                      <div className="text-text-gray text-xs mt-1">{specialization}</div>
                      <span className="block text-xs mt-1/2">{email}</span>
                    </div>
                  </li>
                )}
              )
            }
            {specialistLoading &&
              <li className="text-center py-1 font-medium text-text-gray">Loading...</li>
            }
            {!specialistLoading && specialistList.length === 0 && 
              <li
                className="text-center py-1 font-medium text-text-gray"
              >No {leaderSelection ? "lead" : "specialist"} found!</li>
            }
          </ul>
        </div>

        {showDetails &&
          <div className="grow px-6 relative">
            <div className="flex">
              <img
                src={fixURL(true, selectedSpecialist.id, selectedSpecialist.avatarUrl)}
                alt="profile"
                className="w-11 rounded"
              />
              <div className="grow pl-3">
                <h3
                  className={`text-black text-medium font-bold tracking-normal 
                  whitespace-nowrap overflow-hidden text-ellipsis w-50`}
                >{selectedSpecialist.name}</h3>
                <div
                  className="mt-1 text-text-gray tracking-wider text-xs"
                >{selectedSpecialist.specialization}</div>
              </div>
            </div>
            <div className="mt-3 flex text-xs font-medium tracking-wider">
              <div className="w-1/2">
                <div className="text-text-gray uppercase">Qualification</div>
                <div className="mt-1 text-black">
                  {selectedSpecialist.qualification || "--"}
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-text-gray uppercase">Phone</div>
                <div className="mt-1 text-black">{selectedSpecialist.phone}</div>
              </div>
            </div>
            <div className="mt-3 flex font-medium tracking-wider">
              <div className="text-xs">
                <div className="text-text-gray uppercase">Email</div>
                <div className="mt-1 text-sky">
                  <a
                    href={`mailto:${selectedSpecialist.email}`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >{selectedSpecialist.email}</a>
                </div>
              </div>
            </div>

            <div className="mt-3 text-xs font-medium tracking-wider">
              <div className="text-text-gray uppercase">About</div>
              <div
                className="mt-1 text-black leading-snug html-body"
                dangerouslySetInnerHTML={{__html: selectedSpecialist.bio}}
              />
            </div>

            {(leaderSelection || !existingOrSelected) &&
              <button
                className="mt-3 primary-btn"
                disabled={existingOrSelected}
                onClick={() => {
                  if(leaderSelection){
                    onSelect([selectedSpecialist]);
                    close();
                    return;
                  }

                  setAllSelectedSpecialist(extSpcl => {
                    const updatedSpecialist = [
                      ...extSpcl, selectedSpecialist
                    ];
                    setExistingOrSelected(
                      updatedSpecialist.map(el => el.id).includes(selectedSpecialist.id)
                    );
                    return updatedSpecialist;
                  });
                }}
              >Select {leaderSelection ? "lead" : "specialist"}</button>
            }

            {!leaderSelection && existingOrSelected && 
              <button
                className="mt-3 btn-danger"
                onClick={() => {
                  const index = allSelectedSpecialist.findIndex(el => el.id === selectedSpecialist.id);
                  const clonedSpecialist = [...allSelectedSpecialist];
                  clonedSpecialist.splice(index, 1);
                  setAllSelectedSpecialist(clonedSpecialist);
                  setExistingOrSelected(
                    clonedSpecialist.map(el => el.id).includes(selectedSpecialist.id)
                  );
                }}
              >Remove specialist</button>
            }

            {!leaderSelection && allSelectedSpecialist.length > 0 &&
              <div className="absolute bottom-0 left-0 right-0">
                <div className="flex pl-2">
                  <div className="grow">
                    <ul className="flex items-center">
                    {
                      allSelectedSpecialist.map((specialist, index) => {
                        const {id, avatarUrl} = specialist;
                        return (
                          <li
                            className={index > 0 ? "-ml-4" : ""}
                            key={id + index}
                          >
                            <img
                              src={fixURL(true, id, avatarUrl)}
                              alt="specialist"
                              className="h-7 w-7 rounded-full border border-gray"
                            />
                          </li>
                        );
                      })
                    }
                    </ul>
                  </div>
                  <div>
                    <button
                      className="primary-btn"
                      onClick={() => {
                        onSelect(allSelectedSpecialist);
                        close();
                      }}
                    >Assign Selected</button>
                  </div>
                </div>
              </div>
            }
          </div>
        }

        <div
          className="text-gray absolute top-1 right-1 cursor-pointer text-3xl px-2"
          onClick={close}
        >&times;</div>
      </div>
    </div>
  )
};

export default SearchSpecialist;