export const eventTypes = [{
  label: "Diagnosis",
  value: "diagnosis"
}, {
  label: "Surgery",
  value: "surgery"
}, {
  label: "Adjuvant radiotherapy",
  value: "adjuvant_radiotherapy"
}, {
  label: "Adjuvant systemic therapy",
  value: "adjuvant_systemic_therapy"
}, {
  label: "Neoadjuvant systemic therapy",
  value: "neoadjuvant_systemic_therapy"
}, {
  label: "Neoadjuvant chemoradiotherapy",
  value: "neoadjuvant_chemoradiotherapy"
}, {
  label: "Palliative systemic therapy",
  value: "palliative_systemic_therapy"
}, {
  label: "Palliative radiotherapy",
  value: "palliative_radiotherapy"
}, {
  label: "Palliative surgery",
  value: "palliative_surgery"
}, {
  label: "Disease Relapse",
  value: "disease_relapse"
}, {
  label: "Toxicity event",
  value: "toxicity_event"
}, {
  label: "Disease Progression",
  value: "disease_progression"
}, {
  label: "Palliative care",
  value: "palliative_care"
}, {
  label: "Disease free survival",
  value: "disease_free_survival"
}, {
  label: "Progression free survival",
  value: "progression_free_survival"
}, {
  label: "Death",
  value: "death"
}, {
  label: "Other",
  value: "other"
}];

export const eventTypeObject = eventTypes.reduce((data, eventType) => {
  const {label, value} = eventType;
  data[value] = label;
  return data;
}, {});
