import React, {useEffect, Fragment} from "react";
import {Link, useParams} from "react-router-dom";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {showAlert, formatString} from "../../utils";
import {createPayload} from "./configs/done.config";

import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

const Done = (props) => {
  const {getState} = props;
  const {tenantId} = useParams();
  const stateData = getState();

  const {getSpecificTenants} = API_PATH;
  const payload = createPayload(stateData);
  const {
    data: tenantData, loading: tenantLoading, callAPI: tenantCallAPI, error
  } = useRequest(formatString(getSpecificTenants, [tenantId]), false, payload, "PATCH");
  useEffect(() => {
    if(tenantData && !tenantLoading){
      showAlert("The tenant has been edited successfully!");
    }
  }, [tenantData, tenantLoading]);

  useEffect(() => {
    tenantCallAPI();
  }, [tenantCallAPI]);

  const caseMessage = tenantLoading ? "Tenant edit is in progress..." : (
    error ? "Tenant edit has failed!" : "Tenant has been successfully edited!"
  );

  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {tenantLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!tenantLoading && 
            <Fragment>
              {!error && <Success className="w-4 h-4"/>}
              {error && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {!tenantLoading &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            <div className="pt-2">
              <Link
                className="text-purple-light hover:text-purple"
                to="/manage-tenants/"
              >View all Tenants</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
