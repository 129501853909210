import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Link} from "react-router-dom";
import {usePlacesWidget} from "react-google-autocomplete";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import useRequest from "../hooks/useRequest";
import useDebounce from "../hooks/useDebounce";
import {allTimezones} from "../constant/timezone";
import DotMenu from "../components/common/dotMenu";
import ConfirmDelete from "../components/common/confirmDelete";

import {getDotMenu} from "./configs/manage-specialists.config";
import {languages, getLanguageObject} from "../constant/languages";
import {formUrlEncoded, fixURL, formatString, showAlert} from "../utils";
import {
  experienceRange, API_PATH, API_RESULT_COUNT, GOOGLE_MAP_API_KEY, socialMediaImageMap
} from "../constant";

import cross from "../assets/cross.svg";
import search from "../assets/search.svg";
const grey = require("../assets/grey.svg");

const ManageSpecialist = () => {
  const {loading: contextLoading} = useContextData();
  
  const [offset, setOffset] = useState(0);
  const [typedName, setTypedName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [headerObject, setHeaderObject] = useState({});
  const [allSpecialist, setAllSpecialist] = useState([]);
  const [intendedAction, setIntendedAction] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState("");
  
  const languageObject = getLanguageObject();
  const {getAllSpecialists, getSpecificSpecialist} = API_PATH;
  const {
    data: specialistData, loading: specialistLoading, callAPI: callSpecialistAPI
  } = useRequest(`${getAllSpecialists}?${formUrlEncoded({
    offset, limit: API_RESULT_COUNT
  })}`, false, {}, "GET", {...headerObject});
  useEffect(() => {
    if(!specialistLoading && specialistData && specialistData.result){
      const {result} = specialistData;
      const {content} = result;
      setAllSpecialist(specialist => {
        return [...specialist, ...content];
      });
      setOffset(offset => offset + 1);
    }
  }, [specialistData, specialistLoading]);

  const resetData = () => {
    setAllSpecialist([]);
    setOffset(0);
  };

  useEffect(() => {
    const allFilters = [];
    if(selectedLanguage.length > 0){
      allFilters.push(selectedLanguage.reduce((data, el) => {
        data.push(`languages:${el},* OR languages:*${el},* OR languages:*,${el} OR languages:${el}`);
        return data;
      }, []).join(" OR "));
    }

    if(selectedExperience.length > 0){
      allFilters.push(selectedExperience.reduce((data, el) => {
        const range = el.split("-");
        data.push(`(experience>${range[0]} AND experience<${range[1]})`);
        return data;
      }, []).join(" OR "))
    }

    if(typedName){
      allFilters.push(`name:*${typedName}*`);
    }

    if(selectedCity){
      allFilters.push(`city:${selectedCity.toLowerCase()}*`);
    }

    setHeaderObject(allFilters.length ? {
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ")
    } : {});
  }, [typedName, selectedCity, selectedLanguage, selectedExperience]);

  const debouncedFunc = useDebounce(callSpecialistAPI);
  useEffect(() => {
    resetData();
    setTimeout(() => {
      debouncedFunc();
    });
  }, [headerObject, debouncedFunc]);

  const {data: deleteData, loading: deleteLoading, callAPI: deleteCallAPI} = useRequest(
    formatString(getSpecificSpecialist, [selectedSpecialist]), false, {}, "DELETE"
  );
  useEffect(() => {
    if(deleteData && !deleteLoading){
      showAlert("The specialist has been successfully deleted.");
      resetData();
    }
  }, [deleteData, deleteLoading]);

  const callBack = (id, intend) => {
    setSelectedSpecialist(id);
    setIntendedAction(intend);
  };

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      const {address_components} = place;
      const city = address_components.filter(el => el.types.includes("locality"));
      if(city.length > 0) {
        const [{long_name}] = city;
        setSelectedCity(long_name);
      }
    }
  });

  return (
    <div className="w-full">
      <Head/>
      <Header/>
      <section className="pt-10 pb-10 dotted-background min-h-screen">
        <div className="px-4 py-2 flex justify-end">
          <div className="flex py-1 mr-2 bg-list-bg rounded items-center w-50">
            <div className="pl-2">
              <img src={search} alt="search" className="w-5"/>
            </div>
            <div className="w-full">
              <input
                type="text"
                value={typedName}
                className="h-5 w-full px-2 outline-none bg-transparent"
                placeholder="Search by name..."
                onChange={e => setTypedName(e.target.value)}
              />
            </div>
          </div>
          <div className="mr-2 w-50 relative">
            <input
              type="text"
              name="location"
              className="h-7 px-2 py-1 w-full outline-none bg-list-bg rounded"
              placeholder="Search by city..."
              ref={ref}
            />
            {selectedCity && 
              <div 
                className={`w-7 absolute top-0 bottom-0 right-0 
                cursor-pointer flex items-center justify-center`}
                onClick={() => {
                  if(ref && ref.current){
                    ref.current.value = "";
                    setSelectedCity("");
                  }
                }}
              >
                <img src={cross} alt="remove city"/>
              </div>
            }
          </div>
          <Select
            isSearchable={true}
            isMulti
            name="language"
            placeholder="Filter by languages..."
            defaultValue={languages.filter(el => selectedLanguage.includes(el.value))}
            options={languages}
            onChange={(data) => {
              setSelectedLanguage(data.map(el => el.value));
            }}
            className="w-50 mr-2 dropdown-with-bg leading-snug"
          />
          <Select
            isSearchable={true}
            isMulti
            name="language"
            placeholder="Filter by experience..."
            defaultValue={experienceRange.filter(el => selectedExperience.includes(el.value))}
            options={experienceRange}
            onChange={(data) => {
              setSelectedExperience(data.map(el => el.value));
            }}
            className="w-50 mr-2 dropdown-with-bg leading-snug"
          />
          <Link
            to={"/create-specialist/presonal-details/"}
            className="primary-btn"
          >Create New Specialist</Link>
        </div>
        {!contextLoading && 
          <div className="m-auto max-w-screen-md">
            <ul className="grid grid-cols-2 gap-4">
              {
                allSpecialist.map(specialist => {
                  const {
                    id, avatarUrl, name, qualification, specialization, email, experience, 
                    languages, city, socialMedia = [], canBeLeader, timezone
                  } = specialist;

                  return (
                    <li
                      key={id}
                      className="bg-white relative tracking-wider border-b border-card-shadow rounded p-3"
                    >
                      <div className="absolute right-1 top-1 z-10">
                        <DotMenu
                          items={getDotMenu(callBack, id)}
                        />
                      </div>
                      <div className="flex">
                        <div className="w-11 rounded bg-gray-light overflow-hidden">
                          <LazyLoadImage
                            className="h-11 m-auto"
                            alt={name}
                            placeholder={grey.default}
                            src={fixURL(true, id, avatarUrl)}
                            debounceDelay={100}
                          />
                        </div>
                        <div className="leading-tight grow ml-3 tracking-wider">
                          <h3
                            className={`text-medium font-bold tracking-normal whitespace-nowrap 
                            overflow-hidden text-ellipsis w-50 text-black`}
                          >{name}</h3>
                          <div className="text-text-gray text-xs mt-1/2">{specialization}</div>
                          <span className="block text-xs mt-1/2">{email}</span>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 mt-3 font-medium text-xs">
                        <div className="uppercase">
                          <div className="text-text-gray">City</div>
                          <div className="text-sky mt-1">{city}</div>
                        </div>
                        <div className="uppercase">
                          <div className="text-text-gray">Experience</div>
                          <div className="text-sky mt-1">{experience} years</div>
                        </div>
                        <div className="uppercase">
                          <div className="text-text-gray">Qualification</div>
                          <div className="text-sky mt-1">{qualification}</div>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 mt-3 font-medium text-xs">
                        <div className="uppercase">
                          <div className="text-text-gray">Leader</div>
                          <div className="text-sky mt-1">{canBeLeader ? "true" : "false"}</div>
                        </div>
                        <div className="uppercase">
                          <div className="text-text-gray">Timezone</div>
                          <div className="text-sky mt-1 whitespace-nowrap">{allTimezones[timezone]}</div>
                        </div>
                      </div>
                      <div className="flex space-x-3 mt-3 font-medium text-xs">
                        <div className="uppercase">
                          <div className="text-text-gray">Languages</div>
                          <div className="text-sky mt-1 whitespace-nowrap">{
                            (languages || "").split(",").map(el => languageObject[el]).join(", ")
                          }</div>
                        </div>
                      </div>
                      <ul className="flex space-x-2 mt-2">
                      {
                        socialMedia.map(el => {
                          const {id, type, url} = el;
                          return (
                            <li key={id}>
                              <a href={url} target="_blank" rel="noopener noreferrer">
                                <img className="w-5" src={socialMediaImageMap[type]} alt="social"/>
                              </a>
                            </li>
                          )
                        })
                      }
                      </ul>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {selectedSpecialist && intendedAction === "delete" && 
          <ConfirmDelete
            heading="Deleting specialist..."
            onConfirm={() => {
              deleteCallAPI();
            }}
            close={() => {
              setSelectedSpecialist("");
              setIntendedAction("")
            }}
          />
        }
      </section>
    </div>
  )
};

export default ManageSpecialist;
