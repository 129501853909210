import React from "react";

import Head from "../components/head";
import Footer from "../components/landing/footer";
import Header from "../components/landing/header";
import SaveLife from "../components/landing/save-live";

const FAQs = () => {
  const faqs = [{
    question: "What is Oncominds?",
    answer: `Oncominds is a platform with a network of oncologists, physicians, surgeons, 
    radiologists and other multidisciplinary specialists across the globe facilitating 
    consultations aimed at delivering better better outcomes in Cancer.`
  }, {
    question: "Why should anyone consider using Oncominds' services?",
    answer: `Oncominds has access to world renowned oncology professionals enabling treatment 
    thorough and comprehensive assessment through a multidisciplinary team of experts/educational 
    advisory board.`
  }, {
    question: "Can patients or their family members engage directly with Oncominds platform?",
    answer: `Oncominds platform is exclusively for doctors. Patients will need to work through 
    their consultant oncologist. If you do not have a treating oncologist, are not engaged with one, 
    we can refer you to an experienced oncologist in your city.`
  }, {
    question: "How does one engage their treating oncologist to work with Oncominds?",
    answer: `Please contact us or have your treating oncologist reach us at 
    <a class="text-sky-blue underline" href="mailto:support@oncominds.com" 
    target="_blank" rel="noopener noreferrer">support@oncominds.com</a>. 
    We will follow-up with your doctor to ensure your treating oncologist has access to the Oncominds 
    platform services.`
  }, {
    question: "What are the pricing and payment options for your services?",
    answer: `Our services pricing varies based on the case, the expert/s involved and the services 
    requested by the doctors. Our Oncominds Clinical Care Specialist will coordinate to gather details, 
    calculate pricing and send the invoice for payment.`
  }];

  return (
    <div className="bg-bg-color w-full">
      <Head title={"FAQs"}/>
      <Header selected={"faqs"}/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Frequently asked questions
        </h1>
        <div
          className="pt-15 grid grid-cols-2 gap-x-20 gap-y-8 pb-20 mobile:grid-cols-1 mobile:pt-10 mobile:gap-x-0"
        >
          {
            faqs.map((el, index) => {
              const {question, answer} = el;
              return (
                <div key={index + question} className="leading-snug text-purple">
                  <h3 className="font-bold text-medium-l">{question}</h3>
                  <p className="text-medium mt-2 font-medium html-body"
                    dangerouslySetInnerHTML={{__html: answer}}
                  />
                </div>
              )
            })
          }
        </div>
        <SaveLife
          heading="Submit a case today"
          linkText="Reach us"
          link="https://share.hsforms.com/1sn9Yolz6R-mRbHS6NiVFnAe5ov6"
        />
        <Footer/>
      </div>
    </div>
  );
};

export default FAQs;
