import React, {useState, useEffect, Fragment} from "react";
import {Link, useParams} from "react-router-dom";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";
import {createPayload, createAvatarPayload} from "./configs/done.config";

import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

const Done = (props) => {
  const {getState} = props;
  const stateData = getState();
  const professionalData = stateData["professional-details"];
  const {avatar} = professionalData;
  const isString = typeof avatar === "string";

  const [isLoading, setIsLoading] = useState(true);

  const {specialistId} = useParams();
  const {getSpecificSpecialist, uploadSpecialistAvatar} = API_PATH;
  
  const avatarPayload = createAvatarPayload(isString, stateData);
  const {data: avatarData, loading: avatarLoading, callAPI: avatarCallAPI} = useRequest(
    formatString(uploadSpecialistAvatar, [specialistId]), false, avatarPayload, "POST"
  );
  useEffect(() => {
    if(avatarData && !avatarLoading){
      setIsLoading(false);
      showAlert("The specialist has been successfully edited!");
    }
  }, [avatarData, avatarLoading]);

  const payload = createPayload(stateData);
  const {
    data: specialistData, loading: specialistLoading, callAPI: specialistCallAPI, error
  } = useRequest(formatString(getSpecificSpecialist, [specialistId]), false, payload, "PATCH");
  useEffect(() => {
    if(specialistData && !specialistLoading){
      if(isString){
        setIsLoading(false);
        showAlert("The specialist has been successfully edited!");
      }
    }
  }, [specialistData, specialistLoading, isString]);

  useEffect(() => {
    if(error){
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    specialistCallAPI();
  }, [specialistCallAPI]);

  useEffect(() => {
    if(!isString){
      setTimeout(() => {
        avatarCallAPI();
      });
    }
  }, [isString, avatarCallAPI]);

  const caseMessage = isLoading ? "Specialist edit is in progress..." : (
    error ? "Specialist edit has failed!" : "Specialist has been successfully edited!"
  );

  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {isLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!isLoading && 
            <Fragment>
              {!error && <Success className="w-4 h-4"/>}
              {error && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {specialistId &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            <div className="pt-2">
              <Link
                className="text-purple-light hover:text-purple"
                to="/manage-specialists/"
              >View all Specialists</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
