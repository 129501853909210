import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useContextData} from "../store";
import {bindEvent, unbindEvent, isOmAdmin} from "../utils";

import logout from "../assets/sign-out.png";
import {ReactComponent as Logo} from "../assets/logo.svg";

const Header = (props) => {
  const elm = useRef();
  const {hideLogout = false} = props;
  const data = useContextData();
  const {userName, email, userRoles = []} = data || {};
  const [manageMenuOpen, setManageMenuOpen] = useState(false);
  const [isDropDownOpened, setisDropDownOpened] = useState(false);

  const outSideClick = (e) => {
    if(e && e.path && e.path.length > 0){
      let flag = true;
      e.path.forEach((p) => {
        if(p === elm.current){
          flag = false;
          return flag;
        }
      });
      setisDropDownOpened(!flag);
    }
};

  useEffect(() => {
    if(isDropDownOpened) bindEvent(document, 'click', outSideClick);
    return () => {
      unbindEvent(document, 'click', outSideClick);
    }
}, [isDropDownOpened])

  return (
    <header
      className={`h-10 fixed top-0 left-0 w-full ${!manageMenuOpen ? "z-20" : "z-40"} px-6 \
        bg-gradient-to-b from-deep-purple to-white-purple items-center`
      }
    >
      <div className="flex relative w-full h-full items-center">
        <Link to="/case/">
          <Logo className="h-6 fill-white"/>
        </Link>
        {isOmAdmin(userRoles) && (
          <section className="h-full flex items-center">
            <nav id="navbar" role="navigation">
              <input id="toggle" type="checkbox" onChange={e => setManageMenuOpen(e.target.checked)}/>
              <label className="hamburger" htmlFor="toggle">
                <div className="top"></div>
                <div className="meat"></div>
                <div className="bottom"></div>
              </label>
              <nav className="menu">
                <Link to="/manage-users/" className="menu-item font-medium">
                  Manage Users
                </Link>
                <Link to="/manage-specialists/" className="menu-item font-medium">
                  Manage Specialists
                </Link>
                <Link to="/manage-tenants/" className="menu-item font-medium">
                  Manage Tenants
                </Link>
                <Link to="/manage-consulting-doctors/" className="menu-item font-medium">
                  Manage Consulting Doctors
                </Link>
              </nav>
            </nav>
          </section>
        )}
        {!hideLogout && 
          <div className="grow">
            <div className="relative items-center flex justify-end">
              <div ref={elm}>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setisDropDownOpened(!isDropDownOpened)}
                >
                  <div className="mr-2 font-medium text-xl text-center cursor-default">
                    <div className="bg-white uppercase text-deep-purple rounded-full h-7 w-7 pt-1.5">
                      {userName ? userName[0] : "X"}
                    </div>
                  </div>
                  <div className="text-white">
                    <div className="font-medium whitespace-nowrap">{userName}</div>
                    <div className="mt-0.5 text-xs opacity-80">{email}</div>
                  </div>
                </div>
                
                {isDropDownOpened && 
                  <ul
                    className="absolute bg-white top-full border border-gray/50 min-w-40 right-0 mt-1.5 py-2"
                  >
                    <li className="cursor-pointer hover:bg-gray-light">
                      <Link
                        className="flex font-medium text-xs px-3 py-1 items-center text-text-gray"
                        to="/logout/"
                      >
                        <img src={logout} alt="menu" className="w-3.5 mr-1.5"/>
                        <span className="block">Logout</span>
                      </Link>
                    </li>
                  </ul>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </header>
  );
};

export default Header;
