import React, {useState, useEffect} from "react";

import Note from "./note";
import {API_PATH} from "../../constant";
import {formatString} from "../../utils";
import useRequest from "../../hooks/useRequest";

const CaseNote = (props) => {
  const {caseId, speciaListId, noteData, callback} = props;
  const [specialistDetails, setSpecialistDetails] = useState({});
  const {getSpecificSpecialist} = API_PATH;
  const {data, loading} = useRequest(formatString(getSpecificSpecialist, [speciaListId]));
  useEffect(() => {
    if(data && !loading){
      const {result = {}} = data;
      setSpecialistDetails(result);
    }
  }, [data, loading]);

  const {name = "", specialization = ""} = specialistDetails;
  return (
    <div className="bg-yellow tracking-wider text-xs p-2 font-medium text-text-gray">
      <div className="mb-2">{`${name || "-"}, ${specialization || "-"}`}</div>
      <Note
        callback={callback}
        note={noteData}
        title="Case notes"
        id={caseId}
        speciaListId={speciaListId}
        type="cases"
      />
    </div>
  );
};

export default CaseNote;
