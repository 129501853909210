import React from "react";
import moment from "moment";
import {cancerNameMap, cancerStageMap} from "../constant/cancer";
import {ReactComponent as Emergency} from "../assets/emergency-icon.svg";

const VMTB = (props) => {
  const {
    patient = {}, caseId, cancerType, stage, lead, date, disPatientId, caseType, priority
  } = props;
  const {age, gender} = patient;

  return (
    <div
    className="bg-white tracking-wider relative"
    >
      <div className="px-2 pt-2 flex font-medium space-x-3 text-xs uppercase">
        <div>
          <span className="text-text-gray">Id:</span>
          <span className="text-sky ml-1">{disPatientId}</span>
        </div>
        <div>
          <span className="text-text-gray">Sex:</span>
          <span className="text-sky ml-1">{gender}</span>
        </div>
        <div>
          <span className="text-text-gray">Age:</span>
          <span className="text-sky ml-1">{age}</span>
        </div>
        {priority === "high" && 
          <div className="relative">
            <Emergency
              className="fill-pink-deep w-5 h-5 -top-1.25 absolute"
            />
          </div>
        }
      </div>
      <div className="p-2 mt-2 bg-sky-light relative text-xs">
        <div
          className="absolute bg-white px-2 py-1 rounded-xl border top-2 right-2 leading-none border-sky"
        >
          <span className="font-bold text-sky">{caseType === "p2p" ? "P2P" : "VMTB"}</span>
        </div>
        <div className="text-text-gray font-medium">
          <div className="w-full">
            <span className="uppercase">Case:</span>
            <span className="pl-1 text-sky font-semibold uppercase">{caseId}</span>
          </div>
          <div className="mt-2">
            <span className="text-xs">Cancer:</span>
            <span className="pl-1">{cancerNameMap[cancerType]}</span>
          </div>
          <div className="mt-2">
            <span className="text-xs">Stage:</span>
            <span className="pl-1">{cancerStageMap[stage]}</span>
          </div>
          <div className="flex mt-2">
            <div className="grow whitespace-nowrap text-ellipsis overflow-hidden pr-3 ">
              <span className="text-xs">Lead:</span>
              <span className="pl-1">{lead ? lead.name : "N/A"}</span>
            </div>
            <div className="uppercase min-w-18">{date ? moment(date).format("DD-MMM-YYYY") : "--"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VMTB;
