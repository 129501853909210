import React, {useState, useEffect} from "react";
import moment from "moment";

import {API_PATH} from "../../../constant";
import {formatString} from "../../../utils";
import useRequest from "../../../hooks/useRequest";

const SelectEvent = (props) => {
  const {patientId, close, callback, eventData} = props;

  const [allEvents, setAllEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isEventSelected, setIsEventSelected] = useState(false);

  const {getAllEvents} = API_PATH;
  const {data, loading} = useRequest(formatString(getAllEvents, [patientId]));
  useEffect(() => {
    if(!loading && data){
      const {result = []} = data;
      setAllEvents(result);
    }
  }, [data, loading]);

  useEffect(() => {
    console.log(eventData);
  }, [eventData]);


  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="w-90 bg-white rounded relative m-auto px-5 py-4">
        <h3 className="text-medium font-bold">
          Select an exising event from below
        </h3>
        <div className="mt-3 max-h-screen-110 overflow-y-auto">
          <ul>
            {
              allEvents.map((event, index) => {
                const {id, name, fromDate, toDate} = event;
                const dateToShow = (fromDate === toDate) ? moment(fromDate).format("MMM DD, YYYY") : (
                  `${moment(fromDate).format("MMM DD, YYYY")} - ${moment(toDate).format("MMM DD, YYYY")}`
                );
                return (
                  <li
                    onClick={() => {
                      setSelectedEvent({...event});
                      setIsEventSelected(true);
                    }}
                    key={id}
                    className={
                      `cursor-pointer border border-gray py-2 px-2.5 rounded${
                        selectedEvent.id === id ? " bg-purple-light/30" : ""
                      }${index !== allEvents.length - 1 ? " mb-2" : ""}`
                    }
                  >
                    <h3 className="text-base font-extrabold">{name}</h3>
                    <div 
                      className="text-xs mt-1 text-text-gray leading-tight"
                    >{dateToShow}</div>
                  </li>
                )
              })
            }
            {!loading && allEvents.length === 0 && 
              <li className="py-2 rounded">No event present to choose. Please create one first!</li>
            }
          </ul>
        </div>
        <div className="flex items-center justify-center pt-3">
          <button
            className="mr-4 text-purple-light font-medium text-xs"
            onClick={close}
          >Cancel</button>
          <button
            className="primary-btn"
            disabled={!isEventSelected}
            onClick={() => {
              callback(selectedEvent);
              close();
            }}
          >Select event</button>
        </div>
      </div>
    </div>
  );
};

export default SelectEvent;
