import {cancerType, cancerStage} from "../../../constant/cancer";

export const formConfig =  [
// {
//   component: "paragraph",
//   name: "stage-desc",
//   title: `
//     Stage I: Cancer is localized to a small area and hasn't spread to lymph nodes or other tissues.
//     Stage II: Cancer has grown, but it hasn't spread.
//     Stage III: Cancer has grown larger and has possibly spread to lymph nodes or other tissues.
//     Stage IV: Cancer has spread to other organs or areas of your body. This stage is also referred 
//     to as metastatic or advanced cancer.
//   `
// } 
{
  component: "dropdown",
  name: "cancerType",
  label: "Cancer Type",
  required: true,
  data: [...cancerType]
}, {
  component: "textfield",
  name: "cancerSubType",
  label: "Cancer Sub Type",
  required: false
}, {
  component: "dropdown",
  name: "cancerStage",
  label: "Cancer Stage",
  required: true,
  data: [...cancerStage]
}, {
  component: "textarea",
  name: "summary",
  label: "Summary",
  required: true,
  className: "mt-2"
}, {
  component: "title",
  title: "Queries",
  name: "query-title"
}, {
  component: "paragraph",
  name: "query-desc",
  title: "Click on the plus icon to add multiple queries at once."
}, {
  component: "add-item",
  name: "queries",
  title: "Queries",
  required: true,
  fullWidth: true,
  children: [{
    component: "textarea",
    name: "queries-1",
    label: "Queries",
    required: true
  }]
}];