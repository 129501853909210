import {format} from "date-fns";
import {eventTypes} from "../../../constant/eventType";

const groupEventsRecords = (records, allEvents) => {
  const {allRecords, events} = records.reduce((data, record) => {
    const {event, date: pdate} = record;
    const date = new Date(pdate);
    if(event){
      const {id, name, description, type} = event;
      if(!data.events[id]){
        data.events[id] = {
          id,
          name,
          description,
          type,
          date,
          endDate: date,
          records: [],
          vtype: "event"
        };
      }
      
      if(date > data.events[id].endDate){
        data.events[id].endDate = date;
      }
      data.events[id].records.push(record);
      return data;
    }

    data.allRecords.push({
      ...record,
      date,
      vtype: "record"
    });
    return data;
  }, {allRecords: [], events: {}});

  allEvents.forEach(event => {
    const {id, name, description, fromDate, toDate, type} = event;
    if(!events[id]){
      const date = new Date(fromDate);
      const endDate = new Date(toDate);

      events[id] = {
        date,
        description,
        endDate: date > endDate ? date : endDate,
        id,
        name,
        records: [],
        type,
        vtype: "event"
      };
    }
  });

  return [...allRecords, ...Object.values(events)];
} 

export const createTimelineData = (records, events) => {
  const notSortedTimelineData = groupEventsRecords(records, events);
  return notSortedTimelineData.sort((a, b) => {
    return b.date - a.date;
  });
};

export const createCreateEventPayload = (eventData, patientId, caseId, recordData, isEmptyEvent) => {
  const {fromDate, toDate, records} = recordData.reduce((data, record, index) => {
    const {date} = record;
    if(!data.fromDate && !data.toDate){
      data.fromDate = date;
      data.toDate = date;
    }
    const {fromDate, toDate} = data;
    
    if(fromDate > date){
      data.fromDate = date;
    }
    if(toDate < date){
      data.toDate = date;
    }

    data.records.push({
      ...record,
      date: `${format(date, "yyyy-MM-dd")}T00:00:00.000`,
      caseId,
      patientId,
      recordOrder: index
    });
    return data;
  }, {fromDate: undefined, toDate: undefined, records: []});

  const {date: eventDate} = eventData;
  return {
    ...eventData,
    patient: {
      id: patientId
    },
    caseId,
    fromDate:`${format((isEmptyEvent ? eventDate : fromDate) || new Date(), "yyyy-MM-dd")}T00:00:00.000`,
    toDate: `${format((isEmptyEvent ? eventDate : toDate) || new Date(), "yyyy-MM-dd")}T00:00:00.000`,
    records
  }
};

export const createUpdateEventPayload = (eventDetails, patientId, caseId, isEmptyEvent) => {
  const {name, description, date, endDate, records, type, visible = true} = eventDetails;
  return {
    name,
    patient: {
      id: patientId
    },
    caseId,
    description,
    type,
    visible,
    fromDate: `${format(date || new Date(), "yyyy-MM-dd")}T00:00:00.000`,
    toDate: `${format((isEmptyEvent ? date : endDate) || new Date(), "yyyy-MM-dd")}T00:00:00.000`,
    records
  };
};

export const createEventFormConfig = (isEmptyEvent, isEdit) => [{
  name: "name",
  component: "textfield",
  label: "Title",
  required: true
}, ...(isEmptyEvent ? [{
  name: "date",
  component: "date",
  label: "Date",
  required: true
}] : []), {
  name: "type",
  component: "dropdown",
  label: "Event Type",
  required: true,
  data: eventTypes
}, {
  component: "textarea",
  name: "description",
  label: "Description",
  required: true,
  className: "mt-2"
}, ...(isEdit ? [{
  component: "switch",
  name: "visible",
  label: "Event Visibility",
  text: "Will this event be visible?",
  required: true
}] : [])];