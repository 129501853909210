import {format} from "date-fns";
import {getKeyValueHTML} from "../../../utils/workflowUtils";

export const getSummaryConfig = (isOmAdmin) => {
  return [{
    key: "case-type",
    body: (formData, labelObj) => {
      const keys = [{
        dkey: "caseType",
        label: "Case Type"
      }, {
        dkey: "priority",
        args: ["priority"],
        format: (priority) => {
          return priority ? "High" : "Normal"
        }
      }];

      return getKeyValueHTML(keys, labelObj, formData)
    }
  }, ...[(isOmAdmin ? {
    key: "select-tenant",
    body: (formData, labelObj) => {
      const keys = [{
        dkey: "tenant"
      }];
      return getKeyValueHTML(keys, labelObj, formData);
    }
  } : {})], {
    key: "patient-demographic",
    body: (formData, labelObj) => {
      const keys = [{
        dkey: "pid"
      }, {
        dkey: "gender"
      }, {
        dkey: "maritalStatus"
      }, {
        dkey: "dob",
        args: ["dob"],
        format: (dob) => {
          return dob ? format(dob, "MMM d, yyyy") : dob;
        }
      }, {
        dkey: "age"
      }, {
        dkey: "weight"
      }, {
        dkey: "height"
      }, {
        dkey: "ethnicity"
      }];

      return getKeyValueHTML(keys, labelObj, formData);
    }
  }, {
    key: "case-background",
    body: (formData, labelObj) => {
      const keys = [{
        dkey: "cancerType"
      }, {
        dkey: "cancerSubType"
      }, {
        dkey: "cancerStage"
      }, {
        dkey: "summary",
        args: ["summary"],
        format: (summary) => {
          return (
            <div
              className="whitespace-pre-line"
              dangerouslySetInnerHTML={{__html: summary}}
            />
          )
        }
      }];

      return getKeyValueHTML(keys, labelObj, formData);
    }
  }]
};
