import React, { useContext, createContext, useState, useEffect } from "react";

import useRequest from "../hooks/useRequest";
import {
  API_PATH, TOKEN_KEY, REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, USERNAME_KEY
} from "../constant";

export const ContextData = createContext();

const ContextProvider = ({ children }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [email, setEmail] = useState('xx@xxxx.xxx');

  const [tenantId, setTenantId] = useState();
  const [patientId, setPatientId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [specialistId, setSpecialistId] = useState();

  const {getUser} = API_PATH;
  const userName = localStorage.getItem(USERNAME_KEY);
  const {data, loading, error} = useRequest(getUser);
  useEffect(() => {
    if(data && !loading && data.result) {
      const {email, roles, tenantId, specialistId, patientId} = data.result;
      setEmail(email);
      setUserRoles(roles);
      setTenantId(tenantId);
      setPatientId(patientId);
      setSpecialistId(specialistId);
      setIsLoading(false);
    }
  }, [data, loading]);

  useEffect(() => {
    if(error && !loading){
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USERNAME_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.location.pathname = "/login/";
    }
  }, [error, loading]);

  const contextObj = {
    loading: isLoading,
    userName,
    userRoles,
    email,
    tenantId,
    patientId,
    specialistId
  };

  return (
    <ContextData.Provider
      value={contextObj}
    >
      {children}
    </ContextData.Provider>
  )
};

export const useContextData = () => {
	return useContext(ContextData)
}

export default ContextProvider;
