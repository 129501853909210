import React from "react";

const Block = (props) => {
  const {children} = props;

  return (
    <section className="report page text-purple flex flex-col">
      {children}
    </section>
  )
};

export default Block;
