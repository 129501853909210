import React, {useEffect, Fragment, useCallback} from "react";

import useRequest from "../hooks/useRequest";
import {
  API_PATH, TOKEN_KEY, REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, USERNAME_KEY
} from "../constant";

const LogOut = () => {
  const hardLogout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USERNAME_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.location.assign("/login/");
  }, []);

  const {logout} = API_PATH;
  const username = localStorage.getItem(USERNAME_KEY);
  const {data, loading, error, callAPI} = useRequest(logout, false, {
    username
  }, "POST");
  useEffect(() => {
    if(data && !loading) {
      hardLogout();
    }
    if(error && !loading) {
      hardLogout();
    }
    // removeWorker();
  }, [data, loading, error, hardLogout]);

  useEffect(() => {
    callAPI();
  }, [callAPI])

  return (
    <Fragment></Fragment>
  );
};

export default LogOut;
