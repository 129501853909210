import React, {useState, useEffect, useCallback} from "react";
import Head from "../components/head";
import Header from "../components/header";
import {Link, useParams, useNavigate} from "react-router-dom";

import {API_PATH} from "../constant";
import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import FileUpload from "../components/common/fileUpload";
import DicomUpload from "../components/common/dicomUpload";
import FileList from "../components/uploadReport/fileList";
import {formatString, showAlert, isOmAdmin, isTenantAdmin} from "../utils";
import FileTypePopup from "../components/uploadReport/fileTypePopup";

const UploadReport = () => {
  const navigate = useNavigate();
  const {getAllReports, getUnattachedRecords, uploadDicom} = API_PATH;
  const {patientId, caseId} = useParams();

  const {userRoles = [], loading: contextLoading} = useContextData();

  const [progress, setProgress] = useState(0);
  const [allFiles, setAllFiles] = useState([]);
  const [isDicom, setIsDicom] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const [showFileTypePopup, setShowFileTypePopup] = useState(false);

  const {
    data: extFiles, loading: extFilesLoading, callAPI: callExtFilesAPI
  } = useRequest(formatString(getAllReports, [patientId]), false);
  useEffect(() => {
    if(extFiles && !extFilesLoading){
      const {result = []} = extFiles;
      setAllFiles(result);
    }
  }, [extFiles, extFilesLoading]);

  useEffect(() => {
    if(!contextLoading){
      if(!isOmAdmin(userRoles) && !isTenantAdmin(userRoles)){
        navigate(`/case/${patientId}/${caseId}/`);
        return;
      }

      callExtFilesAPI();
    }
  }, [patientId, caseId, contextLoading, userRoles, navigate, callExtFilesAPI]);

  const {
    data: fileData, loading: fileLoading, callAPI: callFileAPI, 
    error: fileError, errorMessage: fileErrMsg
  } = useRequest(
    formatString(getUnattachedRecords, [patientId]), false, formData, "POST", {}, (progressEvent) => {
      const {loaded, total} = progressEvent;
      const progress = (loaded / total) * 100;
      setProgress(progress);
    }
  );
  useEffect(() => {
    if(fileData && !fileLoading) {
      showAlert("All selected files has been successfully uploaded!");
      setTimeout(() => {
        callExtFilesAPI();
      })
    }
  }, [fileData, fileLoading, callExtFilesAPI]);

  useEffect(() => {
    if(!fileLoading && fileError && fileErrMsg){
      showAlert(fileErrMsg, "error");
    }
  }, [fileLoading, fileError, fileErrMsg]);

  const {
    data: dicomData, loading: dicomLoading, callAPI: callDicomAPI, 
    error: dicomError, errorMessage: dicomErrMsg
  } = useRequest(formatString(uploadDicom, [patientId]), false, formData, "POST", {}, (progressEvent) => {
    const {loaded, total} = progressEvent;
    const progress = (loaded / total) * 100;
    setProgress(progress);
  });
  useEffect(() => {
    if(dicomData && !dicomLoading) {
      showAlert("All dicom files has been successfully uploaded!");
      setTimeout(() => {
        callExtFilesAPI();
      })
    }
  }, [dicomData, dicomLoading, callExtFilesAPI]);

  useEffect(() => {
    if(!dicomLoading && dicomError && dicomErrMsg){
      showAlert(dicomErrMsg, "error");
    }
  }, [dicomLoading, dicomError, dicomErrMsg]);

  const handleSelectFile = useCallback((metaData) => {
    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append("files", file);
    }
    formData.set("files-meta", JSON.stringify(metaData));

    setFormData(formData);
    setTimeout(() => {
      callFileAPI();
    });
  }, [selectedFiles, callFileAPI]);

  const handleDicomFiles = useCallback((files) => {
    if(files.length > 0) {
      setSelectedFiles(files);
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }
      setFormData(formData);
      setTimeout(() => {
        callDicomAPI();
      });
    }
  }, [callDicomAPI]);

  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading &&
        <div className="max-w-screen-lg mx-auto mt-10 py-6 px-4 min-h-screen leading-snug">
          <h1 className="text-xl font-bold">Upload Reports</h1>
          <p className="mt-2 text-base text-text-gray max-w-screen-sm leading-snug mb-2">
            Please upload reports which can be used later while creating events.
          </p>
          <FileList
            allFiles={allFiles}
          />
          <div className="pt-4">
            <div className="grid grid-cols-2 mobile:grid-cols-1">
              <div className="pr-3 border-r border-gray mobile:pr-0 mobile:border-0">
                <h3 className="font-bold text-medium">Upload other reports here</h3>
                <p className="mt-1/2 text-xs text-text-gray max-w-screen-sm leading-snug mb-3">
                  Supported file formats to upload are PNG, JPEG and PDF.
                </p>
                <FileUpload
                  isMultiple={true}
                  progress={!isDicom ? progress : 0}
                  loading={fileLoading}
                  callback={(files) => {
                    setIsDicom(false);
                    setSelectedFiles(files);
                    setShowFileTypePopup(true);
                  }}
                />
              </div>
              <div className="pl-3 mobile:pl-0">
                <h3 className="font-bold text-medium">Upload dicom file here</h3>
                <p className="mt-1/2 text-xs text-text-gray max-w-screen-sm leading-snug mb-3">
                  Only dicom files are supported here to upload.
                </p>
                <DicomUpload
                  progress={isDicom ? progress : 0}
                  loading={dicomLoading}
                  callback={(files) => {
                    setIsDicom(true);
                    setTimeout(() => {
                      handleDicomFiles(files);
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <Link
              className="text-purple-light hover:text-purple"
              to={`/case/${patientId}/${caseId}/`}
            >&lt; Return to Case Details</Link>
            <Link
              className="text-purple-light hover:text-purple"
              to={`/add-event/${patientId}/${caseId}/analyze-records/`}
            >Go to Manage Events &gt;</Link>
          </div>
          {showFileTypePopup &&
            <FileTypePopup
              files={selectedFiles}
              callback={(data) => {
                setShowFileTypePopup(false);
                handleSelectFile(data);
              }}
            />
          }
        </div>
      }
    </div>
  );
};

export default UploadReport;
