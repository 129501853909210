export const formConfig = [{
  component: "textfield",
  name: "name",
  label: "Name",
  required: true
}, {
  component: "textfield",
  name: "email",
  label: "Email",
  required: true
}, {
  component: "textfield",
  name: "userName",
  label: "Username",
  required: true
}, {
  component: "paragraph",
  name: "phone-desc",
  title: "You can add one or more phone numbers. The first one will be considered as primary one."
}, {
  component: "add-item",
  name: "phone",
  title: "Phone number",
  required: true,
  limit: 3,
  children: [{
    component: "textarea",
    name: "phone-1",
    label: "Phone",
    required: true
  }]
}, {
  component: "paragraph",
  name: "address-desc",
  title: `Address should be as per the National id. Preferably the permanent address. 
  Please use address field to search your address, which will autofill other fields.`
}, {
  component: "address",
  name: "address",
  label: "Search address...",
  className: "w-130",
  fullWidth: true,
  autofillMap: {
    address1: [
      "premise", "route", "sublocality_level_3", 
      "sublocality_level_2", "sublocality_level_1"
    ],
    city: ["locality"],
    postalCode: ["postal_code"],
    state: ["administrative_area_level_1"],
    country: ["country"]
  }
}, {
  component: "textfield",
  name: "address1",
  label: "Address 1",
  required: true
}, {
  component: "textfield",
  name: "address2",
  label: "Address 2"
}, {
  component: "textfield",
  name: "city",
  label: "City",
  required: true
}, {
  component: "textfield",
  name: "postalCode",
  label: "Postal Code",
  required: true
}, {
  component: "textfield",
  name: "state",
  label: "State",
  required: true
}, {
  component: "textfield",
  name: "country",
  label: "Country",
  required: true
}];

export const formSchema = {
  email: [{
    condition: value => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value),
    message: "Not a valid email address."
  }]
};
