import React, {useState, useEffect, useRef, useCallback} from "react";
import {useParams, useNavigate} from "react-router-dom";

import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import {API_PATH, recordCategories} from "../constant";
import {getUpdatedSpecialistPayload} from "../utils/componentUtils";
import {
  formatString, fixVMTBDetails, formUrlEncoded, debounce, 
  isOmSpecialist, showAlert, isEmptyObject
} from "../utils";

import Head from "../components/head";
import VMTB from "../components/vmtb";
import Header from "../components/header";
import DotMenu from "../components/common/dotMenu";
import EventDetails from "../components/caseDetails";
import Events from "../components/caseDetails/events";
import History from "../components/caseDetails/history";
import CaseNote from "../components/caseDetails/caseNote";
import ConfirmDelete from "../components/common/confirmDelete";
import VMTBSummary from "../components/caseDetails/vmtbSummary";
import SearchSpecialist from "../components/caseDetails/searchSpecialist";

import {getCaseDetailsDotMenu} from "./configs/case-details.config";

const CaseDetails = () => {
  const vmtbRef = useRef();
  const scrollElmRef = useRef();
  const navigate = useNavigate();
  const ascDescRef = useRef(true);

  const [isAsc, setIsAsc] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [caseDetails, setCaseDetails] = useState({});
  const [allCaseNotes, setAllCaseNotes] = useState([]);
  const [showVMTBSummary, setShowVMTBSummary] = useState(true);
  const [assigningLeader, setAssigningLeader] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({});
  const [selectedSpecialist, setSelectedSpecialist] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(
    recordCategories.map(el => el.value)
    );
    const [specialistNoteData, setSpecialistNoteData] = useState({
      caseNote: {}, eventNote: {}, recordNote: {}
    });
    const [showDeleteCaseConfirm, setShowDeleteCaseConfirm] = useState(false);

  const {
    userRoles = [], specialistId: speciaListId, loading: contextLoading
  } = useContextData();
  const {caseId, patientId} = useParams();
  const {
    caseDetails: caseDetailsAPI, getAllEvents, getAllNotes, caseSpecialist: caseSpecialistAPI
  } = API_PATH;
  const {
    data: eventData, loading: eventLoading, callAPI: callEventAPI
  } = useRequest(`${formatString(getAllEvents, [patientId])}?${formUrlEncoded({
    category: selectedFilters.join(",")
  })}`, false);
  useEffect(() => {
    if(eventData && !eventLoading) {
      const {result = []} = eventData;
      setEventList(result);
      if(result.length > 0){
        const [selectedEventData] = result;
        setSelectedEventData(selectedEventData);
      }
    }
  }, [eventData, eventLoading]);

  const {
    data: noteData, loading: noteLoading, callAPI: callNoteAPI
  } = useRequest(formatString(getAllNotes, [caseId]), false);
  useEffect(() => {
    if(noteData && !noteLoading) {
      const {result} = noteData;
      setAllCaseNotes(result);
      const formattedNote = result.reduce((data, el) => {
        const {type, case: caseData, event, record, specialist} = el;
        const keyName = type === "record_note" ? "recordNote" : (
          type === "case_note" ? "caseNote" : "eventNote"
        );
        const keyId = type === "record_note" ? record.id : (
          type === "case_note" ? caseData.id : event.id
        )
        if(speciaListId === specialist.id){
          data[keyName][keyId] = {...el}
        }
        return data;
      }, {
        caseNote: {}, eventNote: {}, recordNote: {}
      });
      setSpecialistNoteData(formattedNote);
    }
  }, [noteData, noteLoading, speciaListId]);

  const {
    data: caseData, loading: caseLoading, callAPI:callCaseDetailsAPI
  } = useRequest(formatString(caseDetailsAPI, [caseId]), false);
  useEffect(() => {
    if(caseData && !caseLoading && caseData.result) {
      const {result} = caseData;
      setCaseDetails(result);
    }
  }, [caseData, caseLoading]);
  
  const {
    data: addSpecialistData, loading: addSpecialistLoading, callAPI: callAddSpecialistAPI
  } = useRequest(formatString(caseSpecialistAPI, [caseId]), false, getUpdatedSpecialistPayload(
    assigningLeader, selectedSpecialist, caseDetails.lead, caseDetails.specialists
  ), "PUT");
  useEffect(() => {
    if(addSpecialistData && !addSpecialistLoading) {
      if(vmtbRef && vmtbRef.current){
        vmtbRef.current.callSpecialistAPI();
      }
      showAlert(`${assigningLeader ? "Leader" : "Specialist"} has been successfully assigned.`);
      const {result = []} = addSpecialistData;
      if(!assigningLeader){
        setCaseDetails(caseDeails => ({
          ...caseDeails,
          specialists: [...result]
        }));
      }
      
      if(assigningLeader){
        const leader = result.find(el => el.isLeader);
        if(leader){
          const {id: leaderId} = leader;
          const foundSpecialist = selectedSpecialist.find(el => el.id === leaderId);
          if(foundSpecialist){
            const {id, name} = foundSpecialist
            setCaseDetails(caseDeails => ({
              ...caseDeails,
              lead: {
                id, name
              }
            }));
          }
        }
      }
    }
  }, [addSpecialistData, addSpecialistLoading, assigningLeader, selectedSpecialist]);

  useEffect(() => {
    if(!contextLoading){
      callEventAPI();
    }
  }, [contextLoading, selectedFilters, callEventAPI]);

  const {
    data: deleteCaseData, loading: deleteCaseLoading, callAPI: callDeleteCaseAPI,
    error: deleteCaseError, errorMessage: deleteCaseErrorMsg
  } = useRequest(formatString(caseDetailsAPI, [caseId]), false, {}, "DELETE");
  useEffect(() => {
    if(!deleteCaseLoading && deleteCaseData){
      navigate("/case/");
      showAlert("The case been successfully deleted!")
    }
  }, [deleteCaseData, deleteCaseLoading, navigate]);

  useEffect(() => {
    if(deleteCaseErrorMsg && deleteCaseError){
      showAlert(deleteCaseErrorMsg, "error");
    }
  }, [deleteCaseError, deleteCaseErrorMsg]);

  useEffect(() => {
    if(isAsc !== ascDescRef.current){
      setEventList(events => {
        const clonedEvents = [...events];
        return clonedEvents.reverse();
      });
      ascDescRef.current = isAsc;
    }
  }, [isAsc]);

  const {status} = caseDetails;
  const caseDotMenuItems = getCaseDetailsDotMenu(
    caseId, patientId, userRoles, status, setAssigningLeader, setShowSearchModal, setShowDeleteCaseConfirm
  );

  useEffect(() => {
    if(!contextLoading){
      callCaseDetailsAPI();
      callNoteAPI();
    }
  }, [contextLoading, callCaseDetailsAPI, callNoteAPI]);

  const scrollHandler = useCallback(() => {
    const paddingTop = 50;
    let eventId = undefined;
    const {height: parentHeight} = scrollElmRef.current.getBoundingClientRect();
    const allEvents = scrollElmRef.current.querySelectorAll("[id^='event-details']");
    for(let i = 0; i < allEvents.length; i++){
      const {top, height} = allEvents[i].getBoundingClientRect();
      if((top - paddingTop > 0) && ((top + height - paddingTop) <= parentHeight)){
        eventId = allEvents[i].getAttribute("data-id");
        break;
      }
    }

    if(eventId){
      const event = eventList.find(el => el.id === eventId);
      setSelectedEventData({...event});
    }
  }, [eventList]);

  return (
    <div className="w-full text-base dotted-background h-screen overflow-hidden">
      <Head/>
      <Header/>
      {!contextLoading && caseDetails && !isEmptyObject(caseDetails) &&
        <section className="pt-10">
          <div className="fixed w-1/4 left-0 bottom-0 top-10 overflow-auto bg-white z-30">
            <div className="relative">
              {caseDotMenuItems.length > 0 && 
                <div className="absolute right-1 top-1 z-10">
                  <DotMenu
                    items={caseDotMenuItems}
                  />
                </div>
              }
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowVMTBSummary(!showVMTBSummary);
                }}
              >
                <VMTB
                  {...fixVMTBDetails(caseDetails)}
                />
              </div>
            </div>
            {isOmSpecialist(userRoles) &&
              <CaseNote
                callback={callNoteAPI}
                noteData={specialistNoteData.caseNote[caseId]}
                speciaListId={speciaListId}
                caseId={caseId}
              />
            }
            <History
              isAsc={isAsc}
              setIsAsc={setIsAsc}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <div className="mb-4">
            {
              eventList.map((el, index) => <Events
                key={index}
                {...el}
                root={scrollElmRef}
                speciaListId={speciaListId}
                callback={callNoteAPI}
                selectedEventData={selectedEventData}
                setSelectedEventData={setSelectedEventData}
                eventNoteData={specialistNoteData.eventNote}
                setShowVMTBSummary={setShowVMTBSummary}
                showNote={isOmSpecialist(userRoles)}
              />)
            }
            </div>
          </div>
          
          <div className="ml-1/4">
            {!showVMTBSummary && 
              <div
                ref={scrollElmRef}
                className="scroll-container--header pb-3"
                onScroll={debounce(scrollHandler, 500)}
              >
                {
                  eventList.map(event => <EventDetails
                    key={event.id}
                    {...event}
                    root={scrollElmRef}
                    speciaListId={speciaListId}
                    callback={callNoteAPI}
                    recordNoteData={specialistNoteData.recordNote}
                    showRecordNote={isOmSpecialist(userRoles)}
                  />)
                }
              </div>
            }
            {showVMTBSummary && 
              <div className="h-screen overflow-auto">
                <div className="py-3 mx-auto max-w-screen-lg tracking-wider">
                  <VMTBSummary
                    {...caseDetails}
                    patientId={patientId}
                    eventList={eventList}
                    ref={vmtbRef}
                    allCaseNotes={allCaseNotes}
                    setCaseDetails={setCaseDetails}
                  />
                </div>
              </div>
            }
          </div>
          {showSearchModal &&
            <SearchSpecialist
              close={() => {
                setShowSearchModal(false);
              }}
              alreadySelected={
                assigningLeader ? (caseDetails.lead ? [caseDetails.lead] : []) : (
                  caseDetails.specialists ? caseDetails.specialists : []
                )
              }
              leaderSelection={assigningLeader}
              onSelect={(data) => {
                setSelectedSpecialist(data);
                setTimeout(() => {
                  callAddSpecialistAPI();
                });
              }}
            />
          }
          {
            showDeleteCaseConfirm && 
            <ConfirmDelete
              heading="Deleting case..."
              onConfirm={() => {
                callDeleteCaseAPI();
              }}
              close={() => {
                setShowDeleteCaseConfirm(false);
              }}
            />
          }
        </section>
      }
    </div>
  )
};

export default CaseDetails;
