import React from "react";

const ProgressRing = (props) => {
  const {radius, stroke, progress} = props;

  const square = radius * 2;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  
  return (
    <div
      style={{width:square, height:square}}
      className="progress-ring-cont relative mx-auto"
    >
      <svg height={square} width={square}>
        <circle
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          stroke="#ECECEC"
          strokeWidth={stroke}
          fill="transparent"
        />
        <circle stroke="#321E5A"
          style={{strokeDashoffset}}
          strokeWidth={stroke}
          strokeDasharray={circumference +' '+ circumference}
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          className="progress-ring"
        />
      </svg>
      <span
        className="font-medium text-text-gray absolute top-half right-half -translate-y-2/4 translate-x-2/4"
      >{Math.round(progress * 10) / 10}%</span>
    </div>
  )
};

export default ProgressRing;
