import React, {useRef, useEffect} from "react";

import {TOKEN_KEY} from "../../constant";

const DicomViewer = (props) => {
  const {url} = props;
  const iframe = useRef();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    iframe.current.src = `${url}&token=${token}`;
  }, [url]);

  return (
    <iframe
      ref={iframe}
      title="Dicom viewer"
      width="100%"
      height="630"
    />
  )
};

export default DicomViewer;
