import React, {useEffect, useState} from "react";
import {fixURL} from "../../utils";

const ShowCaseNotes = (props) => {
  const {allCaseNotes, allSpecialist} = props;

  const [docCaseNote, setDocCaseNote] = useState([]);
  useEffect(() => {
    if(allCaseNotes.length > 0 && allSpecialist.length > 0) {
      const docCaseNote = allCaseNotes.filter(el => el.type === "case_note").map(el => {
        const {specialist: coaseSpecialist} = el;
        const specialist = allSpecialist.find(specialist => specialist.id === coaseSpecialist.id);
        
        return {...el, ...(specialist ? {
          avatarUrl: specialist.avatarUrl,
          name: specialist.name
        } : {})}
      });
      setDocCaseNote(docCaseNote);
    }
  }, [allCaseNotes, allSpecialist]);

  return (
    <div className="bg-white p-3 mt-3 rounded tracking-wider text-xs font-medium border-b border-card-shadow">
      <div className="uppercase">Case notes</div>
      {
        docCaseNote.map(({id, name, description, specialist}, index) => (
          <div
            className={`flex mt-2${
              index === docCaseNote.length - 1 ? "" : " border-b border-gray pb-2"
            }`}
            key={id}
          >
            <div title={name} className="w-10 min-w-10">
              <img src={fixURL(true, specialist.id, specialist.avatarUrl)} alt={name} className="rounded mx-auto"/>
            </div>
            <div className="pl-2 font-normal leading-tight whitespace-pre-line">
              {description}
            </div>
          </div>
        ))
      }
      {
        docCaseNote.length === 0 && 
        <div className="mt-1 text-center font-normal">No case note found</div>
      }
    </div>
  )
};

export default ShowCaseNotes;
