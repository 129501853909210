import React from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import ContextData from "../store";

import {
  TOKEN_KEY
  //, REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, USERNAME_KEY
} from "../constant";

const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const status = (token) ? true : false;
  
  if(status && !window.setFlag){
    window.setFlag = true;

    axios.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      console.log(error);
      if(error.response && error.response.status === 403 && error.response.data.message === 'Token Expired.'){
        // localStorage.removeItem(TOKEN_KEY);
        // localStorage.removeItem(USERNAME_KEY);
        // localStorage.removeItem(REFRESH_TOKEN_KEY);
        // localStorage.removeItem(ACCESS_TOKEN_KEY);
        // window.location.pathname = "/login/";
      }
      return Promise.reject(error);
    });
  }

  return status;
};

const PrivateRoute = ({children}) => {
  const status = isAuthenticated();
  if(!status){
    return (
      <Navigate to="/login/"/>
    )
  }

  return (
    <ContextData>
      {children}
    </ContextData>
  )
};

export default PrivateRoute;
