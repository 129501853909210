import React, {useState, useEffect} from "react";

import Lottie from "react-lottie-player";

import Animation01 from "../../assets/animation/Animation-01.json";
import Animation02 from "../../assets/animation/Animation-02.json";
import Animation03 from "../../assets/animation/Animation-03.json";
import Animation04 from "../../assets/animation/Animation-04.json";

import Line01 from "../../assets/animation/Illustration-01-Lines.json";
import Line02 from "../../assets/animation/Illustration-02-Lines.json";
import Line03 from "../../assets/animation/Illustration-03-Lines.json";
import MLine01 from "../../assets/animation/M-Lines-1.json";
import MLine02 from "../../assets/animation/M-Lines-2.json";
import MLine03 from "../../assets/animation/M-Lines-3.json";

const Infographics = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if(window.innerWidth < 1024){
      setIsMobile(true);
    }
  }, []);

  return (
    <section className="mt-20 px-2 mobile:mt-14 mobile:px-4">
      <h2 className="text-purple-l font-bold text-3xl">Our Workflow</h2>
      <div className="relative px-16 mt-10 mobile:px-0 mobile:flex mobile:mt-6 mobile:justify-between">
        <div
          className={`
            absolute z-10 top-4 text-deep-sky font-bold text-4xl
            mobile:relative mobile:text-medium
          `}
        >Case study</div>
        <div 
          className={`
            w-26 left-80 top-17 z-10 ml-15 leading-tight absolute text-black
            mobile:left-0 mobile:top-8 mobile:m-0 mobile:text-xs
          `}
        >
          Case information,
          history, lab results,
          imaging and any
          other pertinent
          information
        </div>
        <Lottie
          className="w-80 mobile:w-1/2"
          loop
          animationData={Animation01}
          play
        />
        <Lottie
          className={`
            absolute -bottom-5 left-68 z-10 w-72 
            mobile:w-2/3 mobile:left-1/2 mobile:-translate-x-1/2
            mobile:-mt-5 mobile:top-full mobile:bottom-auto
          `}
          loop
          animationData={isMobile ? MLine01 : Line01}
          play
        />
      </div>
      <div
        className="relative px-16 mobile:px-0 mobile:flex mobile:mt-18 mobile:justify-between"
      >
        <Lottie
          className="w-96 ml-auto -mt-15 mobile:m-0 mobile:w-2/3"
          loop
          animationData={Animation02}
          play
        />
        <div className="absolute z-10 top-0 right-0 mobile:relative">
          <div
            className="text-purple-light text-right font-bold text-4xl mobile:text-medium"
          >Advanced <br/>Tech platform</div>
          <div
            className="mt-8 w-22 ml-auto text-left leading-tight text-black mobile:mt-1 mobile:text-xs"
          >
            Surfaces
            relevant studies,
            clinical trials,
            treatments.
          </div>
        </div>
        <Lottie
          className={`
            absolute -bottom-38 right-50 z-10 w-72
            mobile:w-2/3 mobile:left-1/2 mobile:-translate-x-1/2
            mobile:-mt-5 mobile:top-full mobile:bottom-auto
          `}
          loop
          animationData={isMobile ? MLine02 : Line02}
          play
        />
      </div>
      <div className="relative mobile:flex mobile:mt-26 mobile:justify-between">
        <div
          className="text-pink-deep font-bold text-4xl mobile:text-medium"
        >Educational <br/>Tumor board</div>
        <div
          className={`
            absolute w-70 left-100 top-40 leading-tight text-black z-10 pl-2
            mobile:text-xs mobile:top-8 mobile:left-0 mobile:p-0 mobile:w-26
          `}
        >
          Comprises of Medical Oncologist/ Hematologist,<br/>
          Radiation Oncologist, Surgical Oncologist, <br/>
          Pathologist, Radiologist, Bioinformatician, <br/>
          Data Scientist, Biostatistician, <br/>
          Genetic Counsellor, Palliative care specialist
        </div>
        <Lottie
          className="w-100 -mt-7 mobile:mt-0 mobile:w-2/3"
          loop
          animationData={Animation03}
          play
        />
        <Lottie
          className={`
            absolute -bottom-40 left-38 z-10 w-96
            mobile:w-2/3 mobile:left-1/2 mobile:-translate-x-1/2
            mobile:top-full mobile:bottom-auto mobile:-mt-4
          `}
          loop
          animationData={isMobile ? MLine03 : Line03}
          play
        />
      </div>
      <div className="relative px-16 mobile:px-0 mobile:flex mobile:mt-20 mobile:justify-between">
        <Lottie
          className="w-80 ml-auto -mt-11 mobile:m-0 mobile:w-2/3"
          loop
          animationData={Animation04}
          play
        />
        <div className="mobile:w-1/3">
          <div
            className={`
              absolute text-deep-purple font-bold text-4xl z-10 w-42 
              text-right right-60 mr-4 top-4 leading-tight
              mobile:text-medium mobile:relative mobile:top-0 
              mobile:m-0 mobile:right-auto mobile:left-0
              mobile:text-left mobile:w-auto
            `}
          >Report published.</div>
          <div
            className={`
              absolute w-38 left-60 top-50 leading-tight text-black z-10
              mobile:top-1 mobile:left-0 mobile:text-xs mobile:w-auto mobile:relative
            `}
          >
            Comprehensive report generated based on the discussion with the multi-disciplinary team.
          </div>
        </div>
      </div>
    </section>
  )
};

export default Infographics;
