import React from "react";
import Select from "react-select";

const Dropdown = (props) => {
  const {
    name, label, data, required, multiColumn, formData, onUpdate, 
    touchedFieldObj, validationObj, setTouchedFieldObj, className
  } = props;
  
  const selectedData = formData[name] ? data.find(el => el.value === formData[name]) : undefined;

  return (
    <div className={`h-13 flex flex-col items-start justify-start ${
      multiColumn ? "w-68" : "w-full"} ${className}`
    }>
      <div className="flex flex-row items-center">
        <div className="h-9 w-60 relative">
          <Select
            isSearchable={true}
            name={name}
            placeholder={label}
            defaultValue={selectedData}
            onChange={(data) => {
              const {value} = data;
              onUpdate({
                ...formData,
                [name]: value
              });
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));
            }}
            onMenuClose={() => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));
            }}
            options={data}
            className={`custom-dropdown ${
              (validationObj[name] && validationObj[name].isValid) || !required ? "valid" : "invalid"
            }${required ? " required" : ""}${touchedFieldObj[name] ? " touched" : ""}`}
          />
          {formData[name] && 
            <label className="form-label">
              {label}
              {required && <span className="text-pink-deep">*</span>}
            </label>
          }
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
              className="block w-full text-pink-deep pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
