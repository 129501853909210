import {allReportTypes} from "../../../constant";

export const getFormConfig = (index) => [{
  component: "dropdown",
  name: `type-${index}`,
  label: "Record type",
  required: true,
  className: "",
  data: allReportTypes
}, {
  component: "date",
  name: `date-${index}`,
  label: "Record date"
}];
