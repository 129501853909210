import TimelineView from "../../components/addEvent/timelineView";
import AnalyzeRecords from "../../components/addEvent/analyzeRecords";

export const workflowSteps = [{
  id: "analyze-records",
  title: "Analyze Records",
  description: "View, verify and analyze all uploaded records.",
  component: AnalyzeRecords,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "manage-events",
  title: "Manage Events",
  description: `Here you can view the events in a timeline and merge records to create events, 
  attach/detach records from an event etc.`,
  component: TimelineView,
  layout: {
    footer: false
  }
}];
