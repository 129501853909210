import React from "react";
import {useNavigate} from "react-router-dom";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

import {workflowSteps} from "./configs/create-specialist.config";

const CreateSpecialist = (props) => {
  const navigate = useNavigate();

  const {loading: contextLoading} = useContextData();
  
  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading &&
        <Workflow
          {...props}
          steps={workflowSteps}
          onExit={() => {
            navigate("/manage-specialists/");
          }}
        />
      }
    </div>
  )
};

export default CreateSpecialist;
