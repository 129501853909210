import React, {useEffect, Fragment} from "react";

import Form from "../common/form";
import {getLabelObj} from "../../utils/workflowUtils";
import {formConfig, formSchema} from "./configs/professionalDetails.config";

const ProfessionalDetails = (props) => {
  const {onValidate, onStepUpdate, stepData = {
    canBeLeader: false,
    experience: 1
  }, setLabelObject, stepId} = props;
  
  useEffect(() => {
    const labelObj = getLabelObj(formConfig);
    setLabelObject(lObj => ({...lObj, [stepId]: labelObj}));
  }, [setLabelObject, stepId]);

  return (
    <Fragment>
      <Form
        config={formConfig}
        initialValues={stepData}
        multiColumn={true}
        schema={formSchema}
        onFormValid={(isFormValid) => {
          onValidate(stepId, isFormValid);
        }}
        onFormUpdate={(stepData) => {
          onStepUpdate(stepData)
        }}
      />
    </Fragment>
  )
};

export default ProfessionalDetails;
