import remove from "../../../assets/delete.png";
import {recordCategories} from "../../../constant";

export const getAnalyzeRecordConfigs = (index) => [{
  component: "dropdown",
  name: `type-${index}`,
  label: "Record type",
  required: true,
  className: "",
  data: recordCategories.reduce((data, el) => {
    const {types} = el;
    data.push(...types.map(({name: label, value}) => ({
      label,
      value
    })));
    return data;
  }, [])
}, {
  component: "date",
  name: `date-${index}`,
  label: "Record date",
  required: true
}, {
  component: "textarea",
  name: `description-${index}`,
  label: "Record description",
  required: true
}];

export const getRecordDotMenu = (callback, event, recordId) => [{
  icon: remove,
  name: "Detach record",
  click: () => {
    callback(event, recordId);
  }
}];
