import React, {useState} from "react";

import {ReactComponent as FilterLogo} from "../../assets/filter.svg";

const CaseFilter = () => {
  const [openFilterPopup, setOpenFilterPopup] = useState(false);

  return (
    <div className="relative tracking-wider opacity-50 pointer-events-none h-7" title="available soon">
      <div
        className="flex h-full py-1 px-2 rounded cursor-pointer bg-list-bg items-center"
        onClick={() => {
          setOpenFilterPopup(!openFilterPopup);
        }}
      >
        <FilterLogo className="w-4 h-4 mr-1 fill-purple"/>
        <span className="font-medium text-xs">Filter</span>
      </div>

      {openFilterPopup && 
        <div className="absolute right-0 top-full bg-white rounded mt-1 p-2 w-60">
          <h3 className="text-base font-extrabold">Case Filter</h3>
          <ul className="pl-2 pt-2 px-1 text-text-gray font-medium ">
            <li className="mb-1.5">
              <label
                className="block pl-5 relative custom-input text-xs"
              >
                <input
                  type="checkbox"
                  value="true"
                  name="my-case"
                  className="absolute opacity-0"
                />
                <span
                  className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
                />
                <span className="label-text">My cases</span>
              </label>
            </li>
            <li className="mb-1.5">
              <label
                className="block pl-5 relative custom-input text-xs"
              >
                <input
                  type="checkbox"
                  value="true"
                  name="all-case"
                  className="absolute opacity-0"
                />
                <span
                  className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
                />
                <span className="label-text">All Cases</span>
              </label>
            </li>
            <li className="mb-1.5">
              <label
                className="block pl-5 relative custom-input text-xs"
              >
                <input
                  type="checkbox"
                  value="true"
                  name="all-case"
                  className="absolute opacity-0"
                />
                <span
                  className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
                />
                <span className="label-text">All Cases</span>
              </label>
            </li>
          </ul>
        </div>
      }
    </div>
  )
};

export default CaseFilter;
