import Done from "../../components/editConsultingDoctor/done";
import Summary from "../../components/editConsultingDoctor/summary";

import PersonalDetails from "../../components/createConsultingDoctor/personalDetails";
import ProfessionalDetails from "../../components/createConsultingDoctor/professionalDetails";

export const workflowSteps = [{
  id: "personal-details",
  title: "Personal Details",
  description: "Edit the personal details of the doctor to edit the Consulting Doctor.",
  component: PersonalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "professional-details",
  title: "Professional Details",
  description: "Edit the professional details of the doctor to edit the Consulting Doctor.",
  component: ProfessionalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "summary",
  title: "Summary",
  description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Create Consulting Doctor.`,
  component: Summary,
  layout: {
    primary: {
      label: "Edit Consulting Doctor"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "done",
  title: "Done!",
  description: "The consulting doctor is being edited.",
  component: Done,
  layout: {
    footer: false
  }
}];

export const createStepData = (tenantData) => {
  console.log(tenantData);
  const {
    name, email, userName, phone, phone1, phone2, address1, address2, city, state, country, postalCode, 
    avatarUrl: avatar, timezone, specialization, qualification, experience = 1, languages, bio, 
    socialMedia = [], admin = false
  } = tenantData;

  return {
    "personal-details": {
      name, 
      email, 
      userName,
      phone: [phone, phone1, phone2].reduce((data, el) => {
        if(el){
          data.push(el);
        }
        return data;
      }, []),
      address1,
      address2,
      city, 
      postalCode,
      state,
      country
    },
    "professional-details": {
      avatar,
      timezone,
      admin,
      specialization,
      qualification,
      experience: !experience || experience <= 0 ? 1 : experience,
      languages: languages ? languages.split(",") : [],
      bio,
      ...(
        socialMedia.reduce((data, el) => {
          const {type, url} = el;
          data[type] = url;
          return data;
        }, {})
      )
    },
    "done": {
      ...(
        socialMedia.reduce((data, el) => {
          const {id, type} = el;
          data[type] = id;
          return data;
        }, {})
      )
    }
  }
};
