import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";
import {createPayload, createAvatarPayload} from "./configs/done.config";

import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

const Done = (props) => {
  const {getState} = props;
  const stateData = getState();
  const {tenant} = stateData["select-tenant"];

  const [isLoading, setIsLoading] = useState(true);
  const [consultingDoctorId, setConsultingDoctorId] = useState("");

  const {uploadConsultingDoctorAvatar, getAllConsultingDoctors} = API_PATH;
  const payload = createPayload(stateData);
  const {
    data: consultingDoctorData, loading: consultingDoctorLoading, callAPI: consultingDoctorCallAPI, 
    error: tenantError, errorMessage: tenantErrorMessage
  } = useRequest(formatString(getAllConsultingDoctors, [tenant]), false, payload, "POST");
  useEffect(() => {
    if(consultingDoctorData && !consultingDoctorLoading){
      const {result = {}} = consultingDoctorData;
      setConsultingDoctorId(result.id);
    }
  }, [consultingDoctorData, consultingDoctorLoading]);

  useEffect(() => {
    if(tenantError){
      setIsLoading(false);
      showAlert(tenantErrorMessage, "error");
    }
  }, [tenantError, tenantErrorMessage]);

  useEffect(() => {
    consultingDoctorCallAPI();
  }, [consultingDoctorCallAPI]);
  
  const avatarPayload = createAvatarPayload(stateData);
  const {
    data: avatarData, loading: avatarLoading, callAPI: avatarCallAPI, 
    error: avatarError, errorMessage: avatarErrorMessage
  } = useRequest(
    formatString(uploadConsultingDoctorAvatar, [consultingDoctorId]), false, avatarPayload, "POST"
  );
  useEffect(() => {
    if(avatarData && !avatarLoading){
      setIsLoading(false);
      showAlert("The consultant doctor has been created successfully!");
    }
  }, [avatarData, avatarLoading]);

  useEffect(() => {
    if(avatarError && avatarErrorMessage){
      showAlert(avatarErrorMessage, "error");
    }
  }, [avatarError, avatarErrorMessage]);

  useEffect(() => {
    if(consultingDoctorId){
      setTimeout(() => {
        avatarCallAPI();
      });
    }
  }, [consultingDoctorId, avatarCallAPI]);

  const caseMessage = isLoading ? "Consulting doctor creation is in progress..." : (
    tenantError ? "Consulting doctor creation has failed!" : "Consulting doctor has been created successfully!"
  );

  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {isLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!isLoading && 
            <Fragment>
              {!tenantError && <Success className="w-4 h-4"/>}
              {tenantError && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {consultingDoctorId &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            <div className="pt-2">
              <Link
                className="text-purple-light hover:text-purple"
                to={`/manage-consulting-doctors/?tenant=${tenant}`}
              >View all Consulting Doctors</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
