import React from "react";
import {useNavigate} from "react-router-dom";

import {isOmAdmin} from "../utils";
import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

import {getWorkflowConfig} from "./configs/create-case.config";

const CreateCase = (props) => {
  const navigate = useNavigate();

  const {userRoles = [], loading: contextLoading} = useContextData();
  const steps = getWorkflowConfig(isOmAdmin(userRoles));

  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading &&
        <Workflow
          {...props}
          steps={steps}
          onExit={() => {
            navigate("/case/");
          }}
        />
      }
    </div>
  )
};

export default CreateCase;
