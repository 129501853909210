import React, {useState, useRef, useEffect} from 'react';
import * as dcmjs from 'dcmjs';

import ProgressRing from "./progress";
import uploadImg from "../../assets/upload.svg";



const DicomUpload = (props) => {
  const {progress, callback, loading} = props;

  const dropArea = useRef();
  const [text, setText] = useState("Drag and drop folder or click to select multiple DICOM files");

  useEffect(() => {
    if(progress >= 100 && loading){
      setText('Processing...');
      return;
    }

    if(!loading && progress === 100){
      setText('Sucessfully uploaded! Click here to upload more.');
    }
  }, [progress, loading]);

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    handleSelectedFiles(selectedFiles);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if(event.type === "dragenter" || event.type === "dragover"){
      dropArea.current.classList.add("highlight");
      return;
    }
    
    if(event.type === "dragleave" || event.type === "drop"){
      dropArea.current.classList.remove("highlight");
    }

    if(event.type === "drop"){
      handleSelectedFiles(event.dataTransfer.items);
    }
  };

  const isDicomFile = async (file) => { 
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        try{
          dcmjs.data.DicomMessage.readFile(e.target.result);
          resolve(true);
        }
        catch(e){
          resolve(false);
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  };

  const readEntries = (dirReader) => {
    return new Promise((resolve, reject) => {
      dirReader.readEntries(entries => {
        resolve(entries);
      }, error => {
        reject(error);
      });
    });
  };

  const getFile = async (fileEntry) => {
    try {
      return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  }

  const handleSelectedFiles = async (selectedFiles) => {
    const dicomFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileSystemEntry = file.webkitGetAsEntry ? file.webkitGetAsEntry() : (
        file.mozGetAsFile ? file.mozGetAsFile() : false
      );
      
      //This is folder, read all the files inside the folder
      if (fileSystemEntry && fileSystemEntry.isDirectory) {
        const dirReader = fileSystemEntry.createReader();
        const entries = await readEntries(dirReader);
        for(let j = 0; j < entries.length; j++) {
          const entry = entries[j];
          if (entry.isFile) {
            const data = await getFile(entry);
            const isDicom = await isDicomFile(data);
            if(isDicom) {
              dicomFiles.push(data);
            }
          }
        }
      }
      
      //This is file
      if((file.name && file.type) || file.kind === "file"){
        const fileToProcess = (file.kind === "file") ? file.getAsFile() : file;
        const zipFileTypes = [
          "application/octet-stream", "application/zip", "application/x-zip", "application/x-zip-compressed"
        ];
        const isDicom = await isDicomFile(fileToProcess);
        if(zipFileTypes.includes(fileToProcess.type) || isDicom){
          dicomFiles.push(fileToProcess);
        }
      }
    }
    if(dicomFiles.length === 0){
      setText("Uploaded file format should be .DCM, .DICOM or .ZIP!");
      return;
    }
    callback(dicomFiles);
  };

  return (
    <div
      id="drag-drop-dicom-file"
      ref={dropArea}
      onDrop={handleDrop}
      onDragEnter={handleDrop}
      onDragLeave={handleDrop}
      onDragOver={handleDrop}
    >
      <form encType="multipart/form-data">
        <label className="upload-area">
          <div className="flex flex-wrap flex-row items-center h-full">
            <div className="grow self-auto text-center px-2">
              {progress === 0 && <img src={uploadImg} alt="upload" className="w-14 block mx-auto mb-1"/>}
              {progress !== 0 && 
                <ProgressRing
                  stroke="5"
                  radius="45"
                  progress={progress}
                />
              }
              <span className="font-medium text-text-gray">{text}</span>
              <input
                type="file"
                name="dicomfile"
                className="hidden"
                onChange={handleFileChange}
                onClick={() => {
                  setText("Drag and drop folder or click to select multiple DICOM files");
                }}
                accept={`.dcm, .dicom, application/dicom, application/x-directory, .zip, 
                application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed`}
                multiple
                // directory=""
                // webkitdirectory=""
                // mozdirectory=""
              />
            </div>
          </div>
        </label>
      </form>
    </div>
    // <input type="file" id="picker" name="fileList" directory="" webkitdirectory="" multiple/>
  );
};

export default DicomUpload;
