import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

import Head from "../components/head";
import VMTB from "../components/vmtb";
import Header from "../components/header";
import useRequest from "../hooks/useRequest";
import useDebounce from "../hooks/useDebounce";
// import DotMenu from "../components/common/dotMenu"; 
import CaseFilter from "../components/dashboard/caseFilter";

import {API_PATH} from "../constant";
import {useContextData} from "../store";
import {parseCases, formatString, hasCaseCreationAccess, isOmSpecialist, showAlert} from "../utils";

import search from "../assets/search.svg";
// import upload from "../assets/upload.png";

const Dashboard = () => {
  const [caseData, setCaseData] = useState([]);
  const [typedName, setTypedName] = useState("");
  const [headerObject, setHeaderObject] = useState({});
  const [droppedCaseId, setDroppedCaseId] = useState("");
  const [doppedCaseStatus, setDroppedCaseStatus] = useState("");

  const {
    loading: contextLoading, userRoles = [], specialistId
  } = useContextData();
  const {getAllCases, caseDetails, getSpecialistCase} = API_PATH;

  const getCaseAPI = isOmSpecialist(userRoles) ? formatString(
    getSpecialistCase, [specialistId]
  ) : getAllCases;
  const {
    data: getCaseData, loading: getCaseLoading, callAPI: getCaseCallAPI
  } = useRequest(getCaseAPI, false, {}, "GET", {...headerObject});
  useEffect(() => {
    if(getCaseData && !getCaseLoading) {
      const {result = []} = getCaseData;
      setCaseData(parseCases(result, userRoles));
    }
  }, [userRoles, getCaseData, getCaseLoading]);

  const debouncedFunc = useDebounce(getCaseCallAPI);
  useEffect(() => {
    if(!contextLoading){
      debouncedFunc();
    }
  }, [contextLoading, headerObject, debouncedFunc]);

  useEffect(() => {
    const allFilters = [];
    if(typedName){
      allFilters.push(`patient.name:*${typedName}* OR id:*${typedName}*`);
    }

    setHeaderObject({
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ") + "?sort=priority"
    });
  }, [typedName]);

  const {
    data: updateCaseData, loading: updateCaseLoading, callAPI: updateCaseAPI
  } = useRequest(formatString(caseDetails, [droppedCaseId]), false, {
    status: doppedCaseStatus
  }, "PATCH");
  useEffect(() => {
    if(!updateCaseLoading && updateCaseData){
      showAlert("Case state has been updated successfully!")
    }
  }, [updateCaseData, updateCaseLoading]);

  const onDragEnd = useCallback((result) => {
    const {source, destination} = result;
    // dropped nowhere
    if (!source || !destination) {
      return;
    }

    const {droppableId: sourceId, index: sourceIndex} = source;
    const {droppableId: dropId, index: dropIndex} = destination;
    if(sourceId === dropId) {
      return;
    }

    const clonedData = Array.from(caseData);
    const sourceObject = clonedData.find(el => el.id === sourceId);
    const sourceObjectIndex = clonedData.findIndex(el => el.id === sourceId);

    const clonedObject = {...sourceObject.items[sourceIndex]};
    const {id} = clonedObject;
    setDroppedCaseId(id);
    setDroppedCaseStatus(dropId);

    setTimeout(() => {
      updateCaseAPI();
    });

    const dropObject = clonedData.find(el => el.id === dropId);
    const dropObjectIndex = clonedData.findIndex(el => el.id === dropId);
    sourceObject.items.splice(sourceIndex, 1);
    dropObject.items.splice(dropIndex, 0, clonedObject);

    clonedData[sourceObjectIndex] = sourceObject;
    clonedData[dropObjectIndex] = dropObject;
    setCaseData(clonedData);
  }, [caseData, updateCaseAPI]);

  return (
    <div className="w-full">
      <Head/>
      <Header/>
      <section className="pt-10 dotted-background">
        <div className="px-4 py-2 flex justify-end">
          <div className="flex py-1 mr-2 bg-list-bg rounded items-center w-50">
            <div className="pl-2">
              <img src={search} alt="search" className="w-5"/>
            </div>
            <div className="w-full">
              <input
                value={typedName}
                type="text"
                className="h-5 w-full px-2 outline-none bg-transparent"
                placeholder="Search"
                onChange={e => setTypedName(e.target.value)}
              />
            </div>
          </div>
          <div className="mr-2">
            <CaseFilter/>
          </div>
          {hasCaseCreationAccess(userRoles) && 
            <Link
              to={"/create-case/case-type/"}
              className="primary-btn"
            >Create New Case</Link>
          }
        </div>
        
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <div className="flex px-4 pb-2 custom-dashboard items-start overflow-x-scroll">
            {
              caseData.map(({id, name, items}, index) => (
                <div 
                  key={id}
                  className={
                    `list-container rounded bg-list-bg flex-no-shrink w-1/4 pl-2 pb-2 pr-1 
                    ${index === caseData.length - 1 ? "" : "mr-3"}`
                  }
                >
                  <div className="flex justify-between py-2">
                    <h3 className="text-base font-extrabold px-1">{name}</h3>
                  </div>
                  <Droppable droppableId={id}>
                    {(provided) => (
                      <div 
                        className="list-inner-container text-sm overflow-auto" 
                        {...provided.droppableProps} 
                        ref={provided.innerRef}
                      >
                        {
                          items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided) => (
                                <div
                                  className={`
                                    border-b border-card-shadow cursor-pointer relative 
                                    rounded-md overflow-hidden hover:bg-gray-light
                                    ${index === items.length - 1 ? "" : "mb-2"}
                                  `}
                                  key={item.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Link to={`/case/${item.patient.id}/${item.id}/`}>
                                    <VMTB
                                      {...item}
                                    />
                                  </Link>
                                  {/* <div className="z-10 absolute right-1 top-1">
                                    <DotMenu
                                      items={[{
                                        icon: upload,
                                        name: "Upload reports",
                                        type: "link",
                                        to: `/upload-report/${item.patient.id}/${item.id}/`
                                      }]}
                                    />
                                  </div> */}
                                </div>
                              )}
                            </Draggable>
                          ))
                        }
                        {items.length === 0 && 
                          <div className="text-xs px-1 text-text-gray font-medium">No cards present</div>
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))
            }
          </div>
        </DragDropContext>
      </section>
    </div>
  );
}

export default Dashboard;
