import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";

import CaseDesc from "./caseDesc";
import CaseSummary from "./caseSummary";
import TumourBoard from "./tumourBoard";
import ShowCaseNotes from "./showCaseNotes";
import EventRecordNote from "./eventRecordNote";
import TopicDiscussion from "./topicDiscussion";

import {API_PATH} from "../../constant";
import {useContextData} from "../../store";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";

const VMTBSummary = forwardRef((props, ref) => {
  const {
    summary, description, id:caseId, eventList, allCaseNotes, patientId, setCaseDetails
  } = props;
  
  const [allTopics, setAllTopics] = useState([]);
  const [typedTopic, setTypedTopic] = useState("");
  const [allSpecialist, setAllSpecialist] = useState([]);
  const [tobeDeleteTopic, setTobeDeletedTopic] = useState({});

  const {loading: contextLoading} = useContextData();
  const {getQueries, caseSpecialist, getSpecificQuery} = API_PATH;
  const {
    data: queryData, loading: queryLoading, callAPI: callQueryAPI
  } = useRequest(formatString(getQueries, [caseId]), false, {}, "GET", {
    "X-OM-FILTER": "?sort=createdAt"
  });
  useEffect(() => {
    if(!queryLoading && queryData){
      const {result = []} = queryData;
      setAllTopics(result);
    }
  }, [queryLoading, queryData]);

  const {
    data: specialistData, loading: specialistLoading, callAPI: callSpecialistAPI
  } = useRequest(formatString(caseSpecialist, [caseId]), false);
  useEffect(() => {
    if(!specialistLoading && specialistData){
      const {result} = specialistData;
      setAllSpecialist(result);
    }
  }, [specialistData, specialistLoading]);

  const {
    data: createQueryData, loading: createQueryLoading, callAPI: createQueryAPI
  } = useRequest(formatString(getQueries, [caseId]), false, {
    question: typedTopic
  }, "POST");
  useEffect(() => {
    if(!createQueryLoading && createQueryData){
      callQueryAPI();
      setTypedTopic("");
      showAlert("New question has been added successfully!");
    }
  }, [createQueryData, createQueryLoading, callQueryAPI]);

  const {id: topicId} = tobeDeleteTopic;
  const {data: deleteTopicData, loading: deleteTopicLoading, callAPI: deleteTopicAPI} = useRequest(
    formatString(getSpecificQuery, [topicId]), false, {}, "DELETE"
  );
  useEffect(() => {
    if(!deleteTopicLoading && deleteTopicData && topicId){
      setAllTopics(topics => {
        const clonedTopics = topics.filter(el => el.id !== topicId);
        return clonedTopics;
      });
    }
  }, [deleteTopicData, deleteTopicLoading, topicId]);

  useImperativeHandle(ref, () => ({
    callSpecialistAPI: () => {
      callSpecialistAPI();
    }
  }));

  useEffect(() => {
    if(!contextLoading){
      callQueryAPI();
      callSpecialistAPI();
    }
  }, [contextLoading, callQueryAPI, callSpecialistAPI]);

  return (
    <div className="tracking-wider text-text-gray pb-10">
      <CaseDesc
        description={description}
        caseId={caseId}
        setCaseDetails={setCaseDetails}
      />
      <TopicDiscussion
        typedTopic={typedTopic}
        setTypedTopic={setTypedTopic}
        createQuery={createQueryAPI}
        allTopics={allTopics}
        setAllTopics={setAllTopics}
        setTobeDeletedTopic={setTobeDeletedTopic}
        deleteTopicAPI={() => {
          deleteTopicAPI();
        }}
      />
      <TumourBoard
        allSpecialist={allSpecialist}
      />
      <ShowCaseNotes
        allCaseNotes={allCaseNotes}
        allSpecialist={allSpecialist}
      />
      <EventRecordNote
        allCaseNotes={allCaseNotes}
        eventList={eventList}
        patientId={patientId}
      />
      <CaseSummary
        summary={summary}
        caseId={caseId}
        setCaseDetails={setCaseDetails}
      />
    </div>
  )
});

export default VMTBSummary;
