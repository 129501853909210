import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";

import {API_PATH} from "../../constant";
import useRequest from "../../hooks/useRequest";
import {formatString, showAlert} from "../../utils";
import {createPayload, createAvatarPayload} from "./configs/done.config";

import {ReactComponent as Error} from "../../assets/error.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as HighFive} from "../../assets/high-five.svg";

const Done = (props) => {
  const {getState} = props;
  const stateData = getState();

  const [isLoading, setIsLoading] = useState(true);
  const [specialistId, setSpecialistId] = useState("");

  const {getAllSpecialists, uploadSpecialistAvatar} = API_PATH;
  const payload = createPayload(stateData);
  const {
    data: specialistData, loading: specialistLoading, callAPI: specialistCallAPI, 
    error: specialistError, errorMessage: specialistErrorMessage
  } = useRequest(getAllSpecialists, false, payload, "POST");
  useEffect(() => {
    if(specialistData && !specialistLoading){
      const {result = {}} = specialistData;
      setSpecialistId(result.id);
    }
  }, [specialistData, specialistLoading]);

  useEffect(() => {
    if(specialistError){
      setIsLoading(false);
      showAlert(specialistErrorMessage, "error");
    }
  }, [specialistError, specialistErrorMessage]);

  useEffect(() => {
    specialistCallAPI();
  }, [specialistCallAPI]);
  
  const avatarPayload = createAvatarPayload(stateData);
  const {
    data: avatarData, loading: avatarLoading, callAPI: avatarCallAPI, 
    error: avatarError, errorMessage: avatarErrorMessage
  } = useRequest(
    formatString(uploadSpecialistAvatar, [specialistId]), false, avatarPayload, "POST"
  );
  useEffect(() => {
    if(avatarData && !avatarLoading){
      setIsLoading(false);
      showAlert("The specialist has been created successfully!");
    }
  }, [avatarData, avatarLoading]);

  useEffect(() => {
    if(avatarError && avatarErrorMessage){
      showAlert(avatarErrorMessage, "error");
    }
  }, [avatarError, avatarErrorMessage]);

  useEffect(() => {
    if(specialistId){
      setTimeout(() => {
        avatarCallAPI();
      });
    }
  }, [specialistId, avatarCallAPI]);

  const caseMessage = isLoading ? "Specialist creation is in progress..." : (
    specialistError ? "Specialist creation has failed!" : "Specialist has been created successfully!"
  );

  return (
    <div className="flex items-center">
      <div className="w-half">
        <div className="flex items-center justify-between max-w-76 border-b border-gray pb-5">
          <p className="text-text-gray">{caseMessage}</p>
          {isLoading && <Loading className="w-4 h-4 text-purple-light animate-spin"/>}
          {!isLoading && 
            <Fragment>
              {!specialistError && <Success className="w-4 h-4"/>}
              {specialistError && <Error className="w-4 h-4"/>}
            </Fragment>
          }
        </div>
        {specialistId &&
          <div className="pt-7 leading-snug">
            <h2 className="text-medium-l text-black">What's Next?</h2>
            <div className="pt-2">
              <Link
                className="text-purple-light hover:text-purple"
                to="/manage-specialists/"
              >View all Specialists</Link>
            </div>
          </div>
        }
      </div>
      <div className="w-half">
        <HighFive className="max-w-100"/>
      </div>
    </div>
  );
};

export default Done;
