import {useCallback, useRef} from "react";

const useDebounce = (func, dTimer = 500) => {
  const timer = useRef();
  return useCallback((...args) => {
    const context = this;
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      func.apply(context, args);
    }, dTimer);
  }, [func, dTimer]);
};

export default useDebounce;
