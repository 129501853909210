import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Link, useLocation} from "react-router-dom";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import useRequest from "../hooks/useRequest";
import useDebounce from "../hooks/useDebounce";
import DotMenu from "../components/common/dotMenu";
import ConfirmDelete from "../components/common/confirmDelete";

import {fixURL, formatString, showAlert} from "../utils";
import {languages, getLanguageObject} from "../constant/languages";
import {getDotMenu} from "./configs/manage-consulting-doctor.config";
import {experienceRange, API_PATH, socialMediaImageMap} from "../constant";

import search from "../assets/search.svg";
const grey = require("../assets/grey.svg");

const ManageConsultingDoctors = () => {
  const {loading: contextLoading} = useContextData();
  
  const data = useLocation();
  const query = new URLSearchParams(data.search);
  const tenantId = query.get("tenant");

  const [typedName, setTypedName] = useState("");
  const [allTenants, setAllTenants] = useState([]);
  const [headerObject, setHeaderObject] = useState({});
  const [intendedAction, setIntendedAction] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState("");
  const [selectedTenant, setSelectedTenant] = useState(tenantId || "");
  const [allConsultingDoctors, setAllConsultingDoctors] = useState([]);

  const languageObject = getLanguageObject();
  const {getAllTenantUsers, getSpecificConsultingDoctor, getAllTenants} = API_PATH;

  const {data: tenantData, loading: tenantLoading} = useRequest(getAllTenants);
  useEffect(() => {
    if(!tenantLoading && tenantData){
      const {result = []} = tenantData;
      setAllTenants(result);
      if(result.length > 0 && !tenantId){
        const {id} = result[0];
        setSelectedTenant(id);
      }
    }
  }, [tenantData, tenantLoading, tenantId]);

  const {
    data: consultingDoctorData, loading: consultingDoctorLoading, 
    callAPI: callConsultingDoctorAPI
  } = useRequest(getAllTenantUsers, false, {}, "GET", headerObject);
  useEffect(() => {
    if(!consultingDoctorLoading && consultingDoctorData){
      const {result = []} = consultingDoctorData;
      setAllConsultingDoctors(result);
    }
  }, [consultingDoctorData, consultingDoctorLoading]);

  useEffect(() => {
    const allFilters = [];
    if(selectedLanguage.length > 0){
      allFilters.push(selectedLanguage.reduce((data, el) => {
        data.push(`languages:${el},* OR languages:*${el},* OR languages:*,${el} OR languages:${el}`);
        return data;
      }, []).join(" OR "));
    }

    if(selectedTenant){
      allFilters.push(`tenant.id:${selectedTenant}`);
    }

    if(selectedExperience.length > 0){
      allFilters.push(selectedExperience.reduce((data, el) => {
        const range = el.split("-");
        data.push(`(experience>${range[0]} AND experience<${range[1]})`);
        return data;
      }, []).join(" OR "))
    }

    if(typedName){
      allFilters.push(`name:*${typedName}*`);
    }

    setHeaderObject(allFilters.length ? {
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ")
    } : {});
  }, [selectedLanguage, selectedTenant, selectedExperience, typedName]);

  const debouncedFunc = useDebounce(callConsultingDoctorAPI);
  useEffect(() => {
    if(selectedTenant){
      debouncedFunc();
    }
  }, [selectedTenant, headerObject, debouncedFunc]);

  const {data: deleteData, loading: deleteLoading, callAPI: deleteCallAPI} = useRequest(
    formatString(getSpecificConsultingDoctor, [selectedSpecialist]), false, {}, "DELETE"
  );
  useEffect(() => {
    if(deleteData && !deleteLoading){
      showAlert("The consulting doctor has been successfully deleted.");
      setAllConsultingDoctors([]);
      setTimeout(() => {
        callConsultingDoctorAPI();
      });
    }
  }, [deleteData, deleteLoading, callConsultingDoctorAPI]);

  const callBack = (id, intend) => {
    setSelectedSpecialist(id);
    setIntendedAction(intend);
  };

  const selectedTenantData = allTenants.find(el => el.id === selectedTenant);
  return (
    <div className="w-full">
      <Head/>
      <Header/>
      {!contextLoading && allTenants.length > 0 && 
        <section className="pt-10 pb-10 dotted-background min-h-screen">
          <div className="px-4 py-2 flex justify-end">
            <div className="flex py-1 mr-2 bg-list-bg rounded items-center w-50">
              <div className="pl-2">
                <img src={search} alt="search" className="w-5"/>
              </div>
              <div className="w-full">
                <input
                  type="text"
                  value={typedName}
                  className="h-5 w-full px-2 outline-none bg-transparent"
                  placeholder="Search by name..."
                  onChange={e => setTypedName(e.target.value)}
                />
              </div>
            </div>
            <Select
              isSearchable={true}
              isMulti
              name="language"
              placeholder="Filter by languages..."
              defaultValue={languages.filter(el => selectedLanguage.includes(el.value))}
              options={languages}
              onChange={(data) => {
                setSelectedLanguage(data.map(el => el.value));
              }}
              className="w-50 mr-2 dropdown-with-bg leading-snug z-30"
            />
            <Select
              isSearchable={true}
              isMulti
              name="language"
              placeholder="Filter by experience..."
              defaultValue={experienceRange.filter(el => selectedExperience.includes(el.value))}
              options={experienceRange}
              onChange={(data) => {
                setSelectedExperience(data.map(el => el.value));
              }}
              className="w-50 mr-2 dropdown-with-bg leading-snug z-30"
            />
            <Select
              isSearchable={true}
              name="tenant"
              placeholder="Select tenant..."
              defaultValue={allTenants.filter(el => el.id === selectedTenant).map(el => ({
                label: el.name,
                value: el.id
              }))}
              options={allTenants.map(el => ({
                label: el.name,
                value: el.id
              }))}
              onChange={(data) => {
                const {value} = data;
                setSelectedTenant(value);
              }}
              className="mr-2 w-50 dropdown-with-bg leading-snug"
            />
            <Link
              to={"/create-consulting-doctor/tenant-details/"}
              className="primary-btn"
            >Create New Consulting Doctor</Link>
          </div>
          <div className="m-auto max-w-screen-md">
            {allConsultingDoctors.length > 0 &&
              <ul className="grid grid-cols-2 gap-4">
                {
                  allConsultingDoctors.map(doctor => {
                    const {
                      id, avatarUrl, name, qualification, specialization, experience, 
                      languages, city, socialMedia = []
                    } = doctor;

                    return (
                      <li
                        key={id}
                        className="bg-white relative tracking-wider border-b border-card-shadow rounded p-3"
                      >
                        <div className="absolute right-1 top-1 z-10">
                          <DotMenu
                            items={getDotMenu(callBack, id)}
                          />
                        </div>
                        <div className="flex">
                          <div className="w-11 rounded bg-gray-light overflow-hidden">
                            <LazyLoadImage
                              className="h-11 m-auto"
                              alt={name}
                              placeholder={grey.default}
                              src={fixURL(false, id, avatarUrl, true)}
                              debounceDelay={100}
                            />
                          </div>
                          <div className="leading-tight grow ml-3 tracking-wider">
                            <h3
                              className={`text-medium font-bold tracking-normal whitespace-nowrap 
                              overflow-hidden text-ellipsis w-50 text-black`}
                              title={name}
                            >{name}</h3>
                            <div
                              className={`text-text-gray whitespace-nowrap text-ellipsis 
                              overflow-hidden w-50 text-xs mt-1/2`}
                              title={specialization}
                            >{specialization}</div>
                            <span className="block text-xs mt-1/2">{selectedTenantData.name}</span>
                          </div>
                        </div>
                        <div className="space-x-3 mt-3 font-medium text-xs">
                          <div className="uppercase">
                            <div className="text-text-gray">Qualification</div>
                            <div
                              className="text-sky mt-1 whitespace-nowrap overflow-hidden text-ellipsis"
                              title={qualification}
                            >{qualification}</div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 mt-2 font-medium text-xs">
                          <div className="uppercase">
                            <div className="text-text-gray">City</div>
                            <div className="text-sky mt-1">{city}</div>
                          </div>
                          <div className="uppercase">
                            <div className="text-text-gray">Experience</div>
                            <div className="text-sky mt-1">{experience} years</div>
                          </div>
                        </div>
                        <div className="space-x-3 mt-2 font-medium text-xs">
                          <div className="uppercase">
                            <div className="text-text-gray">Languages</div>
                            <div className="text-sky mt-1 whitespace-nowrap">{
                              (languages || "").split(",").map(el => languageObject[el]).join(", ")
                            }</div>
                          </div>
                        </div>
                        <ul className="flex space-x-2 mt-2">
                        {
                          socialMedia.map(el => {
                            const {id, type, url} = el;
                            return (
                              <li key={id}>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                  <img className="w-5" src={socialMediaImageMap[type]} alt="social"/>
                                </a>
                              </li>
                            )
                          })
                        }
                        </ul>
                      </li>
                    );
                  })
                }
              </ul>
            }
            {allConsultingDoctors.length === 0 && !tenantLoading && !consultingDoctorLoading && (
              <div className="text-center p-3 bg-white rounded leading-snug">
                <h3 className="font-bold">No consulting doctors present for the selected tenant or applied filter!</h3>
                <p className="mt-1 text-xs text-text-gray">
                  To create one click on the "Create New Consulting Doctor"!
                </p>
              </div>
            )}
          </div>

          {selectedSpecialist && intendedAction === "delete" && 
            <ConfirmDelete
              heading="Deleting consulting doctor..."
              onConfirm={() => {
                deleteCallAPI();
              }}
              close={() => {
                setSelectedSpecialist("");
                setIntendedAction("")
              }}
            />
          }
        </section>
      }
    </div>
  );
};

export default ManageConsultingDoctors;