import React, {useState, useEffect, Fragment} from "react";
import { DefaultEditor } from "react-simple-wysiwyg";

import RichEditorModal from "../richEditorModal";
import enlarge from "../../../assets/enlarge.png";

const TextArea = (props) => {
  const {
    name, multiColumn, className, required, label, validationObj, 
    touchedFieldObj, formData, setTouchedFieldObj, onUpdate, isTextArea = false
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [editorText, setEditorText] = useState(formData[name] || "");
  useEffect(() => {
    const previousText = formData[name];
    if(previousText !== editorText){
      onUpdate({
        ...formData,
        [name]: editorText
      });
    }
  }, [name, editorText, formData, onUpdate]);

  return (
    <div
      key={name}
      id={name}
      className={`relative h-27 ${
        multiColumn ? "w-130" : ""
      }${className ? ` ${className}` : ""}`}
    >
      
      {isTextArea &&
        <div className="input-group textarea relative">
          <textarea
            name={name}
            id={`textarea-${name}`}
            className={formData[name] ? "has-value" : ""}
            required={required}
            onChange={(e) => {
              const {value} = e.target;
              setEditorText(value);
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));
            }}
            defaultValue={editorText}
          ></textarea>
          <label className="form-label" htmlFor={`textarea-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
              className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </div>
      }
      {!isTextArea && 
        <Fragment>
          <label className="form-label" htmlFor={`textarea-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          <div
            className={`relative fixed-height w-full mt-1.5 ${
              validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] && required ? "has-error" : ""
            }`}
          >
            <button
              type="button"
              className="rsw-btn absolute top-px right-px rounded"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={enlarge} alt="enlarge" className="w-3 m-auto"/>
            </button>
            <DefaultEditor
              value={editorText}
              onChange={(e) => {
                const {value} = e.target;
                setEditorText(value);
                setTouchedFieldObj(tobj => ({
                  ...tobj, 
                  [name]: true
                }));
              }}
            />
          </div>
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
              className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </Fragment>
      }
      

      {showModal && 
        <RichEditorModal
          text={editorText}
          callback={(value) => {
            setEditorText(value);
            setTouchedFieldObj(tobj => ({
              ...tobj, 
              [name]: true
            }));
          }}
          close={() => {
            setShowModal(false);
          }}
        />
      }
    </div>
  )
};

export default TextArea;
