import React, {useEffect} from "react";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import PDFViewer from "../pdfViewer";
import DicomViewer from "./dicomViewer";
import {fixURL, bindEvent, unbindEvent} from "../../utils";
import {ReactComponent as NewTab} from "../../assets/in-new-tab.svg";

const grey = require("../../assets/grey.svg");

const ModalRecordView = (props) => {
  const {fileType, patientId, url, close} = props;
  useEffect(() => {
    const keyDownEvent = (event) => {
      if(event.keyCode === 27) {
        close();
      }
    };

    bindEvent(window, "keydown", keyDownEvent);
    return () => {
      unbindEvent(window, "keydown", keyDownEvent)
    }
  }, [close]);

  return (
    <div 
      className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center text-base text-black"
    >
      <div
        className={`bg-white rounded relative ${
          fileType === "dicom" ? "w-full max-w-screen-xl" : "w-auto max-w-screen-md"
        } flex m-auto popup-editor font-normal max-h-screen`}
      >
         <a 
          href={fixURL(false, patientId, url)} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-white p-1 absolute left-2 top-2 rounded cursor-pointer z-10"
        >
          <NewTab className="w-5 h-5 fill-purple"/>
        </a>
        {fileType === "pdf" &&
          <PDFViewer
            url={fixURL(false, patientId, url)}
            isFullScreen={true}
          />
        }
        {fileType === "image" && 
          <LazyLoadImage
            className="m-auto rounded max-h-screen"
            alt={"record"}
            src={fixURL(false, patientId, url)}
            placeholder={grey.default}
            debounceDelay={100}
          />
        }
        {fileType === "dicom" && 
          <DicomViewer
            url={url}
          />
        }
        <div
          className="text-white absolute top-0 -right-7 cursor-pointer text-3xl px-2"
          onClick={close}
        >&times;</div>
      </div>
    </div>
  );
};

export default ModalRecordView;
