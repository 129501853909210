import {getKeyValueHTML} from "../../../utils/workflowUtils";

export const configs = [{
  key: "tenant-details",
  body: (formData, labelObj) => {
    const keys = [{
      dkey: "name"
    }, {
      dkey: "email"
    }, {
      dkey: "phone",
      args: ["phone"],
      format: (phone) => {
        return phone.join(", ")
      }
    }, {
      dkey: "address1"
    }, {
      dkey: "address2"
    }, {
      dkey: "city"
    }, {
      dkey: "postalCode"
    }, {
      dkey: "state"
    }, {
      dkey: "country"
    }];

    return getKeyValueHTML(keys, labelObj, formData)
  }
}];