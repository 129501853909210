import React, {Fragment} from "react";

export const getLabelObj = (config) => {
  return config.reduce((data, el) => {
    data[el.name] = el.label || el.title;
    if (el.children){
      el.children.forEach(child => {
        data[child.name] = child.label;
        if(child.data){
          child.data.forEach(el => data[el.value] = el.label);
        }
      });
    }
    if (el.data){
      el.data.forEach(child => {
        if(child.children){
          child.children.forEach(el => data[el.value] = el.label);
          return;
        }
        data[child.value] = child.label;
      });
    }
    return data;
  }, {});
};

export const getKeyValueHTML = (keys, labelObject, formData) => {
  return (
    <div className="grid gap-x-8 gap-y-3 whitespace-nowrap grid-template-column leading-tight">
      {
        keys.map((key, index) => {
          const {dkey, args = [], label, format} = key;
          const argsData = args.length ? args.map(el => formData[el]) : [];
          argsData.push(labelObject);

          const pdata = format ? format.apply(null, argsData) : (
            labelObject[formData[dkey]] || formData[dkey]
          );
          if(pdata === undefined || pdata === "") {
            return undefined;
          }

          return (
            <Fragment key={index + dkey}>
              <div className="text-text-gray">{label || labelObject[dkey]}</div>
              <div className="text-black">{pdata}</div>
            </Fragment>
          );
        })
      }
    </div>
  )
};
