export const getConfig = (emailNotificationSent = false) => {
  const returnObject = [{
    component: "textfield",
    name: "email",
    label: "Email",
    required: true
  }];

  if(emailNotificationSent){
    returnObject.push({
      component: "textfield",
      name: "verificationCode",
      label: "Email Verification Code",
      required: true,
      number: true
    }, {
      component: "textfield",
      name: "username",
      label: "Username",
      required: true,
      readonly: true
    }, {
      component: "textfield",
      name: "newPassword",
      label: "New password",
      required: true,
      password: true
    }, {
      component: "textfield",
      name: "confirmNewPassword",
      label: "Confirm new password",
      required: true,
      password: true
    })
  }

  return returnObject;
};


export const getSchema = (emailNotificationSent = false) => {
  const schemaObject = {
    email: [{
      condition: value => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value),
      message: "Not a valid email address."
    }]
  };

  if(emailNotificationSent){
    Object.assign(schemaObject, {
      newPassword: [{
        condition: value => value && value.length < 8,
        message: "Password should be at least 8 characters long"
      }, {
        condition: value => value && value.length > 64,
        message: "Password should not be more than 64 characters long"
      }, {
        condition: value => !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$/.test(value),
        message: "Must be alpha-numeric with one special character"
      }],
      confirmNewPassword: [{
        condition: value => value && value.length < 8,
        message: "Password should be at least 8 characters long"
      }, {
        condition: value => value && value.length > 64,
        message: "Password should not be more than 64 characters long"
      }, {
        condition: value => !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$/.test(value),
        message: "Must be alpha-numeric with one special character"
      }, {
        condition: (value, formData) => {
          const {newPassword} = formData;
          return value && value !== newPassword;
        },
        message: "The confirm password should match the new password"
      }]
    });
  }

  return schemaObject
};

