import React, {useState} from "react";
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/logo.svg";

const Header = (props) => {
  const {selected} = props;
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header className="h-20 bg-gradient-to-b from-deep-purple to-white-purple mobile:px-3">
      <div className="max-w-screen-lg mx-auto h-full flex items-center justify-between">
        <div className="logo flex">
          <Link aria-label="Oncominds logo" to="/">
            <h1 className="text-white text-xl flex items-center">
              <Logo
                className="logo fill-white"
              />
            </h1>
          </Link>
        </div>

        <div className="hidden absolute right-4 top-7 mobile:block">
          <nav id="navbar" className="mt-1/2" role="navigation">
            <input 
              id="toggle" 
              type="checkbox" 
              onChange={(e) => {
                setOpenMenu(e.target.checked);
              }}
            />
            <label className="hamburger" htmlFor="toggle">
              <div className="top"></div>
              <div className="meat"></div>
              <div className="bottom"></div>
            </label>
          </nav>
        </div>

        <div
          className={`grow flex items-center justify-end mobile:w-full mobile:left-0 mobile:absolute 
            mobile:top-20 mobile:flex-col mobile:bg-white-purple ${!openMenu ? "mobile:hidden" : ""}`}
        >
          {/* <Link
            to="/services/"
            className={`tracking-wide text-medium font-bold mr-8 ${
              selected === "services" ? "text-pink" : "text-white"
            } mobile:mr-0 mobile:p-3 mobile:w-full mobile:border-t mobile:border-gray`}
          >
            Services
          </Link> */}
          {/* <Link
            to="/oncology-network/" 
            className={`tracking-wide text-medium font-bold mr-8 ${
              selected === "oncology-network" ? "text-pink" : "text-white"
            } mobile:mr-0 mobile:p-3 mobile:w-full mobile:border-t mobile:border-gray`}
          >
            Oncology Network
          </Link> */}
          <Link
            to="/faqs/"
            className={`tracking-wide text-medium font-bold mr-8 ${
              selected === "faqs" ? "text-pink" : "text-white"
            } mobile:mr-0 mobile:p-3 mobile:w-full mobile:border-t mobile:border-gray`}
          >
            FAQs
          </Link>
          <Link 
            to="/about-us/" 
            className={`tracking-wide text-medium font-bold mr-8 ${
              selected === "about-us" ? "text-pink" : "text-white"
            } mobile:mr-0 mobile:p-3 mobile:w-full mobile:border-t mobile:border-gray`}
          >
            About us
          </Link>
          <Link to="/login/" 
            className={
              `tracking-wide text-medium font-bold text-white 
              mobile:p-3 mobile:w-full mobile:border-y mobile:border-gray`
            }
          >
            Sign in
          </Link>
        </div>
      </div>
    </header>
  )
};

export default Header;
