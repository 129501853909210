import React from "react";

const BlockFooter = () => {
  return (
    <div
      className="page-footer flex align-center justify-center flex-col text-center gap-1 text-xs-m font-bold"
    >
      <div className="text-purple uppercase">Registered office of the company:</div>
      <div
        className="text-purple uppercase"
      >No. 13/14, 2 nd cross, 7 th Phase, J P Nagar, Navodaya Nagar, Bangalore-560078</div>
      <div className="flex align-center gap-2 justify-center text-purple">
        <span>
          CIN: <span className="ml-1">U73100KA2022PTC165580</span>
        </span>
        <span>|</span>
        <span>
          Email: 
          <a
            href="mailto:contact@oncominds.com"
            target="_blank"
            rel="noreferrer noopener"
            className="ml-1"
          >contact@oncominds.com</a>
        </span>
        <span>|</span>
        <span>
          Phone: 
          <a
            href="tel:+919036939246"
            target="_blank"
            rel="noreferrer noopener"
            className="ml-1"
          >+91 903 693 9246</a>
        </span>
      </div>
    </div>
  )
};

export default BlockFooter;
