import React, {useState} from "react";

import Form from "../common/form";
import {getFileSize} from "../../utils";
import {typeToCategoryMap} from "../../constant";
import {getFormConfig} from "./configs/fileTypePopup.config";

import jpg from "../../assets/jpg.png";
import pdf from "../../assets/pdf.png";


const FileTypePopup = (props) => {
  const {files, callback} = props;
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(
    new Array(files.length).fill(false)
  );

  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="w-80 bg-white rounded relative max-w-screen-md m-auto px-5 py-4">
        <h3 className="text-medium font-bold">Select record information</h3>
        <div className="mt-2 overflow-x-hidden overflow-y-auto select-record-container">
        {
          [...files].map((file, index) => {
            const {name, size, type} = file;
            
            let src = pdf;
            if(type.includes("image")){
              src = jpg;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                document.getElementById(`record-${index}`).setAttribute("src", e.target.result);
              };
            }

            return (
              <div
                className={`${index < files.length - 1 ? "border-b border-gray mb-4" : ""}`}
                key={name + index}
              >
                <div className="flex">
                  <div className="">
                    <img alt="icon" id={`record-${index}`} src={src} className="max-w-15 max-h-15"/>
                  </div>
                  <div className="grow text-text-gray pl-2">
                    <h3 className="font-medium">{name}</h3>
                    <span className="mt-2 text-xs">{getFileSize(size)}</span>
                  </div>
                </div>
                <div className="pt-2">
                  <Form
                    multiColumn={true}
                    initialValues={{}}
                    config={getFormConfig(index)}
                    onFormValid={(isValid) => {
                      setIsFormValid(validArr => {
                        const clonedValidArr = [...validArr];
                        clonedValidArr[index] = isValid;
                        return clonedValidArr;
                      })
                    }}
                    onFormUpdate={(data) => {
                      setFormData(formData => {
                        const clonedData = {...data};
                        const type = clonedData[`type-${index}`];
                        const date = clonedData[`date-${index}`];
                        
                        return {
                          ...formData,
                          [name]: {
                            type,
                            date: date ? date.getTime() : "",
                            category: type ? typeToCategoryMap[type] : ""
                          }
                        }
                      });
                    }}
                  />
                </div>
              </div>
            )
          })
        }
        </div>
        <div className="flex justify-end items-center mt-2">
          <button
            className="primary-btn"
            disabled={!isFormValid.every(el => el === true)}
            onClick={() => {
              callback(formData);
            }}
          >Upload</button>
        </div>
      </div>
    </div>
  )
};

export default FileTypePopup;
