import React, {useState, Fragment} from "react";
import {Link} from "react-router-dom";

import Head from "../components/head";
import Footer from "../components/landing/footer";
import Header from "../components/landing/header";

const TermsOfUse = () => {
  const [policyTab, setPolicyTab] = useState("global");

  return (
    <div className="bg-bg-color w-full">
      <Head title="Privacy Policy"/>
      <Header/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Website Terms of Use
        </h1>
        <ul className="text-medium-l leading-snug pt-13 flex gap-x-6 border-b border-b-gray mobile:pt-10">
          <li
            className={`pb-1.5 font-bold cursor-pointer ${
              policyTab === "global" ? "text-purple border-b border-b-purple" : "text-purple-light"
            }`}
            onClick={() => setPolicyTab("global")}
          >Global</li>
          <li
            className={`pb-1.5 font-bold cursor-pointer ${
              policyTab === "india" ? "text-purple border-b border-b-purple" : "text-purple-light"
            }`}
            onClick={() => setPolicyTab("india")}
          >India</li>
        </ul>
        <div className="pt-5 text-medium font-medium leading-snug text-purple">
          <p>
            PLEASE READ THIS DOCUMENT CAREFULLY. IT CONTAINS VERY IMPORTANT INFORMATION 
            CONCERNING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS LIMITATIONS AND EXCLUSIONS THAT MAY APPLY TO YOU.
          </p>
          {policyTab === "global" && 
            <Fragment>
              <p className="pt-3">
                Last Modified: <b>January 27, 2024</b>
              </p>
              <h3 className="font-bold text-medium-l pt-5">Acceptance of Terms</h3>
              <p className="pt-4">
                Oncominds, Inc. ("Oncominds") makes this internet accessible website ("Site") and 
                related mobile application or mobile accessible website ("App") (Site and App are collectively 
                referred to as the "System"), including all information, documents, communications, files, 
                text, graphics, software, and services available through the System (collectively, the "Content") 
                and any products and services, healthcare education, advice, and consultation related products and 
                services, provided and operated by Oncominds through the System (collectively, the "Services"), 
                available for your use subject to the terms and conditions set forth in this document, any changes 
                to this document that Oncominds may publish from time to time, and any other relevant terms and 
                conditions incorporated by Oncominds (collectively, the "Terms of Use").
              </p>
              <p className="pt-3">
                Oncominds believes in protecting your privacy. Please review our current privacy and 
                confidentiality statement ("Privacy and Confidentiality Policy"), which can be found at 
                [<Link to="/privacy-policy/">https://oncominds.com/privacy-policy/</Link>], and is incorporated 
                herein by reference, to understand and agree to our policies regarding your privacy.
              </p>
              <p className="pt-3">
                You may not interfere with the security of or otherwise abuse the System or any system resources, 
                Content, and/or Services or networks connected to or accessible through the System. You may only 
                use the System for lawful purposes.
              </p>
              <p className="pt-3">
                BY ACCESSING OR USING THIS SYSTEM, WHETHER OR NOT AS A REGISTERED USER, IN ANY WAY, INCLUDING,
                WITHOUT LIMITATION, USE OF ANY OF THE SERVICES, POSTING/UPLOADING OF ANY CONTENT, DOWNLOADING 
                OF ANY CONTENT, OR MERELY BROWSING THE SITE OR APP, YOU REPRESENT THAT YOU ARE OVER 18 YEARS 
                OF AGE AND AGREE TO, AND ARE BOUND BY, THE TERMS OF USE AND PRIVACY AND CONFIDENTIALITY POLICY. 
                YOUR AGREEMENT TO BE BOUND BY THE TERMS OF USE AND PRIVACY AND CONFIDENTIALITY POLICY IS BETWEEN 
                ONCOMINDS AND YOU.
              </p>
              <p className="pt-3">
                IF THESE TERMS OF USE ARE NOT COMPLETELY ACCEPTABLE TO YOU, YOU MUST IMMEDIATELY TERMINATE YOUR 
                USE OF THE SYSTEM.
              </p>
              <p className="pt-3">
                Oncominds reserves the right to update or modify the Terms of Use at any time without prior notice. 
                Your continued use of any portion of the System, including any portion of the Site and/or App, 
                whether or not as a registered user, following any such change constitutes your agreement to follow 
                and be bound by the Terms of Use as changed. For this reason, you are advised to review these Terms 
                of Use whenever you use the System. The Terms of Use may be found at any time on the Site and App, 
                and Oncominds will provide you with a copy of the Terms of Use upon written request.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Use of Services</h3>
              <p className="pt-4">
                The Contents and Services of the System, and the System as a whole, are intended to primarily 
                facilitate healthcare education, consulting, and collaboration related services, including 
                logistics, health and well-being information, general patient resources, insurance education, 
                community events, and the like, which may include users signing-in to the System, processing 
                payment for services and any related products, and the like, as set-forth and defined by Oncominds 
                and subject to change by Oncominds at any time, in Oncominds's sole discretion. Oncominds provides 
                the System for the aforementioned purposes and use outside of this scope is not approved and is 
                strictly prohibited without the express written permission of Oncominds. More information on the 
                Services and Content and specific terms and conditions of their individual use may be defined in 
                specific services/products agreements with Oncominds and may also be available upon written request to 
                Oncominds. Users may use some or all of the features of the Services and Content. 
                ONCOMINDS RESERVES THE RIGHT TO ADD, SUBTRACT, OR CHANGE THESE SERVICES AND CONTENT, AT ANY TIME, 
                IN ITS SOLE DISCRETION.
              </p>
              <p className="pt-3">
                Services and Content provided by Oncominds shall be provided and/or performed with reasonable 
                skill, care, and diligence, however, Oncominds is not responsible for, and you agree not to hold 
                Oncominds liable for, the actions, information, or work product provided by any other service 
                providers that you may select from Oncominds's System, such as medical advice from experts in a 
                field of medicine. Oncominds will use reasonable endeavors to provide Content and/or Services 
                within the reasonable time frame provided by Oncominds for each action requested by you; however, 
                it is agreed by you that time is not of the essence. Any time frame provided by Oncominds is only an 
                estimate, and accordingly, Oncominds does not accept any responsibility or liability, financial or 
                otherwise, in the event that Oncominds is unable for any reason to meet the estimated time frame. 
                Oncominds shall not be responsible for any failure to provide Content or perform Services, including 
                where such failure was caused by an act of God, act of governmental or any other lawful authority, war, 
                riot, civil commotion, natural disaster, loss of power, or loss of communication channels.
              </p>
              <p className="pt-3">
                Permission is granted by Oncominds to you, the user, to review the Content and use the Services on 
                the System in furtherance of the purposes set forth herein by Oncominds in its sole discretion, 
                and in accordance with the System's Terms of Use, provided you (i) register as a user with the 
                System, where necessary, and maintain active registration throughout your use of the System, (ii) 
                agree that any such permission is revoked by Oncominds upon termination, at Oncominds's sole 
                discretion, or lapse of user registration, (iii) operate the System in accordance with Oncominds's 
                policies, Terms of Use, and instructions, including those provided on the Site and App or otherwise 
                provided in other electronic (e.g., email) or hardcopy form, (iv) abide by all applicable state, 
                federal, and local laws, including any laws or regulations regarding performance of general 
                healthcare education services, including logistics, health and well-being information, general 
                patient resources, insurance education, community events, and the like, which may include users 
                signing-in to the System, processing payment for services and any related products, and the like, 
                as set-forth and defined by Oncominds and subject to change by Oncominds at any time, in Oncominds's 
                sole discretion, (v) do not infringe the rights of Oncominds or any third party (including intellectual 
                property or contractual rights), (vi) only use the Content and Services for your own personal use, 
                (vii) agree to promptly pay all fees and costs charged for Content or Services, maintaining 
                active user registration status (where needed), and any other fees necessary for you to 
                operate or use the System in compliance with the Terms of Use, (viii) do not modify or alter 
                the Content or Services in any way, (ix) do not modify or delete the copyright and other proprietary 
                notices contained in the Content, (x) do not use any Content or Services or the System for any 
                unauthorized purpose or assist others in such acts, and (xi) promptly advise Oncominds of any 
                errors, problems, or suggested improvements. Oncominds may refuse Services or Content or suspend 
                or terminate your use of the System at any time and in its sole discretion.
              </p>
              <p className="pt-3">
                Oncominds may provide Content and/or the Services itself, or Oncominds may, in its sole 
                discretion, instruct third parties, including independent contractors, to carry out, in whole 
                or in part, any Services or provide any Content. Oncominds does not provide any warranties of third 
                party provided Services or Content, and shall not be liable for the Services or Content provided by 
                third parties.
              </p>
              <p className="pt-3">
                When using Services or Content on or through the System that are offered by Oncominds, or by a 
                contractor or other third-party service provider through Oncominds, you shall be subject to any 
                posted guidelines, rules, or licenses applicable to such Services or Content and to the Terms of Use. 
                Such guidelines, rules, or licenses may contain terms and conditions in addition to those in the Terms 
                of Use.
              </p>
              <p className="pt-3">
                This Site may also make Content, including information related to Services, user account, 
                payment information, and related personal information, available through secured links on the 
                System or otherwise through the World Wide Web ("Secured Content"). By accessing Secured Content 
                and/or providing information that may be used or included as Secured Content, you agree, subject to 
                the terms of the Privacy and Confidentiality Policy, to waive any and all privacy, publicity, 
                defamatory, moral, or similar rights or protections that you may have with regard to such Secured 
                Content. If you are not willing to waive such protections, then you are not permitted to access the 
                Secured Content available on or through the System.
              </p>
              <p className="pt-3">
                In addition to the Content and Services offered by Oncominds, the System may also make available 
                materials, information, products, and/or services provided by third parties, including related healthcare 
                services (collectively, the "Third-Party Services"). The Third-Party Services are governed by separate 
                agreements that accompany such services. Oncominds does not monitor or endorse the third parties or the 
                Third-Party Services. Oncominds offers no guarantees and assumes no responsibility or liability of any 
                type with respect to the Third-Party Services. You agree that Oncominds is not responsible for the 
                accuracy or truth of any information you provide to Oncominds or any third party, including information 
                about healthcare, logistics, payment processing, or other services you seek, and shall not hold Oncominds 
                liable for any direct or indirect damages or any other liabilities arising therefrom. YOU AGREE THAT, 
                WITH RESPECT TO INFORMATION, INCLUDING THIRD PARTY INFORMATION, YOU PROVIDE OR CAUSE TO BE PROVIDED TO 
                ONCOMINDS OR THE THIRD-PARTY SERVICES, YOU WILL NOT HOLD ONCOMINDS RESPONSIBLE OR LIABLE FOR ANY CLAIMS 
                OR DAMAGES, DIRECT OR INDIRECT, OR SEEK TO DO SO.
              </p>
              <p className="pt-3">
                With regard to any information or data submitted to Oncominds by you or caused to be submitted by you, 
                whether or not such information originates from you or a third party, you agree that Oncominds has 
                no responsibility or liability, direct or indirect, subject to the terms of the Privacy and 
                Confidentiality Policy, for the use or reliance on such other information or data. YOU AGREE 
                THAT SUBMISSION OF DATA OR INFORMATION OF ANY KIND TO ONCOMINDS, THROUGH ITS SYSTEM OR OTHERWISE, 
                IS AT YOUR SOLE RISK AND DISCRETION, AND YOU AGREE TO NOT HOLD ONCOMINDS RESPONSIBLE OR LIABLE 
                FOR ANY CLAIMS OR DAMAGES, DIRECT OR INDIRECT, ARISING THEREFROM, OR SEEK TO DO SO.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Your Responsibilities as the Recipient Services</h3>
              <p className="pt-4">
                As the recipient of Services, you (i) agree to provide clear and concise requests concerning 
                the Services, including healthcare education or related services, (ii) provide all requested 
                information to Oncominds for processing Services in a timely manner, as prescribed by Oncominds,
                and (iii) agree that once your requests for Services are submitted, cancellation or changes 
                to the requests may not be available, and will not be eligible for a refund of moneys paid post 
                utilization of services.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Registration and Payment for Services</h3>
              <p className="pt-4">
                Depending on services requested, users of the System may be required to set up and maintain, 
                keeping all user information correct and current, a unique user account for each specific 
                user (regardless of whether that user is an employee or agent of any other user). Each and 
                every unique user must agree to the Terms of Use and operate the System in accordance with 
                the Terms of Use and instructions provided by Oncominds on the Site and App. You may not: 
                (i) select or use a login name of another person with the intent to impersonate that person; 
                (ii) use a name subject to the rights of any other person without authorization; or 
                (iii) use a login name that Oncominds, in its sole discretion, deems inappropriate or offensive. 
                You shall notify Oncominds of any known or suspected unauthorized use(s) of your account, 
                or any known or suspected breach of security, including loss, theft, or unauthorized disclosure 
                of your password. You shall be responsible for maintaining the confidentiality of your password. 
                Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your 
                account, at Oncominds's sole discretion, and you may be reported to appropriate law-enforcement 
                agencies.
              </p>
              <p className="pt-3">
                YOU AGREE THAT YOUR USE OF THE SYSTEM, AND ANY SERVICES OR CONTENT PROVIDED BY OR THROUGH 
                THE SYSTEM, IS LIMITED TO YOU ALONE, AND YOU AGREE TO ACCEPT FULL RESPONSIBILITY AND INDEMNIFY, 
                DEFEND, AND HOLD ONCOMINDS HARMLESS FOR ANY LIABILITY OR USE ASSOCIATED WITH ANY THIRD PARTY'S 
                USE OF THE SYSTEM, SERVICES, AND/OR CONTENT UNDER YOU OR ON YOUR BEHALF.  YOU AGREE THAT YOUR 
                LIMITED LICENSE GRANTED BY ONCOMINDS TO USE THE SYSTEM IS A NON-TRANSFERABLE LIMITED LICENSE 
                TO USE THE SYSTEM.
              </p>
              <p className="pt-3">
                Any "Fees" to use portions of the System and its Services are set by Oncominds, from time 
                to time, in its sole discretion, and is subject to change without notice. Unless otherwise stated, 
                the Fees do not include taxes or duties of any kind, and all taxes and duties are responsibility 
                of the user.
              </p>
              <p className="pt-3">
                You agree to pay to Oncominds all Fees, as set forth on the Site and/or App, at the time 
                specified on the Site and/or App, or if there is no such time specified, all payments for 
                Fees must be paid as directed by Oncominds, or prior to using such portion of the System. If 
                Fees are not paid, Content may not be provided and Services may not be performed. All payments 
                shall be made in full and without any deduction, whether by set-off, counterclaim, discount, 
                abatement, or otherwise, unless you have Oncominds's prior written approval or a valid order 
                from a court having proper jurisdiction. You agree to abide by any terms and conditions applicable 
                to any payment processing services available through the System, including terms and conditions of 
                any third-party payment processing services.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Maintenance and Support</h3>
              <p className="pt-4">
                Should you require maintenance and support for your use of the System, or should you have 
                any questions, comments, or claims about the System, please contact Oncominds at the following 
                email address: <a 
                  className="text-sky-blue underline ml-1" 
                  href="mailto:contact@oncominds.com" 
                  target="_blank"
                  rel="noopener noreferrer"
                >contact@oncominds.com</a>
              </p>
              <h3 className="font-bold text-medium-l pt-5">Intellectual Property</h3>
              <p className="pt-4">
                All Content and Services of the System, and the System itself, is subject to trademark, 
                service mark, trade dress, internet domain name, copyright, patent, and/or other intellectual 
                property rights or licenses held by Oncominds, one of its affiliates, or by third parties who 
                have licensed their materials to Oncominds. All intellectual property rights in the System 
                attributable to Oncominds, and any derivative rights, shall remain Oncominds's sole property. 
                Provided that you comply with these Terms of Use, including your obligations as to use of the 
                System and otherwise, Oncominds grants to you a limited, terminable at Oncominds's sole discretion, 
                non-exclusive, and non-assignable license to use the intellectual property, excluding trademarks, 
                logos, and service marks ("Marks"), of the System, with any such use not to exceed the scope and 
                intent for use of the System as set forth herein by Oncominds. You may not grant any sub-licenses nor 
                transfer the benefit of the license hereby granted without the prior written consent of Oncominds. 
                If this Agreement terminates and/or if you are in default of these Terms of Use, this license shall 
                automatically terminate.
              </p>
              <p className="pt-3">
                The Marks displayed on or in connection with the System are the property of Oncominds or other 
                third parties. You are not permitted to use the Marks without the prior written consent of 
                Oncominds or such third party that may own the Marks.
              </p>
              <p className="pt-3">
                You may not, nor have third party's on your behalf, copy, download, reproduce, modify, publish, 
                distribute, transmit, transfer, create derivative works from, reverse engineer, improve, import, 
                export, offer for sale, or sell the System, or any Content or Services of the System, without 
                first obtaining written permission from Oncominds, and you agree to assign and do hereby assign 
                to Oncominds all rights, title, and interests in any work product or other interests resulting 
                from such actions, including intellectual property developed or derived by or from improvements 
                or derivations of the System attributable to you.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Communications and Submissions</h3>
              <p className="pt-4">
                Oncominds may from time to time permit you to submit, upload and publish through the System or 
                otherwise submissions, data, comments, content, ideas, and other information related to the 
                Services (such as commentary and feedback related to the Services) (collectively "User Content"). 
                Any User Content which you submit to Oncominds, whether through the System, Site, App, social 
                networking or advertising website, or otherwise, by you or anyone acting on your behalf, 
                regarding the System, Site, App, Content, and Services, shall be and remain your property. Subject 
                to the terms of the Privacy and Confidentiality Policy, any User Content will be treated as 
                non-confidential and, by submitting User Content to Oncominds, you grant to Oncominds and its 
                agents a world-wide, perpetual, irrevocable, transferrable, sub-licensable, royalty-free 
                license to use, copy, modify, creative derivative works of, distribute, publicly display or 
                perform and otherwise exploit your User Content in any manner, in any and all media (whether now 
                known or hereafter invented), in all formats and distribution channels, without further notice to, 
                or consent from, you, and without requirement of payment to you or any other person. Without limiting 
                the foregoing, Oncominds will be entitled to use, reproduce, disclose, publish and distribute any 
                User Content you transmit for any purpose whatsoever, including, but not limited to, developing, 
                manufacturing and marketing products or services without compensating you in any way. 
                Notwithstanding the foregoing, all personal data provided to Oncominds will be handled in 
                accordance with Oncominds's Privacy and Confidentiality Policy. Oncominds prohibits, and you 
                agree to not, post or transmit to or from this Site any unlawful, threatening, libelous, 
                defamatory, obscene, scandalous, inflammatory, offensive, pornographic, or profane material, 
                or any other material that could give rise to any civil or criminal liability under 
                applicable law. Oncominds is not responsible for the content of such materials that you 
                post or transmit to or from this Site.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Internet and Viruses</h3>
              <p className="pt-4">
                While using the internet and the System, you may encounter various costs, expenses, 
                or technical difficulties which could impact you and your computer equipment. You may 
                also inadvertently receive computer viruses or other malicious files while using the System. 
                Oncominds shall not be responsible for such difficulties, viruses or other malicious files 
                encountered during your use of the System or the internet. For these reasons, Oncominds 
                recommends that you take every possible precaution to avoid the reception of such difficulties, 
                viruses or other malicious files, including, but not limited to anti-virus software and equipment.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Indemnification</h3>
              <p className="pt-4">
                In the event you, anyone acting on your behalf, or any third party asserts a claim, cause of action, 
                or liability against Oncominds that arises out of or is in connection with a breach of these 
                Terms of Use, by you or anyone acting on your behalf, and/or arises from or is related to any 
                claims, damages (consequential or otherwise), injuries (including those to a third party, you 
                or anyone acting on your behalf), liabilities, or causes of action arising from the undertaking 
                or performance of the Services, including healthcare education related services, including logistics, 
                health and well-being information, general patient resources, insurance education, community events, 
                and the like, or any Third-Party Services, by you or anyone acting on your behalf, then you agree 
                to indemnify, defend, and hold Oncominds and its subsidiaries, customers, affiliates, successors, 
                and assigns and their respective directors, officers, employees, shareholders, representatives, 
                agents, and customers harmless against any and all of such liability, damages, claims, and related 
                costs, including, but not limited to, reasonable attorney's fees and court costs.
              </p>
              <p className="pt-3">
                You further agree, represent, and warrant that any actions by you or data or information supplied 
                to Oncominds by you shall not be such as will cause Oncominds to infringe any intellectual 
                property rights, including patent and copyright, of any third party and shall indemnify, 
                defend, and hold Oncominds and its subsidiaries, customers, affiliates, successors, and 
                assigns and their respective directors, officers, employees, shareholders, representatives, 
                agents, and customers from and against any claims, damages (consequential or otherwise), 
                liabilities, causes of action, and losses of any kind whatsoever, including reasonable 
                attorney's fees incurred by Oncominds.
              </p>
              <h3 className="font-bold text-medium-l pt-5">WARRANTIES AND DISCLAIMERS</h3>
              <p className="pt-4">
                EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND ONCOMINDS 
                OR YOU AND A THIRD PARTY WITH RESPECT TO SUCH PARTY'S CONTENT OR SERVICES ON THIS SITE, 
                THE SYSTEM, AND ALL MATERIALS, CONTENT, AND SERVICES ACCESSIBLE THROUGH THE SYSTEM, ARE 
                PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT 
                NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR 
                PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, ONCOMINDS 
                MAKES NO WARRANTY THAT (i) THE SYSTEM, SERVICES, AND CONTENT WILL MEET YOUR REQUIREMENTS; 
                (ii) THE SYSTEM, SERVICES, AND CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; 
                (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SYSTEM, SERVICES, OR CONTENT WILL 
                BE EFFECTIVE, ACCURATE, OR RELIABLE; (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, OR CONTENTS 
                PURCHASED OR ACCESSIBLE BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS; AND (v) ANY ERRORS 
                IN THE SOFTWARE OBTAINED FROM OR USED THROUGH THE SITE OR APP, OR ANY DEFECTS IN THE SITE OR 
                APP, ITS SERVICES, CONTENT, OR THE SYSTEM AS A WHOLE, WILL BE CORRECTED.
              </p>
              <p className="pt-3">
                THE SYSTEM, SERVICES, AND CONTENT COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES, 
                OR TYPOGRAPHICAL ERRORS. ONCOMINDS MAY MAKE CHANGES TO THE CONTENTS AND SERVICES OF THE 
                SYSTEM, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS OR SERVICES LISTED HEREIN, 
                AT ANY TIME WITHOUT NOTICE. THE CONTENT OR SERVICES OF THE SYSTEM MAY BE OUT OF DATE, AND 
                ONCOMINDS MAKES NO COMMITMENT TO UPDATE SUCH CONTENT OR SERVICES.
              </p>
              <p className="pt-3">
                YOU UNDERSTAND AND ACKNOWLEDGE THAT (i) ONCOMINDS DOES NOT CONTROL, ENDORSE, OR ACCEPT 
                RESPONSIBILITY FOR ANY CONTENT, PRODUCTS, OR SERVICES SUBMITTED OR OFFERED BY THIRD PARTY 
                ADVERTISING ACCESSIBLE THROUGH LINKS, INCLUDING ADVERTISING BANNERS, ON THE SITE OR APP; 
                (ii) ONCOMINDS MAKES NO REPRESENTATION OR WARRANTIES WHATSOEVER ABOUT ANY SUCH THIRD PARTY 
                ADVERTISERS, THEIR CONTENT, PRODUCTS, OR SERVICES; (iii) ANY DEALINGS YOU MAY HAVE WITH 
                SUCH THIRD PARTY ADVERTISERS ARE AT YOUR OWN RISK; AND (iv) ONCOMINDS SHALL NOT BE LIABLE 
                OR RESPONSIBLE FOR ANY CONTENT, PRODUCTS, OR SERVICES OFFERED BY SUCH THIRD PARTIES.
              </p>
              <p className="pt-3">
                THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER USE OF ANY CONTENT OR COMMUNICATIONS 
                THROUGH ANY PORTION OF THE SYSTEM IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR 
                AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS 
                OF DATA, OR OTHER HARM THAT RESULTS FROM SUCH ACTIVITIES. ONCOMINDS ASSUMES NO LIABILITY 
                FOR ANY COMPUTER VIRUS OR OTHER SIMILAR SOFTWARE CODE THAT IS DOWNLOADED TO YOUR COMPUTER 
                FROM THE SITE OR APP OR IN CONNECTION WITH ANY SERVICES, CONTENT, COMMUNICATIONS, OR EMAIL 
                AS WELL AS CONTENT OFFERED THROUGH THE SYSTEM.
              </p>
              <p className="pt-3">
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ONCOMINDS OR 
                THROUGH OR FROM THE SYSTEM, CONTENT, OR SERVICES SHALL CONSTITUTE LEGAL, COMMERCIAL, 
                HEALTHCARE, OR OTHER ADVICE OF RELIANCE OR CREATE ANY LIABILITY OF ONCOMINDS OR ANY WARRANTY 
                NOT EXPRESSLY STATED IN THE TERMS OF USE. YOUR USE OF THE SYSTEM, CONTENT, AND SERVICES 
                SHALL NOT CREATE, AND YOU AGREE NOT TO ASSERT, ANY FORM OF JOINT VENTURE, PARTNERSHIP, JOINT 
                OR SEVERAL LIABILITY, AGENCY, OR ANY OTHER FORM OF RELATIONSHIP BETWEEN YOU AND ONCOMINDS, 
                EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN.
              </p>
              <p className="pt-3">
                DEPENDING ON YOUR USE OF THE SYSTEM, ONCOMINDS MAY HAVE ADDITIONAL OR REPLACEMENT TERMS 
                AND CONDITIONS AVAILABLE ON THE WEBSITE OR DIRECTLY FROM ONCOMINDS, WHICH MAY BE UPDATED 
                FROM TIME-TO-TIME, AND YOUR USE OF THE SYSTEM MAY BE GOVERNED BY SUCH ADDITIONAL TERMS AND 
                CONDITIONS. IT IS YOUR RESPONSIBILITY TO REVIEW ADDITIONAL ONCOMINDS TERMS AND CONDITIONS AND 
                ENSURE YOUR COMPLIANCE, AT ALL TIMES, WITH ALL SUCH APPLICABLE TERMS AND CONDITIONS. IN THE 
                EVENT THAT THESE TERMS OF USE CONFLICT WITH ANY OTHER APPLICABLE ONCOMINDS WRITTEN TERMS OR 
                CONDITIONS, SUCH ADDITIONAL OR AMENDED TERM(S) SHALL CONTROL.
              </p>
              <p className="pt-3">
                SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS 
                ON HOW LONG AN IMPLIED WARRANTY MAY LAST. IN THE EVENT THE EXCLUSION OF IMPLIED WARRANTIES 
                OR LIMITATIONS CONTAINED IN THESE TERMS OF USE SHALL BE DETERMINED BY A COURT TO BE INVALID 
                OR UNENFORCEABLE, THEN SUCH PROVISIONS SHALL BE REFORMED TO THE MAXIMUM LIMITATION PERMITTED 
                BY APPLICABLE LAW. TO THE EXTENT PERMISSIBLE, ANY IMPLIED WARRANTIES ARE LIMITED TO NINETY (90) DAYS.
              </p>
              <h3 className="font-bold text-medium-l pt-5">LIMITATION OF LIABILITY</h3>
              <p className="pt-4">
                UNDER NO CIRCUMSTANCES, SHALL ONCOMINDS OR ANY OF ITS EMPLOYEES, DIRECTORS, OFFICERS, 
                AGENTS, VENDORS, EDITORS, OR SUPPLIERS BE LIABLE FOR ANY DIRECT OR INDIRECT LOSSES OR DAMAGES 
                ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE SYSTEM OR ANY SERVICES 
                RELATED TO THE SYSTEM.
              </p>
              <p className="pt-3">
                THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY 
                KIND (WHETHER GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR OTHERWISE, INCLUDING, 
                WITHOUT LIMITATION, LOSS OF DATA, INCOME OR PROFITS), WHETHER IN CONTRACT, NEGLIGENCE, GROSS 
                NEGLIGENCE OR OTHER TORTUOUS ACTION, EVEN IF AN AUTHORIZED REPRESENTATIVE OF ONCOMINDS HAS BEEN 
                ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p className="pt-3">
                IF YOU ARE DISSATISFIED WITH THE SYSTEM, ANY CONTENT ON THE SITE OR APP, SERVICES PERFORMED, 
                OR WITH THE ONCOMINDS TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING 
                THE SYSTEM. YOU ACKNOWLEDGE, BY YOUR USE OF THE SYSTEM, THAT SUCH USAGE IS AT YOUR SOLE RISK 
                AND YOU ACCEPT THE TERMS AND CONDITIONS STATED ON THESE TERMS OF USE, PRIVACY AND CONFIDENTIALITY 
                POLICY, AND ANY OTHER APPLICABLE WRITTEN TERMS AND CONDITIONS OF ONCOMINDS, INCLUDING, BUT NOT 
                LIMITED TO THE LIMITATION OF LIABILITY AND DISCLAIMERS.
              </p>
              <p className="pt-3">
                SOME STATES OR JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL 
                OR INCIDENTAL DAMAGES. IN THE EVENT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL 
                OR INCIDENTAL DAMAGES CONTAINED IN THESE TERMS OF USE SHALL BE DETERMINED BY A COURT TO BE 
                INVALID OR UNENFORCEABLE, THEN SUCH PROVISIONS SHALL BE REFORMED TO THE MAXIMUM LIMITATION 
                PERMITTED BY APPLICABLE LAW. IN ANY EVENT, THE TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE 
                TERMS OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PARTS OF THE SITE OR THE SERVICES IN ANY 
                MANNER WHATSOEVER SHALL BE LIMITED TO FIVE THOUSAND DOLLARS ($5000.00) OR THE RETAIL MONETARY 
                VALUE OF YOUR TRANSACTIONS WITH ONCOMINDS OVER THE PAST THREE (3) MONTHS, WHICHEVER IS LESS.
              </p>
              <p className="pt-3">
                YOU AGREE THAT ANY MOBILE APPLICATION MARKETPLACES, INCLUDING GOOGLE PLAY OR THE APPLE ISTORE, 
                HAS NO OBLIGATION WHATSOEVER TO FURNISH ANY MAINTENANCE AND SUPPORT SERVICES WITH RESPECT TO 
                THE APP OR SYSTEM AND SHALL HAVE NO LIABILITY FOR YOUR USE OF THE APP OR SYSTEM OR ANY DAMAGES, 
                DIRECT OR INDIRECT, ARISING THEREFROM.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Waiver</h3>
              <p className="pt-4">
                Any failure, forbearance or delay on the part of Oncominds to exercise any remedy or right 
                herein shall not operate as a waiver. The failure of Oncominds to require your performance of 
                any of the terms, covenants, or provisions herein shall not constitute a waiver of any of the 
                rights herein.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Enforcement of Terms and Conditions</h3>
              <p className="pt-4">
                By accessing and using the System, you agree that your access to and use of the System and 
                any Service, Content, Transmission, or communication related to the System is subject to the 
                Terms of Use and Privacy and Confidentiality Policy, as well as all applicable laws, as governed 
                and interpreted pursuant to the laws of the State of Delaware, United States of America. You 
                also agree that any claims or disputes against Oncominds whatsoever arising hereunder shall be 
                submitted to binding arbitration in the State of Delaware, in accordance with the rules and 
                procedures of the American Arbitration Association.
              </p>
              <h3 className="font-bold text-medium-l pt-5">Contact Us</h3>
              <p className="pt-4">
                If you would like to request additional information regarding these Terms of Use, Oncominds's 
                Privacy and Confidentiality Policy, use of the System, or otherwise, please contact us at 
                <a 
                  className="text-sky-blue underline ml-1" 
                  href="mailto:contact@oncominds.com" 
                  target="_blank"
                  rel="noopener noreferrer"
                >contact@oncominds.com</a>
              </p>
            </Fragment>
          }
          {policyTab === "india" &&
            <Fragment>
              <p className="pt-3">
                Last Modified: <b>January 27, 2024</b>
              </p>
              <p className="pt-3">
                The Oncominds Platform is developed by Oncominds Inc. The Platform enables 
                oncology consultations and collaborations between on-boarded oncologist 
                from India and specialist oncology professionals from leading institutions in the United 
                States and the rest of the world and for this purpose has Registered User with Delaware 
                based Oncology healthtech company, Oncominds, Inc.
              </p>
              <p className="pt-3">
                These Terms of Use constitute a legal agreement ("<b>Terms</b>") between "<b>You</b>" (a 
                registered user being a licensed physician) and the Company (hereafter referred to as 
                "<b>we</b>", "<b>us</b>", "<b>our</b>" or, "<b>Oncominds</b>") in connection with Your access, 
                visit or use of the website <Link className="text-sky-blue underline" to="/">
                  www.oncominds.com</Link> and mobile application <b>'Oncominds' Oncominds Mobile 
                Application</b> (collectively referred to as "<b>Platform</b>").
              </p>
              <p className="pt-3">
                By registering with the Platform, You agree to be bound by these Terms. 
                If You do not agree to all or any of these Terms, then You may not access, download, 
                register with or use the Platform or the Services offered through the Platform.
              </p>
              <ol className="counter">
                <li className="mt-5 pl-6">
                  <span className="font-bold text-medium-l">SERVICES PROVIDED BY THE PLATFORM</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      The Platform is one of its kind technology enabled Platform that enables 
                      collaboration between India Oncologists and expert oncology professionals from leading 
                      institutions in the United States. OSPL has licensed the technology and services from 
                      Delaware based Oncology healthtech company, Oncominds, Inc. Oncominds, Inc. brings 
                      together a technology platform, the best oncology minds and best practices, all on one 
                      platform. OSPL has a network of nationwide oncologists.
                    </li>
                    <li className="pl-8 mt-3">
                      The Platform is an exclusive platform for medical practitioners only.
                    </li>
                    <li className="pl-8 mt-3">
                      Services offered via the Platform inter-alia include: - Educational Webinars and Educational Tumor Boards
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="font-bold text-medium-l">REGISTERED USER</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      Only registered medical practitioners are eligible to be registered on the Platform. 
                      The Services offered via the Platform cannot be used by non-medical end-users (who are 
                      patients or their authorised representatives).
                    </li>
                    <li className="pl-8 mt-3">
                      We retain the right to reject an application for registration on the Platform at 
                      our discretion and our decision on these matters is final.
                    </li>
                    <li className="pl-8 mt-3">
                      You, a registered medical practitioner may register on the Platform by providing 
                      your name, address, email ID, medical license number and other details as required to 
                      be provided to be a part of the Platform and use its Services (“Registered User”)
                    </li>
                    <li className="pl-8 mt-3">
                      Once registered, Oncominds will have the right to display your details such as 
                      your name, email address, medical license number, years of experience and qualifications 
                      on the Platform. By using the Service and registering with the Platform, you are deemed 
                      to have given consent to us to display your name and email address. If you do not wish your 
                      name and email to be displayed on the Platform we ask you to refrain from using the Service.
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">USE OF THE PLATFORM</span>
                  <b className="block pt-2">Use of the Platform  will be subject to the following restrictions:</b>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      The Registered User will use the Platform and the services provided by it only in 
                      compliance with all applicable laws and not use this Platform or any of its services 
                      or any purposes not intended under these Terms.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User understands that in order to register and be on-boarded as a 
                      Registered User on the Platform, the Registered User will be required to provide 
                      information such as name, photograph, gender, age, email address, phone number, information 
                      regarding availability, qualifications, years of experience, affiliation to medical 
                      associations etc (“<b>Registered User Information</b>”).
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User agrees to maintain and promptly update the information you 
                      provide to Platform, and keep all such information accurate, current, and complete.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User acknowledges that once a Registered User creates an account on the 
                      Platform, he/ she is the sole authorized user of such account. The Registered User 
                      must treat information such as username, password, any other piece of information as 
                      part of Our security procedures as confidential and must not disclose it to any other 
                      person. The Registered User is solely and fully responsible for all activities that occur 
                      under his/her account. The Registered User agrees to notify  immediately of any 
                      unauthorized access to or use of his/her account or any other breach of security.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User will not deliberately use the Platform in any way that is 
                      unlawful or harms Oncominds, its directors, employees, affiliates, distributors, 
                      Registered Users, service providers, content providers and/or any other Registered Users 
                      registered on the Platform.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User will not transmit, or procure the sending of, any advertising or 
                      promotional material, including any junk mail, spam, bulk mail, or carry out any 
                      similar solicitations.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User understands that by registering on the Platform, the Registered 
                      User agrees to all the Terms herein and the Privacy Policy on 
                      <Link className="text-sky-blue underline ml-1" to="/">www.oncominds.com</Link>.
                    </li>
                    <li className="pl-8 mt-3">
                      Oncominds reserves the right to suspend or terminate a Registered User’s account with 
                      immediate effect and for an indefinite period, if Oncominds has reason(s) to believe 
                      that any information provided by the Registered User is incorrect or false, or that 
                      the security of the Registered User’s account has been compromised in any way, or there 
                      is a breach of these Terms or upon any unauthorised use of the Registered User’s account 
                      by any third party or for any other reason.	
                    </li>
                    <li className="pl-8 mt-3">
                      If a Registered User becomes aware of any breach of security or unauthorised use of 
                      the account, the Registered User will take all reasonable steps to inform Oncominds 
                      in respect of such unauthorized use and, in this regard, Oncominds shall not be 
                      responsible for any liabilities that a Registered User may incur from the misuse of 
                      their account and information contained therein.
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User agrees to keep all information and medical records confidential 
                      that he/she receives for or during any consultation services that are provided on the 
                      Platform.
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">
                    CODE OF CONDUCT OF REGISTERED USERS ONBOARDED ON THE PLATFORM
                  </span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      <span className="block">
                        The Registered User agrees that they shall not:
                      </span>
                      <ul className="list-lower-roman">
                        <li className="ml-4 pl-2 mt-3">
                          reveal/disclose any confidential or proprietary information of other users of 
                          the Platform, any records pertaining to end-users or any third party information 
                          the Registered User receives or comes in possession of such confidential or 
                          proprietary information;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          incorporate any computer contaminant, software virus or any computer code or 
                          file or program on the Platform designed to interrupt, destroy or limit the 
                          functionality of the Platform;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          incorporate or introduce any program on the Platform that might infringe the 
                          intellectual property rights of any other user and/or of the Platform;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          download, copy, or reproduce any file or information available on the Platform 
                          which You know, or reasonably should know, cannot be legally reproduced, displayed, 
                          performed, and/or distributed;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          in any way, deface or vandalize the Platform or prevent or restrict others 
                          from using the Platform;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          stalks, threaten or harass any other users or infringe upon or attempt to infringe 
                          upon their privacy;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          infringe any patent, trademark, copyright or other proprietary rights or third 
                          party's trade secrets or rights of publicity or privacy, of the Platform, other users 
                          of the Platform or any third Party;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          make any negative, denigrating or defamatory statement and/or comment about the Platform 
                          or otherwise engage in any conduct or action that might tarnish the image, goodwill or 
                          reputation of the Platform or other users on the Platform or other business Registered 
                          Users of Oncominds or otherwise tarnish or dilute any of Oncominds’s trade or service 
                          marks, trade name and/or goodwill associated with such trade or service marks, trade name 
                          as may be owned or used by the Platform.
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          impersonate any person or entity, or falsely state or otherwise misrepresent themselves 
                          or their affiliation with any person or entity; and
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, 
                          the dealing of which is prohibited or restricted in any manner under the provisions of 
                          any applicable law.
                        </li>
                      </ul>
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User shall uphold highest standards of ethics whilst consulting on the 
                      Platform and while using the Services provided by the Platform. 
                    </li>
                    <li className="pl-8 mt-3">
                      <span className="block">
                        Under these Terms, the Registered User agrees not to host, display, upload, modify, 
                        publish, transmit, update or share any information that:
                      </span>
                      <ul className="list-lower-roman">
                        <li className="ml-4 pl-2 mt-3">
                          belongs to another person and in relation to which it does not have any right of use;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          infringes intellectual property rights (including any patent, trademark, 
                          copyright or other proprietary rights) of any person and privacy rights of any person;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          is false or misleading in any way;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          violates any law for time being in force;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          harasses or advocates harassment of another person;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          involves the transmission of junk mail, or unsolicited mass mailing or spamming;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          promotes illegal activities or abusive, obscene, defamatory or libellous conduct;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, 
                          paedophilic, libellous, invasive of another’s privacy, hateful, or racially, 
                          ethnically objectionable, disparaging, relating or encouraging money laundering 
                          or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening 
                          or unlawfully harassing including but not limited to “indecent representation of 
                          women” within the meaning of the Indecent Representation of Women (Prohibition) 
                          Act, 1986;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          deceives or misleads the other users of the Platform about the origin of messages 
                          or communicates any information which is grossly offensive or menacing in nature;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          threatens the unity, integrity, defence, security or sovereignty of India, friendly 
                          relations with foreign states, or public order or causes incitement to the commission 
                          of any cognizable offence or prevents investigation of any offence or is insulting any 
                          other nation;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          promotes an illegal or unauthorized copy of another person’s copyrighted work;
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          provides material that exploits people in a sexual, violent or otherwise 
                          inappropriate manner or solicits personal information from anyone; and
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          provides instructional information about illegal activities such as making or 
                          buying illegal weapons, violating someone’s privacy, or providing or creating 
                          computer viruses;
                        </li>
                      </ul>
                    </li>
                    <li className="pl-8 mt-3">
                      The Registered User agrees that they shall, at all times, ensure full compliance 
                      with the applicable legal provisions including the applicable provision under the 
                      Information Technology Act, 2000, and the rules thereunder as applicable and as amended 
                      from time to time and, also all applicable laws, rules and regulations and international 
                      laws, statutes, ordinances and regulations while using the Platform and availing of any 
                      services it offers. 
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">ROLE OF ONCOMINDS</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      Oncominds is merely a technology solutions provider and has created the Platform 
                      to connect oncologists with other expert oncology professionals from different parts 
                      of the world, who are registered on the Platform.
                    </li>
                    <li className="pl-8 mt-3">
                      The Platform is primarily offered and made available in India. If you are a user 
                      accessing the Platform from the United States of America, European Union, or any other 
                      region with laws or regulations that are different from those applicable in India, your 
                      continued use of the Platform, which is governed by Indian law. 
                    </li>
                    <li className="pl-8 mt-3">
                      Oncominds’ role is that of an 'intermediary' as defined under Section 2 (w) of 
                      the Information Technology Act, 2000 and the rules thereunder. Being an intermediary, 
                      Oncominds has no responsibility and/or liability in respect of the content and interactions 
                      on the Platform including any content or information provided by the Registered Users. 
                      The Registered User is  solely responsible for his/her interactions with other Registered 
                      Users on the Platform.
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">LINKS TO THIRD-PARTY WEBSITES</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      The Registered User  may be provided with links on the Platform that direct to third-party 
                      websites/applications/content or service providers, including advertisers and e-commerce 
                      websites (collectively "<b>Third Party Websites</b>").
                    </li>
                    <li className="pl-8 mt-3">
                      Oncominds does not endorse any Third Party Websites that  may be directed to from the Platform.
                    </li>
                    <li className="pl-8 mt-3">
                      Links to such Third Party Websites are provided for the Registered User's convenience 
                      only and Oncominds is not responsible for the content of the Third Party Websites.
                    </li>
                    <li className="pl-8 mt-3">
                      Oncominds or any Third Party Websites may display advertisements and promotions on 
                      the Platform. The manner, mode and extent of advertising by Oncominds on the Platform 
                      is subject to change. By clicking on any advertisements the Registered User may leave 
                      the Website and be directed to an external application/website, or to an 
                      application/website maintained by an entity other than Oncominds. If the Registered User 
                      decides to visit any such website, he/she may  do so at their own risk and it is his/her 
                      responsibility to take all protective measures to guard against viruses or other destructive 
                      elements. Oncominds makes no warranty or representation regarding, and does not endorse, any 
                      linked websites or the information appearing thereon or any of the products or services 
                      described thereon. 
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">PROPRIETARY RIGHTS</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      <span className="block underline">
                        Oncominds’ Rights to the Platform
                      </span>
                      <ul className="list-lower-roman">
                        <li className="ml-4 pl-2 mt-3">
                          All intellectual property in and to the Platform, any software, techniques and 
                          processes used in connection with it, belongs exclusively to Oncominds. 
                          Through the Registered User’s  use of the Platform, by no means are any rights 
                          impliedly or expressly granted by Oncominds to the Registered User in respect of 
                          such works.
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          All intellectual property rights on the Platform shall belong to and be owned 
                          at all times by Oncominds. All rights to any Registered User created content 
                          shall continue to be owned by the concerned Registered User. The Registered User 
                          grants to Oncominds Inc an irrevocable, non-exclusive, royalty free right and license 
                          to use such Registered User generated content for display on the Platform. 
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          Oncominds assert all proprietary rights in and to all names and trademarks contained 
                          on the Platform. Except as stated otherwise in these Terms all contents (including 
                          all intellectual property rights to such content) is owned exclusively by Oncominds 
                          and/or its licensors.
                        </li>
                        <li className="ml-4 pl-2 mt-3">
                          Unless expressly authorized by Oncominds, The Registered User agree not to 
                          reproduce, modify, sell, distribute, mirror, frame, republish, download, transmit, 
                          or create derivative works of the contents, in whole or in part, by any means. 
                          The Registered Usermust not remove or modify any copyright or trademark notice, 
                          or other notice of ownership.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">DISCLAIMERS</span>
                  <ol className="counter">
                    <li className="pl-8 mt-3">
                      The Registered User’s use of the Platform and any services or items obtained through 
                      the Platform are at their own risk. The Platform, its content and any services or items 
                      obtained through the Platform are provided on an "as is'' and "as available" basis, 
                      without any warranties of any kind, either express or implied. Neither Oncominds nor 
                      any person associated with it makes any warranty or representation with respect to the 
                      completeness, security, reliability, quality, accuracy or availability of the contents, 
                      information and the third party links made available on the Platform. 
                    </li>
                    <li className="pl-8 mt-3">
                      All interaction, communication, or transaction between the Registered User and the 
                      other users or end-user while availing the services on the Platform is a separate 
                      transaction. We do not have any control over such information and play no determinative 
                      role in the finalization of the same and hence do not stand liable for the outcomes of 
                      such communication, interaction, or transactions.

                      <span className="block mt-3">
                        The foregoing does not affect any warranties which cannot be excluded or limited 
                        under applicable law.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">LIMITATION OF LIABILITY</span>
                  <span className="block mt-3">
                    To the fullest extent permitted by law, in no event will Oncominds or its affiliates 
                    be liable for any direct, indirect, special, incidental, punitive, exemplary or 
                    consequential damages (including, without limitation, loss of business, revenue, profits, 
                    goodwill), whether or not Oncominds has been warned of the possibility of such damages 
                    or could have reasonably foreseen such damages.
                  </span>
                  <span className="block mt-3">
                    Notwithstanding anything to the contrary in these Terms, Oncominds’ liability 
                    under these Terms to You shall in no event exceed INR 5000.
                  </span>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">INDEMNITY</span>
                  <span className="block mt-3">
                    The Registered User  shall indemnify, defend and hold harmless Oncominds as well 
                    as each of its representatives, employees, contractors, agents, officers, directors, 
                    shareholders, affiliates and assigns from all liabilities, claims, damages, costs 
                    and expenses, including reasonable attorneys' fees and expenses in relation to all 
                    claims arising out of:
                  </span>
                  <ul className="list-lower-alpha">
                    <li className="ml-4 pl-2 mt-3">
                      a breach of any of the Registered User’s obligations under these Terms;
                    </li>
                    <li className="ml-4 pl-2 mt-3">
                      use or misuse by the Registered User of the Platform’s services; 
                    </li>
                    <li className="ml-4 pl-2 mt-3">
                      breach of any intellectual property or other proprietary rights of any person or entity; or
                    </li>
                    <li className="ml-4 pl-2 mt-3">
                      the inaccuracy or incompleteness of any information or data the Registered User 
                      has provided on the Platform.
                    </li>
                  </ul>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">GOVERNING LAW</span>
                  <span className="block mt-3">
                    This Agreement is governed by the provisions of Indian law, including but not 
                    limited to the following:
                  </span>
                  <ul className="list-disc">
                    <li className="ml-4 pl-2 mt-3">the Indian Contract Act, 1872</li>
                    <li className="ml-4 pl-2 mt-3">the (Indian) Information Technology Act, 2000</li>
                    <li className="ml-4 pl-2 mt-3">
                      the (Indian) Information Technology (Reasonable Security Practices and Procedures and
                      Sensitive Personal Information) Rules, 2011
                    </li>
                    <li className="ml-4 pl-2 mt-3">
                      the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011
                    </li>
                  </ul>
                  <span className="block mt-3">
                    These Terms shall be governed by the laws of the Republic of India without giving 
                    effect to its conflict of laws provisions. These Terms shall be subject to the 
                    exclusive jurisdiction of the courts at Bangalore India.
                  </span>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">SEVERABILITY</span>
                  <span className="block mt-3">
                    If for any reason a court of competent jurisdiction finds any provision or portion 
                    of these Terms to be unenforceable, the remainder of these Terms will continue in f
                    ull force and effect.
                  </span>
                  <span className="block mt-3">
                    Please email us at <a 
                    className="text-sky-blue underline mx-1/2" 
                    href="mailto:contact@oncominds.com" 
                    target="_blank"
                    rel="noopener noreferrer"
                  >contact@oncominds.com</a> or dial <a 
                    className="text-sky-blue underline mx-1/2" 
                    href="tel:9036815038"
                    rel="noopener noreferrer"
                  >+91 903-681-5038</a> to speak to a 
                    technical professional. if you have any questions regarding these Terms
                  </span>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">AMENDMENTS TO THESE TERMS OF USE</span>
                  <span className="block mt-3">
                    We reserve the right, in our discretion, to change or modify all or any part of this 
                    Agreement at any time, effective immediately. Your continued use of the Platform or 
                    Service constitutes Your binding acceptance of these Terms, including any changes or 
                    modifications made by us as permitted above. Please be sure to review this Agreement 
                    periodically to ensure familiarity with the most current version. If at any time these 
                    Terms are no longer acceptable to You, You should immediately cease all use of Our Services.
                  </span>
                </li>
                <li className="mt-5 pl-6">
                  <span className="block font-bold text-medium-l">CONTACT US</span>
                  <span className="block mt-3">
                    If You have any concerns, complaints or grievances about other users or their 
                    manner of use of the Platform, in any manner to harass, abuse, stalk, bully, threaten, 
                    defame, impersonate, gamble, exhibit or transmit sexually explicit, pornographic, 
                    pedophilic content, promulgate hate speech, carry out illegal activities or otherwise 
                    infringe or violate Your rights including any patent, copyright, trademark, trade secret 
                    or other proprietary rights, please feel free to contact the Grievance Officer any time at:
                  </span>
                  <span className="block mt-1">
                    <b>Contact Details:</b>
                    <a 
                      className="text-sky-blue underline ml-1" 
                      href="mailto:grievance@oncominds.com" 
                      target="_blank"
                      rel="noopener noreferrer"
                    >grievance@oncominds.com</a>
                  </span>
                </li>
              </ol>
            </Fragment>
          }
        </div>
        <div className="pt-30 mobile:pt-15">
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
