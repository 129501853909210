import React, {useState, useEffect} from "react";
import moment from "moment";

import {isEmptyObject, fixURL} from "../../utils";

import RecordType from "../common/recordType";

const EventRecordNote = (props) => {
  const {eventList, allCaseNotes, patientId} = props;
  const [notes, setNotes] = useState({});
  
  useEffect(() => {
    if(eventList.length > 0 && allCaseNotes.length > 0){
      const notes = eventList.reduce((data, event) => {
        const {id: eventId} = event;
        const {eventNotes, recordNotes} = allCaseNotes.reduce((data, el) => {
          const {type, specialist, record, event} = el;
          if(event && event.id === eventId){
            const {name, avatarUrl} = specialist;
            if(type === "event_note"){
              data.eventNotes.push({
                ...el,
                docName: name, avatarUrl
              });
            }

            if(type === "record_note"){
              const {id: recordId, fileType, url: recordUrl} = record;
              if(!data.recordNotes[recordId]){
                data.recordNotes[recordId] = {
                  fileType, recordUrl,
                  notes: [{...el, docName: name, avatarUrl}]
                };
                return data;
              }

              data.recordNotes[recordId].notes.push({...el, docName: name, avatarUrl});
            }
          }
          return data;
        }, {eventNotes: [], recordNotes: {}});

        return {
          ...data,
          ...(
            (eventNotes.length > 0 || !isEmptyObject(recordNotes)) ? {
              [eventId]: {
                eventNotes,
                recordNotes
              }
            } : {}
          )
        };
      }, {});
      setNotes(notes);
    }
  }, [eventList, allCaseNotes]);

  return (
    <div className="bg-white p-3 mt-3 rounded tracking-wider text-xs border-b border-card-shadow">
      <div className="uppercase font-medium">Event and record notes</div>
      {
        eventList.map((el, index) => {
          const {
            id, name, description, fromDate, toDate
          } = el;
          if(!notes[id]){
            return undefined;
          }

          const {eventNotes, recordNotes} = notes[id];
          const objectKeys = [...Object.keys(recordNotes)];
          const showDate = (fromDate === toDate) ? moment(fromDate).format("DD MMM YYYY") : 
          `${moment(fromDate).format("DD MMM YYYY")} - ${moment(toDate).format("DD MMM YYYY")}`;
          return (
            <div
              className={`mt-2${
                index === eventList.length - 1 ? "" : " border-b border-gray pb-2"
              }`}
              key={id}
            >
              <div className="flex mb-1">
                <h2 className="grow text-base text-black font-extrabold tracking-normal">{name}</h2>
                <div className="font-medium text-xs uppercase">{showDate}</div>
              </div>
              <div
                className="text-xs leading-tight html-body"
                dangerouslySetInnerHTML={{__html: description}}
              />
              <div className="flex mt-2">
                <div className="w-1/3 pr-1">
                  <div className="uppercase font-medium">Event notes</div>
                  {
                    eventNotes.map(el => {
                      const {id, avatarUrl, specialist, docName, description} = el;
                      return (
                        <div className="flex mt-2" key={id}>
                          <div title={docName} className="w-10 min-w-10">
                            <img
                              src={fixURL(true, specialist.id, avatarUrl)}
                              alt={docName}
                              className="rounded mx-auto"
                            />
                          </div>
                          <div
                            className="pl-2 font-normal whitespace-pre-line leading-tight html-body"
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        </div>
                      )
                    })
                  }
                  {eventNotes.length === 0 &&
                    <div className="mt-2">No notes found for the event</div>
                  }
                </div>
                <div className="w-2/3 pl-1">
                  <div className="uppercase font-medium">record notes</div>
                  {
                    objectKeys.map((el, index) => {
                      const {fileType, recordUrl:url, notes} = recordNotes[el];
                      return (
                        <div
                          className={`flex mt-2${
                            index === objectKeys.length - 1 ? "" : " border-b border-gray pb-2"
                          }`}
                          key={el}
                        >
                          <div className="thumbnail mr-2">
                            <RecordType
                              fileType={fileType}
                              patientId={patientId}
                              url={url}
                              showPopupView={true}
                            />
                          </div>
                          <div className="grow">
                            {
                              notes.map((el, index) => {
                                const {id, avatarUrl, specialist, docName, description} = el;
                                return (
                                  <div
                                    className={`flex${index > 0 ? " mt-2" : ""}${
                                      index === notes.length - 1 ? "" : " border-b border-gray pb-2"
                                    }`}
                                    key={id}
                                  >
                                    <div title={docName} className="w-10 min-w-10">
                                      <img
                                        src={fixURL(true, specialist.id, avatarUrl)}
                                        alt={docName}
                                        className="rounded mx-auto"
                                      />
                                    </div>
                                    <div
                                      className="pl-2 font-normal leading-tight html-body"
                                      dangerouslySetInnerHTML={{__html: description}}
                                    />
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                  {objectKeys.length === 0 &&
                    <div className="mt-2">No notes found for the record</div>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      {
        isEmptyObject(notes) && 
        <div className="mt-1 text-center">No event and record note found</div>
      }
    </div>
  );
};

export default EventRecordNote;
