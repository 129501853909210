import website from "../assets/social/website.svg";
import youtube from "../assets/social/youtube.svg";
import twitter from "../assets/social/twitter.svg";
import facebook from "../assets/social/facebook.svg";
import linkedin from "../assets/social/linkedin.svg";
import instagram from "../assets/social/instagram.svg";

export const API_URL = (
  process.env.NODE_ENV === "production" ? "https://api.oncominds.com" : "https://api.vodex.in"
);

export const API_RESULT_COUNT = 15;

export const GOOGLE_MAP_API_KEY = "AIzaSyD3rt5BviDif5kvpvgmT7MSwaYirXxAbK8";

export const TOKEN_KEY = "idToken";
export const USERNAME_KEY = "username";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const ACCESS_TOKEN_KEY = "accessToken";

export const API_PATH = {
  getUser: "/user-svc/api/v1/userinfo/",
  login: "/user-svc/api/v1/auth/login/",
  getAllCases: "/vmtb-svc/api/v1/cases/",
  logout: "/user-svc/api/v1/auth/logout/",
  getAllTenants: "/vmtb-svc/api/v1/tenants/",
  queryData: "/vmtb-svc/api/v1/queries/{arg1}/",
  caseDetails: "/vmtb-svc/api/v1/cases/{arg1}/",
  eventDetails: "/vmtb-svc/api/v1/events/{arg1}/",
  forgotPassword: "/user-svc/api/v1/auth/password/",
  saveNote: "/vmtb-svc/api/v1/{arg1}/{arg2}/notes/",
  getAllTenantUsers: "/vmtb-svc/api/v1/tenantusers/",
  specificRecord: "/vmtb-svc/api/v1/records/{arg1}/",
  getAllSpecialists: "/vmtb-svc/api/v1/specialists/",
  getAllNotes: "/vmtb-svc/api/v1/cases/{arg1}/notes/",
  getSpecificQuery: "/vmtb-svc/api/v1/queries/{arg1}/",
  getQueries: "/vmtb-svc/api/v1/cases/{arg1}/queries/",
  createCase: "/vmtb-svc/api/v1/tenants/{arg1}/cases/",
  uploadDicom: "/vmtb-svc/api/v1/patients/{arg1}/dicom/",
  getSpecificTenants: "/vmtb-svc/api/v1/tenants/{arg1}/",
  updateSpecialistNote: "/vmtb-svc/api/v1/notes/{arg1}/",
  getAllEvents: "/vmtb-svc/api/v1/patients/{arg1}/events/",
  getAllReports: "/vmtb-svc/api/v1/patients/{arg1}/records/",
  caseSpecialist: "/vmtb-svc/api/v1/cases/{arg1}/specialists/",
  getSpecificSpecialist: "/vmtb-svc/api/v1/specialists/{arg1}/",
  getRecordsForEvent: "/vmtb-svc/api/v1/events/{arg1}/records/",
  getSpecialistCase: "/vmtb-svc/api/v1/specialists/{arg1}/cases/",
  downloadContent: "/vmtb-svc/api/v1/{arg1}/{arg2}/files/{arg3}/",
  getUnattachedRecords: "/vmtb-svc/api/v1/patients/{arg1}/files/",
  getSpecificConsultingDoctor: "/vmtb-svc/api/v1/tenantusers/{arg1}/",
  uploadSpecialistAvatar: "/vmtb-svc/api/v1/specialists/{arg1}/files/",
  getAllConsultingDoctors: "/vmtb-svc/api/v1/tenants/{arg1}/tenantusers/",
  uploadConsultingDoctorAvatar: "/vmtb-svc/api/v1/tenantusers/{arg1}/files/"
};
 
export const recordCategories = [{
  name: "Supporting diagnosis",
  value: "diagnosis",
  types: [{
    name: "Biopsy",
    value: "biopsy"
  }, {
    name: "Immunohistochemistry report",
    value: "immuno-histo-chemistry"
  }, {
    name: "Molecular analysis",
    value: "molecular-analysis"
  }, {
    name: "Bone marrow aspiration report",
    value: "bone-marrow-aspiration"
  }, {
    name: "Flowcytometry report",
    value: "flowcytometry-report"
  }]
}, {
  name: "Staging/Risk stratifying",
  value: "staging",
  types: [{
    name: "PET CT",
    value: "pet-ct"
  }, {
    name: "CT Scan (Plain+/- contrast)",
    value: "ct-scan"
  }, {
    name: "Biochemistry test",
    value: "biochemistry-test"
  }, {
    name: "Molecular test",
    value: "molecular-test"
  }]
}, {
  name: "Assessing treatment fitness",
  value: "fitness",
  types: [{
    name: "ECHO",
    value: "echo"
  }, {
    name: "ECG",
    value: "ecg"
  }, {
    name: "Blood counts",
    value: "blood-count"
  }, {
    name: "Liver function test",
    value: "liver-function-test"
  }, {
    name: "Kidney function test",
    value: "kidney-function-test"
  }, {
    name: "Lung function test",
    value: "lung-function-test"
  }, {
    name: "Viral serology",
    value: "viral-serology"
  }]
}, {
  name: "Miscellaneous",
  value: "misc",
  types: [{
    name: "ECOG performance status",
    value: "ecog"
  }, {
    name: "Karnofsky performance status",
    value: "karnofsky"
  }, {
    name: "Comorbidity",
    value: "comorbidity"
  }, {
    name: "Comprehensive geriatric score",
    value: "geriatric"
  }, {
    name: "Other relevant information",
    value: "other"
  }]
}];

export const allReportTypes = recordCategories.reduce((data, el) => {
  const {types} = el;
  data.push(...types.map(({name: label, value}) => ({
    label,
    value
  })));
  return data;
}, []);

export const typeToCategoryMap = recordCategories.reduce((data, el) => {
  const {types, value} = el;
  types.forEach(type => {
    const {value: tValue} = type;
    data[tValue] = value;
  });
  return data;
}, {});

export const recordCategoryMap = recordCategories.reduce((data, el) => {
  const {types, value: categoryValue, name: categoryName} = el;
  return types.reduce((data, el) => {
    const {name, value} = el;
    data[value] = {
      category: {
        name: categoryName,
        value: categoryValue
      },
      name
    };
    return data;
  }, data);
}, {});

export const recordSelectionOptions = [{
  value: "create-new",
  label: "Create New Event"
}, {
  value: "select-existing",
  label: "Add to Existing Event"
}
// , {
//   value: "delete-selected",
//   label: "Delete selected"
// }
];

export const experienceRange = [{
  value: "0-6",
  label: "0 - 5"
}, {
  value: "5-11",
  label: "6 - 10"
}, {
  value: "10-17",
  label: "11 - 16"
}, {
  value: "16-100",
  label: "16+"
}];

export const socialMediaImageMap = {
  twitter,
  youtube,
  facebook,
  linkedin,
  instagram,
  website
};