import React, {Fragment} from "react";
import {Link} from "react-router-dom";

const Hero = (props) => {
  const {heroData} = props;

  return (
    <Fragment>
      <section
        className={`
          leading-tight mt-4 grid grid-cols-3 gap-4 text-white
          mobile:grid-cols-1 mobile:-mt-10 mobile:px-4
        `}
      >
        {
          heroData.map(({bg, img, link, heading, subtitle}, index) => (
            <Link key={link + index} to={link}>
              <div className={`${bg} p-3 rounded-md flex items-center`}>
                <img src={img}
                  className="h-22 ml-3 mobile:ml-1 mobile:h-18"
                  alt="personalized"
                />
                <div className="pl-5 grow mobile:pl-3">
                  <div
                    className="font-bold border-t border-white border-b py-1.5 text-medium whitespace-pre-line"
                  >
                    {heading}
                  </div>
                  {subtitle && 
                    <div className="mt-1 relative">
                      <div className="w-full">
                        {subtitle}
                      </div>
                      <div
                        className="absolute w-2 h-2 border-t border-r border-white right-0 top-3 rotate-45"
                      />
                    </div>
                  }
                </div>
              </div>
            </Link>
          ))
        }
      </section>
      <section
        className={`
          leading-tight mt-8 pt-7 grid grid-cols-3 gap-4 text-white 
          mobile:grid-cols-1 mobile:px-4
        `}
      >
        <div className="font-bold">
          <h2 className="text-purple-l text-max">Oncominds</h2>
          <h3 className="text-deep-purple text-3xl">It's precision oncology reimagined</h3>
        </div>
        <div className="text-deep-purple flex items-center">
          <p>
          Oncominds uses an advanced technology platform that catapults the traditional tumor board into the future. 
          It brings together multidisciplinary teams of healthcare professionals from a global peer-to-peer network to 
          discuss case studies leading to precision oncology in medical practices.
          </p>
          {/* <p className="pt-2">
            Oncominds uses Analytics and Intelligent Search to surface insights 
            that will help this team analyse, correlate and define the most 
            appropriate treatment based on their knowledge and experience. 
          </p>
          <p className="pt-2">
            The result is a one-stop solution that provides a 
            treatment plan that is tailor-made for each cancer 
            patient, increases their chances of survival and 
            improves their odds of living a normal life.
          </p> */}
        </div>
      </section>
    </Fragment>
  )
};

export default Hero;
