import React from "react";
import moment from "moment";
import RecordType from "../../common/recordType";

const RecordView = (props) => {
  const {isLast, recordData = {}, selectedRecords, selectRecord, setPoints, setClicked} = props;
  const {date, fileType, url, patientId, id} = recordData;

  return (
    <div className="flex">
      <div
        className={`border-r ${!isLast ? "border-gray" : "border-white"} w-50 relative`}
      >
        {moment(date).format("MMM DD, YYYY")}
        <div
          className="absolute top-0 -right-2.5 bg-white z-10"
          onContextMenu={(e) => {
            e.preventDefault();
            setClicked(true);
            setPoints({
              x: e.pageX,
              y: e.pageY,
            });
          }}
        >
          <label
            className="block pl-4 relative custom-input top-0"
          >
            <input
              checked={selectedRecords.some(record => record.id === id)}
              type="checkbox"
              name="action"
              className="absolute opacity-0"
              onChange={() => {
                selectRecord(records => {
                  const clonedSRecords = Array.from(records);
                  const index = clonedSRecords.findIndex(el => el.id === id);
                  if(index > -1){
                    clonedSRecords.splice(index, 1);
                    return clonedSRecords;
                  }

                  clonedSRecords.push(recordData);
                  return clonedSRecords;
                })
              }}
            />
            <span
              className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
            />
          </label>
        </div>
      </div>
      <div className="grow pl-5 pb-4 relative flex">
        <RecordType
          fileType={fileType}
          patientId={patientId}
          url={url}
          showPopupView={true}
        />
      </div>
    </div>
  )
};

export default RecordView;
