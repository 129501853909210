import React, {useState, Fragment, useEffect} from "react";

import Form from "../common/form";
import {useContextData} from "../../store";
import {useNavigate} from "react-router-dom";
import {hasCaseCreationAccess} from "../../utils";
import {formConfig} from "./configs/caseType.config";
import {getLabelObj} from "../../utils/workflowUtils";

const CaseType = (props) => {
  const {onValidate, onStepUpdate, stepData = {
    priority: false
  }, setLabelObject, stepId} = props;
  const navigate = useNavigate();
  const {userRoles = [], loading} = useContextData();
  
  const {priority, caseType = ""} = stepData;
  const [selectedCase, setSelectedCase] = useState(caseType);

  useEffect(() => {
    const hasAccess = hasCaseCreationAccess(userRoles);
    if(!loading && !hasAccess){
      navigate("/case/")
    }
  }, [userRoles, loading, navigate]);
  
  useEffect(() => {
    const {caseType} = stepData;
    if(caseType){
      setSelectedCase(caseType);
    }
  }, [stepData]);

  useEffect(() => {
    const labelObj = getLabelObj(formConfig);
    setLabelObject(lObj => ({...lObj, [stepId]: {
      caseType: "Case Type",
      p2p: "P2P",
      vmtb: "VMTB",
      ...labelObj
    }}));
  }, [setLabelObject, stepId]);

  const caseTypes = [{
    id: "p2p",
    title: "P2P",
    desc: (
      <Fragment>
        Sample description of P2P service
      </Fragment>
    )
  }, {
    id: "vmtb",
    title: "VMTB",
    desc: (
      <Fragment>
        Sample description of VMTB service
        <ul className="mt-1 list-disc pl-3">
          <li>Feature 1 of comprehensive service</li>
          <li>Feature 2 of comprehensive service</li>
          <li>Feature 3 of comprehensive service</li>
        </ul>
      </Fragment>
    )
  }];

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
      {
        caseTypes.map(({id, title, desc}) => (
          <div
            key={id}
            className={
              `rounded box-shadow p-4 cursor-pointer border-2
              ${selectedCase === id ? "border-sky" : "border-transparent"}`
            }
            onClick={() => {
              setSelectedCase(id);
              onValidate(stepId, true);
              onStepUpdate({priority, caseType: id});
            }}
          >
            <div className="text-medium border-b border-card-shadow pb-3">{title}</div>
            <div className="pt-3 text-xs text-text-gray leading-normal">
              {desc}
            </div>
          </div>
        ))
      }
      </div>
      <div className="mt-7">
        <Form
          config={formConfig}
          initialValues={stepData}
          multiColumn={true}
          onFormValid={(isFormValid) => {
            onValidate(stepId, isFormValid && selectedCase);
          }}
          onFormUpdate={(stepData) => {
            onStepUpdate({...stepData, caseType})
          }}
        />
      </div>
    </div>
  )
};

export default CaseType;
