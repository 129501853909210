import React from "react";

const Banner = (props) => {
  const {background, bannerText} = props;

  return (
    <section
      className={
        `${background} h-77 flex items-center justify-around rounded-b-xl mobile:px-8`
      }
    >
      <div
        className={`
          w-full text-max font-bold text-white text-center leading-tight 
          whitespace-pre-line mobile:text-4xl mobile:whitespace-normal
        `}
        dangerouslySetInnerHTML={{__html: bannerText}}
      />
    </section>
  )
};

export default Banner;
