import {format} from "date-fns";

export const createPayload = (stateData) => {
  const caseTypeData = stateData["case-type"];
  const {priority, caseType} = caseTypeData;
  
  const patientDemographic = stateData["patient-demographic"];
  const {
    pid = "", ethnicity = "unknown", gender, maritalStatus, dob, age, weight, height
  } = patientDemographic;

  const caseBackground = stateData["case-background"];
  const {
    cancerStage: stage, cancerType, cancerSubType, summary: description
  } = caseBackground;

  const {patientId: id} = stateData["done"];
  
  const patient = {
    id,
    pid,
    age,
    weight: weight ? weight : 0,
    height: height ? height : 0,
    dob: `${format(dob, "yyyy-MM-dd")}T00:00:00.000`,
    gender,
    maritalStatus
  };

  if(ethnicity){
    Object.assign(patient, {ethnicity})
  }

  return {
    description,
    priority: priority ? "high" : "normal",
    caseType,
    cancerSubType,
    stage,
    cancerType,
    patient
  }
};
