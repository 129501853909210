import React, {useState, useEffect} from "react";
import {usePlacesWidget} from "react-google-autocomplete";
import {GOOGLE_MAP_API_KEY} from "../../../constant";

import cross from "../../../assets/cross.svg";

const Address = (props) => {
  const {
    name, multiColumn, className, required, label, password, autofillMap, fullWidth = false,
    validationObj, touchedFieldObj, formData, setTouchedFieldObj, onUpdate
  } = props;

  const [dataToSet, setDataToSet] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    if(isDataChanged){
      setIsDataChanged(false);
      onUpdate({
        ...formData,
        ...dataToSet
      });
    }
  }, [isDataChanged, formData, onUpdate, dataToSet]);

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    options: {
      types: ["geocode"]
    },
    defaultValue: formData[name] || "",
    onPlaceSelected: (place) => {
      const {formatted_address, address_components} = place;
      const autofillObject = Object.keys(autofillMap).reduce((data, key) => {
        const addressComponent = [];
        const keyToSearch = autofillMap[key];
        keyToSearch.forEach(skey => {
          address_components.forEach(component => {
            const {types, long_name} = component;
            if(types.includes(skey)){
              addressComponent.push(long_name);
            }
          });
        });
        data[key] = addressComponent.join(", ");
        return data;
      }, {});

      setDataToSet({
        [name]: formatted_address,
        ...autofillObject
      });
      setIsDataChanged(true);
    }
  });

  useEffect(() => {
    if(formData && formData[name] && ref && ref.current){
      if(ref.current.value !== formData[name]){
        ref.current.value = formData[name];
      }
    }
  }, [ref, formData, name]);

  return (
    <div
      key={name}
      id={name}
      className={`flex flex-col items-start justify-start relative h-13 ${
        multiColumn ? (fullWidth ? "max-w-130" : "w-68") : ""
      }${className ? ` ${className}` : ""}`}
    >
      <div className="flex flex-row items-center w-full">
        <div className={`input-group${fullWidth ? " full" : ""}`}>
          <input
            className={`${
              validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] && required ? "has-error" : ""
            } ${
              formData[name] ? "has-value" : ""
            }`}
            type={password ? "password" : "text"}
            name={name}
            id={`input-${name}`}
            required={required}
            onChange={() => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));
            }}
            onBlur={() => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }))
            }}
            ref={ref}
          />
          <label className="form-label" htmlFor={`input-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
              className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </div>
      </div>
      {formData[name] && 
        <div 
          className={`w-7 absolute -top-1 bottom-0 right-0 
          cursor-pointer flex items-center justify-center`}
          onClick={() => {
            if(ref && ref.current){
              ref.current.value = "";
              onUpdate({
                ...formData,
                [name]: "",
              });
            }
          }}
        >
          <img src={cross} alt="remove city"/>
        </div>
      }
    </div>
  )
};

export default Address;
