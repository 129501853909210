import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {API_PATH} from "../constant";
import {formatString} from "../utils";
import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import {workflowSteps, createStepData} from "./configs/edit-consulting-doctor.config";

import Head from "../components/head";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

const EditConsultingDoctor = (props) => {
  const navigate = useNavigate();
  const {loading: contextLoading} = useContextData();

  const [tenant, setTenant] = useState("");
  const [stepData, setStepData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  
  const {doctorId} = useParams();
  const {getSpecificConsultingDoctor} = API_PATH;
  const {
    loading: consultingDoctorLoading, data: consultingDoctorData
  } = useRequest(formatString(getSpecificConsultingDoctor, [doctorId]));
  useEffect(() => {
    if(!consultingDoctorLoading && consultingDoctorData && consultingDoctorData.result){
      const {result} = consultingDoctorData;
      const {tenant} = result;
      const stepData = createStepData(result);
      setStepData(stepData);
      setIsLoaded(true);
      setTenant(tenant.id);
    }
  }, [consultingDoctorLoading, consultingDoctorData]);

  
  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading && isLoaded &&
        <Workflow
          {...props}
          isEdit={true}
          initStepData={stepData}
          steps={workflowSteps}
          onExit={() => {
            navigate(`/manage-consulting-doctors/?tenant=${tenant}`);
          }}
        />
      }
    </div>
  )
};

export default EditConsultingDoctor;
