import React from "react";

const File = (props) => {
  const {
    name, multiColumn, className, required, label, validationObj, touchedFieldObj, 
    formData, setTouchedFieldObj, onUpdate, accept = "*/*", multiple = false
  } = props;
  return (
    <div
      key={name}
      id={name}
      className={`flex flex-col items-start justify-start relative h-13 ${
        multiColumn ? "w-68" : ""
      }${className ? ` ${className}` : ""}`}
    >
      <div className="flex flex-row items-center">
        <div className="input-group relative">
        <input
            className={`has-value ${
              validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] && required ? "has-error" : ""
            }`}
            type="file"
            name={name}
            id={`input-${name}`}
            required={required}
            accept={accept}
            multiple={multiple}
            onChange={(e) => { 
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));

              if(e && e.target && e.target.files && e.target.files.length > 0){
                onUpdate({
                  ...formData,
                  [name]: e.target.files
                });
              }
            }}
            onBlur={() => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }))
            }}
          />
          <label className="form-label" htmlFor={`input-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
            className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </div>
      </div>
    </div>
  );
};

export default File;