import React, {useState, Fragment} from "react";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import PDFViewer from "../pdfViewer";
import {fixURL} from "../../utils";
import ModalRecordView from "./modalRecordView";
import dicomThumb from "../../assets/dicom.svg";

const grey = require("../../assets/grey.svg");

const RecordType = (props) => {
  const {fileType, patientId, url, showPopupView = false, bigThumbnail = false} = props;
  const [showPopup, setShowPopup] = useState(false);

  return (
    <Fragment>
      <span 
        className={showPopupView ? "cursor-pointer" : ""}
        onClick={() => {
          if(showPopupView){
            setShowPopup(true);
          }
        }}
      >
        {fileType === "pdf" &&
          <PDFViewer
            url={fixURL(false, patientId, url)}
            isThumbnail={true}
            bigThumbnail={bigThumbnail}
          />
        }
        {fileType === "image" && 
          <LazyLoadImage
            className={`thumbnail m-auto rounded${bigThumbnail ? " big" : ""}`}
            alt="record"
            src={fixURL(false, patientId, url)}
            placeholder={grey.default}
            debounceDelay={100}
          />
        }
        {fileType === "dicom" && 
          <img
            className={`thumbnail m-auto rounded${bigThumbnail ? " big" : ""}`}
            alt={"dicom record"}
            src={dicomThumb}
          />
        }
      </span>
      {showPopup &&
        <ModalRecordView
          url={url}
          fileType={fileType}
          patientId={patientId}
          close={() => {
            setShowPopup(false);
          }}
        />
      }
    </Fragment>
  )
};

export default RecordType;
