import React from "react";

const Switch = (props) => {
  const {name, multiColumn, className, label, text, formData, onUpdate, required} = props;

  return (
    <div
      id={name}
      className={`flex flex-col items-start justify-start relative h-13 ${
        multiColumn ? "w-68" : ""
      }${className ? ` ${className}` : ""}`}
    >
      <div className="flex text-text-gray text-xs whitespace-nowrap overflow-hidden text-ellipsis tracking-wide mb-2">
        {label}
        {required && <span className="text-pink-deep">*</span>}
      </div>
      <label
        className="block pl-5 mb-1 relative custom-input"
      >
        <input
          checked={!!formData[name]}
          type="checkbox"
          name="action"
          className="absolute opacity-0"
          onChange={() => {
            onUpdate({
              ...formData,
              [name]: !formData[name]
            })
          }}
        />
        <span
          className="checkmark absolute left-0 top-1/2 border border-gray bg-white -translate-y-1/2"
        />
        <span className="label-text">{text}</span>
      </label>
    </div>
  );
};

export default Switch;
