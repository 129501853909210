import React, {useEffect, useState} from "react";
import Select from "react-select";
import {usePlacesWidget} from "react-google-autocomplete";
import LazyLoadImage from "@souvik1991/react-lazy-load-image";

import Head from "../components/head";
import Footer from "../components/landing/footer";
import Header from "../components/landing/header";
import SaveLife from "../components/landing/save-live";

import {fixURL} from "../utils";
import useRequest from "../hooks/useRequest";
import useDebounce from "../hooks/useDebounce";
import {languages, getLanguageObject} from "../constant/languages";
import {
  API_PATH, experienceRange, GOOGLE_MAP_API_KEY, socialMediaImageMap
} from "../constant";

import cross from "../assets/cross.svg";
import search from "../assets/search.svg";
import doctor from "../assets/doctor-gray.svg";
const grey = require("../assets/grey.svg");

const OncologyNetwork = () => {
  const [typedName, setTypedName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [selectedTenant, setSelectedTenant] = useState({});
  const [allTenantUsers, setAllTenantUsers] = useState([]);
  const [tenantSelected, setTenantSelected] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);

  const languageObject = getLanguageObject();
  const {getAllTenantUsers} = API_PATH;
  const {data: tenantUserData, loading: tenantUserloading, callAPI} = useRequest(
    getAllTenantUsers, false, {}, "GET", {...filterObject}
  );
  const dCallAPI = useDebounce(callAPI);
  useEffect(() => {
    if(!tenantUserloading && tenantUserData){
      const {result = []} = tenantUserData;
      setAllTenantUsers(result);
    }
  }, [tenantUserData, tenantUserloading]);

  useEffect(() => {
    const allFilters = [];
    if(selectedLanguage.length > 0){
      allFilters.push(selectedLanguage.reduce((data, el) => {
        data.push(`languages:${el},* OR languages:*${el},* OR languages:*,${el} OR languages:${el}`);
        return data;
      }, []).join(" OR "));
    }

    if(selectedExperience.length > 0){
      allFilters.push(selectedExperience.reduce((data, el) => {
        const range = el.split("-");
        data.push(`(experience>${range[0]} AND experience<${range[1]})`);
        return data;
      }, []).join(" OR "))
    }

    if(typedName){
      allFilters.push(`name:*${typedName}*`);
    }

    if(selectedCity){
      allFilters.push(`city:${selectedCity.toLowerCase()}*`);
    }
    
    setFilterObject(allFilters.length ? {
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ")
    } : {});
  }, [selectedLanguage, typedName, selectedExperience, selectedCity]);

  useEffect(() => {
    dCallAPI();
  }, [filterObject, dCallAPI]);

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      const {address_components} = place;
      const city = address_components.filter(el => el.types.includes("locality"));
      if(city.length > 0) {
        const [{long_name}] = city;
        setSelectedCity(long_name);
      }
    }
  });

  const bookNowLink = (
    selectedTenant && selectedTenant.socialMedia && selectedTenant.socialMedia.find(el => el.type === "website")
  );

  return (
    <div className="bg-bg-color w-full">
      <Head title={"Oncology Network"}/>
      <Header selected={"oncology-network"}/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <div className="leading-snug pt-15">
          <h2 className="font-bold text-4xl mobile:text-medium">Oncology Network</h2>
          <div className="flex items-center justify-between">
            <h1 className="font-bold text-purple-light text-max-xxl mobile:text-max mobile:leading-tight">
              Consult with a Doctor
            </h1>
            <div className="flex mobile:hidden">
              {
                [...new Array(4).keys()].map(index => (
                  <img key={index} src={doctor} alt="doctor" className="h-10 w-auto ml-1"/>
                ))
              }
            </div>
          </div>
          <div className="mt-3 grid grid-cols-4 gap-3 mobile:grid-cols-1">
            <div className="w-full">
              <div className="flex py-1 bg-list-bg rounded items-center">
                <div className="pl-2">
                  <img src={search} alt="search" className="w-5"/>
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    value={typedName}
                    className="h-5 w-full px-2 outline-none bg-transparent"
                    placeholder="Search by name..."
                    onChange={e => setTypedName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full relative">
              <input
                type="text"
                className="h-7 px-2 py-1 w-full outline-none bg-list-bg rounded"
                placeholder="Search by city..."
                ref={ref}
              />
              {selectedCity && 
                <div 
                  className={`w-7 absolute top-0 bottom-0 right-0 
                  cursor-pointer flex items-center justify-center`}
                  onClick={() => {
                    if(ref && ref.current){
                      ref.current.value = "";
                      setSelectedCity("");
                    }
                  }}
                >
                  <img src={cross} alt="remove city"/>
                </div>
              }
            </div>
            <Select
              isSearchable={true}
              isMulti
              name="language"
              placeholder="Filter by languages..."
              defaultValue={languages.filter(el => selectedLanguage.includes(el.value))}
              options={languages}
              onChange={(data) => {
                setSelectedLanguage(data.map(el => el.value));
              }}
              className="w-full dropdown-with-bg leading-snug"
            />
            <Select
              isSearchable={true}
              isMulti
              name="language"
              placeholder="Filter by experience..."
              defaultValue={experienceRange.filter(el => selectedExperience.includes(el.value))}
              options={experienceRange}
              onChange={(data) => {
                setSelectedExperience(data.map(el => el.value));
              }}
              className="w-full dropdown-with-bg leading-snug"
            />
          </div>
          <ul className={
            `grid grid-cols-3 gap-3 mt-4 mobile:grid-cols-1${tenantSelected ? " hidden" : ""}`
          }>
            {
              allTenantUsers.map(tenant => {
                const {
                  id, avatarUrl, name, qualification, specialization, experience, 
                  city, languages, socialMedia = [], tenant: {name: tenantName = ""} = {}
                } = tenant;

                return (
                  <li
                    key={id}
                    className="bg-white relative tracking-wider border-b border-card-shadow rounded p-3 cursor-pointer"
                    onClick={() => {
                      setTenantSelected(true);
                      setSelectedTenant(tenant);
                    }}
                  >
                    <div className="flex items-center">
                      <div className="w-11 rounded bg-gray-light overflow-hidden">
                        <LazyLoadImage
                          className="h-11 m-auto"
                          alt={name}
                          placeholder={grey.default}
                          src={fixURL(false, id, avatarUrl, true)}
                          debounceDelay={100}
                        />
                      </div>
                      <div className="leading-tight grow ml-3 tracking-wider whitespace-nowrap">
                        <h3
                          className={`text-medium font-bold tracking-normal 
                          overflow-hidden text-ellipsis w-40 text-black`}
                          title={name}
                        >{name}</h3>
                        <div
                          className={`text-text-gray text-xs mt-1/2 
                          overflow-hidden text-ellipsis w-42`}
                        >{specialization}</div>
                        <span className="block text-xs mt-1/2 overflow-hidden text-ellipsis w-40">{tenantName}</span>
                      </div>
                    </div>
                    <div className="space-x-3 mt-3 font-medium text-xs">
                      <div className="uppercase">
                        <div className="text-text-gray">Qualification</div>
                        <div
                          className="text-sky mt-1 whitespace-nowrap overflow-hidden text-ellipsis"
                          title={qualification}
                        >{qualification}</div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-2 font-medium text-xs">
                      <div className="uppercase">
                        <div className="text-text-gray">City</div>
                        <div className="text-sky mt-1">{city}</div>
                      </div>
                      <div className="uppercase">
                        <div className="text-text-gray">Experience</div>
                        <div className="text-sky mt-1">{experience} {`year${experience > 1 ? "s" : ""}`}</div>
                      </div>
                    </div>
                    <div className="space-x-3 mt-2 font-medium text-xs">
                      <div className="uppercase">
                        <div className="text-text-gray">Languages</div>
                        <div className="text-sky mt-1 whitespace-nowrap">{
                          (languages || "").split(",").map(el => languageObject[el]).join(", ")
                        }</div>
                      </div>
                    </div>
                    <ul className="flex space-x-2 mt-2">
                    {
                      socialMedia.map(el => {
                        const {id, type, url} = el;
                        if(type === "website"){
                          return undefined;
                        }
                        return (
                          <li key={id}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              <img className="w-5" src={socialMediaImageMap[type]} alt="social"/>
                            </a>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </li>
                );
              })
            }
          </ul>

          {tenantSelected &&
            <div className="mt-6 leading-snug">
              <div className="bg-white relative tracking-wider border-b border-card-shadow rounded p-5">
                <div className="flex items-center mobile:flex-col-reverse">
                  <div className="grow pr-5 mobile:pr-0">
                    <h3
                      className="text-xl font-bold tracking-normal text-black"
                    >{selectedTenant.name}</h3>
                    <div className="text-text-gray mt-1">
                      {selectedTenant.specialization} - {selectedTenant.qualification}
                    </div>
                    <div className="grid grid-cols-2 gap-5 mt-3 mobile:grid-cols-1">
                      <div className="text-purple">
                        <div className="font-bold text-medium-l">About</div>
                        <div 
                          className="text-base mt-2 html-body"
                          dangerouslySetInnerHTML={{__html: selectedTenant.bio}}
                        />
                      </div>
                      <div className="font-medium text-xs pt-7 mobile:pt-0">
                        <div className="uppercase">
                          <div className="text-text-gray">Phone</div>
                          <div className="text-sky mt-1">{selectedTenant.phone}</div>
                        </div>
                        <div className="grid grid-cols-2 mt-2 font-medium text-xs">
                          <div className="uppercase">
                            <div className="text-text-gray">City</div>
                            <div className="text-sky mt-1">{selectedTenant.city}</div>
                          </div>
                          <div className="uppercase">
                            <div className="text-text-gray">Experience</div>
                            <div className="text-sky mt-1">
                              {selectedTenant.experience} {`year${selectedTenant.experience > 1 ? "s" : ""}`}
                            </div>
                          </div>
                        </div>
                        <div className="uppercase mt-2">
                          <div className="text-text-gray">Languages</div>
                          <div
                            className="text-sky mt-1"
                          >{
                            (selectedTenant.languages || "").split(",").map(el => languageObject[el]).join(", ")
                          }</div>
                        </div>
                        <ul className="flex space-x-2 mt-2">
                        {
                          selectedTenant.socialMedia && selectedTenant.socialMedia.map(el => {
                            const {id, type, url} = el;
                            if(type === "website"){
                              return undefined;
                            }
                            return (
                              <li key={id}>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                  <img className="w-5" src={socialMediaImageMap[type]} alt="social"/>
                                </a>
                              </li>
                            )
                          })
                        }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="min-w-38 mobile:pb-7">
                    <LazyLoadImage
                      className="w-38 h-auto rounded"
                      alt={selectedTenant.name}
                      placeholder={grey.default}
                      src={fixURL(false, selectedTenant.id, selectedTenant.avatarUrl, true)}
                      debounceDelay={100}
                    />
                    {bookNowLink && bookNowLink.url && 
                      <div className="mt-15 text-center mobile:mt-5">
                        <a
                          href={bookNowLink.url} target="_blank" 
                          className="block primary-btn" rel="noopener noreferrer"
                        >Book now</a>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div
                className="mt-5 cursor-pointer text-purple"
                onClick={() => {
                  setTenantSelected(false);
                  setSelectedTenant({});
                }}
              >&lt; Back to search results</div>
            </div>
          }
        </div>
        <div className="mt-40">
          <SaveLife
            link="https://share.hsforms.com/1sn9Yolz6R-mRbHS6NiVFnAe5ov6"
          />
          <Footer/>
        </div>
      </div>
    </div>
  )
};

export default OncologyNetwork;
