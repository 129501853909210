import React, {useState, useEffect, useCallback, Fragment} from "react";
import {Link, useParams} from "react-router-dom";

import {API_PATH} from "../../constant";
import {formatString, showAlert} from "../../utils";
import EventView from "./components/eventView";
import useRequest from "../../hooks/useRequest";
import ContextMenu from "../common/contextMenu";
import RecordView from "./components/recordView";
import SelectEvent from "./components/selectEvent";
import ConfirmDelete from "../common/confirmDelete";
import useContextMenu from "../../hooks/useContextMenu";
import CreateEditEvent from "./components/createEditEvent";
import {
  createTimelineData, createCreateEventPayload, createUpdateEventPayload
} from "./configs/timelineView.config";

const TimelineView = () => {
  const {patientId, caseId} = useParams();

  const [timelineData, setTimelineData] = useState([]);
  const [isEventEdit, setIsEventEdit] = useState(false);
  const [eventFormData, setEventFormData] = useState({});
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isEmptyEvent, setIsEmptyEvent] = useState(false);
  const [deleteRecordIndex, setDeleteRecordIndex] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDetachConfirm, setShowDetachConfirm] = useState(false);
  const [showCreateEditEvent, setShowCreateEditEvent] = useState(false);
  const [showEventDeleteConfirm, setShowEventDeleteConfirm] = useState(false);
  const [selectedUnattachedRecords, setSelectedUnattachedRecords] = useState([]);
  const [showSelectExistingEventModal, setShowSelectExistingEventModal] = useState(false);

  const {
    points: eventPoints, setPoints: setEventPoints,
    clicked: eventClicked, setClicked: setEventClicked
  } = useContextMenu();
  const {
    points: recordPoints, setPoints: setRecordPoints,
    clicked: recordClicked, setClicked: setRecordClicked
  } = useContextMenu();

  const {getAllReports, getAllEvents, eventDetails, getRecordsForEvent, specificRecord} = API_PATH;
  const {data: allReportData, loading: allReportLoading, callAPI: callReportAPI} = useRequest(
    formatString(getAllReports, [patientId])
  );
  const {data: eventData, loading: eventLoading, callAPI: callEventAPI} = useRequest(
    formatString(getAllEvents, [patientId])
  );
  useEffect(() => {
    if(allReportData && !allReportLoading && eventData && !eventLoading){
      const {result: events} = eventData;
      const {result: reports} = allReportData;
      const timelineData = createTimelineData(reports, events);
      setTimelineData(timelineData);
    }
  }, [allReportData, allReportLoading, eventData, eventLoading]);

  const createEventReqData = createCreateEventPayload(
    eventFormData, patientId, caseId, selectedUnattachedRecords, isEmptyEvent
  );
  const {
    data: createEventData, loading: createEventLoading, callAPI: createEventCallAPI
  } = useRequest(formatString(getAllEvents, [patientId]), false, createEventReqData, "POST");
  useEffect(() => {
    if(!createEventLoading && createEventData){
      setEventFormData({});
      setSelectedEvent({});
      setIsEventEdit(false);
      setIsEmptyEvent(false);
      setSelectedUnattachedRecords([]);
      callReportAPI();
      callEventAPI();
      showAlert("A event has been created successfully!");
    }
  }, [createEventData, createEventLoading, callReportAPI, callEventAPI]);

  const {id: eventId, name, records = []} = selectedEvent;
  const updateEventReqData = createUpdateEventPayload({
    ...selectedEvent,
    ...eventFormData,
  }, patientId, caseId, isEmptyEvent);
  const {
    data: updateEventData, loading: updateEventLoading, callAPI: updateEventCallAPI
  } = useRequest(formatString(eventDetails, [eventId]), false, updateEventReqData, "PUT");
  useEffect(() => {
    if(!updateEventLoading && updateEventData){
      setEventFormData({});
      setSelectedEvent({});
      setIsEventEdit(false);
      setIsEmptyEvent(false);
      setSelectedUnattachedRecords([]);
      callEventAPI();
      callReportAPI();
      showAlert("The event data has been updated successfully!");
    }
  }, [updateEventData, updateEventLoading, callEventAPI, callReportAPI]);

  const {
    data: recordData, loading: recordLoading, callAPI: recordCallAPI
  } = useRequest(formatString(getRecordsForEvent, [eventId]), false);
  useEffect(() => {
    if(!recordLoading && recordData && selectedUnattachedRecords.length){
      const {result = []} = recordData;

      setSelectedEvent((selectedEvent) => {
        const clonedSelectedEvent = {...selectedEvent};
        const {id, name, description} = clonedSelectedEvent;
        let date = new Date();
        let endDate = new Date(null);
        const records = [
          ...result,
          ...selectedUnattachedRecords
        ].map((record, index) => {
          const {date: rDate, vtype} = record;
          const parsedDate = new Date(rDate);
          if(parsedDate < date){
            date = parsedDate;
          }
          if(parsedDate > endDate){
            endDate = parsedDate;
          }
  
          if(vtype){
            delete record.vtype;
          }
  
          return {
            ...record,
            recordOrder: index,
            caseId,
            event: {
              id, name, description,
            }
          }
        });

        return {
          ...clonedSelectedEvent,
          records,
          date,
          endDate,
        }
      });
      setTimeout(() => {
        updateEventCallAPI();
      });
    }
  }, [caseId, selectedUnattachedRecords, recordData, recordLoading, updateEventCallAPI]);

  const {id: recordId} = selectedUnattachedRecords[deleteRecordIndex] || {};
  const {
    data: delRecordData, loading: delRecordLoading, callAPI: delRecordCallAPI
  } = useRequest(formatString(specificRecord, [recordId]), false, {}, "DELETE");
  const handleRecordDelete = useCallback(() => {
    if(deleteRecordIndex < selectedUnattachedRecords.length - 1){
      setDeleteRecordIndex(index => index + 1);
    }

    setTimeout(() => {
      delRecordCallAPI();
    });
  }, [deleteRecordIndex, selectedUnattachedRecords, delRecordCallAPI]);

  useEffect(() => {
    if(selectedUnattachedRecords.length && !delRecordLoading && delRecordData){
      console.log(deleteRecordIndex, selectedUnattachedRecords.length);
      if(deleteRecordIndex < selectedUnattachedRecords.length - 1){
        handleRecordDelete();
        return;
      }

      setSelectedUnattachedRecords([]);
      setShowDeleteConfirm(false);
      setDeleteRecordIndex(0);
      callReportAPI();
      callEventAPI();
    }
  }, [
    selectedUnattachedRecords, deleteRecordIndex, delRecordLoading, 
    delRecordData, handleRecordDelete, callReportAPI, callEventAPI
  ]);

  const {
    data: delEventData, loading: delEventLoading, callAPI: delEventCallAPI
  } = useRequest(formatString(eventDetails, [eventId]), false, {}, "DELETE");
  useEffect(() => {
    if(delEventData && !delEventLoading){
      setShowEventDeleteConfirm(false);
      showAlert("Event has been successfully deleted!");
      setEventFormData({});
      setSelectedEvent({});
      callReportAPI();
      callEventAPI();
    }
  }, [delEventData, delEventLoading, callReportAPI, callEventAPI]);

  const handleRecordDetach = (eventData, recordId) => {
    const clonedEventData = {...eventData};
    const {records} = clonedEventData;
    let date = new Date();
    let endDate = new Date(null);
    clonedEventData.records = records.filter(record => record.id !== recordId).map(record => {
      const {date: rDate} = record;
      const parsedDate = new Date(rDate);
      if(parsedDate < date){
        date = parsedDate;
      }

      if(parsedDate > endDate){
        endDate = parsedDate;
      }
      return record;
    });
    setSelectedEvent({
      ...clonedEventData,
      date,
      endDate
    });
    setShowDetachConfirm(true);
  };

  return (
    <Fragment>
      <div className="pb-4 text-right">
        <button
          className="primary-btn"
          type="button"
          onClick={() => {
            setEventFormData({});
            setSelectedEvent({});
            setIsEmptyEvent(true);
            setShowCreateEditEvent(true);
          }}
        >Create event</button>
      </div>
      {
        timelineData.map((data, index) => {
          const {vtype, id} = data;
          return (
            <Fragment key={id}>
              {vtype === "event" &&
                <EventView
                  isLast={index === timelineData.length - 1}
                  eventData={data}
                  setPoints={setEventPoints}
                  setClicked={setEventClicked}
                  setEventFormData={setEventFormData}
                  setSelectedEvent={setSelectedEvent}
                  handleRecordDetach={handleRecordDetach}
                />
              }
              {vtype === "record" &&
                <RecordView
                  isLast={index === timelineData.length - 1}
                  recordData={data}
                  setPoints={setRecordPoints}
                  setClicked={setRecordClicked}
                  selectedRecords={selectedUnattachedRecords}
                  selectRecord={setSelectedUnattachedRecords}
                />
              }
            </Fragment>
          )
        })
      }

      <Link
        className="text-purple-light hover:text-purple"
        to={`/case/${patientId}/${caseId}/`}
      >&lt; Return to case details</Link>

      {eventClicked && (
        <ContextMenu top={eventPoints.y} left={eventPoints.x}>
          <ul className="w-35 text-text-gray text-xs font-medium">
            <li 
              onClick={() => {
                setIsEmptyEvent(records.length === 0);
                setIsEventEdit(true);
                setShowCreateEditEvent(true);
              }}
              className="cursor-pointer py-1.5 px-3 hover:bg-gray-light"
            >Edit event</li>
            {name && records.length === 0 && (
              <li
                onClick={() => {
                  setShowEventDeleteConfirm(true);
                }}
                className="cursor-pointer py-1.5 px-3 hover:bg-gray-light"
              >Delete event</li>
            )}
          </ul>
        </ContextMenu>
      )}

      {recordClicked && selectedUnattachedRecords.length > 0 && (
        <ContextMenu top={recordPoints.y} left={recordPoints.x}>
          <ul className="w-35 text-text-gray text-xs font-medium">
            <li 
              onClick={() => {
                setEventFormData({});
                setIsEventEdit(false);
                setShowCreateEditEvent(true);
              }}
              className="cursor-pointer py-1.5 px-3 hover:bg-gray-light"
            >Create New Event</li>
            <li
              onClick={() => {
                setEventFormData({});
                setShowSelectExistingEventModal(true);
              }}
              className="cursor-pointer py-1.5 px-3 hover:bg-gray-light"
            >Add to Existing Event</li>
            <li
              onClick={() => {
                setDeleteRecordIndex(0);
                setShowDeleteConfirm(true);
              }}
              className="cursor-pointer py-1.5 px-3 hover:bg-gray-light"
            >Delete selected records</li>
          </ul>
        </ContextMenu>
      )}

      {showCreateEditEvent && 
        <CreateEditEvent
          isEdit={isEventEdit}
          formData={eventFormData}
          setFormData={setEventFormData}
          close={() => {
            setShowCreateEditEvent(false);
          }}
          callback={() => {
            if(isEventEdit){
              updateEventCallAPI();
              return;
            }

            createEventCallAPI();
          }}
          isEmptyEvent={isEmptyEvent}
        />
      }

      {showSelectExistingEventModal &&
        <SelectEvent
          eventData={timelineData}
          patientId={patientId}
          callback={(selectedEvent) => {
            setSelectedEvent(selectedEvent);
            setTimeout(() => {
              recordCallAPI();
            });
          }}
          close={() => {
            setShowSelectExistingEventModal(false);
          }}
        />
      }

      {(showDetachConfirm || showDeleteConfirm || showEventDeleteConfirm) &&
        <ConfirmDelete
          textToConfirm={showDeleteConfirm || showEventDeleteConfirm ? "delete" : "detach"}
          heading={showDeleteConfirm ? "Deleting selected records..." : (
            showEventDeleteConfirm ? "Deleting the selected event..." : "Removing record from event...")
          }
          desc={showDeleteConfirm || showEventDeleteConfirm ? `Are you sure, want to delete selected ${
            showEventDeleteConfirm ? "event" : "record(s)"
          }? Once deleted you will not be able to recover it.` : `Are you sure, you want to detach the record? 
          Once detached it will be available in timeline view as unattached record.`}
          onConfirm={() => {
            if(showDeleteConfirm){
              delRecordCallAPI();
              return;
            }
            if(showEventDeleteConfirm){
              delEventCallAPI();
              return;
            }
            updateEventCallAPI(); 
          }}
          close = {() => {
            if(showDeleteConfirm){
              setShowDeleteConfirm(false);
              return;
            }
            if(showEventDeleteConfirm){
              setShowEventDeleteConfirm(false);
              return;
            }
            setSelectedEvent({});
            setShowDetachConfirm(false);
          }}
        />
      }
    </Fragment>
  );
};

export default TimelineView;
