import {languages} from "../../../constant/languages";
import {getOptions} from "../../../constant/timezone";

export const formConfig = [{
  component: "file",
  name: "avatar",
  label: "Avatar",
  required: true,
  accept: "image/*"
}, {
  component: "dropdown",
  name: "timezone",
  label: "Timezone",
  required: true,
  className: "",
  data: getOptions()
}, {
  component: "textfield",
  name: "specialization",
  label: "Specialization",
  required: true
}, {
  component: "textfield",
  name: "qualification",
  label: "Qualification",
  required: true
}, {
  component: "textfield",
  name: "experience",
  label: "Experience (Years)",
  required: true,
  number: true
}, {
  component: "switch",
  name: "canBeLeader",
  label: "Leader",
  text: "Will this specialist be a leader?",
  required: true
}, {
  component: "paragraph",
  name: "language-desc",
  title: `You can add one or more languages spoken by the specialist. 
  The first one will be considered as primarily spoken language.`
}, {
  component: "add-item",
  name: "languages",
  title: "Languages",
  required: true,
  limit: 5,
  children: [{
    component: "dropdown",
    name: "languages-1",
    label: "Language",
    required: true,
    data: languages
  }]
}, {
  component: "paragraph",
  name: "bio-desc",
  title: "Bio is basically a snapshot of what the specialist does, who he/she is and how he/she meets patient's needs."
}, {
  component: "textarea",
  name: "bio",
  label: "Bio",
  required: true,
  className: "mt-2"
}, {
  component: "paragraph",
  name: "social-desc",
  title: "Fill out the below fields if the specialist has their social presence."
}, {
  component: "textfield",
  name: "linkedin",
  label: "LinkedIn"
}, {
  component: "textfield",
  name: "instagram",
  label: "Instagram"
}, {
  component: "textfield",
  name: "facebook",
  label: "Facebook"
}, {
  component: "textfield",
  name: "twitter",
  label: "Twitter"
}, {
  component: "textfield",
  name: "youtube",
  label: "Youtube"
}, {
  component: "textfield",
  name: "website",
  label: "Website"
}];

export const formSchema = {
  experience: [{
    condition: value => value <= 0,
    message: "Experience should be greater than 0."
  }, {
    condition: value => value >= 60,
    message: "Experience should not be greater than 60."
  }]
};
