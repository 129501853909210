import React, {useState, useRef, useCallback, useEffect} from "react";

import ProgressRing from "./progress";
import uploadImg from "../../assets/upload.svg";

const FileUpload = (props) => {
  const {
    acf, isMultiple, selectableFileCount = 100, progress, callback, loading
  } = props;

  const dropArea = useRef();
  const [text, setText] = useState("Drag and drop or click to upload");

  useEffect(() => {
    if(progress >= 100 && loading){
      setText('Processing...');
      return;
    }

    if(!loading && progress === 100){
      setText('Sucessfully uploaded! Click here to upload more.');
    }
  }, [progress, loading]);

  const acceptedFormats = (acf || "application/pdf, image/png, image/jpg, image/jpeg");

  const handleSelectedFile = useCallback((files) => {
    if(files.length > selectableFileCount){
      setText(`You can choose maximum ${selectableFileCount} files at once!`);
      return;
    }

    const allFileFormat = Array.from(files).reduce((data, el) => {
      data.add(el.type);
      return data;
    }, new Set());
    const isValidFormat = [...allFileFormat].every(el => acceptedFormats.includes(el));
    if(!isValidFormat){
      setText("Uploaded file format should be .PDF, .PNG, .JPG or .JPEG!");
      return;
    }
    
    setText(isMultiple && files.length > 1 ? `${files.length} files selected` : files[0].name);
    callback(files);
  }, [selectableFileCount, isMultiple, acceptedFormats, callback]);

  const handleFile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(e.type === "dragenter" || e.type === "dragover"){
      dropArea.current.classList.add("highlight");
      return;
    }
    
    if(e.type === "dragleave" || e.type === "drop"){
      dropArea.current.classList.remove("highlight");
    }

    if(e.type === "drop"){
      const dt = e.dataTransfer;
      const {files} = dt;
      handleSelectedFile(files);
    }
  };

  const handleManualSelect = (e) => {
    if(e && e.target && e.target.files && e.target.files.length > 0){
      handleSelectedFile(e.target.files);
      return;
    }

    setText("Drag and drop or click to upload");
  };

  return (
    <div
      id="drag-drop-file-area"
      ref={dropArea}
      onDragEnter={handleFile}
      onDragOver={handleFile}
      onDragLeave={handleFile}
      onDrop={handleFile}
    >
      <form className="file-upload" encType="multipart/form-data">
        <label className="upload-area">
          <div className="flex flex-wrap flex-row items-center h-full">
            <div className="grow self-auto text-center px-2">
              {progress === 0 && <img src={uploadImg} alt="upload" className="w-14 block mx-auto mb-1"/>}
              {progress !== 0 && 
                <ProgressRing
                  stroke="5"
                  radius="45"
                  progress={progress}
                />
              }
              <span className="font-medium text-text-gray">{text}</span>
              <input
                type="file"
                name="fileupload"
                className="hidden"
                accept={acceptedFormats}
                onChange={(e) => handleManualSelect(e)}
                onClick={() => handleManualSelect(false)}
                multiple={isMultiple}
              />
            </div>
          </div>
        </label>
      </form>
    </div>
  )
};

export default FileUpload;
