import React from "react";

const Stats = () => {
  const stats = [{
    bg: "pink",
    fill: "purple",
    perc: 96,
    text: `of ASCO survey respondents found Molecular Tumour Boards (MTB) 
    beneficial for patients and of a high educational value.`
  }, {
    bg: "deep-purple",
    fill: "purple-light",
    perc: 40,
    text: `of treatment plans have changed after 
    consulting with MTBs`
  }, {
    bg: "deep-sky",
    fill: "deep-purple",
    perc: 80,
    text: `of oncologists not routinely participating 
    in any molecular tumour boards`
  }, {
    bg: "pink",
    fill: "deep-purple",
    perc: 5,
    text: `nonacademic hospitals have access to an MTB.`
  }];

  return (
    <section className="py-30">
      <h2 className="text-purple-l font-bold text-3xl pb-10 mobile:pb-6">By the numbers</h2>
      <div className="grid grid-cols-4 justify-center mobile:grid-cols-1 mobile:px-4">
        {
          stats.map((el, index) => {
            const {bg, fill, perc, text} = el;
            const rotate = perc * 1.8;

            return (
              <div
                className={`font-bold text-medium text-center${
                    index !== stats.length - 1 ? " border-sky-light border-r-2" : ""
                  }${index !== 0 ? " border-sky-light border-l-2 mobile:pt-6" : ""
                } mobile:border-b-4 mobile:border-l-0 mobile:border-r-0`}
                key={index}
              >
                <div className="pb-6 flex h-full flex-col">
                  <div className={`circle-wrap bg-${bg}`}>
                    <div className="circle text-white">
                      <div
                        className="mask full"
                        style={{transform: `rotate(${rotate}deg)`}}
                      >
                        <div
                          className={`fill bg-${fill}`}
                          style={{transform: `rotate(${rotate}deg)`}}
                        />
                      </div>
                      <div className="mask half">
                        <div
                          className={`fill bg-${fill}`}
                          style={{transform: `rotate(${rotate}deg)`}}
                        />
                      </div>
                      <div
                        className={
                          `w-26 h-26 rounded-full text-center mt-2 ml-2 
                          text-max-xxl absolute z-50 flex items-center justify-center`
                        }
                      >
                        {perc}%
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={`w-22 h-22 bg-${bg} rounded-full mx-auto circle-wrap`}
                  >
                    <div className="relative text-white">
                      <div
                        className={`w-22 h-22 absolute rounded-full mask full`}
                        style={{transform: `rotate(${rotate}deg)`}}
                      >
                        <div
                          className={`w-22 h-22 absolute rounded-full bg-${fill} fill`}
                          style={{transform: `rotate(${rotate}deg)`}}
                        />
                      </div>
                      <div className="w-22 h-22 absolute rounded-full mask half">
                        <div
                          className={`w-22 h-22 absolute rounded-full bg-${fill} fill`}
                          style={{transform: `rotate(${rotate}deg)`}}
                        />
                      </div>
                      <div
                        className={
                          `w-18 h-18 rounded-full text-center mt-2 ml-2 
                          text-max-xxl absolute z-50 flex items-center justify-center`
                        }
                      >
                        {perc}%
                      </div>
                    </div>
                  </div> */}
                  <div
                    className="mx-auto leading-tight mt-6 text-purple w-38 mobile:w-full"
                  >{text}</div>
                </div>
                {/* <div className="text-gray">Source &gt;</div> */}
              </div>
            )
          })
        }
      </div>
    </section>
  );
};

export default Stats;
