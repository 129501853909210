import React, {useState, useEffect, useRef} from "react";
import {useNavigate, Link} from "react-router-dom";

import {showAlert} from "../utils";
import {API_PATH} from "../constant";
import Head from "../components/head";
import useRequest from "../hooks/useRequest";
import Form from "../components/common/form";
import {ReactComponent as Logo} from "../assets/logo.svg";
import {getConfig, getSchema} from "./configs/forgot-password.config";

const ForgotPassword = () => {
  const form = useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailNotificationSent, setEmailNotificationSent] = useState(false);

  const {forgotPassword} = API_PATH;
  const {email, username, newPassword, verificationCode} = formData;
  const {data, loading, error, errorMessage, callAPI} = useRequest(forgotPassword, false, {
    action: !emailNotificationSent ? "forgot_pwd" : "confirm_forgot_pwd",
    ...(emailNotificationSent ? {
      username,
      verificationCode,
      newPassword
    } : {email})
  }, "POST");
  useEffect(() => {
    if(!loading && data && data.result && !error){
      const {username, pwdChanged} = data.result;
      if(pwdChanged){
        showAlert("Password has been reset successfully!");
        navigate("/login/");
        return;
      }

      form.current.updateFormData({username});
      showAlert("Verification code has been sent to your email successfully!");
      setEmailNotificationSent(true);
    }
  }, [data, loading, error, navigate]);

  useEffect(() => {
    if(!loading && error && errorMessage){
      const messageToShow = errorMessage.includes("(") ? errorMessage.split("(")[0] : errorMessage;
      showAlert(messageToShow, "error");
    }
  }, [loading, error, errorMessage]);

  return (
    <div className="w-full h-screen dotted-background">
      <Head title={"Forgot Password"}/>
      <div className="flex h-full w-full items-center justify-center mobile:px-3">
        <div className="bg-white border border-gray w-72 rounded px-5 py-6 mobile:w-full mobile:px-4">
          <Link to="/">
            <Logo
              className="fill-white-purple m-auto"
            />
          </Link>
          <p
            className="mt-4 text-text-gray mx-auto leading-tight"
          >Enter your email that you used to register your account, 
          so we can send you the verification code to reset your password.</p>
          <div className="mt-4">
            <Form
              ref={form}
              config={getConfig(emailNotificationSent)}
              initialValues={formData}
              onFormValid={(isValid) => {
                setIsFormValid(isValid);
              }}
              onFormUpdate={(data) => {
                setFormData(data)
              }}
              schema={getSchema(emailNotificationSent)}
              onSubmit={callAPI}
            />
          </div>

          <div className="my-3">
            <button 
              className="h-7 py-1 text-center rounded font-medium text-xs 
              bg-purple-light text-white disabled:opacity-50 w-full" 
              onClick={() => {
                callAPI();
              }}
              disabled={!isFormValid}
            >{emailNotificationSent ? "Change Password" : "Send Verification Code"}</button>
          </div>
          <div className="text-center mb-1">
            <Link
              to="/login/"
              className="text-xs text-purple hover:text-purple-light"
            >&lt; Back to login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
