import React, {useEffect, useState} from "react";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";

const ManageUsers = () => {
  const {loading: contextLoading} = useContextData();

  return (
    <div className="w-full">
      <Head/>
      <Header/>
      {!contextLoading && 
        <section className="pt-10 pb-10 dotted-background min-h-screen">

        </section>
      }
    </div>
  )
};

export default ManageUsers;
