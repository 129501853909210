import Done from "../../components/createConsultingDoctor/done";
import Summary from "../../components/createConsultingDoctor/summary";
import SelectTenant from "../../components/createConsultingDoctor/selectTenant";
import PersonalDetails from "../../components/createConsultingDoctor/personalDetails";
import ProfessionalDetails from "../../components/createConsultingDoctor/professionalDetails";

export const workflowSteps = [{
  id: "select-tenant",
  title: "Select Tenant",
  description: "Please select tenant under whom this consulting doctor will be created",
  component: SelectTenant,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "personal-details",
  title: "Personal Details",
  description: "Fill out the personal details of the doctor to create Consulting Doctor.",
  component: PersonalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "professional-details",
  title: "Professional Details",
  description: "Fill out the professional details of the doctor to create Consulting Doctor.",
  component: ProfessionalDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "summary",
  title: "Summary",
  description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Create Consulting Doctor.`,
  component: Summary,
  layout: {
    primary: {
      label: "Create Consulting Doctor"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "done",
  title: "Done!",
  description: "The consulting doctor is being created.",
  component: Done,
  layout: {
    footer: false
  }
}];
