import Done from "../../components/createTenant/done";
import Summary from "../../components/createTenant/summary";
import TenantDetails from "../../components/createTenant/tenantDetails";

export const workflowSteps = [{
  id: "tenant-details",
  title: "Tenant Details",
  description: "Fill out the tenant details to create a tenant.",
  component: TenantDetails,
  layout: {
    primary: {
      label: "Next"
    },
    secondary: {
      hidden: true
    }
  }
}, {
  id: "summary",
  title: "Summary",
  description: `Review all data you entered in this workflow. If necessary, click edit to go back and change 
    any data. When you are satisfied with the data, click Create Tenant.`,
  component: Summary,
  layout: {
    primary: {
      label: "Create Tenant"
    },
    secondary: {
      label: "Back"
    }
  }
}, {
  id: "done",
  title: "Done!",
  description: "The tenant is being created.",
  component: Done,
  layout: {
    footer: false
  }
}];