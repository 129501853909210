import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {API_PATH} from "../constant";
import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import {formatString} from "../utils";


import Head from "../components/head";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

import {getWorkflowConfig, createStepData} from "./configs/edit-case.config";

const EditCase = (props) => {
  const navigate = useNavigate();

  const {loading: contextLoading} = useContextData();
  const steps = getWorkflowConfig();

  const [stepData, setStepData] = useState({});
  const [patientId, setPatientId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const {caseId} = useParams();
  const {caseDetails} = API_PATH;
  const {
    loading: selectedCaseLoading, data: selectedCaseData
  } = useRequest(formatString(caseDetails, [caseId]));
  useEffect(() => {
    if(!contextLoading && !selectedCaseLoading && selectedCaseData && selectedCaseData.result){
      const {result} = selectedCaseData;
      const {patient} = result;
      setPatientId(patient.id);
      const stepData = createStepData(result);
      setStepData(stepData);
      setIsLoaded(true);
    }
  }, [contextLoading, selectedCaseLoading, selectedCaseData]);

  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading && isLoaded &&
        <Workflow
          {...props}
          isEdit={true}
          initStepData={stepData}
          steps={steps}
          onExit={() => {
            navigate(`/case/${patientId}/${caseId}/`);
          }}
        />
      }
    </div>
  )
};

export default EditCase;
