import React from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import Workflow from "../components/common/workflow";

import {workflowSteps} from "./configs/add-event.config";

const AddEvent = (props) => {
  const navigate = useNavigate();
  const {loading: contextLoading} = useContextData();
  const {patientId, caseId} = useParams();

  return (
    <div className="w-full text-base dotted-background">
      <Head/>
      <Header/>
      {!contextLoading &&
        <Workflow
          {...props}
          steps={workflowSteps}
          onExit={() => {
            navigate(`/case/${patientId}/${caseId}/`);
          }}
        />
      }
    </div>
  );
};

export default AddEvent;
