import React, {useState, Fragment} from "react";
import DatePicker from "react-datepicker";

const CustomDate = (props) => {
  const {
    name, multiColumn, className, required, label, password, format="dd/MM/yyyy", validationObj, 
    touchedFieldObj, formData, setTouchedFieldObj, onUpdate, tailComponent, change, maxDate = new Date()
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      id={name}
      className={`flex flex-col items-start justify-start relative h-13 ${
        multiColumn ? "w-68" : ""
      }${className ? ` ${className}` : ""}`}
    >
      <div className="flex flex-row items-center">
        <div className={`input-group${isFocused ? " focused" : ""}${formData[name] ? " has-value" : ""}`}>
          <DatePicker
            dateFormat={format}
            showMonthDropdown
            showYearDropdown
            className={`${
              validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] && required ? "has-error" : ""
            } ${
              formData[name] ? "has-value" : ""
            }`}
            type={password ? "password" : "text"}
            name={name}
            id={`input-${name}`}
            required={required}
            maxDate={maxDate}
            onChange={(value) => {
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }));
              
              if(change && change instanceof Function) {
                const dataToSet = change(value, formData);
                onUpdate({
                  ...formData,
                  ...dataToSet
                });
                return;
              }
              onUpdate({
                ...formData,
                [name]: value
              });
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
              setTouchedFieldObj(tobj => ({
                ...tobj, 
                [name]: true
              }))
            }}
            selected={formData[name] ? new Date(formData[name]) : ""}
          />
          <label className="form-label" htmlFor={`input-${name}`}>
            {label}
            {required && <span className="text-pink-deep">*</span>}
          </label>
          {required && validationObj[name] && !validationObj[name].isValid && touchedFieldObj[name] &&
            <small 
              className="text-pink-deep block pt-1 pr-0.5 text-right"
            >{validationObj[name].message}</small>
          }
        </div>
        {tailComponent && 
          <Fragment>
            {tailComponent}
          </Fragment>
        }
      </div>
    </div>
  )
};

export default CustomDate;
