import React from "react";
import moment from "moment";

import DotMenu from "../../common/dotMenu";
import RecordType from "../../common/recordType";
import {getRecordDotMenu} from "../configs/analyzeRecords.config";

const EventView = (props) => {
  const {
    isLast, eventData = {}, handleRecordDetach, setPoints, setClicked, setSelectedEvent, setEventFormData
  } = props;
  const {date, endDate, name, description, type, records, visible = true} = eventData;
  const dateStr = moment(date).format("MMM DD, YYYY");
  const endDteStr = moment(endDate).format("MMM DD, YYYY");
  const dateToShow = (dateStr === endDteStr) ? dateStr : `${dateStr} - ${endDteStr}`;

  return (
    <div className="flex">
      <div className={`border-r ${!isLast ? "border-gray" : "border-white"} w-50 relative`}>
        {dateToShow}
        <span
          className="bg-sky w-4 h-4 -mr-px absolute -top-0.5 -right-2 rounded-full border-2 border-white"
          onContextMenu={(e) => {
            e.preventDefault();
            setEventFormData({
              name, type, description, date, visible
            });
            setSelectedEvent(eventData);
            setClicked(true);
            setPoints({
              x: e.pageX,
              y: e.pageY,
            });
          }}
        />
      </div>
      <div className="grow pb-4 ml-5 relative">
        <h3 className="text-base font-extrabold">{name}</h3>
        <div className="my-2 text-text-gray leading-snug" dangerouslySetInnerHTML={{__html: description}}/>
        <ul className="flex items-start">
          {
            records.map(record => {
              const {id, fileType, url, patientId} = record;
              return (
                <div key={id} className="relative mr-2">
                  <RecordType
                    fileType={fileType}
                    patientId={patientId}
                    url={url}
                    showPopupView={true}
                  />
                  <div className="absolute right-px top-px z-10">
                    <DotMenu
                      items={
                        getRecordDotMenu(handleRecordDetach, eventData, id)
                      }
                    />
                  </div>
                </div>
              );
            })
          }
        </ul>
      </div>
    </div>
  )
};

export default EventView;
