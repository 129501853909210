import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {usePlacesWidget} from "react-google-autocomplete";

import Head from "../components/head";
import {useContextData} from "../store";
import Header from "../components/header";
import useRequest from "../hooks/useRequest";
import useDebounce from "../hooks/useDebounce";
import DotMenu from "../components/common/dotMenu";
import ConfirmDelete from "../components/common/confirmDelete";

import {formatString, showAlert} from "../utils";
import {API_PATH, GOOGLE_MAP_API_KEY} from "../constant";
import {getDotMenu} from "./configs/manage-tenants.config";

import cross from "../assets/cross.svg";
import search from "../assets/search.svg";

const ManageTenants = () => {
  const {loading: contextLoading} = useContextData();
  
  const [typedName, setTypedName] = useState("");
  const [allTenants, setAllTenants] = useState([]);
  const [headerObject, setHeaderObject] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [intendedAction, setIntendedAction] = useState("");
  const [selectedTenant, setSelectedTenant] = useState("");

  const {getAllTenants, getSpecificTenants} = API_PATH;
  const {
    data: tenantData, loading: tenantLoading, callAPI: callTenantAPI
  } = useRequest(getAllTenants, false, {}, "GET", {...headerObject});
  useEffect(() => {
    if(!tenantLoading && tenantData){
      const {result = []} = tenantData;
      setAllTenants(result);
    }
  }, [tenantData, tenantLoading]);

  useEffect(() => {
    const allFilters = [];
    if(typedName){
      allFilters.push(`name:*${typedName}*`);
    }

    if(selectedCity){
      allFilters.push(`city:${selectedCity.toLowerCase()}*`);
    }

    setHeaderObject(allFilters.length ? {
      "X-OM-FILTER": allFilters.map(el => `(${el})`).join(" AND ")
    } : {});
  }, [typedName, selectedCity]);

  const debouncedFunc = useDebounce(callTenantAPI);
  useEffect(() => {
    debouncedFunc();
  }, [headerObject, debouncedFunc]);

  const {data: deleteData, loading: deleteLoading, callAPI: deleteCallAPI} = useRequest(
    formatString(getSpecificTenants, [selectedTenant]), false, {}, "DELETE"
  );
  useEffect(() => {
    if(deleteData && !deleteLoading){
      showAlert("The tenant has been successfully deleted.");
      setAllTenants([]);
      setTimeout(() => {
        callTenantAPI();
      });
    }
  }, [deleteData, deleteLoading, callTenantAPI]);

  const callBack = (id, intend) => {
    setSelectedTenant(id);
    setIntendedAction(intend);
  };

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      const {address_components} = place;
      const city = address_components.filter(el => el.types.includes("locality"));
      if(city.length > 0) {
        const [{long_name}] = city;
        setSelectedCity(long_name);
      }
    }
  });

  return (
    <div className="w-full">
      <Head/>
      <Header/>
      <section className="pt-10 pb-10 dotted-background min-h-screen">
        <div className="px-4 py-2 flex justify-end">
          <div className="flex py-1 mr-2 bg-list-bg rounded items-center w-50">
            <div className="pl-2">
              <img src={search} alt="search" className="w-5"/>
            </div>
            <div className="w-full">
              <input
                type="text"
                value={typedName}
                className="h-5 w-full px-2 outline-none bg-transparent"
                placeholder="Search by name..."
                onChange={e => setTypedName(e.target.value)}
              />
            </div>
          </div>
          <div className="mr-2 w-50 relative">
            <input
              type="text"
              className="h-7 px-2 py-1 w-full outline-none bg-list-bg rounded"
              placeholder="Search by city..."
              ref={ref}
            />
            {selectedCity && 
              <div 
                className={`w-7 absolute top-0 bottom-0 right-0 
                cursor-pointer flex items-center justify-center`}
                onClick={() => {
                  if(ref && ref.current){
                    ref.current.value = "";
                    setSelectedCity("");
                  }
                }}
              >
                <img src={cross} alt="remove city"/>
              </div>
            }
          </div>
          <Link
            to={"/create-tenant/tenant-details/"}
            className="primary-btn"
          >Create New Tenant</Link>
        </div>
        
        {!contextLoading && 
          <div className="m-auto max-w-screen-md">
            <ul className="grid grid-cols-2 gap-4">
              {
                allTenants.map(tenant => {
                  const {id, name, email, phone, city, state, country} = tenant;

                  return (
                    <li
                      key={id}
                      className="bg-white relative border-b border-card-shadow rounded p-3"
                    >
                      <div className="absolute right-1 top-1 z-10">
                        <DotMenu
                          items={getDotMenu(callBack, id)}
                        />
                      </div>
                      <div className="tracking-wider leading-tight">
                        <h3
                          className={`text-medium font-bold tracking-normal whitespace-nowrap 
                          overflow-hidden text-ellipsis w-50 text-black`}
                        >{name}</h3>
                        <div className="text-text-gray text-xs mt-1">{city || "NA"}, {state || "NA"}, {country || "NA"}</div>
                        <span className="block text-xs mt-1">{email}</span>
                        <span className="text-sky-blue block text-xs mt-1">{phone}</span>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }

        {selectedTenant && intendedAction === "delete" && 
          <ConfirmDelete
            heading="Deleting tenant..."
            onConfirm={() => {
              deleteCallAPI();
            }}
            close={() => {
              setSelectedTenant("");
              setIntendedAction("")
            }}
          />
        }
      </section>
    </div>
  )
};

export default ManageTenants