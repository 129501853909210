import React, {useState, useEffect, useCallback, Fragment} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";

import ConfirmExit from "./confirmExit";
import {ReactComponent as Logout} from "../../assets/logout.svg";

const Workflow = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {steps, initStepData = {}, onExit, isEdit = false} = props;
  
  const {currentStep} = useParams();
  const stepId = currentStep ? currentStep : steps[0].id;
  const stepIndex = steps.findIndex(el => el.id === stepId);
  
  const [labelObject, setLabelObject] = useState({});
  const [confirmExit, setConfirmExit] = useState(false);
  const [stepState, setStepState] = useState(initStepData);
  const [stepValidationMap, setStepValidationMap] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex);

  const {
    id, title, description, component: Component, layout = {}
  } = currentStepIndex > -1 ? steps[currentStepIndex] : {};
  const {footer = true, primary = {}, secondary = {}} = layout;
  const {label: pLabel, hidden: pHidden = false} = primary;
  const {label: sLabel, hidden: sHidden = false} = secondary;

  // on component render create step validatio object
  useState(() => {
    const stepValidationMap = steps.reduce((data, el) => {
      const {id} = el;
      data[id] = isEdit;
      return data;
    }, {});
    setStepValidationMap(stepValidationMap);
  }, [isEdit]);

  useEffect(() => {
    const {pathname} = location;
    if(id && !pathname.includes(`/${id}`)){
      const splitPath = pathname.split("/").filter(el => !!el);
      splitPath.pop();
      splitPath.push(id);
      navigate(`/${splitPath.join("/")}/`);
    }
  }, [id, location, navigate]);

  // Validate step on page load or on step update
  useEffect(() => {
    if(currentStepIndex === -1){
      setCurrentStepIndex(0);
      return;
    }

    const index = steps.findIndex(el => !stepValidationMap[el.id]);
    if(index > -1 && currentStepIndex > index){
      setCurrentStepIndex(index);
    }
  }, [steps, currentStepIndex, stepValidationMap]);

  const validateStep = useCallback((stepId, isValid) => {
    setStepValidationMap(validMap => {
      return {
        ...validMap,
        [stepId]: isValid
      };
    });
  }, []);

  const onStepUpdate = useCallback((stepData) => {
    setStepState(sData => {
      return {
        ...sData,
        [id]: stepData
      };
    });
  }, [id]);

  return (
    <section className="pt-10 min-h-screen bg-white">
      {currentStepIndex > -1 && 
        <Fragment>
          <div className="fixed left-0 top-10 w-full h-1 bg-gray z-20">
            <div
              className="bg-purple-light h-1 transition-width duration-500"
              style={{width: `${(100/steps.length) * (currentStepIndex + 1)}%`}}
            />
          </div>
          <div className="max-w-screen-xl mx-auto pt-10 px-4 pb-15">
            <h2 className="text-xl font-bold">{title}</h2>
            <p
              className="mt-2 text-base text-text-gray max-w-screen-sm leading-snug"
            >{description}</p>
            <div className="pt-5">
              <Component
                stepId={id}
                goto={(stepId) => {
                  const index = steps.findIndex(el => el.id === stepId);
                  if(index > -1){
                    setCurrentStepIndex(index);
                  }
                }}
                getState={() => {
                  return stepState;
                }}
                getSteps={() => {
                  return steps;
                }}
                getLabels={() => {
                  return labelObject;
                }}
                stepData={stepState[id]}
                onStepUpdate={onStepUpdate}
                onValidate={validateStep}
                setLabelObject={setLabelObject}
              />
            </div>
          </div>

          {footer &&
            <div
              className="fixed bottom-0 left-0 w-full h-13 bg-white footer-shadow z-20"
            >
              <div className="px-5 flex items-center justify-between h-full">
                <button className="flex items-center text-base text-purple-light" onClick={() => {
                  setConfirmExit(true)
                }}>
                  <Logout className="w-3 h-3 mr-1.5"/>
                  Exit
                </button>
      
                <div className="flex items-center">
                  {!sHidden && 
                    <button
                      className="secondary-btn mr-5"
                      onClick={() => {
                        setCurrentStepIndex(currentStepIndex - 1);
                      }}
                    >{sLabel}</button>
                  }
                  {!pHidden && 
                    <button
                      className="primary-btn"
                      disabled={!stepValidationMap[id]}
                      onClick={() => {
                        setCurrentStepIndex(currentStepIndex + 1);
                      }}
                    >{pLabel}</button>
                  }
                </div>
              </div>
            </div>
          }

          {confirmExit && 
            <ConfirmExit
              close={() => {
                setConfirmExit(false);
              }}
              onConfirm={onExit}
            />
          }
        </Fragment>
      }
    </section>
  )
};

export default Workflow;
