import React, {useState} from "react";
import Form from "../../common/form";
import {createEventFormConfig} from "../configs/timelineView.config";

const CreateEditEvent = (props) => {
  const {callback, close, formData, setFormData, isEdit, isEmptyEvent} = props;
  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <div className="bg-black/50 z-30 fixed top-0 left-0 w-full h-full flex justify-center">
      <div className="w-110 bg-white rounded relative m-auto px-5 py-4">
        <h3 className="text-medium font-bold">Event details</h3>
        <div className="mt-3">
          <Form
            config={createEventFormConfig(isEmptyEvent, isEdit)}
            initialValues={formData}
            multiColumn={true}
            onFormValid={(isFormValid) => {
              setIsFormValid(isFormValid);
            }}
            onFormUpdate={(formData) => {
              setFormData(formData);
            }}
          />
        </div>

        <div className="flex items-center justify-center mt-1">
          <button
            className="mr-4 text-purple-light font-medium text-xs"
            onClick={close}
          >Cancel</button>
          <button
            className="primary-btn"
            disabled={!isFormValid}
            onClick={() => {
              callback();
              close();
            }}
          >{`${isEdit ? "Edit" : "Create"} event`}</button>
        </div>
      </div>
    </div>
  );
};

export default CreateEditEvent;
