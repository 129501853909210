import React, {useEffect, useState, Fragment} from "react";

import {API_PATH} from "../../constant";
import {formatString, showAlert} from "../../utils";
import useRequest from "../../hooks/useRequest";
import {DefaultEditor} from "react-simple-wysiwyg";
import RichEditorModal from "../common/richEditorModal";

import enlarge from "../../assets/enlarge.png";

const Note = (props) => {
  const {
    title, id, type, speciaListId, note:noteBody, callback
  } = props;

  const [note, setNote] = useState("");
  const [noteId, setNoteId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {saveNote, updateSpecialistNote} = API_PATH;
  const {data: noteData, loading: noteLoading, callAPI: callNoteAPI} = useRequest((
    noteId ? formatString(updateSpecialistNote, [noteId]) : formatString(saveNote, [type, id])
  ), false, {
    ...(noteId ? noteBody : {}),
    description: note
  }, noteId ? "PATCH" : "POST");
  useEffect(() => {
    if(noteData && !noteLoading) {
      callback();
      showAlert("Note has been saved successfully!");
    }
  }, [noteData, noteLoading, callback]);

  useEffect(() => {
    const {description = "", specialist, id:nId} = noteBody || {};
    if(specialist && specialist.id !== speciaListId){
      setNote("");
      setNoteId("");
      return;
    }

    setNote(description);
    setNoteId(nId);
  }, [noteBody, type, id, speciaListId]);

  return (
    <Fragment>
      <div className="flex justify-between mb-2">
        <div className="uppercase">{title}</div>
        <button
          className="uppercase text-sky font-bold tracking-wider disabled:opacity-50"
          disabled={!note || noteLoading}
          onClick={() => callNoteAPI()}
        >
          Save
        </button>
      </div>
      <div className="relative fixed-height">
        <button
          type="button"
          className="rsw-btn absolute top-px right-px rounded"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <img src={enlarge} alt="enlarge" className="w-3 m-auto"/>
        </button>
        <DefaultEditor
          value={note}
          onChange={(e) => {
            setNote(e.target.value)
          }}
        />
      </div>
      {showModal && 
        <RichEditorModal
          text={note}
          callback={setNote}
          close={() => {
            setShowModal(false);
          }}
        />
      }
    </Fragment>
  );
};

export default Note;
