import React from "react";

import Head from "../components/head";
import Footer from "../components/landing/footer";
import Header from "../components/landing/header";
import SaveLife from "../components/landing/save-live";

import shrini from "../assets/team/shrini.jpeg";
import vishwajit from "../assets/team/vishwajit.jpeg";
import srilatha from "../assets/team/srilatha.jpeg";
import aishwarya from "../assets/team/aishwarya.jpg";
import shikha from "../assets/team/shikha.jpg";
import vijay from "../assets/team/Vijay-Jagannathan.jpeg"
// import ashwin from "../assets/team/ashwin.jpeg";

// import nagesh from "../assets/team/nagesh.jpg";
// import swaroop from "../assets/team/swaroop.jpg";
// import santhosh from "../assets/team/santhosh.png";
// import vishwanath from "../assets/team/vishwanath.jpeg";
// import anilKamath from "../assets/team/anil-kamath.jpeg";
// import govindrajan from "../assets/team/govindarajan.jpeg";

import advisor1 from "../assets/team/advisor-1.jpg";
import advisor2 from "../assets/team/advisor-2.jpg";
import advisor3 from "../assets/team/advisor-3.jpg";
// import advisor4 from "../assets/team/advisor-4.jpg";
// import advisor5 from "../assets/team/advisor-5.jpg";
import advisor6 from "../assets/team/advisor-6.jpg";
import advisor7 from "../assets/team/advisor-7.jpg";
import advisor8 from "../assets/team/advisor-8.jpeg";
// import advisor9 from "../assets/team/advisor-9.jpeg";
import advisor10 from "../assets/team/advisor-10.jpg";
import advisor11 from "../assets/team/advisor-11.jpeg";
// import advisor12 from "../assets/team/advisor-12.jpg";
import advisor13 from "../assets/team/advisor-13.jpeg";
import advisor14 from "../assets/team/advisor-14.jpg";
import advisor15 from "../assets/team/advisor-15.jpg";
import advisor16 from "../assets/team/advisor-16.jpg";
import advisor18 from "../assets/team/advisor-18.jpg";
import advisor19 from "../assets/team/advisor-19.jpg";
import advisor20 from "../assets/team/advisor-20.jpg";

const AboutUs = () => {
  const executiveTeam = [{
    pic: shrini,
    name: "Srini Srinivasan",
    designation: "Chief Mentor",
    bio: `Srini Srinivasan brings with him over 30 years of experience in 
    global leadership roles at Pfizer, Novartis, GSK, and AstraZeneca.`
  }, {
    pic: vishwajit,
    name: "Vishwajit Kamat",
    designation: "Chief Operating Officer",
    bio: `Vishwajit brings over 30 years of experience in the Indian 
    pharmaceutical industry with key roles leading commercial operations, 
    sales and business development.`
  }, {
    pic: srilatha,
    name: "Dr. Srilatha Vishwanath",
    designation: "Head, Clinical Operations",
    bio: `Dr. Srilatha Vishwanath has several years of experience in Clinical 
    care. She has done her MBBS, MS in Ophthalmology and a Postdoctoral 
    Research from the Baylor College of Medicine.`
  }, {
    pic: vijay,
    name: "Vijay Jagannathan",
    designation: "Vice President, Business Development & Operations",
    bio: `Vijay leverages 23 years of global team-building experience in his role as VP of 
    Business Development and Operations. His expertise in fostering partnerships and driving 
    operational excellence propels our mission to transform global oncology`
  }, {
    pic: shikha,
    name: "Dr. Shikha Singh, Ph. D",
    designation: "Technology",
    bio: `Shikha is an AI researcher passionate about developing products and solutions for 
    Healthcare using AI/ML/IA technologies. Her experience during her PhD at IIT Madras involves 
    working with expert systems as well as machine learning models.`
  }, {
    pic: aishwarya,
    name: "Dr. Aishwarya Bhargav, Ph. D",
    designation: "Customer Experience",
    bio: `Aishwarya is an Engineer and an Entrepreneur, and is passionate about Healthcare. 
    She has completed her PhD from the National University of Singapore (NUS), Masters in 
    Biomedical Engineering from Nanyang Technological University (NTU).`
  }
  // , {
  //   pic: ashwin,
  //   name: "Ashwin Prakash",
  //   designation: "Engineering",
  //   bio: `Ashwin Prakash has worked with startups in hardware and software building MVPs and bringing 
  //   products to market. He has a BSE in Biomedical Engineering from Duke University.`
  // }
  ];

  // const medicalTeam = [{
  //   pic: vishwanath,
  //   name: "Dr. Vishwanath Sathyanarayanan",
  //   designation: `Dr. (Prof.) Vishwanath Sathyanarayanan is a senior medical oncologist in Bangalore with a 
  //   fellowship from the University of Texas, MD Anderson Cancer Center in Houston, TX, USA.`
  // }, {
  //   pic: santhosh,
  //   name: "Dr. Santhosh Kumar Devadas",
  //   designation: `Dr. Santhosh Kumar Devadas has extensive experience in the field of 
  //   Medical Oncology and Bone marrow transplantation. He heads the Center of Hematology 
  //   and Bone marrow transplant (CHBMT) at Ramaiah Hospitals.`
  // }, {
  //   pic: swaroop,
  //   name: "Dr. Swaroop Revannasiddaiah",
  //   designation: `Dr. Swaroop is a seasoned oncologist with dual qualifications in 
  //   radiation oncology and medical oncology. He subsequently obtained qualifications 
  //   of MRCP-SCE-Medical Oncology from the Royal College of Physicians, UK and DM-Medical 
  //   Oncology from RGUHS, Bangalore.`
  // }, {
  //   pic: nagesh,
  //   name: "Dr. Nagesh Taterao Sirsath",
  //   designation: `Dr. Nagesh Taterao Sirsath did his DM medical oncology from 
  //   Kidwai Memorial Institute of Oncology, Banglore. Working as senior consultant in Medical 
  //   oncology at M N Budhrani cancer institute, Inlaks and Budhrani Hospital for last 8 years. 
  //   He is Involved in DNB training programmes and actively involved in various research activities.`
  // }, {
  //   pic: anilKamath,
  //   name: "Dr. Anil Kamath",
  //   designation: `Dr. Anil Kamath is consultant Surgical Oncologist at Apollo Hospital, Bangalore.
  //   He has done his MBBS from Mysore Medical College and MS from St Johns Medical College Bangalore. 
  //   He is well trained to perform all type of cancer surgeries including head and neck, breast, 
  //   gastrointestinal and gynecologic cancers.`
  // }, {
  //   pic: govindrajan,
  //   name: "Dr. Govindarajan MJ",
  //   designation: `Experienced Senior Consultant Radiologist has lead entire departments, both radiology and 
  //   oncoimaging. His expertise is in Fetal medicine, Ultrasound, Doppler, Mammography, breast imaging, X-Ray, CT, MRI, 
  //   PET CT, interventions, teleradiology and radiology medical devices and equipments`
  // }];

  const advisors = [{
    pic: advisor1,
    name: "Dr. Frederick B. Hagemeister, MD",
    designation: `Senior professor in the department of lymphoma and myeloma at the University of Texas, 
    MD Anderson Cancer Center.`
  }, {
    pic: advisor8,
    name: "Dr. Manmeet Ahluwalia, MD, MBA",
    designation: `Chief of solid tumor medical oncology, deputy director and chief scientific officer 
    at Miami Cancer Institute. As a neuro-oncologist, Dr. Ahluwalia's primary areas of research and 
    patient care are primary tumors and brain metastases.`
  }, {
    pic: advisor6,
    name: "Dr. Neeta Somaiah, MD",
    designation: `Associate Professor of Sarcoma Medical Oncology and serves as the Deputy Chair of Sarcoma 
    Medical Oncology at The University of Texas MD Anderson Cancer Center in Houston, Texas.`
  }, {
    pic: advisor20,
    name: "Dr. Jason Westin, MD",
    designation: `Dr. Jason Westin is the Director of Lymphoma Clinical Research Program and Section Chief of 
    Aggressive Lymphoma research team at the University of Texas MD Anderson Cancer Center in the 
    Department of Lymphoma and Myeloma.`
  }, {
    pic: advisor11,
    name: "Dr. Saurabh Dahiya, MD, FACP",
    designation: `Associate Professor at Stanford University School of Medicine and Clinical Director 
    of Cancer Cell Therapy in the Stanford BMT and Cell Therapy division. Dr. Dahiya's research 
    focuses on cellular immunotherapy for hematologic malignancies.`
  }, {
    pic: advisor18,
    name: "Dr. Kanwal P.S. Raghav, MBBS, MD",
    designation: `Associate Professor, Department of Gastrointestinal (GI) Medical Oncology, 
    Division of Cancer Medicine, The University of Texas MD Anderson Cancer Center, Houston, TX.`
  }, {
    pic: advisor19,
    name: "Dr. Shirish M Gadgeel, MD",
    designation: `Dr. Shirish Gadgeel is a lung cancer specialist who joined the Henry Ford Cancer Institute in 2020, 
    bringing with him more than 20 years of experience in clinical research.Gadgeel treats a variety of 
    thoracic malignancies.`
  }, {
    pic: advisor10,
    name: "Dr. Shilpa Gupta, MD",
    designation: `Director of the Genitourinary Medical Oncology at Taussig Cancer Institute and 
    Co-Leader of the Genitourinary Oncology Program at Cleveland Clinic.`
  }, {
    pic: advisor7,
    name: "Dr. Aparna Kamat, MD",
    designation: `Associate Professor of Clinical Obstetrics and Gynecology, Academic Institute
    Houston Methodist Weill Cornell Medical College.`
  }, {
    pic: advisor15,
    name: "Dr. Jameel Muzaffar, MD",
    designation: `Associate Professor and the Clinical Director of the Head and 
    Neck Medical Oncology program at the Duke University and the Duke Cancer Institute. He specializes in head, 
    neck and endocrine cancers and his interest lies in developing new therapy options for patients with 
    advanced stage cancers and to bring innovative trials for the benefit of these patients.`
  }, {
    pic: advisor16,
    name: "Dr. Praveen Vikas, MD",
    designation: `Associate Professor with University Of Iowa. Dr. Vikas is highly 
    rated in 13 conditions. His top areas of expertise are Breast Cancer, Paget Disease of the Breast, Angiosarcoma 
    and Inflammatory Breast Cancer. His clinical research consists of co-authoring 22 peer reviewed articles and 
    participating in 17 clinical trials in the past 15 years.`
  }, {
    pic: advisor14,
    name: "Dr. Davendra P. Sohal, MD",
    designation: `Oncologist in Cincinnati, Ohio and is affiliated with 
    University of Cincinnati Medical Center. He specializes in clinical care and research in gastrointestinal 
    malignancies and precision oncology. He received his medical degree from All India Institute of Medical 
    Sciences and has been in practice for more than 20 years.`
  }, {
    pic: advisor2,
    name: "Dr. Rashmi Kanagal Shamanna, MD",
    designation: `Tenured hematopathologist, molecular genetic pathologist and the director of 
    the Clinical Microarray Facility in the CLIA-certified Molecular Diagnostic Laboratory.`
  }, {
    pic: advisor3,
    name: "Dr. Manjunath Ramarao, Ph.D",
    designation: `Chief Scientific Officer, Ribometrix Inc; Previous roles at Bristol Myers Squibb; 
    Wyeth (Pfizer); Harvard Medical School. Has over 20 years of drug discovery and development 
    experience across multiple therapeutic areas.`
  }, {
    pic: advisor13,
    name: "Dr. Vivek Jayaswal",
    designation: `The Head of Artificial Intelligence at Ribometrix, Inc and has extensive experience in 
    applied statistics and computer science to transform data into actionable insights. He has led data 
    science teams at two startups and a pharmaceutical research center. His research includes development 
    of novel models, algorithms for Geometric Deep Learning and Natural Language Understanding.`
  }];

  return (
    <div className="bg-bg-color w-full">
      <Head title={"About us"}/>
      <Header selected={"about-us"}/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <div className="pt-15 leading-snug text-medium-l text-purple font-medium max-w-screen-sm mobile:pt-10">
          True to its name, Oncominds is an ecosystem of top notch Oncology minds. Oncominds brings 
          together a network of oncologists, physicians, surgeons, radiologists and other multidisciplinary 
          specialists across the globe facilitating comprehensive consultations.
        </div>
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-10">
          Executive Team
        </h1>
        <div className="mt-7 grid grid-cols-2 gap-x-2 gap-y-5 mobile:grid-cols-1 mobile:gap-x-0">
          {
            executiveTeam.map((team, index) => {
              const {pic, name, bio, designation} = team;

              return (
                <div key={name + index} className="flex items-start justify-between">
                  <img src={pic} className="w-26 rounded" alt={name}/>
                  <div className="px-3 leading-snug text-purple">
                    <h3 className="font-bold text-medium-l">{name}</h3>
                    <div className="mt-1 text-purple font-medium">{designation}</div>
                    <div className="mt-1">{bio}</div>
                  </div>
                </div>
              );
            })
          }
        </div>

        {/* <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Medical Team
        </h1>
        <div className="mt-7 grid grid-cols-2 gap-x-2 gap-y-5 mobile:grid-cols-1 mobile:gap-x-0">
          {
            medicalTeam.map((team, index) => {
              const {pic, name, designation} = team;

              return (
                <div key={name + index} className="flex items-start justify-between">
                  <img src={pic} className="w-26 rounded" alt={name}/>
                  <div className="px-3 leading-snug text-purple">
                    <h3 className="font-bold text-medium-l">{name}</h3>
                    <div className="mt-1">
                      {designation}
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div> */}
        
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Advisors
        </h1>
        <div className="pt-5 leading-snug text-medium-l text-purple font-medium max-w-screen-sm">
          Oncominds is blessed to have access to an extremely talented and experienced team of 
          renowned oncology professionals. The list below touches major cancer areas and helps us stay 
          connected and abreast with the latest in the field.
        </div>
        <div className="mt-7 grid grid-cols-2 gap-x-2 gap-y-5 mobile:grid-cols-1 mobile:gap-x-0">
          {
            advisors.map((team, index) => {
              const {pic, name, designation} = team;

              return (
                <div key={name + index} className="flex items-start justify-between">
                  <img src={pic} className="w-26 rounded" alt={name}/>
                  <div className="px-3 leading-snug text-purple">
                    <h3 className="font-bold text-medium-l">{name}</h3>
                    <div className="mt-1">
                      {designation}
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <h1 className="font-bold text-purple-light text-max-xxxl mobile:text-max-xl mt-15">
          Partner with us
        </h1>
        <div className="pt-5 leading-snug text-medium-l text-purple font-medium max-w-screen-sm">
          Oncominds seeks to build collaborations that will help deliver better cancer care 
          outcomes for our patients.
          <div className="mt-4">
            <b>United States</b> - Oncominds, Inc. (HQ)
            #1 Chestnut Hill Plaza #1171, Newark, Delaware 19713
          </div>
          <div className="mt-4 bold">
            <b>India</b> - Oncominds Solutions Private Limited
            #13/14, 2nd Cross, 7th Phase, J P Nagar, Navodaya Nagar, Bangalore 560078
          </div>
        </div>
        <div className="pt-20">
          <SaveLife
            heading="Submit a case today"
            linkText="Reach us"
            link="https://share.hsforms.com/1sn9Yolz6R-mRbHS6NiVFnAe5ov6"
          />
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
