import React from "react";

const SaveLife = (props) => {
  const {link, heading = "", linkText = "Contact us"} = props;

  return (
    <section className="text-center font-bold pt-4 pb-30 mobile:px-4">
      <h3
        className="text-purple-light text-max-xxxl mobile:text-max-xl"
      >{heading || "Let's save lives together"}</h3>
      <p className="mt-3 text-3xl">
        <a
          href={link ? link : "mailto:contact@oncominds.com"}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray"
        >{linkText}</a>
      </p>
    </section>
  );
};

export default SaveLife;
