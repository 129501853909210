import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {API_PATH} from "../constant";
import {formatString} from "../utils";
import {useContextData} from "../store";
import useRequest from "../hooks/useRequest";
import {createStepData, workflowSteps} from "./configs/edit-tenant.config";

import Head from "../components/head";
import Header from "../components/header";
import Workflow from "../components/common/workflow";


const EditTenant = (props) => {
  const navigate = useNavigate();
  const {loading: contextLoading} = useContextData();

  const [stepData, setStepData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  
  const {tenantId} = useParams();
  const {getSpecificTenants} = API_PATH;
  const {
    loading: tenantLoading, data: tenantData
  } = useRequest(formatString(getSpecificTenants, [tenantId]));
  useEffect(() => {
    if(!tenantLoading && tenantData && tenantData.result){
      const {result} = tenantData;
      const stepData = createStepData(result);
      setStepData(stepData);
      setIsLoaded(true);
    }
  }, [tenantLoading, tenantData]);

  return (
    <div className="w-full text-base dotted-background">
    <Head/>
    <Header/>
    {!contextLoading && isLoaded &&
      <Workflow
        {...props}
        isEdit={true}
        initStepData={stepData}
        steps={workflowSteps}
        onExit={() => {
          navigate("/manage-tenants/");
        }}
      />
    }
  </div>
  )
};

export default EditTenant;