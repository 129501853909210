import React from "react";

import Head from "../components/head";
import Hero from "../components/landing/hero";
import Stats from "../components/landing/stats";
import Header from "../components/landing/header";
import Banner from "../components/landing/banner";
import Footer from "../components/landing/footer";
import Aspects from "../components/landing/aspects";
import SaveLife from "../components/landing/save-live";
import Infographics from "../components/landing/infographics";

import service from "../assets/service.svg";
import personalized from "../assets/personalized.svg";
import professionals from "../assets/professionals.svg";

const Landing = () => {
  const heroData = [{
    bg: "bg-purple-light",
    img: personalized,
    link: "/",
    heading: `Reimagine precision oncology.`,
    subtitle: ""
  }, {
    bg: "bg-deep-sky",
    img: professionals,
    link: "/",
    heading: `Access world renowned experts.`,
    subtitle: ""
  }, {
    bg: "bg-pink",
    img: service,
    link: "/",
    heading: `Enable Educational webinars and tumor boards.`,
    subtitle: ""
  }];

  return (
    <div className="bg-bg-color w-full">
      <Head/>
      <Header/>
      <div className="max-w-screen-lg mx-auto mobile:px-3">
        <Banner
          background="bg-deep-purple"
          bannerText={"The best oncology minds in one place."}
        />
        <Hero
          heroData={heroData}
        />
        <Infographics/>
        <Aspects/>
        <Stats/>
        <SaveLife
          heading="Submit a case today"
          linkText="Reach us"
          link="https://share.hsforms.com/1sn9Yolz6R-mRbHS6NiVFnAe5ov6"
        />
        <Footer/>
      </div>
    </div>
  )
};

export default Landing;
