import React from "react";

const ContextMenu = (props) => {
  const {top, left, children} = props;
  
  return (
    <div
      style={{top, left}}
      className="absolute bg-white z-20 rounded border border-gray/50"
    >
      {children}
    </div>
  );
};

export default ContextMenu;
