import edit from "../../assets/edit.png";
import remove from "../../assets/delete.png";

export const getDotMenu = (callback, specialistId) => {
  return [{
    icon: edit,
    type: "link",
    name: "Edit specialist",
    to: `/edit-specialist/${specialistId}/personal-details/`
  }, {
    icon: remove,
    name: "Delete specialist",
    click: () => {
      callback(specialistId, "delete");
    }
  }];
};