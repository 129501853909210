export const cancerType = [{
  label: "Adenoid Cystic Carcinoma",
  value: "adenoid_cystic_carcinoma"
}, {
  label: "Adrenal Gland Tumor",
  value: "adrenal_gland_tumor"
}, {
  label: "Amyloidosis",
  value: "amyloidosis"
}, {
  label: "Anal Cancer",
  value: "anal_cancer"
}, {
  label: "Appendix Cancer",
  value: "appendix_cancer"
}, {
  label: "Astrocytoma - Childhood",
  value: "astrocytoma_childhood"
}, {
  label: "Ataxia-Telangiectasia",
  value: "ataxia_telangiectasia"
}, {
  label: "Beckwith-Wiedemann Syndrome",
  value: "beckwith_wiedemann_syndrome"
}, {
  label: "Bile Duct Cancer (Cholangiocarcinoma)",
  value: "bile_duct_cancer_cholangiocarcinoma"
}, {
  label: "Birt-Hogg-Dubé Syndrome",
  value: "birt_hogg_dubé_syndrome"
}, {
  label: "Bladder Cancer",
  value: "bladder_cancer"
}, {
  label: "Bone Cancer (Sarcoma of Bone)",
  value: "bone_cancer_sarcoma_of_bone"
}, {
  label: "Brain Stem Glioma - Childhood",
  value: "brain_stem_glioma_childhood"
}, {
  label: "Brain Tumor",
  value: "brain_tumor"
}, {
  label: "Breast Cancer",
  value: "breast_cancer"
}, {
  label: "Breast Cancer - Inflammatory",
  value: "breast_cancer_inflammatory"
}, {
  label: "Breast Cancer - Metastatic",
  value: "breast_cancer_metastatic"
}, {
  label: "Breast Cancer, Male",
  value: "breast_cancer_male"
}, {
  label: "Carney Complex",
  value: "carney_complex"
}, {
  label: "Central Nervous System Tumors (Brain and Spinal Cord) - Childhood",
  value: "central_nervous_system_tumors_brain_and_spinal_cord_childhood"
}, {
  label: "Cervical Cancer",
  value: "cervical_cancer"
}, {
  label: "Childhood Cancer",
  value: "childhood_cancer"
}, {
  label: "Colorectal Cancer",
  value: "colorectal_cancer"
}, {
  label: "Cowden Syndrome",
  value: "cowden_syndrome"
}, {
  label: "Craniopharyngioma - Childhood",
  value: "craniopharyngioma_childhood"
}, {
  label: "Desmoid Tumor",
  value: "desmoid_tumor"
}, {
  label: "Desmoplastic Infantile Ganglioglioma, Childhood Tumor",
  value: "desmoplastic_infantile_ganglioglioma_childhood_tumor"
}, {
  label: "Ependymoma - Childhood",
  value: "ependymoma_childhood"
}, {
  label: "Esophageal Cancer",
  value: "esophageal_cancer"
}, {
  label: "Ewing Sarcoma - Childhood and Adolescence",
  value: "ewing_sarcoma_childhood_and_adolescence"
}, {
  label: "Eye Cancer",
  value: "eye_cancer"
}, {
  label: "Eyelid Cancer",
  value: "eyelid_cancer"
}, {
  label: "Familial Adenomatous Polyposis",
  value: "familial_adenomatous_polyposis"
}, {
  label: "Familial GIST",
  value: "familial_gist"
}, {
  label: "Familial Malignant Melanoma",
  value: "familial_malignant_melanoma"
}, {
  label: "Familial Pancreatic Cancer",
  value: "familial_pancreatic_cancer"
}, {
  label: "Gallbladder Cancer",
  value: "gallbladder_cancer"
}, {
  label: "Gastrointestinal Stromal Tumor - GIST",
  value: "gastrointestinal_stromal_tumor_gist"
}, {
  label: "Germ Cell Tumor - Childhood",
  value: "germ_cell_tumor_childhood"
}, {
  label: "Gestational Trophoblastic Disease",
  value: "gestational_trophoblastic_disease"
}, {
  label: "Head and Neck Cancer",
  value: "head_and_neck_cancer"
}, {
  label: "Hereditary Breast and Ovarian Cancer",
  value: "hereditary_breast_and_ovarian_cancer"
}, {
  label: "Hereditary Diffuse Gastric Cancer",
  value: "hereditary_diffuse_gastric_cancer"
}, {
  label: "Hereditary Leiomyomatosis and Renal Cell Cancer",
  value: "hereditary_leiomyomatosis_and_renal_cell_cancer"
}, {
  label: "Hereditary Mixed Polyposis Syndrome",
  value: "hereditary_mixed_polyposis_syndrome"
}, {
  label: "Hereditary Pancreatitis",
  value: "hereditary_pancreatitis"
}, {
  label: "Hereditary Papillary Renal Carcinoma",
  value: "hereditary_papillary_renal_carcinoma"
}, {
  label: "HIV/AIDS_Related Cancer",
  value: "hiv/aids_related_cancer"
}, {
  label: "Juvenile Polyposis Syndrome",
  value: "juvenile_polyposis_syndrome"
}, {
  label: "Kidney Cancer",
  value: "kidney_cancer"
}, {
  label: "Lacrimal Gland Tumor",
  value: "lacrimal_gland_tumor"
}, {
  label: "Laryngeal and Hypopharyngeal Cancer",
  value: "laryngeal_and_hypopharyngeal_cancer"
}, {
  label: "Leukemia - Acute Lymphoblastic - ALL - Childhood",
  value: "leukemia_acute_lymphoblastic_all_childhood"
}, {
  label: "Leukemia - Acute Lymphocytic - ALL",
  value: "leukemia_acute_lymphocytic_all"
}, {
  label: "Leukemia - Acute Myeloid - AML",
  value: "leukemia_acute_myeloid_aml"
}, {
  label: "Leukemia - Acute Myeloid - AML - Childhood",
  value: "leukemia_acute_myeloid_aml_childhood"
}, {
  label: "Leukemia - B_cell Prolymphocytic Leukemia and Hairy Cell Leukemia",
  value: "leukemia_b_cell_prolymphocytic_leukemia_and_hairy_cell_leukemia"
}, {
  label: "Leukemia - Chronic Lymphocytic - CLL",
  value: "leukemia_chronic_lymphocytic_cll"
}, {
  label: "Leukemia - Chronic Myeloid - CML",
  value: "leukemia_chronic_myeloid_cml"
}, {
  label: "Leukemia - Chronic T_Cell Lymphocytic",
  value: "leukemia_chronic_t_cell_lymphocytic"
}, {
  label: "Leukemia - Eosinophilic",
  value: "leukemia_eosinophilic"
}, {
  label: "Li_Fraumeni Syndrome",
  value: "li_fraumeni_syndrome"
}, {
  label: "Liver Cancer",
  value: "liver_cancer"
}, {
  label: "Lung Cancer - Non Small Cell",
  value: "lung_cancer_non_small_cell"
}, {
  label: "Lung Cancer - Small Cell",
  value: "lung_cancer_small_cell"
}, {
  label: "Lymphoma - Hodgkin",
  value: "lymphoma_hodgkin"
}, {
  label: "Lymphoma - Hodgkin - Childhood",
  value: "lymphoma_hodgkin_childhood"
}, {
  label: "Lymphoma - Non Hodgkin",
  value: "lymphoma_non_hodgkin"
}, {
  label: "Lymphoma - Non Hodgkin - Childhood",
  value: "lymphoma_non_hodgkin_childhood"
}, {
  label: "Lynch Syndrome",
  value: "lynch_syndrome"
}, {
  label: "Mastocytosis",
  value: "mastocytosis"
}, {
  label: "Medulloblastoma - Childhood",
  value: "medulloblastoma_childhood"
}, {
  label: "Melanoma",
  value: "melanoma"
}, {
  label: "Meningioma",
  value: "meningioma"
}, {
  label: "Mesothelioma",
  value: "mesothelioma"
}, {
  label: "Multiple Endocrine Neoplasia Type 1",
  value: "multiple_endocrine_neoplasia_type_1"
}, {
  label: "Multiple Endocrine Neoplasia Type 2",
  value: "multiple_endocrine_neoplasia_type_2"
}, {
  label: "Multiple Myeloma",
  value: "multiple_myeloma"
}, {
  label: "MUTYH (or MYH)_Associated Polyposis",
  value: "mutyh_or_myh_associated_polyposis"
}, {
  label: "Myelodysplastic Syndromes - MDS",
  value: "myelodysplastic_syndromes_mds"
}, {
  label: "Nasal Cavity and Paranasal Sinus Cancer",
  value: "nasal_cavity_and_paranasal_sinus_cancer"
}, {
  label: "Nasopharyngeal Cancer",
  value: "nasopharyngeal_cancer"
}, {
  label: "Neuroblastoma - Childhood",
  value: "neuroblastoma_childhood"
}, {
  label: "Neuroendocrine Tumor of the Gastrointestinal Tract",
  value: "neuroendocrine_tumor_of_the_gastrointestinal_tract"
}, {
  label: "Neuroendocrine Tumor of the Lung",
  value: "neuroendocrine_tumor_of_the_lung"
}, {
  label: "Neuroendocrine Tumor of the Pancreas",
  value: "neuroendocrine_tumor_of_the_pancreas"
}, {
  label: "Neuroendocrine Tumors",
  value: "neuroendocrine_tumors"
}, {
  label: "Neurofibromatosis Type 1",
  value: "neurofibromatosis_type_1"
}, {
  label: "Neurofibromatosis Type 2",
  value: "neurofibromatosis_type_2"
}, {
  label: "Nevoid Basal Cell Carcinoma Syndrome",
  value: "nevoid_basal_cell_carcinoma_syndrome"
}, {
  label: "Oral and Oropharyngeal Cancer",
  value: "oral_and_oropharyngeal_cancer"
}, {
  label: "Osteosarcoma - Childhood and Adolescence",
  value: "osteosarcoma_childhood_and_adolescence"
}, {
  label: "Ovarian, Fallopian Tube, and Peritoneal Cancer",
  value: "ovarian_fallopian_tube_and_peritoneal_cancer"
}, {
  label: "Pancreatic Cancer",
  value: "pancreatic_cancer"
}, {
  label: "Parathyroid Cancer",
  value: "parathyroid_cancer"
}, {
  label: "Penile Cancer",
  value: "penile_cancer"
}, {
  label: "Peutz_Jeghers Syndrome",
  value: "peutz_jeghers_syndrome"
}, {
  label: "Pheochromocytoma and Paraganglioma",
  value: "pheochromocytoma_and_paraganglioma"
}, {
  label: "Pituitary Gland Tumor",
  value: "pituitary_gland_tumor"
}, {
  label: "Pleuropulmonary Blastoma - Childhood",
  value: "pleuropulmonary_blastoma_childhood"
}, {
  label: "Prostate Cancer",
  value: "prostate_cancer"
}, {
  label: "Retinoblastoma - Childhood",
  value: "retinoblastoma_childhood"
}, {
  label: "Rhabdomyosarcoma - Childhood",
  value: "rhabdomyosarcoma_childhood"
}, {
  label: "Salivary Gland Cancer",
  value: "salivary_gland_cancer"
}, {
  label: "Sarcoma - Kaposi",
  value: "sarcoma_kaposi"
}, {
  label: "Sarcomas, Soft Tissue",
  value: "sarcomas_soft_tissue"
}, {
  label: "Skin Cancer (Non-Melanoma)",
  value: "skin_cancer_non_melanoma"
}, {
  label: "Small Bowel Cancer",
  value: "small_bowel_cancer"
}, {
  label: "Stomach Cancer",
  value: "stomach_cancer"
}, {
  label: "Testicular Cancer",
  value: "testicular_cancer"
}, {
  label: "Thymoma and Thymic Carcinoma",
  value: "thymoma_and_thymic_carcinoma"
}, {
  label: "Thyroid Cancer",
  value: "thyroid_cancer"
}, {
  label: "Tuberous Sclerosis Complex",
  value: "tuberous_sclerosis_complex"
}, {
  label: "Unknown Primary",
  value: "unknown_primary"
}, {
  label: "Uterine Cancer",
  value: "uterine_cancer"
}, {
  label: "Vaginal Cancer",
  value: "vaginal_cancer"
}, {
  label: "Von Hippel_Lindau Syndrome",
  value: "von_hippel_lindau_syndrome"
}, {
  label: "Vulvar Cancer",
  value: "vulvar_cancer"
}, {
  label: "Waldenstrom Macroglobulinemia (Lymphoplasmacytic Lymphoma)",
  value: "waldenstrom_macroglobulinemia_lymphoplasmacytic_lymphoma"
}, {
  label: "Werner Syndrome",
  value: "werner_syndrome"
}, {
  label: "Wilms Tumor - Childhood",
  value: "wilms_tumor_childhood"
}, {
  label: "Xeroderma Pigmentosum",
  value: "xeroderma_pigmentosum"
}, {
  label: "Unknown",
  value: "unknown"
}];

export const cancerNameMap = cancerType.reduce((data, el) => {
  const {label, value} = el;
  data[value] = label;
  return data;
}, {});

export const cancerStage = [{
  label: "Stage I",
  value: "stage_1"
}, {
  label: "Stage II",
  value: "stage_2"
}, {
  label: "Stage III",
  value: "stage_3"
}, {
  label: "Stage IV",
  value: "stage_4"
}, {
  label: "Unknown",
  value: "unknown"
}];

export const cancerStageMap = cancerStage.reduce((data, el) => {
  const {label, value} = el;
  data[value] = label;
  return data;
}, {});
