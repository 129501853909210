export const getValidationObj = (isValid, message) => {
  const commonMessage = "This field is required.";
  if(isValid) {
    return {
      isValid,
      message: ""
    };
  }

  return {
    isValid,
    message: message || commonMessage
  };
};

export const schemaValidate = (value, schema, formData) => {
  if(schema){
    for(let i = 0; i < schema.length; i++){
      const {condition, message} = schema[i];
      const isInvalid = condition(value, formData);
      if(isInvalid){
        return {
          isValid: !isInvalid, message
        };
      }
    }
  }
  return {isValid: true, message: ""}
};

export const validateForm = (formData, keyConfig, schema = {}) => {
  return [...Object.keys(keyConfig)].reduce((data, key) => {
    const value = formData[key];
    const {component, required, regex, message} = keyConfig[key];
    if(!required){
      if(schema && schema[key] && component !== "add-item"){
        const {isValid, message} = schemaValidate(value, schema[key], formData);
        data[key] = getValidationObj(isValid, message);
        return data;
      }
      data[key] = getValidationObj(true);
      return data;
    }

    if(component !== "title" && component !== "paragraph"){
      if(schema && schema[key]){
        if(component === "add-item" && value){
          for(let i = 0; i < value.length; i++){
            const {isValid, message} = schemaValidate(value[i], schema[key], formData);
            if(!isValid){
              data[key] = getValidationObj(isValid, message);
              return data;
            }
          }

          data[key] = getValidationObj(true);;
          return data;
        }

        const {isValid, message} = schemaValidate(value, schema[key], formData);
        data[key] = getValidationObj(isValid, message);;
        return data;
      }
      
      const isValid = regex !== undefined ? regex.test(value) : value !== "";
      data[key] = getValidationObj(isValid, message);
    }
    return data;
  }, {});
};
