import React, {useState, useEffect} from "react";
import {API_PATH} from "../../constant";
import {formatString, showAlert} from "../../utils";
import useRequest from "../../hooks/useRequest";
import { DefaultEditor } from "react-simple-wysiwyg";
import RichEditorModal from "../common/richEditorModal";

import enlarge from "../../assets/enlarge.png";

const CaseSummary = (props) => {
  const {summary: psummary, caseId, setCaseDetails} = props;
  const [summary, setSummary] = useState(psummary);
  const [showModal, setShowModal] = useState(false);
  
  const {caseDetails} = API_PATH;
  const {
    loading: caseLoading, data: caseData, callAPI: callCaseAPI, error: caseError, errorMessage: caseErrorMessage
  } = useRequest(
    formatString(caseDetails, [caseId]), false, {
      summary
    }, "PATCH"
  );
  useEffect(() => {
    if(!caseLoading && caseData) {
      showAlert("Case summary has been updated successfully.");
      setCaseDetails(caseDetails => {
        return {
          ...caseDetails,
          summary
        }
      });
    }
  }, [caseLoading, caseData, summary, setCaseDetails]);

  useEffect(() => {
    if(caseError && caseErrorMessage) {
      showAlert(caseErrorMessage, "error");
    }
  }, [caseError, caseErrorMessage]);

  return (
    <div className="bg-white mt-3 p-3 rounded tracking-wider border-b border-card-shadow">
      <div className="flex justify-between mb-2 text-xs font-medium">
        <div className="uppercase">Case summary (Further Management Options)</div>
        <button
          className="uppercase text-sky font-bold tracking-wider disabled:opacity-50"
          disabled={!summary || caseLoading}
          onClick={() => callCaseAPI()}
        >
          Save
        </button>
      </div>
      <div className="relative fixed-height">
        <button
          type="button"
          className="rsw-btn absolute top-px right-px rounded"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <img src={enlarge} alt="enlarge" className="w-3 m-auto"/>
        </button>
        <DefaultEditor
          value={summary}
          onChange={(e) => {
            setSummary(e.target.value)
          }}
        />
      </div>

      {showModal && 
        <RichEditorModal
          text={summary}
          callback={setSummary}
          close={() => {
            setShowModal(false);
          }}
        />
      }
    </div>
  )
};

export default CaseSummary;
