import React from "react";
import {Helmet} from "react-helmet";

// Importing favicon icons
import favIco from "../assets/favicons/favicon.ico";
import favIco180 from "../assets/favicons/apple-touch-icon.png";
import favIco32 from "../assets/favicons/favicon-32x32.png";
import favIco16 from "../assets/favicons/favicon-16x16.png";

const Head = (props) => {
  const themeColor = "#321E5A";
  const author = "ONCOMINDS";
  const companyName = "Oncominds";
  const description = `Oncominds is a Virtual Molecular Tumor Board (vMTB) focused on 
  improving outcomes and recommending personalized, quality care for cancer patients 
  around the world. Oncominds brings together multidisciplinary teams (MDT) of healthcare 
  professionals from a global peer-to-peer network to consult on each individual patient. 
  Oncominds enables care givers, institutions and patients to get personalized cancer treatment.`;
  const defaultTitle = `${companyName} - Virtual Molecular Tumor Board`;

  const {title} = props;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en"
      }}
      title={title ? `${companyName} - ${title}` : defaultTitle}
      meta = {[
        {'http-equiv': "X-UA-Compatible", content: "IE=9"},
        {'http-equiv': "content-type", content: "text/html; charset=UTF-8"},
        {charset: "UTF-8"},
        {name: "viewport", content: "width=device-width, initial-scale=1, user-scalable=no"},
        {name: "author", content: author},
        {name: "description", content: description},

        // Theme color
        {property: "theme-color", content: themeColor},
        {property: "msapplication-navbutton-color", content: themeColor},
        {property: "apple-mobile-web-app-status-bar-style", content: themeColor}
      ]}

      link={[
        {rel: 'icon', type:"image/x-icon", href: favIco},
        {rel: 'apple-touch-icon', sizes:"180x180", href: favIco180},
        {rel: 'icon', type:"image/png", sizes:"32x32", href: favIco32},
        {rel: 'icon', type:"image/png", sizes:"16x16", href: favIco16}
      ]}
    />
  )
};

export default Head;
