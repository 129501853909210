import {isOmAdmin, isOmLeader, isOmSpecialist} from "../utils";

export const getListItems = (userRoles = []) => {
  const isAdmin = isOmAdmin(userRoles);
  const isLeader = isOmLeader(userRoles);
  const isSpecialist = isOmSpecialist(userRoles);

  const caseStates = [{
    name: "New Cases",
    id: "new"
  }, {
    name: "Data and Initial Analysis Completed",
    id: "analysis_completed"
  }, {
    name: "Leader Assigned",
    id: "leader_assigned"
  }, {
    name: "Ready for Review",
    id: "specialists_assigned"
  }, {
    name: "Meeting Scheduled",
    id: "meeting_scheduled"
  }, {
    name: "Meeting Completed",
    id: "meeting_completed"
  }, {
    name: "Recommendation Completed",
    id: "recommendation_completed"
  }, {
    name: "Report Created",
    id: "report_created"
  }, {
    name: "Report Approved & Published",
    id: "report_published"
  }];

  if(!isAdmin) {
    if(isLeader){
      caseStates.splice(0, 1);
      return caseStates;
    }
  
    if(isSpecialist){
      caseStates.splice(0, 3);
      return caseStates;
    }
  }

  return caseStates;
};
